import { grid } from '@aceandtate/ds';
import styled from 'styled-components';

type WrapperProps = {
  backgroundColor: string;
  color: string;
};

export const WarningBarWrapper = styled.div<WrapperProps>`
  align-items: center;
  background: ${props => props.backgroundColor};
  color: ${props => props.color};
  display: flex;
  justify-content: center;
  padding: ${grid[4]} ${grid[8]};
  text-align: center;

  a {
    text-decoration: underline;
  }

  p {
    margin: 0;
  }
`;
