import React, { useContext, useState } from 'react';
import { Button, InputControl, TextInput, Typography } from '@aceandtate/ds';
import { FormattedMessage, useIntl } from 'react-intl';
import authenticationMessages from 'messages/authentication';
import formValidation from 'messages/formValidation';
import globalErrorMessages from 'messages/globalErrors';
import * as Styles from '../styles';
import { ErrorMessage } from '@hookform/error-message';
import { useForm } from 'react-hook-form';
import useValidationOptions from 'utils/hooks/useValidationOptions';
import { useGetHashedEmail, useUserService } from 'services/userService';
import NotificationsContext from 'features/Notifications/NotificationsContext';
import { accountEvents } from 'tracking';
import { trackGaEvent, trackVariable } from 'tracking/helpers';
import { useRouter } from 'next/router';
import { paths, usePath } from 'paths';
import { parseQuery } from 'utils/helpers/parseQuery';

type FormValues = {
  password: string;
};

type LoginFormProps = {
  email: string;
  onSuccess: (flow: 'easy_login_sent' | 'login') => void;
  disableEasyLogin: boolean;
};

const LoginForm = ({ email, onSuccess, disableEasyLogin }: LoginFormProps) => {
  const {
    register,
    formState: { errors, isSubmitting },
    handleSubmit,
    setError,
    setValue
  } = useForm<FormValues>({ mode: 'onBlur' });
  const pathTo = usePath();
  const { loginUser, requestEasyLogin } = useUserService();
  const [isEasyLoginLoading, setIsEasyLoginLoading] = useState(false);
  const validationOptions = useValidationOptions();
  const notifications = useContext(NotificationsContext.Context);
  const intl = useIntl();
  const getHashedEmail = useGetHashedEmail();
  const router = useRouter();
  const { query } = router;
  const sourceQuery = query.source;

  const handleUnexpectedError = () => {
    notifications.addNotification({
      children: <FormattedMessage {...globalErrorMessages.apiRequestError} />,
      name: 'error-notification',
      variant: 'error'
    });
  };

  const handleInvalidLogin = () => {
    setError('password', {
      message: intl.formatMessage(formValidation.invalidLogin),
      type: 'invalid'
    });
    setValue('password', '');
  };

  const handleUnexpectedEasyLoginError = () => {
    notifications.addNotification({
      children: <FormattedMessage {...authenticationMessages.easyLoginError} />,
      name: 'error-notification',
      variant: 'error'
    });
  };

  const attemptLogin = async (data: FormValues) => {
    const loginResponse = await loginUser({
      email: email,
      password: data.password
    });

    if (loginResponse.error) {
      // Do we need two different error messages for invalid login and unexpected error?
      handleUnexpectedError();
      handleInvalidLogin();
      return;
    } else if (!loginResponse.success) {
      handleInvalidLogin();
      return;
    }
    accountEvents.loginSuccess(loginResponse.data.user);
    // Probably trackVariable should be called in the loginSuccess event ?
    trackVariable('userStatus', 'Login');
    onSuccess('login');
  };

  async function attemptEasyLogin() {
    // // if a redirect query exists, we will always prioritize this in the easy login
    // // if none exists, the redirect path will be used as fallback
    const redirectPath = router.asPath === paths.login ? paths.userProfile : router.asPath;
    const easyLoginResponse = await requestEasyLogin({
      email: email,
      redirectUrl: parseQuery(router.query.redirect) || redirectPath
    });

    if (easyLoginResponse.error || !easyLoginResponse.success) {
      handleUnexpectedEasyLoginError();
      setIsEasyLoginLoading(false);
      return;
    }
    trackVariable('userStatus', 'easyLoginSent');
    getHashedEmail(email).then(res => {
      const from = (() => {
        if (router.asPath.includes(`?auth=`)) {
          // takes precedence over /login page
          return 'drawer';
        } else if (router.asPath.startsWith(paths.login)) {
          return '/login';
        } else {
          return null;
        }
      })();
      trackGaEvent({
        event: 'easyLoginSent',
        from,
        hashM: res.data.email_hash256,
        hashM256: res.data.email_hash256
      });
    });
    setIsEasyLoginLoading(false);
    onSuccess('easy_login_sent');
  }
  return (
    <form onSubmit={handleSubmit(attemptLogin)}>
      <Typography gutterBottom>
        <FormattedMessage {...authenticationMessages.welcomeBackWithEasyLogin} values={{ email: <b>{email}</b> }} />
      </Typography>
      <Styles.FormRow>
        <InputControl id='password'>
          <InputControl.Label>
            <FormattedMessage {...authenticationMessages.enterYourPasswordLabel} />
          </InputControl.Label>
          <TextInput
            fullWidth
            {...register('password', validationOptions.loginPasswordValidation)}
            type='password'
            data-testid='auth.loginPassword'
            autoComplete='password'
          />
          <ErrorMessage
            name='password'
            errors={errors}
            render={({ message }) => (
              <InputControl.Validation data-testid={`password.error`} status='error'>
                {message}
              </InputControl.Validation>
            )}
          />
        </InputControl>
      </Styles.FormRow>
      <Styles.FormRow>
        <Button fullWidth data-testid='auth.loginButton' type='submit' loading={isSubmitting}>
          <FormattedMessage {...authenticationMessages.login} />
        </Button>
      </Styles.FormRow>
      <Styles.FormRow>
        <Styles.LoginLinkBox data-cs-capture>
          <Typography variant='h6' gutterBottom>
            <FormattedMessage {...authenticationMessages.forgotPassword} />
          </Typography>

          {!disableEasyLogin && (
            <Typography gutterBottom>
              <FormattedMessage {...authenticationMessages.forgotPasswordOrEasyLoginDescription} />
            </Typography>
          )}

          <Styles.ButtonRow>
            {!disableEasyLogin && (
              <Button
                variant='outlined'
                data-testid='auth.easyLoginButton'
                onClick={() => attemptEasyLogin()}
                loading={isEasyLoginLoading}
                type='button'
              >
                <FormattedMessage {...authenticationMessages.emailLoginLink} />
              </Button>
            )}
            <Button
              variant='link'
              as='a'
              href={sourceQuery ? `${pathTo(paths.recover)}?source=${sourceQuery}` : pathTo(paths.recover)}
            >
              <FormattedMessage {...authenticationMessages.resetPassword} />
            </Button>
          </Styles.ButtonRow>
        </Styles.LoginLinkBox>
      </Styles.FormRow>
    </form>
  );
};

export default LoginForm;
