import { defineMessages } from 'react-intl';

export default defineMessages({
  addHtoItem: {
    defaultMessage: 'This item has been added to your Home Try-on order. {htoLeft} more to go!',
    id: 'snackbar.hto.AddHtoItemNotification'
  },
  countryDisabled: {
    defaultMessage: `Heads up {br}Unfortunately, we’re no longer shipping to your country {sadFace}. We’re still reachable at {email}, so drop us an email if you have any questions.`,
    id: 'snackbar.countryDisabled'
  },
  htoInYourCountry: {
    defaultMessage: 'New: Home Try-on, order 4 frames to try free at home for 5 days',
    id: 'snackbar.htoInYourCountry'
  },
  productAddedToCart: {
    defaultMessage: 'The product has been added to your cart.',
    id: 'snackbar.productAddedToCart'
  },
  view: {
    defaultMessage: 'View',
    id: 'snackbar.view'
  }
});
