import {
  brandColors,
  Button,
  Chip,
  Drawer,
  Flex,
  grid,
  IconEdit,
  InputControl,
  Modal,
  TextArea,
  TextInput,
  Typography
} from '@aceandtate/ds';
import ecommerceMessages from 'messages/ecommerce';
import giftcardMessages from 'messages/giftcard';
import globalErrorMessages from 'messages/globalErrors';
import productConfiguratorMessages from 'messages/productConfigurator';
import validationMessages from 'messages/formValidation';
import React, { ComponentProps, useContext, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import * as Styles from './styles';
import { format, isFuture, parseISO, addDays } from 'date-fns';
import { ServicesContext } from 'services/context';
import { useCheckIfEmailIsValid, useUserState } from 'services/userService';
import ImageRadioButton from 'components/ImageRadioButton';
import OptimizedCloudinaryImage from 'components/Images/OptimizedCloudinaryImage';
import { useForm } from 'react-hook-form';
import FormattedPrice from 'components/DisplayLabels/FormattedPrice';
import { VariableGiftcardParams } from './types';
import { GiftcardLine } from 'types/solidus';
import InputMask, { InputState } from 'react-input-mask';
import useValidationOptions from 'utils/hooks/useValidationOptions';
import NotificationsContext from 'features/Notifications/NotificationsContext';
import CharacterCounter from 'components/Configurators/GiftcardConfigurator/CharacterCounter';
import { trackArtificialPageView } from 'tracking/helpers';

type DrawerProps = ComponentProps<typeof Drawer>;

type Props = {
  drawerProps: Partial<DrawerProps> & Pick<DrawerProps, 'open' | 'onClose'>;
  selectedPrice: number;
  onSubmit: (data: VariableGiftcardParams) => Promise<any> | void;
  line?: GiftcardLine;
  defaultMode?: 'edit' | 'create' | 'view';
  onDuplicate?: () => Promise<any>;
};

export default function GiftcardConfigurator({
  onSubmit,
  onDuplicate,
  drawerProps,
  selectedPrice,
  line,
  defaultMode = 'create'
}: Props) {
  const { userProfile } = useUserState();
  const intl = useIntl();
  const notifications = useContext(NotificationsContext.Context);
  const { webStore } = useContext(ServicesContext);
  const [confirmCloseOpen, setConfirmCloseOpen] = useState(false);
  const [currentMessage, setCurrentMessage] = useState(line?.gift_card_personalisation?.message || '');
  const checkIfEmailIsValid = useCheckIfEmailIsValid();

  const { handleSubmit, setError, register, formState, getFieldState } = useForm({
    defaultValues: line?.gift_card_personalisation,
    mode: 'onBlur',
    shouldUnregister: true
  });
  const { errors, isDirty } = formState;
  const formValidation = useValidationOptions();
  const { customer } = userProfile || {};
  const lineDate =
    line?.gift_card_personalisation?.delivery_date && new Date(line?.gift_card_personalisation?.delivery_date);
  const [customDate, setCustomDate] = useState(lineDate);
  const isCustomDate = !!customDate;

  const [mode, setMode] = useState<'create' | 'edit' | 'view'>(defaultMode);
  const isEditDisabled = mode === 'view';

  useEffect(() => {
    if (!drawerProps.open) {
      return;
    }

    switch (mode) {
      case 'create':
        trackArtificialPageView('giftCardCustomisation');
        break;
      case 'edit':
        trackArtificialPageView('giftCardCustomisationEdit');
        break;
      case 'view':
        trackArtificialPageView('giftCardCustomisationReview');
        break;
      default:
        break;
    }
  }, [drawerProps.open, mode]);

  function normalizeFormData(data: VariableGiftcardParams['gift_card_personalisation']) {
    const { delivery_date: send_date } = data;
    const delivery_date = send_date ? new Date(send_date).toString() : null;

    const gift_card_personalisation: VariableGiftcardParams['gift_card_personalisation'] = {
      ...data,
      delivery_date
    };
    return { gift_card_personalisation, user_entered_price: selectedPrice };
  }

  async function inlineEmailValidation(email: string, field: Parameters<typeof setError>[0]) {
    const isRoutable = await checkIfEmailIsValid(email);

    if (!isRoutable.success) {
      setError(field, {
        message: intl.formatMessage(validationMessages.invalidEmail),
        type: 'invalid'
      });
    }
  }

  async function saveAndClose() {
    setConfirmCloseOpen(false);
    handleSubmit(async data => {
      const res = await onSubmit(normalizeFormData(data));
      res?.success && drawerProps.onClose();
      setMode('view');
    })();
  }

  async function handleDuplicate() {
    const res = await onDuplicate();
    if (res.success) {
      drawerProps.onClose();
    } else {
      notifications.addNotification({
        children: <FormattedMessage {...globalErrorMessages.somethingWentWrong} />,
        name: 'error-duplicating-giftcard-order',
        sticky: true,
        title: <FormattedMessage {...globalErrorMessages.oops} />,
        variant: 'error'
      });
    }
  }

  function renderActionBarChildren() {
    if (mode === 'view') {
      return (
        <Flex justifyContent='space-between' alignItems='center' gap={grid[12]} style={{ width: '100%' }}>
          <Button variant='link' onClick={handleDuplicate}>
            <FormattedMessage {...giftcardMessages.giftcardDuplicateOrder} />
          </Button>
          <Button variant='outlined' onClick={() => setMode('edit')}>
            <IconEdit color={brandColors.dark50} />
            <FormattedMessage {...giftcardMessages.giftcardEditButton} />
          </Button>
        </Flex>
      );
    } else if (mode === 'edit') {
      return (
        <Flex justifyContent='flex-end' style={{ width: '100%' }}>
          <Button loading={formState.isSubmitting} onClick={saveAndClose}>
            <FormattedMessage {...giftcardMessages.giftcardSaveEditsButton} />
          </Button>
        </Flex>
      );
    } else {
      return (
        <Flex justifyContent='space-between' alignItems='center' style={{ width: '100%' }}>
          <div>
            <Typography variant='bodyS' color={brandColors.dark50}>
              <FormattedMessage {...ecommerceMessages.subtotal} />
            </Typography>
            <FormattedPrice price={selectedPrice} currency={webStore.config.currency} />
          </div>
          <Button
            loading={formState.isSubmitting}
            color='accent'
            onClick={handleSubmit(data => onSubmit(normalizeFormData(data)))}
            data-testid='giftcard.add-personalised-to-cart'
          >
            <FormattedMessage {...productConfiguratorMessages.addToCartButton} />
          </Button>
        </Flex>
      );
    }
  }

  function renderValidation(key: Parameters<typeof getFieldState>[0]) {
    const currentError = errors?.[key];

    if (currentError) {
      return (
        <InputControl.Validation status={currentError.type === 'warn' ? 'warning' : 'error'}>
          {currentError.message}
        </InputControl.Validation>
      );
    } else {
      return null;
    }
  }

  return (
    <>
      <Modal
        open={confirmCloseOpen}
        content={
          <Flex flexDirection='column' gap={grid[12]}>
            <Typography variant='h5'>
              <FormattedMessage {...giftcardMessages.giftcardCloseModalTitle} />
            </Typography>
            <Typography>
              <FormattedMessage {...giftcardMessages.giftcardCloseModalDescription} />
            </Typography>
            <Flex gap={grid[12]}>
              <Button onClick={saveAndClose}>
                <FormattedMessage {...giftcardMessages.saveAndCloseButton} />
              </Button>
              <Button
                onClick={() => {
                  setConfirmCloseOpen(false);
                  drawerProps.onClose();
                }}
                color='error'
              >
                <FormattedMessage {...giftcardMessages.abandonChangesButton} />
              </Button>
            </Flex>
          </Flex>
        }
      />
      <Drawer
        width='700px'
        position='right'
        actionBarChildren={renderActionBarChildren()}
        headerProps={{
          color: 'primary',
          title:
            mode === 'create'
              ? intl.formatMessage(giftcardMessages.giftcardCreateYourPersonalisedGift)
              : intl.formatMessage(giftcardMessages.giftcardEditPersonalisation),
          variant: 'small'
        }}
        {...drawerProps}
        onClose={() => {
          mode === 'edit' && isDirty ? setConfirmCloseOpen(true) : drawerProps.onClose();
        }}
      >
        <form onSubmit={e => e.preventDefault()}>
          <Styles.GiftcardDrawerInset>
            {mode === 'create' && (
              <Styles.ProductHighlightBlock>
                <Typography variant='h6' as='p'>
                  <FormattedMessage {...giftcardMessages.giftcardCallout} />
                </Typography>
              </Styles.ProductHighlightBlock>
            )}

            <Styles.GiftcardSection>
              <Typography variant='h5' gutterBottom>
                <FormattedMessage {...giftcardMessages.giftcardChooseDesign} />
              </Typography>
              <Flex gap={grid[12]} justifyContent='flex-start' style={{ flexWrap: 'wrap' }}>
                {imageOptions.map((option, i) => {
                  const croppedUrl = option.url.replace('/image/upload', '/image/upload/ar_2.3,c_fill');

                  return (
                    <ImageRadioButton
                      {...register('image_name')}
                      withCheck
                      disabled={isEditDisabled}
                      key={option.image_name}
                      data-testid={`image-picker-${option.image_name}`}
                      defaultChecked={i === 0 ? true : null}
                      value={option.image_name}
                    >
                      <OptimizedCloudinaryImage
                        src={croppedUrl}
                        width={280}
                        height={120}
                        alt={`Ace & Tate | Giftcard design option ${i + 1}`}
                        style={{
                          width: '100%',
                          height: 'auto'
                        }}
                      />
                    </ImageRadioButton>
                  );
                })}
              </Flex>
            </Styles.GiftcardSection>

            <Styles.GiftcardSection>
              <Typography variant='h5' gutterBottom>
                <FormattedMessage {...giftcardMessages.giftcardForWho} />
              </Typography>
              <div>
                <Styles.InputControlDense
                  disabled={isEditDisabled}
                  required
                  id='gc.recipient_name'
                  style={{ flexGrow: 1 }}
                >
                  <InputControl.Label>
                    <FormattedMessage {...giftcardMessages.giftcardRecipientNameLabel} />
                  </InputControl.Label>
                  <TextInput
                    {...register('recipient_name', formValidation.requiredField)}
                    fullWidth
                    placeholder='Jon Doe'
                    data-testid='giftcard.recipient-name'
                  />
                  {renderValidation('recipient_name')}
                </Styles.InputControlDense>
                <Styles.InputControlCaption>
                  <FormattedMessage {...giftcardMessages.giftcardRecipientNameCaption} />
                </Styles.InputControlCaption>
              </div>
              <div>
                <InputControl disabled={isEditDisabled} required id='gc.recipient_email'>
                  <InputControl.Label>
                    <FormattedMessage {...giftcardMessages.giftcardRecipientEmailLabel} />
                  </InputControl.Label>
                  <TextInput
                    {...register('recipient_email', {
                      ...formValidation.emailValidation,
                      onBlur: e => inlineEmailValidation(e.target.value, 'recipient_email')
                    })}
                    type='email'
                    fullWidth
                    placeholder='jon@doe.com'
                    data-testid='giftcard.recipient-email'
                  />
                  {renderValidation('recipient_email')}
                </InputControl>
              </div>
              <div>
                <Styles.InputControlDense disabled={isEditDisabled} id='gc.message'>
                  <InputControl.Label>
                    <FormattedMessage {...giftcardMessages.giftcardWriteSomethingNice} />
                  </InputControl.Label>
                  <TextArea
                    rows={4}
                    {...register('message', {
                      ...formValidation.maxLengthValidation(500),
                      onChange: e => setCurrentMessage(e.target.value)
                    })}
                    fullWidth
                    placeholder='Dear Jon...'
                    data-testid='giftcard.message'
                  />
                  {renderValidation('message')}
                </Styles.InputControlDense>
                {mode !== 'view' && <CharacterCounter count={currentMessage?.length || 0} limit={500} />}
              </div>
            </Styles.GiftcardSection>

            <Styles.GiftcardSection>
              <Typography variant='h5' gutterBottom>
                <FormattedMessage {...giftcardMessages.giftcardFromWho} />
              </Typography>
              <InputControl disabled={isEditDisabled} required id='gc.sender_name'>
                <InputControl.Label>
                  <FormattedMessage {...giftcardMessages.giftcardSenderNameLabel} />
                </InputControl.Label>
                <TextInput
                  {...register('sender_name', formValidation.requiredField)}
                  fullWidth
                  defaultValue={customer && `${customer.firstname} ${customer.lastname}`}
                  data-testid='giftcard.sender-name'
                />
                {renderValidation('sender_name')}
              </InputControl>
            </Styles.GiftcardSection>

            <Styles.GiftcardSection>
              <Typography variant='h5' gutterBottom>
                <FormattedMessage {...giftcardMessages.giftcardSendWhen} />
              </Typography>
              <Flex flexDirection='column' gap={grid[12]}>
                <Flex gap={grid[12]}>
                  <Chip
                    disabled={isEditDisabled}
                    label={intl.formatMessage(giftcardMessages.giftcardSendNowLabel)}
                    onClick={() => setCustomDate(null)}
                    active={!isCustomDate}
                    style={{ flexGrow: 1 }}
                  />
                  <Chip
                    disabled={isEditDisabled}
                    label={intl.formatMessage(giftcardMessages.giftcardPickDateLabel)}
                    onClick={() => setCustomDate(addDays(new Date(), 1))}
                    active={isCustomDate}
                    style={{ flexGrow: 1 }}
                    data-testid='giftcard.custom-date-button'
                  />
                </Flex>

                {isCustomDate && (
                  <div>
                    <InputMask
                      disabled={isEditDisabled}
                      mask='9999-99-99'
                      maskChar='_'
                      defaultValue={format(customDate, 'yyyy-MM-dd')}
                    >
                      {
                        ((inputProps: InputState) => {
                          return (
                            <Styles.InputControlDense disabled={isEditDisabled} required id='gc.delivery_date'>
                              <InputControl.Label>
                                <FormattedMessage {...giftcardMessages.giftcardDateLabel} />
                              </InputControl.Label>
                              <TextInput
                                fullWidth
                                {...register('delivery_date', {
                                  ...formValidation.requiredField,
                                  validate: value => {
                                    if (value && !isFuture(parseISO(value))) {
                                      return intl.formatMessage(giftcardMessages.dateErrorMessage);
                                    }
                                    return true;
                                  }
                                })}
                                {...inputProps}
                                data-testid='giftcard.delivery-date-input'
                              />
                              {renderValidation('delivery_date')}
                            </Styles.InputControlDense>
                          );
                        }) as any
                      }
                    </InputMask>
                    {!errors.delivery_date && (
                      <Styles.InputControlCaption>
                        <FormattedMessage {...giftcardMessages.giftcardDateTimeSendCaption} />
                      </Styles.InputControlCaption>
                    )}
                  </div>
                )}
              </Flex>
            </Styles.GiftcardSection>
          </Styles.GiftcardDrawerInset>
        </form>
      </Drawer>
    </>
  );
}

type ImageOption = {
  url: string;
  image_name: string;
};

const imageOptions: ImageOption[] = [
  {
    image_name: 'GiftCard-3',
    url: 'https://images.aceandtate.com/image/upload/v1666863843/giftcards/drawer-select/AT_GiftCard-3-renamed.png'
  },
  {
    image_name: 'GiftCard-2',
    url: 'https://images.aceandtate.com/image/upload/v1666863843/giftcards/drawer-select/AT_GiftCard-2.png'
  },
  {
    image_name: 'GiftCard-1',
    url: 'https://images.aceandtate.com/image/upload/v1666863843/giftcards/drawer-select/AT_GiftCard-1-renamed.png'
  },
  {
    image_name: 'GiftCard-4',
    url: 'https://images.aceandtate.com/image/upload/v1666863843/giftcards/drawer-select/AT_GiftCard-4.png'
  }
];
