export const fontSizes = {
  large: {
    fontSize: 25,
    lineHeight: 40
  },
  medium: {
    fontSize: 18,
    lineHeight: 32
  },
  micro: {
    fontSize: 14,
    lineHeight: 24
  },
  nano: {
    fontSize: 12,
    lineHeight: 20
  },
  small: {
    fontSize: 16,
    lineHeight: 28
  }
};
