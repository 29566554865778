import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';

type Props = {
  iso: string;
  size?: string;
};
const sizeMap = {
  large: 30,
  medium: 20,
  small: 12
};

const Flag = (props: Props) => {
  const { iso = 'nl', size = 'medium' } = props;

  return (
    <LazyLoadImage
      src={`/static/flags/${iso.toLowerCase()}.svg`}
      width={sizeMap[size]}
      key={`${iso}-flag`}
      style={{ borderRadius: 2 }}
    />
  );
};

export default Flag;
