import styled from 'styled-components';
import { navHeightMobile } from 'styles';
import { breakpointRules, brandColors, grid } from '@aceandtate/ds';

export const SecondaryMobileMenuContainer = styled.div`
  display: flex;
  flex-flow: row;
  height: ${navHeightMobile}px;
  justify-content: space-around;
  align-items: center;
  padding: 0 ${grid[12]};
  border-top: 1px solid ${brandColors.dark10};

  @media ${breakpointRules.laptop} {
    display: none;
  }
`;
