import { defineMessages } from 'react-intl';

export const general = defineMessages({
  apply: {
    defaultMessage: 'Apply',
    id: 'intl.action.apply'
  },
  changeCountry: {
    defaultMessage: 'Shipping Country',
    id: 'intl.shippingCountry'
  },
  changeLanguage: {
    defaultMessage: 'language',
    id: 'intl.changeLanguage'
  },
  noShippingCountry: {
    defaultMessage: 'No shipping',
    id: 'intl.noShippingCountry'
  }
});

export const country = defineMessages({
  AUSTRIA: {
    defaultMessage: 'Austria',
    id: 'country.AUSTRIA'
  },
  BELGIUM: {
    defaultMessage: 'Belgium',
    id: 'country.BELGIUM'
  },
  'CZECH REPUBLIC': {
    defaultMessage: 'Czech Republic',
    id: 'country.CZECH_REPUBLIC'
  },
  DENMARK: {
    defaultMessage: 'Denmark',
    id: 'country.DENMARK'
  },
  FINLAND: {
    defaultMessage: 'Finland',
    id: 'country.FINLAND'
  },
  FRANCE: {
    defaultMessage: 'France',
    id: 'country.FRANCE'
  },
  GERMANY: {
    defaultMessage: 'Germany',
    id: 'country.GERMANY'
  },
  IRELAND: {
    defaultMessage: 'Ireland',
    id: 'country.IRELAND'
  },
  ITALY: {
    defaultMessage: 'Italy',
    id: 'country.ITALY'
  },
  LUXEMBOURG: {
    defaultMessage: 'Luxembourg',
    id: 'country.LUXEMBOURG'
  },
  NETHERLANDS: {
    defaultMessage: 'Netherlands',
    id: 'country.NETHERLANDS'
  },
  POLAND: {
    defaultMessage: 'Poland',
    id: 'country.POLAND'
  },
  PORTUGAL: {
    defaultMessage: 'Portugal',
    id: 'country.PORTUGAL'
  },
  ROMANIA: {
    defaultMessage: 'Romania',
    id: 'country.ROMANIA'
  },
  SPAIN: {
    defaultMessage: 'Spain',
    id: 'country.SPAIN'
  },
  SWEDEN: {
    defaultMessage: 'Sweden',
    id: 'country.SWEDEN'
  },
  SWITZERLAND: {
    defaultMessage: 'Switzerland',
    id: 'country.SWITZERLAND'
  },
  'THE NETHERLANDS': {
    defaultMessage: 'Netherlands',
    id: 'country.NETHERLANDS'
  },
  'UNITED KINGDOM': {
    defaultMessage: 'United Kingdom',
    id: 'country.UNITED_KINGDOM'
  },
  'UNITED STATES': {
    defaultMessage: 'United States',
    id: 'country.UNITED_STATES'
  }
});

export const language = defineMessages({
  da: {
    defaultMessage: 'Danish',
    id: 'language.DANISH'
  },
  de: {
    defaultMessage: 'German',
    id: 'language.GERMAN'
  },
  en: {
    defaultMessage: 'English',
    id: 'language.ENGLISH'
  },
  es: {
    defaultMessage: 'Spanish',
    id: 'language.SPANISH'
  },
  fr: {
    defaultMessage: 'French',
    id: 'language.FRENCH'
  },
  nl: {
    defaultMessage: 'Dutch',
    id: 'language.DUTCH'
  },
  sv: {
    defaultMessage: 'Swedish',
    id: 'language.SWEDISH'
  }
});
