import { trackGaEvent } from '../helpers';

/*
    3.2 Footer Interactions
    When the user clicks on elements of the footer, trigger the following event.
    Please include the items under “CUSTOMER SERVICE”, “OUR COMPANY” and also the items on the footer-wrapper such as privacy, cookie policy etc.

    3.4 Social Media
    When the user clicks out to any social network whose icons located at the bottom of the page. This event covers click outs on Facebook, Twitter, Instagram, Snapchat,  Soundcloud and Medium.

    3.5 Contact Link
    When the user clicks out to any contact link those located in the lower left of the page. This event covers click outs on Call us, WhatsApp, F.A.Q, Email, and Facebook.

    Chat status
    When a user starts a ZenDesk chat or ends one. The status can be either 'start' or 'end'

*/

export function chatStatus(status: 'start' | 'end') {
  trackGaEvent({
    event: 'chatStatus',
    status
  });
}

export function footerInteraction(clickedItemName: string) {
  trackGaEvent({
    clickedItemName,
    event: 'footerInteraction'
  });
}

export function getInTouch(contactLink: string) {
  trackGaEvent({
    contactLink,
    event: 'getInTouch'
  });
}

export function socialClickout(socialNetworkName: string) {
  trackGaEvent({
    event: 'socialClickout',
    socialNetworkName
  });
}
