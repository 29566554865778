import { NextRouter } from 'next/router';
import baseNames from 'plugins/baseNames';

export function stripURLLocale(urlToStrip: string) {
  const locales = Object.keys(baseNames);

  let url: URL;
  try {
    url = new URL(urlToStrip);
  } catch (e) {
    return urlToStrip;
  }

  const { pathname } = url;
  const findLocale = locales.find(loc => {
    const [, locale] = pathname.split('/');
    return locale === loc;
  });

  if (!findLocale) {
    return urlToStrip;
  }

  const [, , ...path] = pathname.split('/');
  url.pathname = path.join('/');

  return url.toString();
}

// function to get the current path server side
export function getCurrentPath(asPath: NextRouter['asPath']) {
  return asPath?.split('?')[0].split('#')[0] || '/';
}
