import { useRouter } from 'next/router';
import * as Sentry from '@sentry/nextjs';
import { useCartService } from 'services/cartService';
import { paths } from 'paths';
import { ProductVariant, PrescriptionType, ProductType, Variant } from 'types/torii';
import { VariableGiftcardParams } from 'components/Configurators/GiftcardConfigurator/types';
import { LensColor, LensUpgradeType } from 'types/solidus';
import { isSunny } from 'utils/product/productHelpers';

export type UseAddToCartProps = {
  additionalProducts?: ProductVariant | ProductVariant[] | null;
  polarised?: boolean;
  /** @deprecated */
  premiumLenses?: boolean;
  lenses_production_type?: string;
  lens_upgrade_type?: LensUpgradeType;
  prescriptionType?: PrescriptionType;
  productType: ProductType;
  /** Default is true */
  redirectToCart?: boolean;
  lensColor?: LensColor;
  variant: Variant;
} & VariableGiftcardParams;

export default function useAddToCart() {
  const cartService = useCartService();
  const router = useRouter();

  return async (props: UseAddToCartProps) => {
    const {
      additionalProducts = null,
      polarised = null,
      premiumLenses = null,
      prescriptionType = null,
      productType = null,
      redirectToCart = true,
      user_entered_price = null,
      gift_card_personalisation = null,
      lenses_production_type = null,
      lens_upgrade_type = null,
      variant
    } = props;

    let { lensColor = null } = props;

    if (isSunny(variant) && productType === 'frame') {
      lensColor = 'sunny';
    }

    let response;

    const mainProductToAdd = {
      gift_card_personalisation,
      is_hto: false,
      lens_color: lensColor,
      lenses_production_type,
      lens_upgrade_type,
      polarised: polarised,
      /** @deprecated */
      premium_lenses: premiumLenses,
      prescription_type: prescriptionType,
      user_entered_price,
      variant_id: variant.id
    };

    if (additionalProducts && Array.isArray(additionalProducts)) {
      const otherProductsToAdd = additionalProducts.map(x => ({
        variant_id: x.id
      }));

      response = await cartService.addToCart([mainProductToAdd, ...otherProductsToAdd]);
    } else {
      response = await cartService.addToCart(mainProductToAdd);
    }

    if (response.error) {
      window.alert(`Something went wrong: ${response.error}`);
      const err = new Error(`Something went wrong when adding product to cart: ${response.error}`);
      Sentry.captureException(err);
    } else {
      if (redirectToCart) {
        router.push(paths.cart);
      } else {
        return response;
      }
    }
  };
}
