import styled, { css } from 'styled-components';
import { Typography } from '@aceandtate/ds';
import { fadeUpAnimation } from 'styles';
import { MediaProps } from './types';
import ATPlayButton from './images/AT-Play-Button.svg';

type VisibilityProps = {
  'data-media'?: string;
  hidden?: boolean;
};

export const Text = styled(Typography)<VisibilityProps>`
  /* always display Title if media is image or if isVisible is true for video */
  opacity: ${props => (!props.hidden || props['data-media'] === 'image' ? 1 : 0)};
  transition: opacity ease-in-out 0.2s;
`;

export const PlayIcon = styled.img.attrs({ src: ATPlayButton })<VisibilityProps>`
  opacity: ${props => (!props.hidden ? '' : 0)} !important;
  transition: opacity ease-in-out 0.2s;
`;

const fitContainer = css`
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
`;

type MediaQueryProps = {
  media: string;
};

export const MediaQuery = styled.span<MediaQueryProps>`
  display: none;
  width: 100%;
  @media ${props => props.media} {
    display: block;
  }
`;

export const MediaWrapper = styled.span<MediaProps>`
  align-items: center;
  cursor: ${props => (props.isManualPlay ? 'pointer' : 'unset')};
  display: flex;
  flex-direction: column;
  justify-content: center;
  ${props => (props.fitContainer ? fitContainer : '')}
  width: 100%;
  max-height: ${props => props.maxHeight ?? 'auto'};

  img,
  video {
    display: block;
    height: ${props => (props.fitContainer ? '100%' : 'auto')};
    width: 100%;
    object-fit: cover;
    ${props =>
      props.animate &&
      css`
        animation: 0.6s ease-out 0.3s 1 ${fadeUpAnimation};
        animation-fill-mode: both;
      `}
  }

  ${MediaQuery} {
    height: ${props => (props.fitContainer ? '100%' : 'auto')};
  }

  /* The Text component will only be displayed if a title is set in Contentful */
  ${Text} {
    color: ${props => props.titleTextColor || 'inherit'};
    text-align: center;
    z-index: 1;
  }

  /* The PlayIcon will only be displayed if the video isn't autoplayed */
  ${PlayIcon} {
    border-radius: 50%;
    color: ${props => props.playButtonColor || 'white'};
    cursor: pointer;
    margin-top: 20px;
    transform: scale(2);
    max-height: 120px;
    max-width: 120px;
    width: 6vw;
    height: 6vw;
    z-index: 1;
    opacity: ${props => (!props.hidden ? 0.75 : 0)};
  }

  :hover ${PlayIcon} {
    opacity: ${props => (!props.hidden ? 1 : 0)};
  }
`;

export const Container = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: absolute;
`;
