import React, { useContext, ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';
import { ProductType, Variant } from 'types/torii';
import NotificationsContext from 'features/Notifications/NotificationsContext';
import { paths, usePath } from 'paths';
import { WishlistContext, WishlistContextProps, RenderChildProps } from '.';
import config from 'iris.config';
import { ViewType } from './types';
import messages from './messages';
import { Toast } from '@aceandtate/ds';
import Link from 'next/link';

type WishlistActionProps = {
  item?: Variant | Variant[];
  productType?: ProductType;
  navigateToWishlist?: () => void;
  children: (props: RenderChildProps) => ReactNode;
  view?: ViewType;
};

const WishlistAction = (props: WishlistActionProps) => {
  const pathTo = usePath();
  const { item, children, productType, view } = props;

  const notifications = useContext(NotificationsContext.Context);
  const messageValues = item => `${item.name} ${item.displayAttributes.color}`;

  const getOnToggle = (wishlistContext: WishlistContextProps) => {
    if (!item) {
      // noop
      return () => {};
    }

    const { addToWishlist, hasItem, removeFromWishlist } = wishlistContext;

    if (hasItem(item) && !Array.isArray(item)) {
      return () => {
        removeFromWishlist({ from: view, item: item as Variant, productType });
        notifications.addNotification({
          action: closeFn => (
            <Toast.Action
              altText='Undo'
              onClick={() => {
                closeFn();
                addToWishlist({ from: view, item: item as Variant, productType });
              }}
            >
              <FormattedMessage {...messages.actionOnRemovedFrom} />
            </Toast.Action>
          ),
          children: (
            <FormattedMessage {...messages.wishlistRemovedFrom} values={{ displayName: messageValues(item) }} />
          ),
          name: 'wishlist-remove-from'
        });
      };
    }

    return () => {
      addToWishlist({ from: view, item, productType });
      notifications.addNotification({
        action: closeNotification => (
          <Toast.Action altText='View' onClick={closeNotification}>
            <Link href={pathTo(paths.wishlist)}>
              <FormattedMessage {...messages.actionOnAddedTo} />
            </Link>
          </Toast.Action>
        ),
        children:
          item instanceof Array ? (
            <FormattedMessage {...messages.wishlistAllAdded} />
          ) : (
            <FormattedMessage {...messages.wishlistAddedTo} values={{ displayName: messageValues(item) }} />
          ),
        name: 'wishlist-add-to'
      });
    };
  };

  const getRenderProps = (wishlistContext: WishlistContextProps) => {
    const { count, hasItem, wishlist } = wishlistContext;

    return {
      active: !!item && hasItem(item),
      count,
      onToggle: getOnToggle(wishlistContext),
      wishlist
    };
  };

  if (!config.wishlistEnabled) {
    return null;
  }

  return <WishlistContext.Consumer>{context => children(getRenderProps(context))}</WishlistContext.Consumer>;
};

export default WishlistAction;
