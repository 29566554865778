import { VariantHitProps } from './types';

export function formatDate(date: string) {
  const options: Intl.DateTimeFormatOptions = { day: 'numeric', month: 'long', weekday: 'long', year: 'numeric' };
  const formatter = new Intl.DateTimeFormat('en-US', options);
  return formatter.format(new Date(date));
}

export type NormalizedVariantHits = VariantHitProps & { imageUrl: string };

export function normalizeVariantHits(hits: VariantHitProps[], countryCode?: string): NormalizedVariantHits[] {
  const normalizedHits = hits.map(hit => ({
    ...hit,
    imageUrl: hit.image_url
  }));

  if (countryCode) {
    return normalizedHits.filter(({ store_codes }) => (store_codes ? store_codes.includes(countryCode) : true));
  }
  return normalizedHits;
}
