import { Button, Typography } from '@aceandtate/ds';
import React, { useContext, useEffect, useState } from 'react';
import { ServicesContext } from 'services/context';
import { pupillaryDistanceTool } from 'tracking';
import { PdToolContext } from 'features/PdTool';
import * as utils from 'utils/helpers/scripts';
import * as config from './config';
import type { Analytics } from '../types';
import messages from './messages';
import { FormattedMessage } from 'react-intl';
import prescriptionMessages from 'messages/prescription';
import styled, { CSSProperties } from 'styled-components';

const ResultWrapper = styled.div`
  color: ${({ color }) => color};
`;

const initializeSdk = utils.once(async (...options: Parameters<typeof window.glasseson.init>) => {
  await utils.loadScript(config.pdSdkUrl);
  await window.glasseson.init(...options);

  window.glasseson.setResultCallback(() => {
    pupillaryDistanceTool.flowEnd();
  });

  window.glasseson.setAnalyticsCallback((value: Analytics) => {
    pupillaryDistanceTool.flowStateUpdate(value.title);
  });
});

const startPdFlow = () => {
  if (window.glasseson) {
    window.glasseson.open('pd');
  }
};

type Props = {
  children: React.ReactNode;
  buttonColor?: React.ComponentProps<typeof Button>['color'];
  buttonVariant?: React.ComponentProps<typeof Button>['variant'];
  resultColor?: CSSProperties['color'];
};

const PupillaryDistance = ({ children, buttonVariant, buttonColor, resultColor = 'black', ...props }: Props) => {
  const [status, setStatus] = useState('uninitialized');
  const { lang } = useContext(ServicesContext).locale;
  const { updatePd, pd } = useContext(PdToolContext);

  useEffect(() => {
    const pd = sessionStorage.getItem('pd');
    if (pd) {
      updatePd(pd);
    }
    addContainer();

    return () => removeContainer();
  }, []);

  const addContainer = () => {
    const container = document.getElementById('glasseson');
    const body = document.querySelector('body');
    if (!container && body) {
      const container = document.createElement('div');
      container.setAttribute('id', 'glasseson');
      body.appendChild(container);
    }
  };

  const removeContainer = () => {
    const container = document.getElementById('glasseson');
    if (container && container.parentNode) {
      container.parentNode.removeChild(container);
    }
  };

  const startFlow = async e => {
    e.preventDefault();
    setStatus('loading');
    await startPreload();
    startPdFlow();
    pupillaryDistanceTool.flowStart();
    setStatus('loaded');
  };

  const startPreload = async () => {
    await initializeSdk(config.clientId, config.serverUrl, config.getOptions(lang));

    window.glasseson.setCloseCallback(result => {
      if (result?.data?.pd) {
        sessionStorage.setItem('pd', result.data.pd);
        updatePd(result.data.pd);
      }
    });
  };

  if (pd?.left && pd?.right) {
    return (
      <ResultWrapper color={resultColor}>
        <Typography variant='h4' gutterBottom>
          <FormattedMessage {...messages.yourPdIs} /> {parseInt(pd.right) + parseInt(pd.left)} mm
        </Typography>
        <Typography>
          <FormattedMessage {...prescriptionMessages.rightEye} />: {parseInt(pd.right).toFixed(2)} mm
        </Typography>
        <Typography>
          <FormattedMessage {...prescriptionMessages.leftEye} />: {parseInt(pd.left).toFixed(2)} mm
        </Typography>

        <Button
          variant={buttonVariant}
          color={buttonColor}
          onClick={startFlow}
          onMouseEnter={() => startPreload()}
          loading={status === 'loading'}
        >
          <FormattedMessage {...messages.measurePdAgain} />
        </Button>
      </ResultWrapper>
    );
  }

  return (
    <Button
      variant={buttonVariant}
      color={buttonColor}
      {...props}
      onClick={startFlow}
      onMouseEnter={() => startPreload()}
      loading={status === 'loading'}
    >
      {children}
    </Button>
  );
};

export default PupillaryDistance;
