import { PRODUCT_TAXONS } from 'globalConstants/productTaxonomies';
import { Product } from 'types/torii';

export const isRegularGlasses = (currentVariant: Product['currentVariant']) =>
  currentVariant.filterOptions.taxons?.includes(PRODUCT_TAXONS.REGULAR);

export const isKidsGlasses = (currentVariant: Product['currentVariant']) =>
  currentVariant.filterOptions.taxons?.includes(PRODUCT_TAXONS.KIDS);

// TODO: update this fn based on PRODUCT_TAXONS, once ecom(?) tags skus properly
export const isSportsGlasses = (currentVariant: Product['currentVariant']) =>
  currentVariant.sku.includes('benchpress-bobby-') ||
  currentVariant.sku.includes('deadlift-tom-') ||
  currentVariant.sku.includes('home-run-quinn-') ||
  currentVariant.filterOptions.taxons?.includes(PRODUCT_TAXONS.SPORT_GLASSES);

/** TODO: update this fn based on PRODUCT_TAXONS, once ecom(?) tags skus properly */
export const isSkiGoggles = (currentVariant: Product['currentVariant']) =>
  currentVariant.filterOptions.taxons?.includes(PRODUCT_TAXONS.SKI_GOGGLES);

export const isRawCollection = (currentVariant: Product['currentVariant']) =>
  ['andre-granite-sun', 'vic-sage-sun', 'peggy-tangerine-sun'].includes(currentVariant.sku);

export const isGanniCollab2024 = (currentVariant: Product['currentVariant']) =>
  [
    'dakota-ganni-cherry-bomb-w-cloth-case-sun',
    'dakota-ganni-kaffe-w-cloth-case-sun',
    'dakota-ganni-liquorice-w-cloth-case-sun',
    'kayla-ganni-kaffe-w-cloth-case-sun',
    'kayla-ganni-liquorice-w-cloth-case-sun',
    'sadie-ganni-cherry-bomb-w-cloth-case-sun',
    'sadie-ganni-kaffe-w-cloth-case-sun',
    'sadie-ganni-liquorice-w-cloth-case-sun',
    'twiggy-ganni-cherry-bomb-w-cloth-case-sun',
    'twiggy-ganni-kaffe-w-cloth-case-sun',
    'noel-ganni-liquorice-w-cloth-case-sun',
    'noel-ganni-moondust-w-cloth-case-sun'
  ].includes(currentVariant.sku);

export const isNotReturnable = (currentVariant: Product['currentVariant']) =>
  currentVariant.filterOptions.taxons?.includes(PRODUCT_TAXONS.NOT_RETURNABLE);

export const isArchiveSale = (currentVariant: Product['currentVariant']) =>
  currentVariant.filterOptions.taxons?.includes(PRODUCT_TAXONS.ARCHIVE_SALE);

export default {
  isRegularGlasses,
  isKidsGlasses,
  isSkiGoggles,
  isSportsGlasses,
  isRawCollection,
  isGanniCollab2024,
  isNotReturnable
};
