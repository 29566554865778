import { grid, brandColors, uiColors, borderRadius } from '@aceandtate/ds';
import styled from 'styled-components';

export const ErrorMessage = styled.div`
  background: ${uiColors.errorLight};
  color: ${uiColors.error};
  padding-left: ${grid[8]};
  border-radius: ${borderRadius.medium};
`;

export const LoaderWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

export const TableScroll = styled.div`
  width: 100%;
  overflow-x: scroll;
  padding-bottom: ${grid[12]};
`;

export const Table = styled.table`
  border-collapse: collapse;
  margin: ${grid[40]} 0 ${grid[12]};
  min-width: 100%;
  border-spacing: 2px;
  border-color: ${brandColors.dark20};

  ul {
    margin-top: 0;
  }

  tr {
    text-align: left;
    vertical-align: middle;
    padding-bottom: ${grid[8]};
    border-bottom: 1px solid ${brandColors.dark20};

    &:last-child {
      border-bottom: none;
    }
  }

  th {
    padding-bottom: ${grid[12]};
  }

  td {
    height: auto;
    border-right: 1px solid ${brandColors.dark20};
    padding: ${grid[24]} ${grid[12]};

    &:first-child {
      padding-left: 0;
    }

    &:last-child {
      border-right: none;
    }
  }
`;
