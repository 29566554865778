export const GENDER = {
  MEN: 'male',
  UNISEX: 'unisex',
  WOMEN: 'female'
};

export const frameConfig: {
  regular: {
    allowsLensColor: boolean;
    showPrescriptionType: boolean;
  };
} = {
  regular: {
    allowsLensColor: true,
    showPrescriptionType: true
  }
} as const;
