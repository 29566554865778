import React from 'react';
import Link from 'next/link';
import * as Styles from './styles';
import * as MenuStyles from '../../styles';
import * as Types from '../../types';
import { headerInteraction } from 'tracking';

type Props = {
  menu: Types.Menu;
  datalayerL1?: string;
};

const MediaColumn = (props: Props) => {
  const {
    menu: { media, text, caption, urlRoute, path, datalayerId },
    datalayerL1
  } = props;

  const mediaMap = {
    description: media.description,
    file: {
      contentType: media.contentType,
      details: {
        image: {
          height: media.height,
          width: media.width
        },
        size: media.size
      },
      fileName: media.fileName,
      url: media.url.replace('https:', '')
    },
    title: media.title
  };

  return (
    <MenuStyles.ColumnContainer
      onClick={() =>
        headerInteraction.level2({
          clickedItemType: 'media',
          navigationL1: `${datalayerL1}`,
          navigationL2: `${datalayerId}`
        })
      }
    >
      <Link
        href={urlRoute && urlRoute ? urlRoute.path : path}
        prefetch={false}
        data-testid={`menuNav.mediaColumn.${props.menu.datalayerId}`}
        style={{ maxWidth: '250px' }}
      >
        <Styles.MediaCard portrait={mediaMap} portraitConfig={{ fm: 'jpg', q: 90, w: 400 }} />
        <Styles.TextContainer>
          <MenuStyles.ColumnTitle variant='bodyS'>{text}</MenuStyles.ColumnTitle>
          {caption && <MenuStyles.TextLink variant='bodyS'>{caption}</MenuStyles.TextLink>}
        </Styles.TextContainer>
      </Link>
    </MenuStyles.ColumnContainer>
  );
};

export default MediaColumn;
