import { Typography, brandColors, breakpointRules, grid, fontSizes, IconPhone, IconEmail } from '@aceandtate/ds';
import { FaWhatsapp } from 'react-icons/fa';
import { listInline } from 'styles';
import styled from 'styled-components';
import * as Types from './types';

export const FooterWrapper = styled.div`
  background: ${brandColors.dark};
  color: ${brandColors.light};
  padding: ${grid[24]};
`;

export const FooterContainer = styled.div`
  margin-bottom: ${grid[24]};
  flex-wrap: wrap;
  margin-left: calc(${grid[24]} * -1);
  margin-top: calc(${grid[12]} * -1);
  justify-content: space-between;
  display: flex;

  @media ${breakpointRules.laptop} {
    margin-bottom: ${grid[80]};
    margin-top: ${grid[4]};
  }
`;

export const ContactDetails = styled.div`
  margin-bottom: ${grid[40]};

  @media ${breakpointRules.tablet} {
    margin-bottom: ${grid[60]};
  }
`;

export const InfoColumn = styled.div`
  min-width: 300px;
  margin-left: ${grid[24]};
`;

export const TaglineColumn = styled.div`
  display: flex;
  flex-flow: column;
  text-transform: lowercase;
  min-width: fit-content;
`;

export const Hr = styled.hr`
  border: 0.5px solid;
  border-color: ${brandColors.dark};
  margin: ${grid[24]} 0;
`;

export const TextLinkWithOutStyle = styled(Typography)`
  font-weight: 800;

  > a {
    text-decoration: none;
    border-bottom: none;
  }
`;

export const FooterCopyrightWrapper = styled.div`
  color: ${brandColors.light};
  margin-left: ${grid[24]};
  margin-top: ${grid[12]};
`;

export const PaddingTop = styled.div`
  @media ${breakpointRules.tablet} {
    padding-top: ${grid[8]};
  }
`;

export const EmailText = styled.span`
  color: inherit;
  font-weight: 200;
  margin-left: 5px;
  font-size: ${fontSizes.serif.base};
`;

export const FooterInfoLinksWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  gap: ${grid[12]};
  margin-top: ${grid[32]};

  @media ${breakpointRules.tablet} {
    flex-direction: row;
    row-gap: normal;
    margin-top: ${grid[12]};
  }

  @media ${breakpointRules.laptop} {
    margin-left: ${grid[24]};
    justify-content: center;
  }
`;

export const OpeningHoursWrapper = styled.div`
  display: block;
  color: inherit;
  margin-bottom: ${grid[12]};
`;

export const ContactContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: calc(${grid[24]} * -1);
  margin-left: calc(${grid[24]} * -1);
  gap: ${grid[24]};

  @media ${breakpointRules.tablet} {
    flex-direction: column;
    margin-right: 0;
  }

  @media ${breakpointRules.laptop} {
    align-items: flex-end;
    margin-top: calc(${grid[80]} * -1);
  }
`;

export const ContactWrapper = styled.div`
  display: flex;
  margin-top: ${grid[24]};
  align-items: flex-start;
  flex-direction: column;
  gap: ${grid[32]};

  @media ${breakpointRules.tablet} {
    justify-content: space-between;
    flex-direction: row;
    gap: ${grid[40]};
  }

  @media ${breakpointRules.laptop} {
    margin-top: ${grid[40]};
  }
`;

export const BCorpImage = styled.span`
  width: fit-content;
  height: 100px;
  width: 60px;
  object-position: bottom;

  @media ${breakpointRules.tablet} {
    height: 60px;
    width: 36px;
  }

  @media ${breakpointRules.laptop} {
    height: 84px;
    width: 50.4px;
  }
`;

export const PhoneNumberDisplay = styled(Typography)`
  font-weight: 200;
  display: inline;
  margin-left: 5px;
`;

export const FormatOnNavigation = styled(Typography)`
  font-weight: 800;
  font-size: ${fontSizes.serif.base};
  color: inherit;
`;

export const WhatsAppInfoDisplay = styled.div`
  margin-top: ${grid[8]};
  margin-bottom: ${grid[8]};
`;

export const WhatsAppNumber = styled(Typography)`
  display: inline;
  margin-left: 5px;
  font-weight: 200;
`;

export const WhatsAppLink = styled.div`
  color: ${brandColors.light};
  display: inline;
`;

export const IconImage = styled.img`
  width: 16px;
  height: 16px;
  object-position: bottom;

  svg {
    fill: #fff;
  }
`;

export const PhoneIconStyled = styled(IconPhone).attrs({ fontSize: 18 })`
  transform: translateY(4px);
  margin-right: ${grid[8]};
`;

export const WhatsappIconStyled = styled(FaWhatsapp).attrs({ size: 18 })`
  transform: translateY(4px);
  margin-right: ${grid[8]};
`;

export const EmailIconStyled = styled(IconEmail).attrs({ fontSize: 18 })`
  transform: translateY(4px);
  margin-right: ${grid[8]};
`;

export const SwitchItem = styled.div``;

export const FooterSwitcherWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;

  & > *:not(:last-child) {
    margin-right: ${grid[8]};
  }
`;

export const PickerWrapper = styled.div<Types.PickerWrapperProps>`
  width: 100%;
  max-width: 370px;

  &:first-child {
    margin-bottom: ${props => (props.combined ? `${grid[12]}` : '0')};
  }

  &:last-child {
    width: ${props => (props.combined ? '100%' : '0')};
  }
  @media ${breakpointRules.laptop} {
    max-width: unset;

    &:first-child {
      margin-bottom: 0;
      margin-right: ${props => (props.combined ? `${grid[12]}` : '0')};
      width: ${props => (props.combined ? '265px' : '440px')};
    }

    &:last-child {
      width: ${props => (props.combined ? '170px' : '0')};
    }
  }
`;

export const TpWidgetWrapper = styled.div`
  & > iframe {
    margin-top: ${grid[4]};
    margin-bottom: ${grid[4]};
  }
`;

export const SocialLinksList = styled.ul<Types.SocialLinksListProps>`
  ${listInline()};
  margin-left: ${grid[24]};
  margin-top: ${grid[12]};
  overflow: hidden;
  display: flex;
  > li {
    &:not(:last-child) {
      margin-right: ${grid[12]};
    }
    &:hover {
      opacity: 0.8;
    }
  }
  ${props => (props.navigation ? 'float: left;' : 'text-align: center;')};
`;

export const InfoGroup = styled.div`
  @media ${breakpointRules.tablet} {
    padding-left: ${grid[24]};
  }
  @media ${breakpointRules.laptop} {
    width: 100%;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
  }
`;
