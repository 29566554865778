import React from 'react';
import { Options, documentToReactComponents } from '@contentful/rich-text-react-renderer';
import PluginLink, { parsePluginOptions } from 'components/Link';

import { INLINES, Document, BLOCKS } from '@contentful/rich-text-types';
import WarningBar from 'blocks/MenuNavigation/partials/Warning/WarningBar';
import Link from 'next/link';
import { InfoRibbon } from 'types/contentful';
import styled, { css } from 'styled-components';
import { Typography } from '@aceandtate/ds';

const WarningMessageItem = styled.li<{
  messageCount: number;
  index: number;
  animationDelay: number;
  animationDuration: number;
  itemDuration: number;
}>`
  opacity: 0;
  overflow: hidden;

  position: ${({ index }) => (index === 0 ? 'relative' : 'absolute')};
  top: 0;
  width: 100%;

  ${({ itemDuration, animationDuration }) => {
    // create animation for each ribbon duration
    const FADE_TIME = 0.5; // seconds

    // calculate the fraction of the animation that this item should take
    const fraction = (itemDuration / animationDuration) * 100;
    const fadeFraction = (FADE_TIME / animationDuration) * 100;

    return css`
      @keyframes ribbon-blink-${itemDuration} {
        ${FADE_TIME}%,
        ${fraction - fadeFraction}% {
          opacity: 1;
          z-index: 1;
        }

        ${fraction}%,
        ${100 - fadeFraction}% {
          opacity: 0;
          z-index: 0;
        }
      }
    `;
  }}

  animation: ${({ animationDelay, animationDuration, itemDuration }) =>
    `ribbon-blink-${itemDuration} ${animationDuration}s ${animationDelay}s ease-out infinite`};
`;

const WarningBarWrapper = styled.ul`
  width: 100%;
  list-style: none;
  padding: 0;
  margin: 0;
  position: relative;
  isolation: isolate;

  &:hover {
    ${WarningMessageItem} {
      animation-play-state: paused;
    }
  }
`;

type Props = {
  ribbonItems?: InfoRibbon[];
};

type InnerLinkProps = {
  href?: string;
  children: React.ReactNode;
};

function InnerLink(props: InnerLinkProps) {
  const href = props.href || '';
  const isExternal = href.startsWith('http') || href.startsWith('//');

  if (isExternal) {
    return (
      <a href={href} target='_blank' rel='noreferrer noopener'>
        {props.children}
      </a>
    );
  } else {
    return (
      <Link href={href} prefetch={false}>
        {props.children}
      </Link>
    );
  }
}

function guardException(fn: (...args: any[]) => any) {
  return (...args: any[]) => {
    try {
      return fn(...args);
    } catch (err) {
      return null;
    }
  };
}

export default function Warning({ ribbonItems }: Props) {
  function renderGqlDocumentWithLinks({ json, links }: { json: Document; links: any }) {
    // create an asset map
    const assetMap = new Map();

    // loop through the linked assets and add them to a map
    for (const asset of links.entries.hyperlink) {
      assetMap.set(asset.sys.id, asset);
    }

    const options: Options = {
      renderNode: {
        [BLOCKS.PARAGRAPH]: guardException((_, children) => (
          <Typography variant='bodyS' color='inherit'>
            {children}
          </Typography>
        )),
        [INLINES.ENTRY_HYPERLINK]: guardException((node, children) => {
          const asset = assetMap.get(node.data.target.sys.id);
          const { isPlugin } = parsePluginOptions(asset.path);
          if (isPlugin) {
            return (
              <PluginLink href={asset.path} buttonVariant='link'>
                {children}
              </PluginLink>
            );
          }
          return <InnerLink href={asset.path}>{children}</InnerLink>;
        }),
        [INLINES.HYPERLINK]: guardException((node, children) => {
          const href = node.data.uri;
          const { isPlugin } = parsePluginOptions(href);
          if (isPlugin) {
            return (
              <PluginLink href={href} buttonVariant='link'>
                {children}
              </PluginLink>
            );
          }
          return <InnerLink href={href}>{children}</InnerLink>;
        })
      }
    };

    return documentToReactComponents(json as Document, options);
  }

  function getAnimationDuration() {
    return ribbonItems.reduce((acc, item) => acc + (item.timeout || 5), 0);
  }

  function getAnimationDelay(i: number) {
    // get time of all prior items to stagger the start time of the animations
    const passedItems = ribbonItems.slice(0, i);
    return passedItems.reduce((acc, item) => acc + (item.timeout || 5), 0);
  }

  return ribbonItems?.length > 0 ? (
    <WarningBar>
      <WarningBarWrapper>
        {ribbonItems?.length === 1 && renderGqlDocumentWithLinks(ribbonItems[0].body as any)}

        {ribbonItems?.length > 1 &&
          ribbonItems.map((message, i) => (
            <WarningMessageItem
              key={(message as any).sys.id}
              animationDelay={getAnimationDelay(i)}
              animationDuration={getAnimationDuration()}
              itemDuration={message.timeout || 5}
              messageCount={ribbonItems.length}
              index={i}
            >
              {renderGqlDocumentWithLinks(message.body as any)}
            </WarningMessageItem>
          ))}
      </WarningBarWrapper>
    </WarningBar>
  ) : null;
}
