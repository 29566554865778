import baseNames from 'plugins/baseNames';
import Cookie from 'js-cookie';
import { useContext } from 'react';
import { ServicesContext } from 'services/context';
import { intlEvents } from 'tracking';
import { useRouter } from 'next/router';

// Important: these paths are added to the sitemap, so if you want to add something that does not index add them in hiddenPaths
export const pathnames = {
  about: '/about',
  accessibility: '/legal/accessibility',
  accessories: '/accessories',
  acetateGlasses: '/glasses/acetate-glasses',
  adjustments: '/services/adjustments',
  aw17titanium: '/aw17-titanium',
  blueLightFilter: '/products/blue-light-filter',
  bookAppointment: '/book-appointment',
  bookAppointmentSuccess: '/book-appointment/success',
  cart: '/cart',
  checkYourBalance: '/gift-card/check-your-balance',
  classOf2017: '/class-of-2017',
  cleaningYourGlasses: '/help/how-to-take-care-of-your-glasses',
  clipOn: '/sunglasses/clip-ons',
  cmmnSwdn: '/cmmn-swdn',
  contact: '/contact',
  cookieOverview: '/legal/cookie-overview',
  cookiePolicy: '/legal/cookie-policy',
  creativeFund: '/creative-fund',
  dailyContactLenses: '/daily-contact-lenses',
  deliveryOptions: '/help/shipping-and-returns',
  eyeTest: '/eye-test',
  faq: '/faq',
  fieldNotes: '/field-notes',
  giftCard: '/giftcards',
  glasses: '/glasses',
  glasses_hto: '/glasses/home-try-on',
  healthInsurance: '/health-insurance',
  help: '/help',
  highPrescriptionGlasses: '/glasses/high-prescription-glasses',
  highPrescriptionSunglasses: '/sunglasses/high-prescription-sunglasses',
  holdOntoSummer: '/hold-onto-summer',
  home: '/',
  homeTryOn: '/home-try-on',
  imanWhitfield: '/iman-whitfield-sunnies',
  impressum: '/legal/impressum',
  conformity: '/legal/declaration-of-conformity',
  join: '/join',
  journalHome: '/journal',
  journalOriginals: '/journal/originals',
  journalPortraits: '/journal/portraits',
  journalSounds: '/journal/sounds',
  journalThroughTheEyes: '/journal/through-the-eyes',
  latestCollection: '/latest-collection',
  legacyTitanium: '/titanium',
  legal: '/legal',
  lennyCliff: '/winding-down',
  lensesAndCoatings: '/help/lenses-and-coatings',
  lernertSander: '/lernert-sander',
  meMyselfAndI: '/ss18-me-myself-i',
  meMyselfAndIProducts: '/ss18-me-myself-i-products',
  menGlasses: '/men-glasses',
  menGlasses_hto: '/men-glasses/home-try-on',
  menSunglasses: '/men-sunglasses',
  metalGlasses: '/glasses/metal-glasses',
  metalGlassesMen: '/metal-glasses/men',
  metalGlassesWomen: '/metal-glasses/women',
  metalSunglasses: '/metal-sunglasses',
  metalSunglassesMen: '/metal-sunglasses/men',
  metalSunglassesWomen: '/metal-sunglasses/women',
  multifocalGlasses: '/products/multifocal-glasses',
  notorious: '/aw17-notorious',
  polarisedSunglasses: '/sunglasses/polarised-sunglasses',
  premiumLenses: '/help/lenses-and-coatings',
  prescriptionCheckerTermsOfUse: '/legal/ace-and-tate-prescription-checker-terms-and-conditions',
  prescriptions: '/help/prescriptions',
  press: '/help/press',
  privacyPolicy: '/legal/privacy-policy',
  privacyPolicyJobs: '/legal/privacy-policy-job-applicants',
  productWarranty: '/help/product-warranty',
  products: '/products',
  rectangularGlasses: '/glasses/rectangle-glasses',
  rectangularGlassesMen: '/rectangular-glasses/men',
  rectangularGlassesWomen: '/rectangular-glasses/women',
  rectangularSunglasses: '/rectangular-sunglasses',
  rectangularSunglassesMen: '/rectangular-sunglasses/men',
  rectangularSunglassesWomen: '/rectangular-sunglasses/women',
  reframe: '/reframe',
  reglazing: '/services/reglazing',
  returnPolicy: '/help/return-policy',
  roundGlasses: '/glasses/round-glasses',
  roundGlassesMen: '/round-glasses/men',
  roundGlassesWomen: '/round-glasses/women',
  roundSunglasses: '/round-sunglasses',
  roundSunglassesMen: '/round-sunglasses/men',
  roundSunglassesWomen: '/round-sunglasses/women',
  russel: '/aw18-russel',
  sameDayGlasses: '/services/same-day-glasses',
  sameDayService: '/services/same-day-service',
  search: '/search',
  services: '/services',
  squareGlasses: '/glasses/square-glasses',
  squareGlassesMen: '/square-glasses/men',
  squareGlassesWomen: '/square-glasses/women',
  squareSunglasses: '/square-sunglasses',
  squareSunglassesMen: '/square-sunglasses/men',
  squareSunglassesWomen: '/square-sunglasses/women',
  ss17SunsetToSunrise: '/ss17-sunset-to-sunrise',
  ss18ComeAsYouAre: '/ss18-come-as-you-are',
  ss18OpticalRefresh: '/ss18-optical-refresh',
  ss18SunnyRefresh: '/ss18-splash',
  stores: '/stores',
  storesMap: '/stores/search',
  sunglasses: '/sunglasses',
  termsConditions: '/legal/terms-and-conditions',
  termsConditionsTreesForAll: '/legal/trees-for-all-terms-and-conditions',
  titaniumGlasses: '/glasses/titanium-glasses',
  titaniumGlassesMen: '/titanium-glasses/men',
  titaniumGlassesWomen: '/titanium-glasses/women',
  titaniumSunglasses: '/titanium-sunglasses',
  titaniumSunglassesMen: '/titanium-sunglasses/men',
  titaniumSunglassesWomen: '/titanium-sunglasses/women',
  updatePdPagePage: '/update-your-pd?token=',
  updatePdPageStatus: '/update-your-pd/status?token=',
  updatePdPageSuccess: '/update-your-pd/success',
  updatePrescriptionPage: '/update-your-prescription?token=',
  updatePrescriptionPagePending: '/update-your-prescription/pending',
  updatePrescriptionPageStatus: '/update-your-prescription/status?token=',
  updatePrescriptionPageSuccess: '/update-your-prescription/success',
  vision: '/vision',
  websiteTerms: '/legal/website-terms',
  wishlist: '/wishlist',
  womenGlasses: '/women-glasses',
  womenGlasses_hto: '/women-glasses/home-try-on',
  womenSunglasses: '/women-sunglasses'
};

// Profile Paths
export const userProfilePaths = {
  userAppointments: '/profile/appointments',
  userOrderDetail: '/profile/orders/:order',
  userOrders: '/profile/orders',
  userPrescriptionEdit: '/profile/prescriptions/:prescription',
  userPrescriptionNew: '/profile/prescriptions/new',
  userPrescriptions: '/profile/prescriptions',
  userProfile: '/profile',
  userAccountInformation: '/profile/account-information',
  userSubscriptions: '/profile/subscriptions'
} as const;

// Paths that are not added to the sitemap
export const hiddenPaths = {
  allTheSmoke: '/collection/all-the-smoke',
  ashleyWilliams: '/collection/ashley-williams',
  aw18Pine: '/collection/aw18-pine',
  billy: '/collection/this-is-billy',
  campaign: '/campaign',
  chagingerSelection: '/chaginger-selection',
  checkoutHTO: '/checkout/home-try-on',
  checkoutRegular: '/checkout/regular',
  collection: '/collection',
  colourPopSunnies: '/collection/colour-pop-sunnies',
  company: '/responsibility/company',
  contactLenses: '/contact-lenses',
  emarsysUnsubscribe: '/emarsys/unsubscribe/index/email',
  fiveYearsForm: '/five',
  fiveYearsLanding: '/birthday',
  glasses_hto: '/glasses/home-try-on',
  impact: '/responsibility/impact',
  jimi: '/collection/meet-jimi',
  juliaBSelection: '/julia-b-selection',
  juliaSelection: '/julia-selection',
  legacyCollectionGlasses: '/collection/glasses',
  legacyCollectionMenGlasses: '/collection/men-glasses',
  legacyCollectionMenSunglasses: '/collection/men-sunglasses',
  legacyCollectionSunglasses: '/collection/sunglasses',
  legacyCollectionWomenGlasses: '/collection/women-glasses',
  legacyCollectionWomenSunglasses: '/collection/women-sunglasses',
  login: '/login',
  matt: '/collection/be-squared',
  michaelSelection: '/michael-selection',
  neilFamily: '/collection/neil-family',
  podcastLasterschwestern: '/podcast-lasterschwestern',
  podcastUpgrade: '/podcast-upgrade',
  productResponsibility: '/responsibility/product',
  recover: '/recover',
  register: '/register',
  reset: '/reset',
  responsibility: '/responsibility',
  samio: '/samio-selection',
  sebastianBSelection: '/sebastian-b-selection',
  sebastianSelection: '/sebastian-selection',
  sofia: '/collection/oh-sofia',
  subscription: '/subscription',
  successDonation: '/success/donation',
  successEyeTest: '/book-appointment/success',
  successHto: '/success/home-try-on',
  successRegular: '/success/regular',
  supplyChain: '/responsibility/supply-chain',
  takeAnotherLook: '/take-another-look',
  unsubscribe: '/unsubscribe',
  orderStatus: '/order-status'
} as const;

// All the paths normal and hidden from sitemap, used when adding links
export const paths = { ...pathnames, ...hiddenPaths, ...userProfilePaths };

export const catalogPaths = [
  paths.roundGlasses,
  paths.glasses,
  paths.sunglasses,
  paths.womenGlasses,
  paths.womenSunglasses,
  paths.menGlasses,
  paths.menSunglasses,
  paths.roundGlasses,
  paths.roundGlassesWomen,
  paths.roundGlassesMen,
  paths.roundSunglasses,
  paths.roundSunglassesMen,
  paths.roundSunglassesWomen,
  paths.squareGlasses,
  paths.squareGlassesMen,
  paths.squareGlassesWomen,
  paths.squareSunglasses,
  paths.squareSunglassesMen,
  paths.squareSunglassesWomen,
  paths.rectangularGlasses,
  paths.rectangularGlassesMen,
  paths.rectangularGlassesWomen,
  paths.rectangularSunglasses,
  paths.rectangularSunglassesMen,
  paths.rectangularSunglassesWomen,
  paths.metalGlasses,
  paths.metalGlassesMen,
  paths.metalGlassesWomen,
  paths.metalSunglasses,
  paths.metalSunglassesMen,
  paths.metalSunglassesWomen,
  paths.titaniumGlasses,
  paths.titaniumGlassesMen,
  paths.titaniumGlassesWomen,
  paths.titaniumSunglasses,
  paths.titaniumSunglassesMen,
  paths.titaniumSunglassesWomen,
  paths.clipOn,
  paths.giftCard,
  paths.holdOntoSummer,
  paths.fieldNotes,
  paths.notorious,
  paths.aw17titanium,
  paths.classOf2017,
  paths.meMyselfAndI,
  paths.meMyselfAndIProducts,
  paths.ss18ComeAsYouAre,
  paths.ss18OpticalRefresh,
  paths.ss18SunnyRefresh,
  paths.neilFamily,
  paths.podcastUpgrade,
  paths.imanWhitfield,
  paths.allTheSmoke,
  paths.billy,
  paths.samio,
  paths.sofia,
  paths.ashleyWilliams,
  paths.michaelSelection,
  paths.russel,
  paths.chagingerSelection,
  paths.matt,
  paths.accessories
] as const;

export const latestCollection = paths.lennyCliff;

// TODO
export function getPathAlternatives(pathname: string, language: string) {
  return { language, pathname };
}

export const usePath = () => {
  const context = useContext(ServicesContext);
  const prefix = context?.locale?.prefix;

  return (path?: string) => {
    if (typeof path !== 'string') {
      return undefined;
    }

    return path.startsWith('http') ? path : `/${prefix}${path}`;
  };
};

function matchByCountryLang(country = '', lang = '') {
  const basename = `${country}-${lang}`;

  if (baseNames[country] && baseNames[country].language === lang) {
    return country;
  } else {
    return [basename, `${country}-en`, country].find(part => baseNames[part]);
  }
}

export const useChangeRegion = () => {
  const router = useRouter();
  const { locale } = router;
  let currentCountryCode: string;
  let currentLanguageCode: string;

  if (locale && locale.length > 2) {
    currentCountryCode = locale.slice(0, 2);
    currentLanguageCode = locale.slice(3, 5);
  } else if (locale) {
    currentCountryCode = locale.slice(0, 2);
    currentLanguageCode = locale.slice(0, 2);
  }

  return (countryCode: string = currentCountryCode, languageCode: string = currentLanguageCode) => {
    const targetPath = matchByCountryLang(countryCode.toLowerCase(), languageCode.toLowerCase());
    const match = new RegExp(locale, 'i');
    const newPath = window.location.pathname.replace(match, targetPath).replace('#country-select', '');
    const newTarget = `${window.location.protocol}//${window.location.host}${newPath}${window.location.search}${window.location.hash}`;

    intlEvents.changeCountry({ clickedItem: countryCode });
    Cookie.set('v1-lang-preference', targetPath, { expires: 365 });
    window.location.replace(newTarget);
  };
};
