import React from 'react';
import groupBy from 'lodash/groupBy';
import { connectHits } from 'react-instantsearch-dom';
import { FormattedMessage } from 'react-intl';

import ContentResult from '../ContentResult';

import messages from '../../messages';
import * as Types from '../../types';
import * as SearchStyles from '../../styles';
import * as Styles from './styles';
import { Button } from '@aceandtate/ds';
import { paths } from 'paths';
import Link from 'next/link';

type Props = {
  hasQuery: boolean;
  hits: Types.ContentHitProps[];
  closeHandler?: () => void;
};

const ContentfulHits = (props: Props) => {
  const { hits, hasQuery, closeHandler } = props;
  if (hits.length === 0) return null;

  // Sometimes algolia bugs out and spits out results for other indices,
  // so we check and make sure this looks like an object from 'production_frontend_search'
  if (!hits[0].contentType) return null;

  // Add content title (category) to object for ordering
  const hitsByCategory = hits.map(hit => {
    const contentTitleMessage = messages[hit.contentType as keyof typeof messages] || messages.ContentPage;

    return {
      ...hit,
      contentTitleMessage
    };
  });

  const groupedHits = groupBy(hitsByCategory, 'contentTitleMessage.defaultMessage');
  delete groupedHits.Journal;
  const { ...mainHits } = groupedHits;

  const mainContentSections = Object.keys(mainHits).map(contentTitle => {
    const hitsByContentTitle = groupedHits[contentTitle];
    const { contentTitleMessage } = hitsByContentTitle[0];

    return (
      <Styles.ContentSectionWrapper key={`searchSection-${contentTitle}`} data-testid={`searchSection-${contentTitle}`}>
        <SearchStyles.ContentSectionTitle variant='h4' gutterBottom>
          <FormattedMessage {...contentTitleMessage} />
        </SearchStyles.ContentSectionTitle>
        {hitsByContentTitle.map(mainHit => (
          <ContentResult key={mainHit.objectID} hit={mainHit} hasQuery={hasQuery} closeHandler={closeHandler} />
        ))}
        {hitsByContentTitle[0].contentType === 'FaqQuestion' && (
          <Link href={paths.faq}>
            <Button variant='link' as='span'>
              <FormattedMessage {...messages.searchSeeAllFaqs} />
            </Button>
          </Link>
        )}
      </Styles.ContentSectionWrapper>
    );
  });

  return <Styles.MainContentWrapper>{mainContentSections}</Styles.MainContentWrapper>;
};

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export default connectHits(ContentfulHits);
