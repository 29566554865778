import { webColors } from 'styles';
import { Language } from 'types/language';

export const clientId = '84009495-3f73-4229-8ba6-b424273f9ce7';
export const profileNames = ['web_en', 'web_de', 'web_es', 'web_fr', 'web_nl'];
export const pdSdkUrl = 'https://web.cdn.glasseson.com/glasseson-2.15.0.js';
export const serverUrl = 'https://api.glasseson.com/prod/';

export function getProfileName(lang: Language) {
  const profileName = `web_${lang}`.toLowerCase();
  return profileNames.includes(profileName) ? profileName : profileNames[0];
}

export function getOptions(lang: Language) {
  return {
    flow: 'pd',
    fontFamily: 'AmperSans',
    fullPage: true,
    profileName: getProfileName(lang),
    themeColor: webColors.dark,
    width: 835
  } as const;
}
