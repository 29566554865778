import formValidation from 'messages/formValidation';
import { useIntl } from 'react-intl';

export const EMAIL_REGEX =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export default function useValidationOptions(hookForm?: any) {
  const intl = useIntl();

  return {
    createPasswordValidation: {
      minLength: {
        message: intl.formatMessage(formValidation.minCharactersRequired, { number: 8 }),
        value: 8
      },
      required: intl.formatMessage(formValidation.requiredPassword)
    },
    emailValidation: {
      pattern: {
        message: intl.formatMessage(formValidation.invalidEmail),
        value: EMAIL_REGEX
      },
      required: intl.formatMessage(formValidation.requiredField)
    },
    loginPasswordValidation: {
      minLength: {
        message: intl.formatMessage(formValidation.minCharactersRequired, { number: 6 }),
        value: 6
      },
      required: intl.formatMessage(formValidation.requiredPassword)
    },
    maxLengthValidation: (length: number) => ({
      maxLength: {
        message: intl.formatMessage(formValidation.maxCharactersRequired, { number: length }),
        value: length
      }
    }),
    nameValidation: {
      pattern: {
        message: intl.formatMessage(formValidation.invalidField),
        value: /\D+$/i
      },
      required: intl.formatMessage(formValidation.requiredField)
    },
    repeatPasswordValidation: {
      minLength: {
        message: intl.formatMessage(formValidation.minCharactersRequired, { number: 8 }),
        value: 8
      },
      required: intl.formatMessage(formValidation.requiredPassword),
      validate: value => {
        if (hookForm) {
          if (value === hookForm.getValues('personalInformation.newPassword')) {
            return true;
          } else {
            return intl.formatMessage(formValidation.passwordsNoMatch);
          }
        }
      }
    },
    requiredField: {
      required: intl.formatMessage(formValidation.requiredField)
    },
    tosRequired: {
      required: intl.formatMessage(formValidation.tosRequired)
    },
    validatedDate: {
      pattern: {
        message: intl.formatMessage(formValidation.invalidField),
        value: /^(19|20)\d\d([- /.])(0[1-9]|1[012])\2(0[1-9]|[12][0-9]|3[01])$/
      },
      required: intl.formatMessage(formValidation.requiredField)
    }
  };
}
