import styled from 'styled-components';
import { grid, breakpointRules } from 'styles';

export const ResultsWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-flow: column;
  margin: ${grid.l} ${grid.m};

  @media ${breakpointRules.largeMin} {
    flex-flow: row;
  }
`;
