import { defineMessages } from 'react-intl';

export default defineMessages({
  basecurveTooltip: {
    defaultMessage:
      'Technically speaking, our lenses have a Base Curve of 8.4mm, but they’re actually designed to comfortably fit over 90% of lens wearers within a “regular fit” range.',
    id: 'contactLensConfigurator.tooltip.basecurve'
  },
  boxSizeLabel: {
    defaultMessage: '{amount} pack',
    id: 'contactLensConfigurator.boxSizeLabel'
  },
  bundleDealDescription: {
    defaultMessage: 'Receive a discount when you order {min} packs or more.',
    id: 'contactLensConfigurator.bundleDealDescription'
  },
  bundleDealTitle: {
    defaultMessage: 'Bundle deals',
    id: 'contactLensConfigurator.bundleDealTitle'
  },
  contactBundleIntro: {
    defaultMessage: 'Receive a discount when you order {min} packs or more.',
    id: 'contactLensConfigurator.contactBundleIntro'
  },
  dailyLenses: {
    defaultMessage: 'Daily contact lenses',
    id: 'contactLensConfigurator.lenstype.daily'
  },
  lensCount: {
    defaultMessage: '{amount} lenses total',
    id: 'contactLensConfigurator.lensCount'
  },
  missingInputError: {
    defaultMessage:
      'Looks like you forgot to select a prescription for both eyes. Need contact lenses for only one eye? Just set the quantity for the other one to 0',
    id: 'contactLensConfigurator.errors.missingInput'
  },
  missingInputErrorNoQuantity: {
    defaultMessage:
      'Oops! Our trial pack comes in two — this means we need a prescription for both eyes. Need contact lenses for only one eye? Just pick the same prescription for the other eye and you’ll be able to try them out twice as long!',
    id: 'contactLensConfigurator.errors.missingInputNoQuantity'
  },
  oos: {
    defaultMessage: 'One or both of your selected prescriptions are currently out of stock.',
    id: 'contactLensConfigurator.oos'
  },
  oosTitle: {
    defaultMessage: 'This product is out of stock',
    id: 'contactLensConfigurator.oosTitle'
  },
  prescriptionTooltip: {
    defaultMessage:
      'Note: You need a valid prescription ​​for contact lenses (it contains different values than your glasses prescription!).',
    id: 'contactLensConfigurator.tooltip.prescription'
  },
  pricePerBox: {
    defaultMessage: '{price} per box',
    id: 'contactLensConfigurator.pricePerBox'
  },
  productNotFound: {
    defaultMessage: 'It looks like the prescription values you entered are not possible for this product.',
    id: 'contactLensConfigurator.productNotFound'
  },
  productNotFoundTitle: {
    defaultMessage: 'This combination is unavailable',
    id: 'contactLensConfigurator.productNotFoundTitle'
  },
  regularLensDescription: {
    defaultMessage:
      'Premium hydrogel daily contact lenses, for crystal-clear vision and expressive eyes. Daily Contacts are what you might call ‘regular’ lenses and the ones most of us probably need.',
    id: 'contactLensConfigurator.regularLensDescription'
  },
  selectBoxSize: {
    defaultMessage: 'Select box size',
    id: 'contactLensConfigurator.titles.selectBoxSize'
  },
  selectLensType: {
    defaultMessage: 'Select your lens type',
    id: 'contactLensConfigurator.titles.selectLensType'
  },
  selectPrescription: {
    defaultMessage: 'Select your prescription',
    id: 'contactLensConfigurator.titles.selectPrescription'
  },
  toricLensDescription: {
    defaultMessage:
      'Our premium toric lenses help correct astigmatism – indicated by a Cylinder measurement in your contact lens prescription.',
    id: 'contactLensConfigurator.toricLensDescription'
  },
  trialLensesIntro: {
    defaultMessage:
      'Give our new daily contact lenses a try — you’ll get 6 pairs for {voucherValue}. The best part? It comes with a {voucherValue} voucher for your next purchase, so you’re basically trying them out for free.',
    id: 'contactLensConfigurator.trialLensesIntro'
  },
  upsell: {
    defaultMessage: 'Some extras',
    id: 'contactLensConfigurator.upsell'
  }
});
