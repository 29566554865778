import { Currency } from 'types/currency';
import { useFetch } from 'utils/helpers/customFetch';

export type GiftcardBalanceRequestParams = { code: string; email: string };
export type GiftcardBalanceResponse = { amount: number; currency: Currency; expires_at: string; error?: string };

export function useGiftcardService() {
  const fetch = useFetch<GiftcardBalanceResponse>();

  async function checkBalance(body: GiftcardBalanceRequestParams) {
    return fetch('/gift_cards/check_balance', {
      body,
      method: 'POST'
    });
  }

  return {
    checkBalance
  };
}
