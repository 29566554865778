import styled from 'styled-components';
import { grid, breakpointRules, brandColors, Button } from '@aceandtate/ds';

export const CartItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${grid[16]};
`;

export const ItemProduct = styled.div`
  flex: 1;

  @media ${breakpointRules.laptop} {
    display: flex;
    align-items: center;
  }
`;

export const DisplayData = styled.div`
  flex: 1;
  display: flex;
  align-items: stretch;

  & > a {
    height: 100%;
  }
`;

export const ProductDetails = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  position: relative;
  margin-left: ${grid[12]};
  gap: ${grid[4]};
`;

export const ItemAddOns = styled.div`
  display: flex;
  align-items: baseline;
  flex-direction: column;
  :empty {
    display: none;
  }
`;

export const ItemName = styled.div`
  display: flex;
  align-items: baseline;
  flex-direction: column;
`;

export const RemoveFromCart = styled.span`
  cursor: pointer;
  margin-left: 0;
  margin-right: auto;

  @media ${breakpointRules.laptop} {
    margin-left: 0;
    margin-right: 0;
  }
`;

export const ItemPrice = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const RemoveFromCartDrawer = styled.span`
  cursor: pointer;
  display: contents;
`;

export const DisplayAction = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: ${grid[8]};
`;

export const ActionButton = styled(Button)`
  color: ${brandColors.dark50};
  text-align: left;
  gap: ${grid[4]};

  :hover {
    color: ${brandColors.dark};
  }

  span {
    font-size: 12px;

    @media ${breakpointRules.tablet} {
      font-size: 14px;
    }
  }

  svg {
    font-size: 20px;
  }
`;
