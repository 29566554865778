import React, { useState } from 'react';
import { Button } from '@aceandtate/ds';
import { Variant } from 'types/torii';
import OOSModal from '../../OOSModal';

type Props = {
  currentVariant: Variant;
  children: React.ReactNode;
};

export default function NotifyMe(props: Props) {
  const { currentVariant, children } = props;

  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <>
      <Button variant='outlined' fullWidth data-testid='notify-me-button' onClick={() => setIsModalOpen(true)}>
        {children}
      </Button>
      {isModalOpen && <OOSModal isOpen={isModalOpen} sku={currentVariant.sku} onClose={() => setIsModalOpen(false)} />}
    </>
  );
}
