import { useContext, useRef } from 'react';
import { Product, ProductAvailabilities, Variant, VariantVisibility, FrameProduct } from 'types/torii';
import { Locale } from 'types/locale';
import useSWR, { SWRConfiguration, useSWRConfig } from 'swr';
import { ServicesContext } from './context';
import { captureException } from '@sentry/nextjs';
import taxonsFn from 'utils/product/productTaxonomies';

const TORII_ENDPOINT_BASE = process.env.NEXT_PUBLIC_TORII_ENDPOINT_BASE;
// const FOCUS_ENDPOINT_BASE = process.env.NEXT_PUBLIC_FOCUS_ENDPOINT_BASE;

/**
 * Helper to generate the full url to be fetched for a product in the catalog
 */

export type CatalogOptions = {
  withFocus?: boolean;
  sortKey?: string;
  draftMode?: boolean;
  visibility?: VariantVisibility[];
  /** @deprecated temporary source to investigate */
  source?: 'pcp' | 'shopstory' | 'configurator-or-cart-upsells' | 'shopstory-product-picker';
};

export function getCatalogPath(url: string, locale: Locale, options: CatalogOptions = {}) {
  const { draftMode, source } = options;
  const queryPrefix = url.includes('?') ? '&' : '?';

  const params = new URLSearchParams();
  locale.lang && params.append('language-code', locale.lang ? locale.lang.toLowerCase() : 'en');
  locale.country && params.append('country-code', locale.country ? locale.country.toLowerCase() : 'nl');
  options.visibility?.forEach(vis => {
    params.append('visibility', vis);
  });
  draftMode && params.append('with-draft', 'true');
  source && params.append('source', source);

  const paramsString = params.toString();
  // resorted ecommerce collection
  // if (withFocus && sortKey) {
  //   return `${FOCUS_ENDPOINT_BASE}/catalog/v1${url}${queryPrefix}${paramsString}`;
  // }

  return `${TORII_ENDPOINT_BASE}/catalog/v1${url}${queryPrefix}${paramsString}`;
}

/**
 * Temporary response cache, mainly used for SSG during build
 */

function get<T>(url: string): Promise<T> {
  return fetch(url)
    .then(res => res.json())
    .then(json => json.data)
    .catch(e => {
      const err = new Error('Error fetching product(s)');
      captureException(err, { extra: { error: e, requestUrl: url } });
      console.error(e);
    });
}

/**
 * Fetching all products / variants in catalog
 */

export async function getAllProducts(locale: Locale) {
  const fullPath = getCatalogPath('/products', locale, { visibility: ['pdp', 'pcp'] });
  const products = await get<Product[]>(fullPath);

  return products.filter(product => product.productType !== 'contact_lenses');
}

export function getAllFrames(locale: Locale) {
  const fullPath = getCatalogPath('/products/frames', locale, { visibility: ['pdp'] });
  return get<FrameProduct[]>(fullPath);
}

export function getAllFramesExploded(locale: Locale) {
  const fullPath = getCatalogPath('/products/exploded/frames', locale, { visibility: ['pdp'] });
  return get<FrameProduct[]>(fullPath);
}

export async function getAllAccessoriesVariants(locale: Locale, options?: CatalogOptions) {
  const fullPath = getCatalogPath('/products/exploded/accessories', locale, options);
  const products = await get<Product[]>(fullPath);
  return products.map(product => product.currentVariant);
}

export async function getRelatedAccessories(sku: string, locale: Locale, options?: CatalogOptions) {
  const fullPath = getCatalogPath(`/products/${sku}/related-accessories/exploded`, locale, options);
  const products = await get<Product[]>(fullPath);

  return products.map(product => product.currentVariant);
}

export async function getAllVariants(locale: Locale, options?: CatalogOptions) {
  const fullPath = getCatalogPath('/products/exploded', locale, { visibility: ['pdp', 'pcp'], ...options });
  return get<Product[]>(fullPath);
}

/**
 * Fetcher function and hook for a single product.
 * The fetcher function by itself should only be used during SSR, otherwise use the useProduct hook.
 */

export async function fetchProduct(url: string) {
  const products = await get<Product[]>(url);

  const product = products[0]?.currentVariant.visibility.includes('pdp') && products[0];
  return product;
}

export async function fetchProductBySku(sku: string, locale: Locale) {
  const fullPath = getCatalogPath(`/products/filter?sku=${sku}`, locale, { draftMode: true }); // TODO: Figure out the draft mode situation
  return fetchProduct(fullPath);
}

export function useProduct(sku: string, options: SWRConfiguration = { revalidateOnFocus: false }) {
  const { locale, draftMode } = useContext(ServicesContext);
  const fullPath = getCatalogPath(`/products/filter?sku=${sku}`, locale, { draftMode });

  return useSWR(sku ? fullPath : null, fetchProduct, options);
}

/**
 * Fetcher function and hook for multiple products
 * The fetcher function by itself should only be used during SSR, otherwise use the useMultipleProducts hook.
 */

export async function fetchMultipleProducts(url: string) {
  if (!url) return [];
  const products = await get<Product[]>(url);
  const availableProducts = (products || []).filter((product: Product) =>
    ['pdp', 'pcp'].some((type: VariantVisibility) => product.currentVariant.visibility.includes(type))
  );
  return availableProducts;
}

export function useMultipleProducts(skus: string[] | null = [], cacheKey: string | null = null) {
  const { mutate } = useSWRConfig();
  const prevSkus = useRef(skus);
  const { locale, draftMode } = useContext(ServicesContext);

  const fullPath =
    skus.length === 0
      ? null
      : getCatalogPath(`/products/filter?${skus.map(x => `sku=${x}`).join('&')}`, locale, { draftMode });

  if (!fullPath) {
    mutate(cacheKey, [], false);
    prevSkus.current = [];
  } else if (prevSkus.current && prevSkus.current.join() !== skus.join()) {
    mutate(cacheKey, fetchMultipleProducts(fullPath), false);
    prevSkus.current = skus;
  }

  const key = cacheKey && fullPath ? cacheKey : fullPath;
  return useSWR(key, () => fetchMultipleProducts(fullPath), {
    revalidateOnFocus: false
  });
}

/**
 * Hook to fetch a list of product SKUs and their associated Clipon products
 * This hook should only be known if the full model of the parent skus is not available
 */

export function useProductClipons(skus: string[], cacheKey?: string) {
  const productQuery = useMultipleProducts(skus);
  const clipOnsSkus = productQuery.data
    .map(i => i.currentVariant.relatedProducts?.clipOns?.skus)
    .filter(Boolean)
    .flat();

  return useMultipleProducts(clipOnsSkus, cacheKey || null);
}

/**
 * This is a helper function to enrich an existing product with live availablity data
 * Depending on the staleness of the product model, this might be necesary e.g. on a PDP
 */

export async function enrichProductWithAvailability(locale: Locale, product: Product) {
  let { variantsLiveEndpoint } = product;

  function fetchFromAvailabilityEndpoint(url: string) {
    return fetch(url).then(res => res.json()) as Promise<{
      message: string;
      data: ProductAvailabilities;
    }>;
  }

  if (!variantsLiveEndpoint) {
    const skuQuery = product.variants.map((variant: Variant) => variant.sku).join('&sku=');
    variantsLiveEndpoint = [getCatalogPath(`/products/availability?sku=${skuQuery}`, locale)];
  }

  const requests = variantsLiveEndpoint.map(fetchFromAvailabilityEndpoint);
  const results = await Promise.all(requests);
  const availabilityData = results.flatMap(res => res.data).filter(Boolean);
  const availabilityForCurrentVariant = availabilityData.find(x => x.sku === product.currentVariant.sku);
  const variantsWithAvailability = product.variants.map((variant: Variant) => {
    const liveAvailability = availabilityData.find(x => x.sku === variant.sku);
    return {
      ...variant,
      availability: {
        ...variant.availability,
        ...(liveAvailability || {})
      }
    };
  });

  const fullProduct = {
    ...product,
    currentVariant: {
      ...product.currentVariant,
      availability: {
        ...product.currentVariant.availability,
        ...(availabilityForCurrentVariant || {})
      }
    },
    variants: variantsWithAvailability
  };

  return fullProduct as Product;
}

export function useProductWithAvailability(sku: string, options: SWRConfiguration = { revalidateOnFocus: false }) {
  const { locale, draftMode } = useContext(ServicesContext);
  const fullPath = getCatalogPath(`/products/filter?sku=${sku}`, locale, { draftMode });

  return useSWR(
    sku ? `${fullPath}+availability` : null,
    async () => {
      const product = await fetchProduct(fullPath);

      return enrichProductWithAvailability(locale, product);
    },
    options
  );
}

/**
 * the useRecommendedProducts hook will return available upsells for any SKU
 */

export function useRecommendedProducts(sku: string, cacheKey?: string) {
  const productQuery = useProduct(sku);
  const parentProduct = productQuery.data;
  const recommendedProductsQuery = useMultipleProducts(
    parentProduct ? parentProduct.currentVariant.relatedProducts?.recommended?.skus : null,
    cacheKey || null
  );

  return {
    ...recommendedProductsQuery,
    data: recommendedProductsQuery.data.filter(
      product => product.currentVariant.availability.isAvailableOnline && product.name !== parentProduct.name
    )
  };
}

export function useGetUpsellProducts() {
  const { locale, draftMode } = useContext(ServicesContext);

  return async (sku?: string) => {
    const req = sku
      ? await getRelatedAccessories(sku, locale, { draftMode, visibility: ['pdp'] })
      : await getAllAccessoriesVariants(locale, {
          draftMode,
          source: 'configurator-or-cart-upsells',
          visibility: ['pdp']
        });

    return req
      .filter(x => x.visibility.includes('pdp'))
      .filter(x => x.availability.isAvailableOnline)
      .filter(x => !taxonsFn.isSkiGoggles(x) && !taxonsFn.isSportsGlasses(x))
      .sort((a, b) => a.upsellPosition - b.upsellPosition) // show best sellers first
      .slice(0, 8);
  };
}

export async function getUpsellProducts(sku: string, locale: Locale, draftMode: boolean) {
  const req = sku
    ? await getRelatedAccessories(sku, locale, { draftMode, visibility: ['pdp'] })
    : await getAllAccessoriesVariants(locale, {
        draftMode,
        source: 'configurator-or-cart-upsells',
        visibility: ['pdp']
      });

  return req
    .filter(x => x.visibility.includes('pdp'))
    .filter(x => x.availability.isAvailableOnline)
    .filter(x => !taxonsFn.isSkiGoggles(x) && !taxonsFn.isSportsGlasses(x))
    .sort((a, b) => a.upsellPosition - b.upsellPosition) // show best sellers first
    .slice(0, 8);
}
