import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import OptimizedCloudinaryImage from 'components/Images/OptimizedCloudinaryImage';
import { Modal, Typography, Button, grid, breakpointRules, Flex, TextInput, InputControl } from '@aceandtate/ds';
import { useRouter } from 'next/router';
import { paths } from 'paths';
import newsletterFormMessages from 'messages/newsletterForm';
import formLabelMessages from 'messages/formLabels';
import ecommerceMessages from 'messages/ecommerce';
import authenticationMessages from 'messages/authentication';
import { FormattedMessage, useIntl } from 'react-intl';
import { saveToStorage, getFromStorage } from 'utils/helpers/storage';
import useValidationOptions from 'utils/hooks/useValidationOptions';
import { ServicesContext } from 'services/context';
import { useSubscribe } from 'services/newsletterService';
import { useUserState } from 'services/userService';
import Link from 'next/link';
import CheckboxWithConditionalChildren, { StyledPrivacyPolicyLink } from 'components/CheckboxWithConditionalChildren';
import { Controller, useForm, FormProvider } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';

const PAGE_INTERACTION_KEY = 'PAGES_INTERACTED_COUNT';
const MODAL_VIEWED_KEY = 'NEWSLETTER_MODAL_VIEWED';
const FORBIDDEN_PATHS = [
  '/checkout/',
  paths.cart,
  paths.login,
  'utm_source=email',
  paths.bookAppointment,
  paths.bookAppointmentSuccess,
  paths.orderStatus
];

const PrivacyWrapper = styled.div`
  margin-top: ${grid[12]};
  margin-bottom: ${grid[40]};
`;

type Status = 'default' | 'submit' | 'complete';

type FormData = { email: string; privacyPolicy: boolean; dateOfBirth: string; gender: string };

export default function NewsletterPrompt() {
  const intl = useIntl();
  const hookForm = useForm<FormData>();
  const {
    control,
    register,
    handleSubmit,
    formState: { errors }
  } = hookForm;
  const validationOptions = useValidationOptions();
  const user = useUserState();
  const router = useRouter();
  const [status, setStatus] = useState<Status>('default');
  const [modalOpen, setModalOpen] = useState(false);
  const [canShowModal, setCanShowModal] = useState(false);
  const [processedStorage, setProcessedStorage] = useState(false);
  const subscribe = useSubscribe();
  const { webStore } = useContext(ServicesContext);
  const userIsSubscribed = user.isValidating || user?.userProfile?.customer.newsletter;
  const isForbiddedRoute = FORBIDDEN_PATHS.some(x => router.asPath.includes(x));

  useEffect(() => {
    if (userIsSubscribed || isForbiddedRoute || processedStorage) return;

    const pagesInteractedCount = getFromStorage(PAGE_INTERACTION_KEY);
    const modalViewed = getFromStorage(MODAL_VIEWED_KEY);

    if (!pagesInteractedCount) {
      saveToStorage(PAGE_INTERACTION_KEY, '1');
    } else {
      const parsedNumber = parseInt(pagesInteractedCount);
      if (parsedNumber >= 3 && !modalViewed) {
        setCanShowModal(true);
        saveToStorage(MODAL_VIEWED_KEY, 'true');
      } else if (!modalViewed) {
        saveToStorage(PAGE_INTERACTION_KEY, `${parsedNumber + 1}`);
      }
    }

    setProcessedStorage(true);
  }, [userIsSubscribed, processedStorage]);

  useEffect(() => {
    function handleRouteChange(_url: string, { shallow }) {
      if (shallow) return;

      setProcessedStorage(false);
    }

    router.events.on('routeChangeComplete', handleRouteChange);

    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, []);

  useEffect(() => {
    if (canShowModal) {
      setTimeout(() => {
        setModalOpen(true);
      }, 2000);
    }
  }, [canShowModal]);

  function handleClose() {
    setModalOpen(false);
  }

  async function onSubmit(data: FormData) {
    if (status === 'complete') {
      setCanShowModal(false);
      return;
    }

    setStatus('submit');
    await subscribe({ email: data.email, date_of_birth: data.dateOfBirth, gender: data.gender, formType: 'modal' });
    setStatus('complete');
  }

  if (!canShowModal) return null;

  return (
    <Modal
      open={modalOpen}
      width='900px'
      onClose={handleClose}
      content={
        <>
          <Typography variant='h3' gutterBottom='12'>
            {status !== 'complete' && <FormattedMessage {...newsletterFormMessages.title} />}
            {status === 'complete' && <FormattedMessage {...newsletterFormMessages.successMessage} />}
          </Typography>
          <Typography variant='bodyM' gutterBottom='24'>
            {status !== 'complete' && <FormattedMessage {...newsletterFormMessages.description} />}
            {status === 'complete' && <FormattedMessage {...newsletterFormMessages.subscribeSuccessAccountSection} />}
          </Typography>
          {status !== 'complete' && (
            <form onSubmit={handleSubmit(onSubmit)} noValidate>
              <InputControl id='email'>
                <TextInput
                  type='email'
                  placeholder={intl.formatMessage(formLabelMessages.yourEmail)}
                  {...register('email', { ...validationOptions.emailValidation })}
                  fullWidth
                />
                <ErrorMessage
                  name='email'
                  errors={errors}
                  render={({ message }) => <InputControl.Validation status='error'>{message}</InputControl.Validation>}
                />
              </InputControl>
              <PrivacyWrapper>
                <InputControl id='privacyPolicy'>
                  <Controller
                    render={({ field }) => (
                      <CheckboxWithConditionalChildren
                        checked={field.value}
                        onChange={field.onChange}
                        id='newsletter-modal'
                        label={
                          <Typography variant='bodyS'>
                            <FormattedMessage
                              {...authenticationMessages.privacyPolicyConsent}
                              values={{
                                privacyPolicy: (
                                  <StyledPrivacyPolicyLink
                                    href={paths.privacyPolicy}
                                    target='blank'
                                    rel='noreferrer noopener'
                                  >
                                    <FormattedMessage {...authenticationMessages.privacyPolicy} />
                                  </StyledPrivacyPolicyLink>
                                )
                              }}
                            />
                          </Typography>
                        }
                      >
                        <Flex flexDirection='row' gap={grid[12]}>
                          <FormProvider {...hookForm}>
                            <CheckboxWithConditionalChildren.Gender />
                          </FormProvider>
                          <CheckboxWithConditionalChildren.DateOfBirth {...register('dateOfBirth')} />
                        </Flex>
                      </CheckboxWithConditionalChildren>
                    )}
                    name='privacyPolicy'
                    rules={validationOptions.requiredField}
                    control={control}
                  />
                  <ErrorMessage
                    name='privacyPolicy'
                    errors={errors}
                    render={({ message }) => (
                      <InputControl.Validation status='error'>{message}</InputControl.Validation>
                    )}
                  />
                </InputControl>
              </PrivacyWrapper>
              <Button loading={status === 'submit'} style={{ whiteSpace: 'nowrap' }} type='submit'>
                <FormattedMessage {...newsletterFormMessages.signUp} />
              </Button>
            </form>
          )}

          {status === 'complete' && (
            <Flex gap={grid[8]} style={{ flexFlow: 'row wrap' }}>
              <Button onClick={handleClose}>
                <FormattedMessage {...ecommerceMessages.continueBrowsing} />
              </Button>

              {webStore.config.hasRetailStores ? (
                <Link href={paths.bookAppointment}>
                  <Button variant='outlined' onClick={handleClose}>
                    <FormattedMessage {...ecommerceMessages.planYourEyeTest} />
                  </Button>
                </Link>
              ) : (
                <Link href={paths.sunglasses}>
                  <Button variant='outlined' onClick={handleClose}>
                    <FormattedMessage {...ecommerceMessages.shopSunglasses} />
                  </Button>
                </Link>
              )}
            </Flex>
          )}
        </>
      }
      image={
        status !== 'complete' ? (
          <OptimizedCloudinaryImage
            sizes={`350px, ${breakpointRules.tablet} 30vw`}
            src='https://images.aceandtate.com/image/upload/v1686060347/web/modals/newsletter/a.jpg'
            fill
            style={{
              objectFit: 'cover'
            }}
            key='a'
            priority
            alt='Ace & Tate | Newsletter image'
          />
        ) : (
          <OptimizedCloudinaryImage
            sizes={`350px, ${breakpointRules.tablet} 30vw`}
            src='https://images.aceandtate.com/image/upload/v1686060347/web/modals/newsletter/b.jpg'
            key='b'
            fill
            style={{
              objectFit: 'cover'
            }}
            priority
            alt='Ace & Tate | Newsletter success image'
          />
        )
      }
    />
  );
}
