import styled, { css, keyframes } from 'styled-components';
import { brandColors, grid, Typography, breakpointRules, duration, easing } from '@aceandtate/ds';
import { zIndexMasterList, navHeightMobile, navHeightDesktop } from 'styles';
import { MenuDisplayOn } from './types';
import { CSSProperties } from 'react';
// This function manages the visibility of the navbar regarding the scroll
function navbarScrollAnimation(props: GlobalNavContainerProps, navbarHeight: number) {
  if (props.top) {
    return `${props.top}px`;
  } else if (props.navVisible) {
    return 0;
  }
  return `-${navbarHeight}px`;
}

type GlobalNavContainerProps = {
  navVisible: boolean;
  top: number;
  hasBorder: boolean;
};

const fadeIn = keyframes`
  0% {
    opacity: 0;
    transform: translateY(50px);
  }

  100% {
    opacity: 1;
    transform: translateY(0px);
  }
`;

type TopMenuItemProps = {
  $styleProps: {
    displayOn?: MenuDisplayOn;
    isFirstMobileSubtitle?: boolean;
    isInactive?: boolean;
    isMobileSubmenuOpen?: boolean;
    isSubmenuOpen?: boolean;
  };
  $isClickable?: boolean;
  href?: string;
  component?: React.FC;
};

export const TopMenuItem = styled(Typography)<TopMenuItemProps>`
  animation: ${fadeIn} ease-in-out 400ms 1;
  align-items: center;
  cursor: pointer;
  height: 100%;
  width: auto;

  && {
    display: ${props => (props.$styleProps.isMobileSubmenuOpen ? 'none' : 'flex')};
    margin-bottom: 16px;
    text-decoration: none;
  }

  ${props =>
    props.$styleProps.displayOn === 'mobile' &&
    css`
      @media ${breakpointRules.mobile} {
        && {
          margin-bottom: 15px;
        }
      }
    `}

  @media ${breakpointRules.laptop} {
    animation: none;
    border-bottom: ${props =>
      props.$styleProps.isSubmenuOpen ? `2px solid ${brandColors.dark}` : '2px solid transparent'};
    cursor: ${props => (props.$isClickable ? 'pointer' : 'default')};
    && {
      margin: 0 ${grid[16]} 0 0;
    }
  }
`;

export const FloatingButtonWrapper = styled.div`
  z-index: -1;
  position: absolute;
  right: ${grid[24]};
  top: calc((${grid[16]}) + (${navHeightMobile * 2}px));

  @media ${breakpointRules.laptop} {
    top: calc((${grid[16]}) + (${navHeightDesktop}px));
  }
`;

export const GlobalNavContainer = styled.div<GlobalNavContainerProps>`
  background: ${brandColors.light};
  color: ${brandColors.dark};
  box-sizing: border-box;
  display: flex;
  flex-flow: column;
  position: sticky;

  top: ${props => (props.navVisible ? props.top : navbarScrollAnimation(props, navHeightMobile * 2))};
  transition: all 0.3s ease;
  z-index: ${zIndexMasterList.navbar};
  /* using pseudo element as border to not impact rendering height */
  ${({ hasBorder }) =>
    hasBorder &&
    css`
      :after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 0.5px;
        background: ${brandColors.sand};
      }
    `}
  @media ${breakpointRules.laptop} {
    top: ${props => navbarScrollAnimation(props, navHeightDesktop)};
  }
`;

type TopMenuContainerProps = {
  isActive: boolean;
  displayOn?: MenuDisplayOn;
  isMobile?: boolean;
  $styleProps?: {
    color?: CSSProperties['color'];
  };
};

type PrimaryMenuContainerProps = {
  isTransparent?: boolean;
  isHomepage?: boolean;
  menuTextColor?: string;
};

export const TopMenuContainer = styled.div<TopMenuContainerProps>`
  display: none;
  a:hover {
    text-decoration: none;
  }
  ${props =>
    props.isMobile &&
    css`
      @media ${breakpointRules.mobile} {
        display: flex;
        flex-flow: column;
      }
    `}

  ${({ $styleProps = {} }) =>
    css`
      color: ${$styleProps.color || 'inherit'};
    `};

  @media ${breakpointRules.laptop} {
    display: flex;
    flex-flow: column;
    justify-content: center;
    position: relative;

    ${props =>
      props.isActive &&
      css`
        :after {
          align-self: center;
          bottom: -100%;
          height: 100%;
          right: -12px;
          left: -12px;
          content: '';
          display: flex;
          position: absolute;
          width: 100%;
        }
      `}
    img {
      height: 16.65rem !important;
    }
    :hover {
      ${TopMenuItem} {
        transition: border-bottom ${duration.medium} ${easing.in};
        ${({ $styleProps = {} }) => `border-bottom: 2px solid ${$styleProps.color || brandColors.dark}`};
      }
    }
  }

  ${props =>
    props.displayOn === 'mobile' &&
    css`
      @media ${breakpointRules.laptop} {
        display: none;
      }
    `}

  ${props =>
    props.displayOn === 'desktop' &&
    css`
      @media ${breakpointRules.mobile} {
        display: none;
      }
    `}
`;

export const PrimaryMenuContainer = styled.div<PrimaryMenuContainerProps>`
  display: flex;
  flex-flow: row;
  height: ${navHeightMobile}px;
  justify-content: space-between;
  padding: 0 ${grid[12]};

  @media ${breakpointRules.laptop} {
    height: ${navHeightDesktop}px;
    padding: ${grid[16]};
    position: ${({ isHomepage }) => (isHomepage ? 'absolute' : 'sticky')};
    width: 100%;
    color: ${brandColors.dark};
    background-color: ${brandColors.light};
    transition: all ${duration.long} ${easing.inOut};

    ${props =>
      props.isTransparent &&
      css`
        :not(:hover) {
          color: ${({ menuTextColor }: { menuTextColor?: string }) => menuTextColor ?? brandColors.dark};
          background-color: transparent;
          ${TopMenuItem} {
            color: ${({ menuTextColor }: { menuTextColor?: string }) => menuTextColor ?? brandColors.dark} !important;
          }
        }
      `}
  }

  a {
    outline: none;
  }
`;

// Left part of the MenuNavigation
export const Left = styled.div`
  align-items: center;
  display: flex;
`;

type NavbarIconProps = {
  name?: string;
  hidden?: boolean;
};

export const NavbarIcon = styled.div<NavbarIconProps>`
  cursor: pointer;
  display: ${props => (props.hidden ? 'none' : 'flex')};
  margin-bottom: ${grid[4]};
  margin-left: ${grid[4]};
  width: 120px;

  img {
    width: 120px;
  }
`;

// Center part of the MenuNavigation
export const Center = styled.div`
  display: flex;
  flex-flow: row;
  margin-left: ${grid[24]};
  margin-right: auto;
`;

export const ColumnContainer = styled.div`
  border-bottom: 2px solid ${brandColors.dark};
  display: flex;
  flex-flow: column;
  padding-bottom: 40px;
  padding-top: 40px;

  &:first-child {
    padding-top: 0;
  }

  &:last-child {
    border-bottom: none;
  }

  @media ${breakpointRules.laptop} {
    border-bottom: none;
    height: 420px;
    margin: 0 ${grid[12]};
    padding-bottom: ${grid[24]};
    padding-top: ${grid[24]} !important;
    width: 300px;
  }
`;

type ColumnTitleProps = {
  type?: 'media' | 'list';
};

export const ColumnTitle = styled(Typography)<ColumnTitleProps>`
  display: block;

  && {
    margin-bottom: ${grid[24]};
  }

  @media ${breakpointRules.laptop} {
    && {
      margin-top: 0;
      margin-bottom: ${props => (props.type === 'list' ? `${grid[12]}` : `${grid[4]}`)};
    }
  }
`;

type TextLinkProps = {
  component?: React.ReactNode;
  as?: string;
  href?: React.ReactNode;
};

export const TextLink = styled(Typography)<TextLinkProps>`
  cursor: pointer;

  && {
    margin-bottom: ${grid[8]};
  }

  &:last-child {
    && {
      margin-bottom: 0;
    }
  }
`;

// Right part of the MenuNavigation
export const Right = styled.div`
  align-items: center;
  display: flex;
  flex-flow: row;
`;

export const StaticLinks = styled.div`
  display: none;
  @media ${breakpointRules.laptop} {
    margin-right: ${grid[4]};
    display: flex;
  }
`;

export const StaticLink = styled.span`
  display: inline-block;
  padding: 0 ${grid[8]};
  cursor: pointer;
  border: none;
  border-right: 1px solid ${brandColors.dark};
  background: none;

  &:hover {
    text-decoration: underline;
  }

  &:last-child {
    border-right: unset;
  }
`;

type ImgProps = {
  href?: string;
};

export const AmpIcon = styled.img<ImgProps>`
  height: 55px;
  margin-top: 30px;
  width: 55px;
`;
