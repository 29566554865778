import React, { useContext } from 'react';
import { ServicesContext } from 'services/context';
import * as Styles from '../styles';

export default function TrustpilotWidget() {
  const { locale } = useContext(ServicesContext);
  const currentLang = locale.lang;

  return (
    <Styles.TpWidgetWrapper
      className='trustpilot-widget'
      data-locale={currentLang}
      data-template-id='5419b6ffb0d04a076446a9af'
      data-businessunit-id='586cc8200000ff00059a2d9f'
      data-style-height='19px'
      data-style-width='100%'
      data-theme='light'
    >
      <a href='https://www.trustpilot.com/review/aceandtate.com' target='_blank' rel='noopener noreferrer' />
    </Styles.TpWidgetWrapper>
  );
}
