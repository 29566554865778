import styled from 'styled-components';
import { Typography, grid, uiColors, breakpointRules, brandColors } from '@aceandtate/ds';

export const ConfiguratorSummaryContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: calc(-${grid[24]});
  align-items: center;
  flex-flow: column;
  @media ${breakpointRules.tablet} {
    flex-flow: row;
  }
`;

export const PriceSummaryContainer = styled.div`
  display: flex;
`;

export const Column = styled.div`
  display: flex;
  flex-flow: column;
  align-items: center;
  width: 50%;

  :first-child {
    margin-right: ${grid[24]};
  }
`;

export const Row = styled.div`
  display: block;
  margin-bottom: ${grid[12]};
  max-width: 168px;
  width: 100%;
`;

export const Content = styled.div`
  display: flex;
  border-top: 1px solid ${brandColors.dark10};
  padding-top: ${grid[24]};
`;

export const ColumnTitle = styled(Typography).attrs({ variant: 'h5' })`
  && {
    margin-bottom: ${grid[12]};
  }
`;

export const StepDescription = styled(Typography).attrs({ variant: 'bodyM' })`
  && {
    text-align: center;
    margin-bottom: ${grid[24]};
  }
`;

export const PriceLabel = styled.div`
  margin-right: ${grid[24]};
  white-space: nowrap;
  display: none;
  @media ${breakpointRules.tablet} {
    display: block;
  }
`;

export const SummaryDescription = styled.div`
  display: none;
  flex-flow: column;
  padding-right: ${grid[40]};
  margin-bottom: ${grid[24]};
  @media ${breakpointRules.tablet} {
    display: flex;
  }
`;

export const MobileSummary = styled.div`
  display: none;
  margin-bottom: ${grid[8]};
  flex-flow: column;
  width: 100%;
  display: flex;
  align-items: space-between;
  > * {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  @media ${breakpointRules.tablet} {
    display: none;
    align-items: unset;
  }
`;

export const PriceContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: ${grid[24]};
  width: 100%;
  @media ${breakpointRules.tablet} {
    width: auto;
  }
`;

export const LoadingOverlay = styled.div`
  display: flex;
  top: 0;
  left: 0;
  align-items: center;
  justify-content: center;
  position: absolute;
  height: 100%;
  width: 100%;
  background: ${brandColors.light};
`;

export const PromoContainer = styled.div`
  background: ${uiColors.infoLight};
  width: 100%;
  padding: ${grid[24]};
  margin-top: auto;
  left: 0;
  bottom: 0;
  position: relative;
  display: flex;
  align-items: center;
  flex-flow: column;
  @media ${breakpointRules.tablet} {
    flex-flow: row;
  }
`;

export const PromoCopy = styled.div`
  max-width: 100%;
  width: 100%;
  text-align: center;
  @media ${breakpointRules.tablet} {
    max-width: 300px;
    text-align: left;
    width: auto;
  }
`;

export const PromoList = styled.div`
  display: flex;
  flex-flow: column;
  margin-right: auto;
  align-items: center;
  width: 100%;
  margin-top: ${grid[24]};
  @media ${breakpointRules.tablet} {
    margin-right: unset;
    margin-left: auto;
    margin-top: 0;
    align-items: unset;
    width: auto;
  }
`;

export const PromoLabel = styled.div`
  border: 1px solid ${brandColors.light};
  border-radius: 4px;
  overflow: hidden;
  display: flex;
  font-weight: 400;
  line-height: 14px;
  font-size: 16px;
  margin-bottom: ${grid[8]};

  span {
    background: ${brandColors.light};
    padding: ${grid[12]};
    display: flex;
    align-items: center;
    min-width: 120px;
  }

  span:last-of-type {
    background: ${brandColors.sand};
    color: ${brandColors.accent};
    min-width: 0;
    border-right: none;
    border-left: 1px solid ${brandColors.light10};
  }

  :last-child {
    margin-bottom: 0;
  }
`;

export const TextHighlight = styled.span`
  color: ${brandColors.accent};
`;

type PriceProps = {
  lineThrough?: boolean;
};

export const Price = styled.span<PriceProps>`
  text-decoration: ${props => (props.lineThrough ? 'line-through' : 'none')};
`;

export const ATCButton = styled.div`
  flex-grow: 1;
  margin-left: auto;
  > * {
    width: 100%;
    flex-grow: 1;
  }
  @media ${breakpointRules.tablet} {
    > * {
      width: auto;
      flex-grow: 0;
    }
  }
`;
