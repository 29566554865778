import { trackGaEvent } from '../helpers';

/*
    3.1 Header Interactions
    When the user clicks navigation items, please send this event accordingly.
    It was designed as 2-level navigation click parameters.
    Navigation level one is the main item in the header and the second one is the submenu item.
    In case there is not any submenu item as level 2, you can exclude the parameter e.g. search.
*/

const isPlainObject = function (obj: any) {
  return Object.prototype.toString.call(obj) === '[object Object]';
};

type HeaderInteractionData = {
  navigationL1?: string;
  navigationL2?: string;
  clickedItemType?: string;
};

export function level1(payload: HeaderInteractionData) {
  if (!isPlainObject(payload)) return;

  trackGaEvent({
    event: 'headerInteraction',
    navigationL1: payload
  });
}

export function level2(payload: HeaderInteractionData) {
  if (!isPlainObject(payload)) return;

  trackGaEvent({
    clickedItemType: payload.clickedItemType,
    event: 'headerInteraction',
    navigationL1: payload.navigationL1,
    navigationL2: payload.navigationL2
  });
}
