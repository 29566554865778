import React from 'react';
import { brandColors } from '@aceandtate/ds';
import * as Styles from './styles';

type Props = {
  children: React.ReactNode;
  color?: string;
  backgroundColor?: string;
};

export default function WarningBar(props: Props & React.HTMLAttributes<HTMLDivElement>) {
  const { color = brandColors.light, backgroundColor = brandColors.dark, children } = props;

  return (
    <Styles.WarningBarWrapper color={color} backgroundColor={backgroundColor} {...props}>
      {children}
    </Styles.WarningBarWrapper>
  );
}
