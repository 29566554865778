import React, { memo, SVGProps } from 'react';
import dynamic from 'next/dynamic';
import { SkeletonBox } from 'components/Loaders/Skeleton/SkeletonBox';

type DynamicIconProps = {
  id: string;
  fontSize?: string;
  [rest: string]: any;
};

/**
 * Dynamically imports an icon from the design system
 * @param id the name of the icon. e.g: "IconBack"
 * @param fontSize Optional. The fontsize of the icon
 * @returns Icon or icon sized SkeletonBox while loading
 */
const DynamicIcon = ({ id, fontSize = null, ...rest }: DynamicIconProps) => {
  if (!id) return null;

  const Icon = dynamic<SVGProps<SVGSVGElement>>(
    () => import('@aceandtate/ds').then(mod => mod[id] || 'undefined') as any,
    {
      loading: () => <SkeletonBox as='span' width='1em' height='1em' style={{ fontSize: fontSize }} />
    }
  );

  /** If module does not exist, we return 'undefined' which will trigger the following warning/error:
   * "hydration-error-info.js:63 Warning: The tag <undefined> is unrecognized in this browser.
   * If you meant to render a React component, start its name with an uppercase letter."
   * The error is caught by the isValidElement check and we return null instead.
   * */

  if (!React.isValidElement(<Icon fontSize={fontSize} {...rest} />)) return null;
  return <Icon fontSize={fontSize} {...rest} />;
};

export default memo(DynamicIcon);
