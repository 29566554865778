import { useEffect, useContext } from 'react';
import { useUserState } from 'services/userService';
import { ServicesContext } from 'services/context';
import { User } from 'types/solidus';
import * as Sentry from '@sentry/nextjs';

function hasKustomerOnWindow() {
  return !!(window.Kustomer && window.Kustomer.start);
}

function startKustomer(lang: string, userProfile?: User) {
  hasKustomerOnWindow() &&
    window.Kustomer.start({ lang }, () => {
      if (userProfile) {
        describeUser(userProfile);
      }
    });
}

function describeUser(userProfile: User) {
  try {
    if (!userProfile) return;
    const { email } = userProfile;
    hasKustomerOnWindow() &&
      window.Kustomer.describeCustomer({
        attributes: {
          emails: email ? [email] : []
        }
      });
  } catch (err) {
    Sentry.captureException(`Error describing Kustomer User: ${err}`);
  }
}

export default function KustomerChat() {
  const user = useUserState();
  // ref to read state in
  const context = useContext(ServicesContext);
  const { lang } = context.locale;
  const { hasChatWidget } = context.webStore.config;

  useEffect(() => {
    function handleLoad() {
      startKustomer(lang, user.userProfile);
    }
    if (hasChatWidget && hasKustomerOnWindow()) {
      startKustomer(lang, user.userProfile);
    } else {
      window.addEventListener('kustomerLoaded', handleLoad);
    }

    return () => {
      window.removeEventListener('kustomerLoaded', handleLoad);
    };
  }, []);

  useEffect(() => {
    hasKustomerOnWindow() && window.Kustomer.startFinished && describeUser(user.userProfile);
  }, [user.isUserLoggedIn]);

  return null;
}
