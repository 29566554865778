import { createContext } from 'react';

export type PdPageContextProps = {
  pd: {
    left: string | null;
    right: string | null;
  };
  updatePd: (val: string) => void;
  orderNumber: string;
  setOrderNumber: React.Dispatch<React.SetStateAction<string>>;
};

export const initialState = {
  orderNumber: '',
  pd: {
    left: '',
    right: ''
  },
  setOrderNumber() {},
  updatePd() {}
};

export default createContext<PdPageContextProps>(initialState);
