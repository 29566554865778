import { defineMessages } from 'react-intl';

export default defineMessages({
  addToCart: {
    defaultMessage: 'Add to cart',
    id: 'global.ecommerce.addToCart'
  },
  apply: {
    defaultMessage: 'Apply',
    id: 'global.ecommerce.apply'
  },
  back: {
    defaultMessage: 'Back',
    id: 'global.ecommerce.back'
  },
  backToHomepage: {
    defaultMessage: 'Back to Ace & Tate',
    id: 'global.ecommerce.backToHomepage'
  },
  cancel: {
    defaultMessage: 'No thanks',
    id: 'global.ecommerce.cancel'
  },
  change: {
    defaultMessage: 'change',
    id: 'global.ecommerce.change'
  },
  clear: {
    defaultMessage: 'clear',
    id: 'global.ecommerce.clear'
  },
  close: {
    defaultMessage: 'close',
    id: 'global.ecommerce.close'
  },
  closed: {
    defaultMessage: 'Closed',
    id: 'global.ecommerce.closed'
  },
  closedNow: {
    defaultMessage: 'Closed Now',
    id: 'global.ecommerce.closedNow'
  },
  continue: {
    defaultMessage: 'Continue',
    id: 'global.ecommerce.continue'
  },
  continueBrowsing: {
    defaultMessage: 'Continue browsing',
    id: 'global.ecommerce.continueBrowsing'
  },
  continueShopping: {
    defaultMessage: 'continue shopping',
    id: 'global.ecommerce.continueShopping'
  },
  deal: {
    defaultMessage: 'Deal',
    id: 'cart.promotions.deal'
  },
  free: {
    defaultMessage: 'Free',
    id: 'global.ecommerce.free'
  },
  delete: {
    defaultMessage: 'Delete',
    id: 'global.ecommerce.delete'
  },
  discount: {
    defaultMessage: 'discount',
    id: 'global.ecommerce.discount'
  },
  orderDiscount: {
    defaultMessage: 'Discount',
    id: 'global.ecommerce.order.discount'
  },
  shipping: {
    defaultMessage: 'Shipping',
    id: 'global.ecommerce.shipping'
  },
  done: {
    defaultMessage: 'Done',
    id: 'global.ecommerce.done'
  },
  edit: {
    defaultMessage: 'Edit',
    id: 'global.ecommerce.edit'
  },
  findOutMore: {
    defaultMessage: 'Find out more',
    id: 'global.ecommerce.findOutMore'
  },
  isNew: {
    defaultMessage: 'New',
    id: 'global.ecommerce.isNew'
  },
  kids: {
    defaultMessage: 'Kids',
    id: 'global.ecommerce.kids'
  },
  archive: {
    defaultMessage: 'Archive',
    id: 'global.ecommerce.archive'
  },
  learnMore: {
    defaultMessage: 'Learn more',
    id: 'global.ecommerce.learnMore'
  },
  limitedEdition: {
    defaultMessage: 'Limited edition',
    id: 'global.ecommerce.limitedEdition'
  },
  loadMore: {
    defaultMessage: 'Load more',
    id: 'global.ecommerce.loadMore'
  },
  next: {
    defaultMessage: 'Next',
    id: 'global.ecommerce.next'
  },
  no: {
    defaultMessage: 'No',
    id: 'globalFormLabel.no'
  },
  ok: {
    defaultMessage: 'Ok',
    id: 'global.ecommerce.ok'
  },
  onlineOnly: {
    defaultMessage: 'Online only',
    id: 'global.ecommerce.onlineOnly'
  },
  openNow: {
    defaultMessage: 'Open Now',
    id: 'global.ecommerce.openNow'
  },
  pick: {
    defaultMessage: 'Pick',
    id: 'global.ecommerce.pick'
  },
  planYourEyeTest: {
    defaultMessage: 'Plan your eye test',
    id: 'global.button.planYourEyeTest'
  },
  prev: {
    defaultMessage: 'Prev',
    id: 'global.ecommerce.prev'
  },
  quantity: {
    defaultMessage: 'Quantity',
    id: 'global.ecommerce.quantity'
  },
  readMore: {
    defaultMessage: 'Read more',
    id: 'global.ecommerce.readMore'
  },
  sale: {
    defaultMessage: 'Sale',
    id: 'cart.promotions.sale'
  },
  sameDayGlasses: {
    defaultMessage: 'Same Day Glasses',
    id: 'global.ecommerce.sameDayGlasses'
  },
  selectStore: {
    defaultMessage: 'select store',
    id: 'global.ecommerce.selectStore'
  },
  showAll: {
    defaultMessage: 'Show all',
    id: 'global.showAll'
  },
  showLess: {
    defaultMessage: 'Show less',
    id: 'global.showLess'
  },
  send: {
    defaultMessage: 'Send',
    id: 'global.ecommerce.send'
  },
  shopContacts: {
    defaultMessage: 'Shop contacts',
    id: 'global.ecommerce.shopContacts'
  },
  shopGlasses: {
    defaultMessage: 'Shop glasses',
    id: 'global.ecommerce.shopGlasses'
  },
  shopNow: {
    defaultMessage: 'Shop Now',
    id: 'global.ecommerce.shopNow'
  },
  shopProduct: {
    defaultMessage: 'Shop Product',
    id: 'global.ecommerce.shopProduct'
  },
  shopSunglasses: {
    defaultMessage: 'Shop sunglasses',
    id: 'global.ecommerce.shopSunglasses'
  },
  shortCancel: {
    defaultMessage: 'Cancel',
    id: 'global.ecommerce.shortCancel'
  },
  startShopping: {
    defaultMessage: 'Start shopping',
    id: 'global.ecommerce.startShopping'
  },
  submit: {
    defaultMessage: 'Submit',
    id: 'global.ecommerce.submit'
  },
  total: {
    defaultMessage: 'Total',
    id: 'global.ecommerce.total'
  },
  subtotal: {
    defaultMessage: 'Subtotal',
    id: 'global.ecommerce.subtotal'
  },
  updateItem: {
    defaultMessage: 'Update item',
    id: 'global.ecommerce.updateItem'
  },
  yes: {
    defaultMessage: 'Yes',
    id: 'globalFormLabel.yes'
  }
});
