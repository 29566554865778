import styled, { css } from 'styled-components';
import { brandColors } from '@aceandtate/ds';
import { progressbar } from 'styles';

type PropsOuterBar = {
  width?: string;
};

export const OuterBar = styled.div<PropsOuterBar>`
  background-color: ${brandColors.dark10};
  border-radius: 50px;
  height: 2px;
  width: ${({ width }) => width || '100%'};
`;

type PropsInnerBar = {
  duration?: string;
};

export const InnerBar = styled.div<PropsInnerBar>`
  animation: ${({ duration }) =>
    css`
      ${progressbar} ${duration || '30s'};
    `};
  background-color: ${brandColors.dark};
  border-bottom-left-radius: 50px;
  border-top-left-radius: 50px;
  height: 2px;
  transition: transform 0.8s ease;
  transform-origin: left;
`;
