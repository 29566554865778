import { useContext } from 'react';
import { useFetch } from 'utils/helpers/customFetch';
import { ServicesContext } from 'services/context';

export function useSubscribeToStockMailingList() {
  const fetch = useFetch();
  const context = useContext(ServicesContext);
  const { locale } = context;
  return async data => {
    const response = fetch(`/frames/${data.sku}/stock_notification`, {
      body: {
        ...data,
        country: `${locale.country.toUpperCase()}`,
        lang: `${locale.lang}_${locale.country}`
      },
      method: 'POST'
    });
    return response;
  };
}
