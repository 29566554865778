import { borderRadius, breakpointRules, Flex, grid, Typography, uiColors } from '@aceandtate/ds';
import styled, { CSSProperties } from 'styled-components';
import { zIndexMasterList } from 'styles';

export const ButtonWrap = styled.div`
  transition: all 250ms ease;
  max-width: 0px;
  opacity: 0;
  overflow: hidden;
  visibility: hidden;
`;

export const DraftModeToastContent = styled(Typography)`
  align-items: center;
  display: flex;
  gap: ${grid[8]};
`;

export const DraftModeToast = styled(Flex)`
  align-items: center;
  background-color: ${uiColors.warningLight};
  border-radius: ${borderRadius.medium};
  bottom: 10px;
  color: ${uiColors.warning};
  gap: ${grid[12]};
  padding: ${grid[4]} ${grid[12]};
  position: fixed;
  right: 10px;

  z-index: ${zIndexMasterList.draftMode};

  @media ${breakpointRules.laptop} {
    padding: ${grid[12]} ${grid[24]};
  }

  &:hover {
    ${ButtonWrap} {
      opacity: 1;
      visibility: visible;
      max-width: 75px;
    }
  }
`;

export const DraftModeTagWrap = styled(Typography)<{ position?: CSSProperties['position'] }>`
  display: inline-flex;
  align-items: center;
  align-self: flex-start;
  white-space: nowrap;
  gap: ${grid[8]};

  background-color: ${uiColors.warningLight};
  border-radius: ${borderRadius.medium};
  color: ${uiColors.warning};
  padding: 0 ${grid[8]};
`;
