import { defineMessages } from 'react-intl';

export default defineMessages({
  errorModalBody: {
    defaultMessage: 'Oops, something went wrong.',
    id: 'productConfigurator.plugin.errorModalBody'
  },
  errorModalCta: {
    defaultMessage: 'Close',
    id: 'productConfigurator.plugin.errorModalCta'
  }
});
