import styled from 'styled-components';
import { uiColors, grid, breakpointRules, brandColors, borderRadius } from '@aceandtate/ds';
import NextLink from 'next/link';
import { ProgressIndicatorProps } from './partials/ProgressIndicator';

export const DescriptionBlock = styled.div`
  background-color: ${uiColors.warningLight};
  padding: ${grid[8]} ${grid[12]};
  border-radius: ${grid[8]};
`;

export const SummaryContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const CliponStepLink = styled.div`
  display: flex;
  flex-direction: row-reverse;
  margin-bottom: ${grid[12]};
  padding-right: ${grid[4]};
`;

export const DescriptionsUl = styled.ul`
  padding-inline-start: 0;
  list-style-type: disc;
  list-style-position: inside;
  & > li {
    margin-top: ${grid[8]};
    margin-bottom: ${grid[4]} !important;
    margin-left: 0;
  }
`;

export const DescriptionsList = styled.li`
  margin-left: ${grid[24]};

  &:first-child {
    background-color: ${uiColors.warningLight};
    list-style: none;
    padding: 2px ${grid[8]};
    margin-left: 0;
    margin-bottom: ${grid[12]};
    width: fit-content;
    border-radius: ${grid[4]};
    font-size: ${grid[12]};
    font-weight: 500;

    @media ${breakpointRules.laptop} {
      font-size: 14px;
    }
  }
`;

export const ScrollMargin = styled.div`
  flex-shrink: 0;
  height: 1px;
  width: ${grid[12]};
`;

export const SubTotalRow = styled.div`
  @media ${breakpointRules.laptop} {
    display: flex;
    align-items: center;
    gap: ${grid[12]};
  }
`;

export const CategoryBlock = styled.div`
  margin-top: ${grid[24]};
`;

export const ExceptionBlock = styled.div`
  margin-top: ${grid[24]};
`;

export const NoticeBlock = styled.div`
  margin-top: ${grid[8]};
`;

export const ModalContent = styled.div`
  padding: ${grid[24]};
`;

export const ProgressIndicatorShell = styled.div<{ height: ProgressIndicatorProps['height'] }>`
  width: 100%;
  height: ${({ height }) => height};
  background-color: ${brandColors.dark10};
`;

export const ProgressIndicatorBar = styled.div<{ width: string }>`
  transition: transform 0.5s ease-in-out;
  transform-origin: left;
  transform: scaleX(${({ width }) => width || 1});
  background-color: ${brandColors.accent};
  border-bottom-right-radius: 50px;
  border-top-right-radius: 50px;
  height: inherit;
`;

export const PromotionsBlock = styled.div`
  display: flex;
  justify-content: space-between;
  background-color: ${uiColors.infoLight};
  border-radius: ${borderRadius.small};
  padding: ${grid[8]} ${grid[12]};
`;

export const PaymentBlock = styled.div`
  border: 1px solid ${brandColors.dark10};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: ${grid[12]};
  padding: ${grid[12]};
  border-radius: ${grid[4]};
`;

export const UnderlineNextLink = styled(NextLink)`
  text-decoration: underline;
`;

export const FeatureContainer = styled.div`
  position: relative;
  padding: 40px;
  background: ${brandColors.sandLight};
  border-radius: ${borderRadius.medium};
  display: flex;
  justify-content: center;
  flex-flow: column;
  text-align: center;
`;

export const FeatureImage = styled.div`
  margin-bottom: ${grid[20]};
  position: relative;
  width: 100%;
  height: 100px;
  @media ${breakpointRules.laptop} {
    height: 120px;
  }
`;
