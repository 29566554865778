import { Breakpoint, breakpointRules } from '@aceandtate/ds';
import { CSSProperties } from 'react';
import styled, { css } from 'styled-components';

type VisibilityProps = {
  visibleOn: Breakpoint[];
  displayAs?: CSSProperties['display'];
  layout?: 'fill' | 'auto';
};

const Visibility = styled.div<VisibilityProps>`
  ${({ layout = 'fill' }) =>
    layout === 'fill' &&
    css`
      height: 100%;
      width: 100%;
    `}

  ${({ visibleOn, displayAs = 'block' }) => {
    const brkMap: Breakpoint[] = ['tablet', 'laptop', 'desktop'];

    return css`
      display: ${visibleOn.includes('mobile') ? displayAs : 'none'};

      ${brkMap.map(
        brk => css`
          @media ${breakpointRules[brk]} {
            display: ${visibleOn.includes(brk) ? displayAs : 'none'};
          }
        `
      )}
    `;
  }}
`;

export default Visibility;
