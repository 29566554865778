import React from 'react';
import LoaderIcon from 'components/Loaders/LoaderIcon';
import ProgressBar from 'components/ProgressBar';
import ScrollLock from 'components/ScrollLock';
import type { ProgressBarProps } from 'components/ProgressBar';
import * as Styles from './styles';

type Props = {
  fullscreen?: boolean;
  messages?: string[];
  progressBarProps?: ProgressBarProps;
  type?: 'icon' | 'text';
  hasProgressBar?: boolean;
  hasScrollLock?: boolean;
};

export default function FullscreenLoader(props: Props) {
  const {
    type = 'icon',
    messages = [],
    fullscreen = true,
    hasScrollLock = true,
    hasProgressBar,
    progressBarProps
  } = props;

  return (
    <Styles.Wrapper fullscreen={fullscreen}>
      {hasScrollLock && <ScrollLock />}
      {type === 'icon' && <LoaderIcon size={50} />}
      {type === 'text' && (
        <Styles.MessagesWrapper fullscreen={fullscreen} messagesLength={messages.length}>
          {messages.map((message: string, index: number) => (
            <Styles.Message key={message} index={index} messagesLength={messages.length}>
              {message}
            </Styles.Message>
          ))}
        </Styles.MessagesWrapper>
      )}
      {hasProgressBar && <ProgressBar {...progressBarProps} />}
    </Styles.Wrapper>
  );
}
