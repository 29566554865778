import styled, { css } from 'styled-components';
import { breakpointRules, Typography, brandColors, grid } from '@aceandtate/ds';

export const Option = styled.li<{ $active: boolean }>`
  display: flex;
  cursor: pointer;
  align-items: center;
  padding: ${grid[8]} ${grid[12]};

  ${({ $active }) =>
    $active &&
    css`
      background-color: ${brandColors.accent10};
    `}

  :hover {
    background-color: ${brandColors.accent20};
  }
`;

export const Wrapper = styled.div`
  width: 100%;
`;

export const MessageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex-wrap: wrap;
  column-gap: ${grid[8]};
  margin-left: ${grid[8]};
  justify-content: center;

  @media ${breakpointRules.tablet} {
    flex-direction: row;
    align-items: center;
  }
`;

export const ShippingExclusion = styled(Typography).attrs({
  color: brandColors.dark75,
  variant: 'bodyS'
})`
  white-space: pre-wrap;
  text-align: left;
`;

export const FlagWrapper = styled.div`
  display: flex;
`;
