import { defineMessages } from 'react-intl';

export default defineMessages({
  addToCartButton: {
    defaultMessage: 'Add to cart',
    id: 'pdp.config.addToCart'
  },
  addedToHtoLabel: {
    defaultMessage: 'Added to home try-on',
    id: 'pdp.addedToHtoLabel'
  },
  htoButton: {
    defaultMessage: 'Add to Home try-on',
    id: 'pdp.htoButton'
  },
  htoLimitReachedSnackbar: {
    defaultMessage: 'You already have {htoLimit} items in your Home Try-on order.',
    id: 'pdp.htoLimitReachedSnackbar'
  },
  htoUnavailableLabel: {
    defaultMessage: 'Home try-on unavailable',
    id: 'pdp.htoUnavailableLabel'
  },
  personaliseProduct: {
    defaultMessage: 'Personalise for a loved one',
    id: 'pdp.personaliseProduct'
  },
  selectGlasses: {
    defaultMessage: 'Select your glasses',
    id: 'pdp.config.selectGlasses'
  },
  selectLenses: {
    defaultMessage: 'Select lenses and buy',
    id: 'pdp.config.selectLenses'
  },
  selectSunglasses: {
    defaultMessage: 'Select your sunglasses',
    id: 'pdp.config.selectSunglasses'
  }
});
