import { defineMessages } from 'react-intl';

export default defineMessages({
  contactLensTrialBody: {
    defaultMessage:
      'It looks like we don’t yet offer this product in your country. Not to worry though, you can still order the 30-day pack of Ace & Tate Contacts.',
    id: 'incompatibleCountryModal.contactLensTrialBody'
  },
  cta: {
    defaultMessage: 'Ok',
    id: 'incompatibleCountryModal.cta'
  },
  productBody: {
    defaultMessage: 'It looks like we don’t yet offer this product in your country.',
    id: 'incompatibleCountryModal.productBody'
  },
  serviceBody: {
    defaultMessage: 'It looks like we don’t yet offer this service in your country.',
    id: 'incompatibleCountryModal.body'
  },
  title: {
    defaultMessage: 'Oh dear',
    id: 'incompatibleCountryModal.title'
  }
});
