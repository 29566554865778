import { defineMessages } from 'react-intl';

export default defineMessages({
  UV420: {
    defaultMessage: 'Blue Light filter',
    id: 'cart.productDetails.filters.blueLight'
  },
  ATGREY01TR: {
    defaultMessage: 'Light Responsive Lenses',
    id: 'cart.productDetails.filters.photochromic'
  },
  index_174: {
    defaultMessage: '1.74 Lenses',
    id: 'cart.productDetails.filters.174Lenses'
  },
  polarised: {
    defaultMessage: 'Polarised',
    id: 'cart.productDetails.filters.polarised'
  },
  premium: {
    defaultMessage: 'Premium lenses',
    id: 'cart.productDetails.lenses.premiumLenses'
  },
  sun_premium: {
    defaultMessage: 'Sun premium lenses',
    id: 'cart.productDetails.lenses.sunPremiumLenses'
  },
  supreme: {
    defaultMessage: 'Supreme lenses',
    id: 'cart.productDetails.lenses.supremeLenses'
  },
  undefined: {
    defaultMessage: 'undefined',
    id: 'cart.productDetails.lenses.undefined'
  }
});
