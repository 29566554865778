import React from 'react';
import { FormattedMessage } from 'react-intl';
import Modal from 'components/Modal';
import messages from './messages';
import { Button, Typography } from '@aceandtate/ds';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  type?: 'service' | 'product' | 'contactLensTrial';
};

export default function IncompatibleConfigModal(props: Props) {
  const { isOpen, onClose, type = 'service' } = props;
  return (
    <Modal open={isOpen} onClose={onClose} data-testid={'incompatible-country-modal'}>
      <Modal.Title>
        <Typography variant='h4'>
          <FormattedMessage {...messages.title} />
        </Typography>
      </Modal.Title>
      <Modal.Body>
        {type === 'service' && (
          <Typography variant='bodyM' fontWeight='regular'>
            <FormattedMessage {...messages.serviceBody} />
          </Typography>
        )}
        {type === 'product' && (
          <Typography variant='bodyM'>
            <FormattedMessage {...messages.productBody} />
          </Typography>
        )}
        {type === 'contactLensTrial' && (
          <Typography variant='bodyM'>
            <FormattedMessage {...messages.contactLensTrialBody} />
          </Typography>
        )}
      </Modal.Body>
      <Modal.Buttons>
        <Button color='primary' data-testid='incompatible-country-modal-cta' onClick={onClose}>
          <FormattedMessage {...messages.cta} />
        </Button>
      </Modal.Buttons>
    </Modal>
  );
}
