import styled from 'styled-components';
import { brandColors, breakpointRules, grid } from '@aceandtate/ds';
import { navHeightMobile, navHeightDesktop } from 'styles';

type MenuPaneProps = {
  hidden?: boolean;
  top?: number;
  name: string;
};

export const MenuPane = styled.div<MenuPaneProps>`
  background-color: ${brandColors.light};
  bottom: 0;
  display: ${props => (props.hidden ? 'none' : 'block')};
  left: 0;
  margin-top: ${navHeightMobile}px;
  max-height: 100vh;
  overflow-x: hidden;
  overflow-y: scroll;
  padding: ${grid[16]};
  position: fixed;
  right: 0;
  top: ${props => (props.top ? `${props.top}px` : '0')};
  -webkit-overflow-scrolling: touch;

  @media ${breakpointRules.tablet} {
    padding: ${grid[24]};
  }

  @media ${breakpointRules.laptop} {
    margin-top: ${navHeightDesktop}px;
  }
`;
