import { defineMessages } from 'react-intl';

const productFeatureMessages = defineMessages({
  nosepads: {
    defaultMessage: 'Nose pads',
    id: 'productType.nosepads'
  }
});

export default productFeatureMessages;
