import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { SearchBox, Stats, connectStateResults } from 'react-instantsearch-dom';
import { breakpointRules, Button, Typography, useMediaQuery, IconClose, IconSearch } from '@aceandtate/ds';

import ecommerceMessages from 'messages/ecommerce';
import messages from '../../messages';
import * as Styles from './styles';

const StateStats = connectStateResults(({ children, searchState, searchResults }: any) => {
  if (!searchResults || !searchState.query) {
    return null;
  }
  return children;
});

type Props = {
  maxWidth?: number;
  stats?: boolean;
  closeHandler?: () => void;
};

export default function SearchInput(props: Props) {
  const { maxWidth = null, stats, closeHandler } = props;

  const intl = useIntl();
  const isLaptop = useMediaQuery(breakpointRules.laptop);

  return (
    <Styles.InputWrapper maxWidth={maxWidth}>
      <Styles.StyledSearchBox>
        <SearchBox
          autoFocus
          submit={<IconSearch />}
          translations={{
            placeholder: 'Pierce Large',
            resetTitle: intl.formatMessage(messages.resetTitle),
            submitTitle: intl.formatMessage(messages.submitTitle)
          }}
          reset={
            !isLaptop ? (
              <Typography variant='bodyM'>
                <FormattedMessage {...ecommerceMessages.clear} />
              </Typography>
            ) : (
              <IconClose />
            )
          }
        />
        <Button size='small' onClick={closeHandler}>
          <FormattedMessage {...ecommerceMessages.shortCancel} />
        </Button>
      </Styles.StyledSearchBox>

      {stats && (
        <StateStats>
          <Stats
            translations={{
              stats(count: number) {
                return intl.formatMessage(messages.results, { count });
              }
            }}
          />
        </StateStats>
      )}
    </Styles.InputWrapper>
  );
}
