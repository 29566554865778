import { useContext } from 'react';
import { ServicesContext } from 'services/context';
import clonedeep from 'lodash.clonedeep';
import { ExperienceConfiguration, BaselineWithVariants, Baseline } from '@ninetailed/experience.js-shared';
import { ExperienceVariants, SupportedComponentProps, Variant } from 'types/ninetailed';

function findExperienceMatch(experienceId: string, experiences: ExperienceConfiguration[]) {
  return experiences.find(experiences => experiences.id === experienceId);
}

function isExperienceEligible(
  experienceVariants: ExperienceVariants<SupportedComponentProps>,
  matchedExperience: ExperienceConfiguration
) {
  if (!matchedExperience) return false;

  const requiredVariantsLength = matchedExperience.distribution.length - 1; // (distribution = variants + baseline)

  const isEligible = requiredVariantsLength <= experienceVariants.variants.length;

  !isEligible &&
    console.warn(
      `Ninetailed: The experience with id ${matchedExperience.id}, requires more variants than the available in the component config. The component will not be included in this experience.`
    );

  return isEligible;
}

function buildComponentsProperty<T extends SupportedComponentProps & { id: string }>(
  baselineId: string,
  variants: Variant<T>[]
): BaselineWithVariants<T>[] {
  return [{ baseline: { id: baselineId }, variants: [...clonedeep(variants)] }];
}

function buildFullExperience<T extends SupportedComponentProps & { id: string }>(
  partialExperience: ExperienceConfiguration,
  components: BaselineWithVariants<T>[]
): ExperienceConfiguration {
  return {
    ...clonedeep(partialExperience),
    components
  };
}

type GetComponentExperiencesProps = {
  baselineId: string;
  experiencesVariants: readonly ExperienceVariants<SupportedComponentProps>[];
  experiences: ExperienceConfiguration[];
};

function getComponentExperiences({ baselineId, experiencesVariants, experiences }: GetComponentExperiencesProps) {
  return experiencesVariants.reduce((componentExperiences: ExperienceConfiguration[], experienceVariants) => {
    const matchedExperience = findExperienceMatch(experienceVariants.experienceId, experiences);

    if (!isExperienceEligible(experienceVariants, matchedExperience)) return componentExperiences;

    const newComponentsProperty = buildComponentsProperty(baselineId, experienceVariants.variants);

    const fullComponentExperience = buildFullExperience(matchedExperience, newComponentsProperty);

    return [...componentExperiences, fullComponentExperience];
  }, []);
}

type ExperienceComponentProps = {
  baseline: Baseline<SupportedComponentProps>;
  experiencesVariants: readonly ExperienceVariants<SupportedComponentProps>[];
};

export function useExperienceConfiguration({ baseline, experiencesVariants }: ExperienceComponentProps) {
  Object.freeze(experiencesVariants);

  const ctx = useContext(ServicesContext);

  const experiences = getComponentExperiences({
    baselineId: baseline.id,
    experiences: ctx?.ninetailed?.experiences || [],
    experiencesVariants
  });

  return {
    experiences
  };
}
