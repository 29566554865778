import React from 'react';
import { Drawer, Typography, IconButton, IconClose, IconChevronLeft } from '@aceandtate/ds';

import * as Styles from './styles';

type AdditionalContent = {
  pageIndex: number;
  component: React.ReactNode;
};

type ConfiguratorProps = {
  children: React.ReactNode;
  open: boolean;
  onClose: () => void;
  titles: React.ReactNode[];
  promotion?: React.ReactNode;
  anchor?: 'bottom' | 'left' | 'right' | 'top';
  fullScreen?: boolean;
  onBack?: (previousStepName?: string) => void;
  showSummary?: boolean;
  stepIndex?: number;
  stepName?: string;
  summary?: React.ReactNode;
  additionalContent?: AdditionalContent[];
};

export default function Configurator(props: ConfiguratorProps) {
  const {
    open,
    onClose,
    onBack,
    children,
    titles,
    showSummary = false,
    summary = null,
    stepIndex = 0,
    stepName = '',
    fullScreen = false,
    anchor = 'right',
    additionalContent
  } = props;

  const drawerElements = React.Children.toArray(children);

  function findAdditionalContent(index: number) {
    const matchedContent = additionalContent && additionalContent.find(x => x.pageIndex === index);

    if (matchedContent) {
      return matchedContent.component;
    }

    return null;
  }

  return (
    <Drawer open={open} onClose={onClose} width='auto' position={anchor}>
      <Styles.TopIconsContainer stepIndex={stepIndex} data-cs-signal-enabled>
        {onBack && stepIndex > 0 && (
          <IconButton color='secondary' onClick={() => onBack(stepName)} data-testid='back-button'>
            <IconChevronLeft fontSize={24} />
          </IconButton>
        )}
        <IconButton color='secondary' onClick={onClose} data-testid='close-button'>
          <IconClose fontSize={24} />
        </IconButton>
      </Styles.TopIconsContainer>
      <Styles.InnerModalContainer fullScreen={fullScreen} data-cs-signal-enabled>
        <Styles.SliderContainer fullScreen={fullScreen}>
          <Styles.SlideAnimationWrapper>
            <Styles.SlideAnimationInnerScroll stepIndex={stepIndex}>
              {drawerElements.map((child, index) => (
                <Styles.Slide key={index}>
                  <Styles.SlideContent active={index >= 0}>
                    <Styles.SlideHeader>
                      <Typography variant='h4'>{titles[index]}</Typography>
                    </Styles.SlideHeader>
                    {child}
                  </Styles.SlideContent>
                  {findAdditionalContent(index)}
                </Styles.Slide>
              ))}
            </Styles.SlideAnimationInnerScroll>
          </Styles.SlideAnimationWrapper>
          {showSummary && <Styles.BottomDrawer>{summary}</Styles.BottomDrawer>}
        </Styles.SliderContainer>
      </Styles.InnerModalContainer>
    </Drawer>
  );
}
