import { defineMessages } from 'react-intl';

const messages = defineMessages({
  account: {
    defaultMessage: 'Account',
    id: 'navbar.account'
  },
  cart: {
    defaultMessage: 'Cart',
    id: 'navbar.cart'
  },
  closeMobileMenu: {
    defaultMessage: 'Close menu',
    id: 'navbar.closeMobileMenu'
  },
  closeMobileSubmenu: {
    defaultMessage: 'Close submenu',
    id: 'navbar.closeMobileSubmenu'
  },
  contacts: {
    defaultMessage: 'Contacts',
    id: 'navbar.contacts'
  },
  'dashboard.item.accountInformation': {
    defaultMessage: 'Account Information',
    id: 'dashboard.item.accountInformation'
  },
  'dashboard.item.orders': {
    defaultMessage: 'Orders',
    id: 'dashboard.item.orders'
  },
  'dashboard.item.prescriptions': {
    defaultMessage: 'Prescriptions',
    id: 'dashboard.item.prescriptions'
  },
  'dashboard.item.subscriptions': {
    defaultMessage: 'Newsletter subscriptions',
    id: 'dashboard.item.subscriptions'
  },
  'dashboard.item.tryOn': {
    defaultMessage: 'Virtual try-on',
    id: 'dashboard.item.tryOn'
  },
  eyeTest: {
    defaultMessage: 'Eye Test',
    id: 'navbar.eyeTest'
  },
  freeEyeTest: {
    defaultMessage: 'Free Eye Test',
    id: 'navbar.freeEyeTest'
  },
  glasses: {
    defaultMessage: 'Glasses',
    id: 'navbar.glasses'
  },
  login: {
    defaultMessage: 'Login',
    id: 'navbar.login'
  },
  'navbar.account.login': {
    defaultMessage: 'Login',
    id: 'navbar.account.login'
  },
  'navbar.account.logout': {
    defaultMessage: 'Log out',
    id: 'navbar.account.logout'
  },
  openMobileMenu: {
    defaultMessage: 'Open menu',
    id: 'navbar.openMobileMenu'
  },
  search: {
    defaultMessage: 'Search',
    id: 'navbar.search'
  },
  stores: {
    defaultMessage: 'Stores ',
    id: 'navbar.stores'
  },
  sunglasses: {
    defaultMessage: 'Sunglasses',
    id: 'navbar.sunglasses'
  },
  wishlist: {
    defaultMessage: 'Wishlist',
    id: 'navbar.wishlist'
  }
});

export default messages;
