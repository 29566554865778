import { defineMessages } from 'react-intl';

const messages = defineMessages({
  aceatate: {
    defaultMessage: 'Acetate',
    id: 'global.filters.acetate'
  },
  allFilters: {
    defaultMessage: 'All filters',
    id: 'global.filters.allFilters'
  },
  angular: {
    defaultMessage: 'Angular',
    id: 'global.filters.angular'
  },
  aviator: {
    defaultMessage: 'aviator',
    id: 'global.filters.aviator'
  },
  basePrice: {
    defaultMessage: '{basePrice}',
    id: 'global.filters.basePrice'
  },
  beige: {
    defaultMessage: 'Beige',
    id: 'global.filters.colors.beige'
  },
  bio_acetate: {
    defaultMessage: 'Bio Acetate',
    id: 'global.filters.bio_acetate'
  },
  black: {
    defaultMessage: 'Black',
    id: 'global.filters.colors.black'
  },
  blue: {
    defaultMessage: 'Blue',
    id: 'global.filters.colors.blue'
  },
  bold: {
    defaultMessage: 'Bold',
    id: 'global.filters.bold'
  },
  brown: {
    defaultMessage: 'Brown',
    id: 'global.filters.colors.brown'
  },
  cat_eye: {
    defaultMessage: 'Cat Eye',
    id: 'global.filters.cat_eye'
  },
  classic: {
    defaultMessage: 'Classic',
    id: 'global.filteres.classic'
  },
  clear: {
    defaultMessage: 'Clear',
    id: 'global.filters.colors.clear'
  },
  clearAll: {
    defaultMessage: 'Clear all',
    id: 'global.filters.clearAll'
  },
  clearFilters: {
    defaultMessage: 'Clear filters',
    id: 'global.filters.clearFilters'
  },
  clubmaster: {
    defaultMessage: 'Clubmaster',
    id: 'global.filterse.clubmaster'
  },
  colorPatterns: {
    defaultMessage: 'Color Pattern',
    id: 'global.filters.groups.color_pattern'
  },
  colors: {
    defaultMessage: 'Colours',
    id: 'global.filters.groups.colors'
  },
  combi: {
    defaultMessage: 'Combi',
    id: 'global.filters.combi'
  },
  filters: {
    defaultMessage: 'Filters',
    id: 'global.filters.filters'
  },
  flat_top: {
    defaultMessage: 'Flat Top',
    id: 'global.filters.flat_top'
  },
  gold: {
    defaultMessage: 'Gold',
    id: 'global.filters.colors.gold'
  },
  gradient_or_two_tone: {
    defaultMessage: 'Gradient',
    id: 'global.filters.gradient_or_two_tone'
  },
  green: {
    defaultMessage: 'Green',
    id: 'global.filters.colors.green'
  },
  grey: {
    defaultMessage: 'Grey',
    id: 'global.filters.colors.grey'
  },
  hexagonal: {
    defaultMessage: 'Hexagonal',
    id: 'globa.fiters.hexagonal'
  },
  limitedEditionPrice: {
    defaultMessage: '{limitedEditionPrice}',
    id: 'global.filters.limitedEditionPrice'
  },
  materials: {
    defaultMessage: 'Material',
    id: 'global.filters.groups.material'
  },
  medium: {
    defaultMessage: 'Medium',
    id: 'global.filters.medium'
  },
  metal: {
    defaultMessage: 'Metal',
    id: 'global.filter.metal'
  },
  narrow: {
    defaultMessage: 'Narrow',
    id: 'global.filters.narrow'
  },
  orange: {
    defaultMessage: 'Orange',
    id: 'global.filters.colors.orange'
  },
  oval: {
    defaultMessage: 'Oval',
    id: 'global.filters.oval'
  },
  oversized: {
    defaultMessage: 'Oversized',
    id: 'global.filters.oversized'
  },
  pattern: {
    defaultMessage: 'Pattern',
    id: 'global.filters.pattern'
  },
  pink: {
    defaultMessage: 'Pink',
    id: 'global.filters.colors.pink'
  },
  premiumPrice: {
    defaultMessage: '{premiumPrice}',
    id: 'global.filters.premiumPrice'
  },
  prices: {
    defaultMessage: 'Price from',
    id: 'global.filters.groups.prices'
  },
  purple: {
    defaultMessage: 'Purple',
    id: 'global.filters.colors.purple'
  },
  rectangular: {
    defaultMessage: 'Rectangle',
    id: 'global.filters.rectangular'
  },
  recycled: {
    defaultMessage: 'Recycled',
    id: 'global.filters.recycled'
  },
  red: {
    defaultMessage: 'Red',
    id: 'global.filters.colors.red'
  },
  renew_bio_acetate: {
    defaultMessage: 'Renew Bio Acetate',
    id: 'global.filters.renew_bio_acetate'
  },
  rimless: {
    defaultMessage: 'Rimless',
    id: 'global.filters.rimless'
  },
  round: {
    defaultMessage: 'Round',
    id: 'global.filters.round'
  },
  shapes: {
    defaultMessage: 'Frame Shapes',
    id: 'global.filters.groups.shapes'
  },
  silver: {
    defaultMessage: 'Silver',
    id: 'global.filters.colors.silver'
  },
  solid: {
    defaultMessage: 'Solid',
    id: 'global.filters.solid'
  },
  square: {
    defaultMessage: 'Square',
    id: 'global.filters.square'
  },
  styles: {
    defaultMessage: 'Frame Styles',
    id: 'global.filters.groups.styles'
  },
  titanium: {
    defaultMessage: 'Titanium',
    id: 'filters.global.titanium'
  },
  transparent: {
    defaultMessage: 'Transparent',
    id: 'global.filters.transparent'
  },
  wayfarer: {
    defaultMessage: 'Wayfarer',
    id: 'global.filters.wayfarer'
  },
  white: {
    defaultMessage: 'White',
    id: 'global.filters.colors.white'
  },
  wide: {
    defaultMessage: 'Wide',
    id: 'global.filters.wide'
  },
  widths: {
    defaultMessage: 'Frame Width',
    id: 'global.filters.groups.width'
  },
  yellow: {
    defaultMessage: 'Yellow',
    id: 'global.filters.colors.yellow'
  }
});

export default messages;
