import { Locale } from 'types/locale';
import { useToriiFetch, toriiFetch } from 'utils/helpers/customFetch';

export type CustomizationOptions =
  | 'lens_color_blf'
  | 'premium_lenses' // deprecated TODO Remove from options
  | 'premium_basic_lenses'
  | 'premium_plus_lenses'
  | 'sunny_pro_lenses'
  | 'service_sds'
  | 'polarised_lenses'
  | 'in_store_production'
  | 'lens_color_photochromic';

type ConfigurationData = {
  'product-description': string;
  'prescription-type': string;
  'lens-production-type'?: string;
  'selected-customizations'?: CustomizationOptions[];
  'left-cylinder'?: number;
  'right-cylinder'?: number;
  'left-sphere'?: number;
  'right-sphere'?: number;
  'left-addition'?: number;
  'right-addition'?: number;
};

type ChangeDescription =
  | 'RemovePremium'
  | 'RemovePremiumBasic'
  | 'RemovePremiumPlus'
  | 'RemoveSunnyPro'
  | 'RemovePolarized'
  | 'RemoveBlf'
  | 'RemovePhotochromic'
  | 'RemoveSDS'
  | 'AddPremiumRemoveSDS'
  | 'AddPremium'
  | 'AddPremiumBasic'
  | 'AddPremiumPlus'
  | 'AddSunnyPro';

export const CHANGE_REASON_OLC_ACTION_NONE = [
  'PrescriptionTooHigh',
  'IncompatiblePrescriptionType',
  'InvalidOrderLineConfiguration',
  'FrameIncompatibleWithComputerLenses',
  'FrameIncompatibleWithPrescription',
  'PrescriptionAdditionIncompatibleWithComputerLenses',
  'ComputerLensesIncompatibleWithPrescription',
  'MultiplePremiumTypesSelected'
] as const;

export type ChangeReasonActionNone = (typeof CHANGE_REASON_OLC_ACTION_NONE)[number];

type ChangeReason =
  | 'frameIncompatibleWithPolarized'
  | 'frameIncompatibleWithBlf'
  | 'sdsIncompatibeWithCustomizations'
  | 'sdsIncompatibleWithPrescriptionType'
  | 'prescriptionRequiresPremiumLenses'
  | 'prescriptionInFrameRequiresPremiumLenses'
  | 'blfRestrictsPremiumEffectiveness'
  | 'prescriptionIndicatesPremiumLenses'
  | 'PrescriptionIndicatesPremiumBasicLenses'
  | 'PrescriptionRequiresPremiumPlusLenses'
  | 'PrescriptionRequiresSunnyProLenses'
  | 'PrescriptionInFrameRequiresPremiumPlusLenses'
  | 'PrescriptionTooHighForPhotochromicLens'
  | 'FrameIncompatibleWithPremiumBasic'
  | 'FrameIncompatibleWithPremiumPlus'
  | 'FrameIncompatibleWithSunnyPro'
  | 'BlfRestrictsPremiumPlusEffectiveness'
  | 'PremiumBasicIncompatibleWithPremiumPlus'
  | 'PremiumPlusIncompatibleWithPremiumBasic'
  | 'PremiumBasicIncompatibleWithPrescription'
  | 'PremiumPlusIncompatibleWithPrescription'
  | ChangeReasonActionNone;

export type Customization<T = CustomizationOptions> = {
  priceDetails: {
    value: number;
    currency: string;
    display: string;
  };
  customizationKey: T;
  whenAvailable?: {
    isSelected: boolean;
    validationUrl: string;
  };
};

type ConfigurationChanges = {
  description: ChangeDescription;
  validationUrl: string;
  customizationsToAdd: Customization[];
  customizationsToRemove: Customization[];
};

type ConfigurationConfig = {
  reason: ChangeReason;
  changes?: ConfigurationChanges;
};

export type PrescriptionAdvice =
  | 'HighDifferenceBetweenDiopters'
  | 'NegativeAndPositiveSphere'
  | 'NegativeAndPositiveCylinder'
  | 'DifferentAdditions';

export type ValidationResponse = {
  prescriptionAdvisories?: PrescriptionAdvice[];
  mandatoryConfigurationChanges?: ConfigurationConfig;
  recommendedConfigurationChanges?: ConfigurationConfig;
  customizations: Customization[];
  // TODO: confirm response type from OLC
  invalidReasons?: any;
};

export function useValidateProductConfiguration() {
  const fetch = useToriiFetch<ValidationResponse>();
  return async (data: ConfigurationData) => {
    return fetch('/order-line-configurator/v2/validate-configuration', {
      method: 'GET',
      params: { ...data }
    });
  };
}

export function validateProductConfiguration(data: ConfigurationData, locale: Locale) {
  return toriiFetch<ValidationResponse>(
    '/order-line-configurator/v2/validate-configuration',
    {
      method: 'GET',
      params: { ...data }
    },
    locale
  );
}

export async function validateProductConfigurationUrl(url: string) {
  const res = await fetch(`${url}`, {
    method: 'GET'
  });
  const data = await res.json();

  return { data };
}
