import { defineMessages } from 'react-intl';

export default defineMessages({
  apply: {
    defaultMessage: 'Apply',
    id: 'pcp.filterbar.apply'
  },
  count: {
    defaultMessage: '{productCount, number} {productCount, plural, one {frame} other {frames} }',
    id: 'pcp.framesCount'
  },
  imageAltMessage: {
    defaultMessage: '{name} {material} glasses in {color} by Ace & Tate ',
    id: 'ProductImages.imageAltMessage2'
  },
  imageTitleMessage: {
    defaultMessage: 'Ace & Tate {name} in {color} ',
    id: 'ProductImages.imageTitleMessage'
  },
  isNew: {
    defaultMessage: 'New',
    id: 'skucard.label.isNew'
  },
  less: {
    defaultMessage: 'less',
    id: 'productCollectionPage.less.text'
  },
  limitedEdition: {
    defaultMessage: 'Limited edition',
    id: 'skucard.label.limitedEdition'
  },
  lowStock: {
    defaultMessage: 'Low stock',
    id: 'skucard.label.lowStock'
  },
  more: {
    defaultMessage: 'more',
    id: 'productCollectionPage.more.text'
  },
  outOfStock: {
    defaultMessage: 'Out of stock',
    id: 'skucard.label.outOfStock'
  },
  pageDivider: {
    defaultMessage: '- page {pageCount}',
    id: 'pcp.pageDivider'
  },
  'pcp.loadMoreFrames': {
    defaultMessage: 'show more',
    id: 'pcp.loadMoreFrames'
  },
  'pcp.search.noProductsFound': {
    defaultMessage: 'No products found',
    id: 'pcp.search.noProductsFound'
  },
  remainingProductCount: {
    defaultMessage:
      '{productCount, number} {productCount, plural, one {item still to browse} other {items still to browse} }',
    id: 'pcp.remainingProductCount'
  },
  soldOut: {
    defaultMessage: 'Sold out',
    id: 'skucard.label.soldOut'
  }
});

export const filterMessages = defineMessages({
  count: {
    defaultMessage: '{productCount, number} {productCount, plural, one {frame} other {frames} }',
    id: 'pcp.framesCount'
  },
  filter: {
    defaultMessage: 'filter',
    id: 'pcp.filter.filter'
  },
  hto: {
    defaultMessage: 'home try-on',
    id: 'pcp.HTO'
  },
  htoFilter: {
    defaultMessage: 'Home Try-on',
    id: 'pcp.filter.htoFilter'
  },
  htoHelp: {
    defaultMessage: 'home try-on help text',
    id: 'pcp.HTOHelpText'
  },
  htoTooltipText: {
    defaultMessage: 'Choose 4 frames to try on at home for 5 days and then return them free of charge.',
    id: 'pcp.filter.htoTooltip'
  },
  reset: {
    defaultMessage: 'reset',
    id: 'pcp.filter.reset'
  },
  search: {
    defaultMessage: 'search...',
    id: 'pcp.searchPlaceholder'
  },
  selected: {
    defaultMessage: '{count} selected',
    id: 'pcp.filter.countSelected'
  }
});
