import React from 'react';
import { Typography } from '@aceandtate/ds';
import OpeningHours from './OpeningHours';
import * as Styles from '../../styles';

export default function FooterOpeningHours(): JSX.Element {
  return (
    <Styles.OpeningHoursWrapper>
      <Typography variant='bodyM' fontWeight='regular'>
        <OpeningHours />
      </Typography>
    </Styles.OpeningHoursWrapper>
  );
}
