import React from 'react';
import { Flex, IconShoppingBag, Typography } from '@aceandtate/ds';
import { FormattedMessage } from 'react-intl';
import messages from '../messages';

export type ConfiguratorStepsType =
  | 'PRESCRIPTION_STEP'
  | 'SUN_PRESCRIPTION_STEP'
  | 'LENSES_STEP'
  | 'SUN_LENSES_STEP'
  | 'LENSES_EXTRAS_STEP'
  | 'SUN_LENSES_EXTRAS_STEP'
  | 'ACCESSORIES_STEP'
  | 'CART_STEP';

export type ConfiguratorStepsProps = {
  id: ConfiguratorStepsType;
  title: React.ReactNode;
  trackName: string;
  editTrackName: string;
};

export const prescription: ConfiguratorStepsProps = {
  id: 'PRESCRIPTION_STEP',
  title: (
    <Typography variant='h3'>
      <FormattedMessage {...messages.prescriptionStep} />
    </Typography>
  ),
  trackName: 'selectYourGlasses',
  editTrackName: 'editGlassesLenses'
};

export const sunPrescription: ConfiguratorStepsProps = {
  id: 'SUN_PRESCRIPTION_STEP',
  title: (
    <Typography variant='h3'>
      <FormattedMessage {...messages.sunPrescriptionStep} />
    </Typography>
  ),
  trackName: 'selectYourGlasses',
  editTrackName: 'editSunglassesType'
};

export const lenses: ConfiguratorStepsProps = {
  id: 'LENSES_STEP',
  title: (
    <Typography variant='h3'>
      <FormattedMessage {...messages.lensesStep} />
    </Typography>
  ),
  trackName: 'lensesAndCoatings',
  editTrackName: 'editGlassesLenses'
};

export const sunLenses: ConfiguratorStepsProps = {
  id: 'SUN_LENSES_STEP',
  title: (
    <Typography variant='h3'>
      <FormattedMessage {...messages.lensesStep} />
    </Typography>
  ),
  trackName: 'lensesAndCoatings',
  editTrackName: 'editSunglassesLenses'
};

export const lensesExtras: ConfiguratorStepsProps = {
  id: 'LENSES_EXTRAS_STEP',
  title: (
    <Typography variant='h3'>
      <FormattedMessage {...messages.lensesExtrasStep} />
    </Typography>
  ),
  trackName: 'lensExtras',
  editTrackName: 'editGlassesUpgrade'
};

export const sunLensesExtras: ConfiguratorStepsProps = {
  id: 'SUN_LENSES_EXTRAS_STEP',
  title: (
    <Typography variant='h3'>
      <FormattedMessage {...messages.lensesExtrasStep} />
    </Typography>
  ),
  trackName: 'lensExtras',
  editTrackName: 'editSunglassesUpgrade'
};

export const accessories: ConfiguratorStepsProps = {
  id: 'ACCESSORIES_STEP',
  title: (
    <Typography variant='h3'>
      <FormattedMessage {...messages.accessoriesStep} />
    </Typography>
  ),
  trackName: 'someExtras',
  editTrackName: 'editAccessories'
};

export const cart: ConfiguratorStepsProps = {
  id: 'CART_STEP',
  title: (
    <Flex gap={8} alignItems='center'>
      <IconShoppingBag fontSize={26} />
      <Typography variant='h3' textAlign='left'>
        <FormattedMessage {...messages.cartStep} />
      </Typography>
    </Flex>
  ),
  trackName: 'configuratorCart',
  editTrackName: 'configuratorCart'
};
