import React from 'react';
import { IconCheckCircle, IconPlusCircle, brandColors, uiColors } from '@aceandtate/ds';
import styled from 'styled-components';

type Props = {
  selected?: boolean;
  onClick?: () => void;
  preventDefaultClick?: boolean;
};
const Offset = styled.div`
  margin-inline: -4px;
`;

export default function AddSelector(props: Props) {
  const { selected, onClick, preventDefaultClick } = props;
  return (
    <Offset>
      {selected ? (
        <IconCheckCircle color={brandColors.accent} fontSize={40} onClick={preventDefaultClick ? null : onClick} />
      ) : (
        <IconPlusCircle color={uiColors.disabled} fontSize={40} onClick={preventDefaultClick ? null : onClick} />
      )}
    </Offset>
  );
}
