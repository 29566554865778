/** Specifies preset giftcard values for each currency.
 *  deprecated min/max values, now using solidus webStore values */
const variableGiftcardPrices = {
  CHF: {
    max: 750,
    min: 15,
    presets: [44, 90, 170, 245, 530]
  },
  DKK: {
    max: 5000,
    min: 100,
    presets: [300, 600, 1250, 1750, 3650]
  },
  EUR: {
    max: 500,
    min: 10,
    presets: [30, 60, 125, 175, 365]
  },
  GBP: {
    max: 500,
    min: 10,
    presets: [30, 60, 125, 175, 365]
  },
  SEK: {
    max: 7100,
    min: 140,
    presets: [420, 850, 1750, 2450, 5100]
  },
  USD: {
    max: 750,
    min: 15,
    presets: [15, 45, 90, 165, 240, 330, 375]
  }
};

module.exports = variableGiftcardPrices;
