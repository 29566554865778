module.exports = {
  alternateHrefCacheEnabled: true,
  contentfulBuildCacheEnabled: process.env.ENABLE_BUILD_CACHE === 'true',
  localeBuildCacheEnabled: process.env.ENABLE_BUILD_CACHE === 'true',
  prerenderStorePages: process.env.NEXT_PUBLIC_IS_PRODUCTION === 'true',
  productBuildCacheEnabled: process.env.ENABLE_BUILD_CACHE === 'true' && process.env.PRERENDER_LOCALES,
  storeStockAvailabilityEnabled: process.env.NEXT_PUBLIC_ENABLE_STORES_STOCK_AVAILABILITY,
  storesBuildCacheEnabled: process.env.ENABLE_BUILD_CACHE === 'true',
  trustpilotEnabled: process.env.NEXT_PUBLIC_ENABLE_TRUSTPILOT_WIDGET === 'true',
  wishlistEnabled: true,
  sentryReplayEnabled: process.env.NEXT_PUBLIC_ENABLE_SENTRY_REPLAY === 'true'
};
