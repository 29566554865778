import { defineMessages } from 'react-intl';

const messages = defineMessages({
  alreadyInUseEmail: {
    defaultMessage: 'This email address is already in use. Please login to continue.',
    id: 'global.formValidation.alreadyInUseEmail'
  },
  emailDoesNotExist: {
    defaultMessage: 'This email is not registered with an Ace & Tate account. Please create a new account to continue.',
    id: 'global.formValidation.emailDoesNotExist'
  },
  invalidEmail: {
    defaultMessage: 'This email does not appear to be valid.',
    id: 'global.formValidation.invalidEmail'
  },
  invalidField: {
    defaultMessage: 'This field does not look right.',
    id: 'global.formValidation.invalidField'
  },
  invalidHouseNumber: {
    defaultMessage: 'Please enter a valid house number',
    id: 'global.formValidation.invalidHouseNumber'
  },
  invalidLogin: {
    defaultMessage: 'The combination of email & password did not match.',
    id: 'global.formValidation.invalidLogin'
  },
  invalidPostalCode: {
    defaultMessage: 'Please enter a valid postal code',
    id: 'global.formValidation.invalidPostalCode'
  },
  maxCharactersRequired: {
    defaultMessage: 'Please enter at most {number} characters',
    id: 'global.formValidation.maxCharactersRequired'
  },
  minCharactersRequired: {
    defaultMessage: 'Please enter at least {number} characters',
    id: 'global.formValidation.minCharactersRequired'
  },
  passwordsNoMatch: {
    defaultMessage: 'The passwords do not match',
    id: 'global.formValidation.passwordsNoMatch'
  },
  requiredField: {
    defaultMessage: 'This field is required.',
    id: 'global.formValidation.requiredField'
  },
  requiredPassword: {
    defaultMessage: 'You need to enter a password.',
    id: 'global.formValidation.requiredPassword'
  },
  tosRequired: {
    defaultMessage: 'Please accept the terms of service to proceed.',
    id: 'global.formValidation.tosRequiredWarning'
  }
});

export default messages;
