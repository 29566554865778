import { PromotionRule } from 'types/solidus';
import useSWR from 'swr';
import { useFetch } from 'utils/helpers/customFetch';

export function useFetchContactLensPromotions() {
  const path = '/contacts_bundle_promotions';
  const fetch = useFetch<PromotionRule[]>();

  return useSWR(path, fetch, {
    revalidateOnFocus: false
  });
}
