import { defineMessages } from 'react-intl';
export default defineMessages({
  frameLimitModalTitle: {
    defaultMessage: 'Frame limit reached',
    id: 'frameLimitModal.title'
  },
  frameLimitModalBody: {
    defaultMessage:
      'Oh! Youve reached the limit of 6 frames per order. But dont worry, you can still add other items to your cart. If you want to order more frames, our Customer Service team is here to help.',
    id: 'frameLimitModal.body'
  },
  archiveFrameLimitModalBody: {
    defaultMessage: `It's great you found so many favourites, but we have a one item per order limit during Archive Sale. The styles you see here are available in very limited quantities. They are also our way of combatting environmental waste, so we really encourage shopping small for a big impact. `,
    id: 'frameLimitModal.archiveBody'
  },
  frameLimitModalPrimaryButton: {
    defaultMessage: 'Go to cart',
    id: 'frameLimitModal.primaryButton'
  },
  frameLimitModalSecondaryButton: {
    defaultMessage: 'Contact us',
    id: 'frameLimitModal.contactUs'
  }
});
