export const shopstoryDevices = {
  '2xl': {
    startsFrom: 1680
  },
  lg: {
    startsFrom: 1024
  },
  md: {
    startsFrom: 768
  },
  sm: {
    startsFrom: 480
  },
  xs: {
    startsFrom: 0
  },
  xl: {
    startsFrom: 1280
  }
};
