import styled from 'styled-components';
import DynamicIcon from 'components/DynamicIcon';
import React from 'react';

type Props = {
  id?: string;
  size?: 's' | 'm' | 'l';
};

const sizeMap = {
  l: '1.6em',
  m: '1.2em',
  s: '1em'
};

// fine tuning positioning of icons when appearing inline
const InlineIcon = styled.span`
  align-items: center;
  display: inline-flex;
  vertical-align: middle;
  margin-left: -1px;
  margin-bottom: 0.28em;
`;

export default function Icon({ id, size = 's' }: Props) {
  if (!id) {
    return null;
  }

  return (
    <InlineIcon>
      <DynamicIcon id={id} fontSize={sizeMap[size]} />
    </InlineIcon>
  );
}
