import { keyframes } from 'styled-components';

export const fadeInAnimation = keyframes`
  0% {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`;

export const fadeUpAnimation = keyframes`
  from {
    opacity: 0;
    transform: translateY(40px);
  }

  to {
    opacity: 1;
    transform: translateY(0px);
  }
`;

export const fadeDownAnimation = keyframes`
  from {
    opacity: 0;
    transform: translateY(-20px);
  }

  to {
    opacity: 1;
    transform: translateY(0px);
  }
`;

export const bounceAnimation = keyframes`
    0%,
    100% {
        transform: translateY(0);
    }

    50% {
        transform: translateY(-10px);
    }
`;

export const rotateAnimation = keyframes`
  0% {
    opacity: 0;
    transform: translate3d(0, 24px, 0);
  }

  20%, 80% {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }

  100% {
    opacity: 0;
    transform: translate3d(0, -24px, 0);
  }
`;

export const blinker = keyframes`
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
`;

export const progressbar = keyframes`
  from {
    transform: scaleX(0);
  }
  to {
      transform: scaleX(1);
  }
`;
