import React, { ComponentProps, useContext } from 'react';
import { ServicesContext } from 'services/context';
import Flag from 'components/Flag';
import { FormattedMessage } from 'react-intl';
import { country as countryMessages } from 'messages/intl';
import { useChangeRegion } from 'paths';
import { Button, Flex, Typography, brandColors } from '@aceandtate/ds';
import Popover from 'components/Popover/Popover';
import styled from 'styled-components';
const { shippingCountries } = require('config/intl');

type Props = {
  id?: string;
  color?: ComponentProps<typeof Button>['color'];
  hasFlag?: boolean;
};

const StyledNav = styled.nav`
  padding-left: 0;
  overflow: auto;
`;

const HEADER_HEIGHT = `95px`;

const StyledPopoverContent = styled(Popover.Content)`
  &[data-side='bottom'] {
    ${StyledNav} {
      max-height: calc(
        var(--radix-popover-content-available-height) - 10px
      ); /* 10px to leave some space to the bottom of the page */
    }
  }
  &[data-side='top'] {
    ${StyledNav} {
      /* If the direction is top it accounts for the header to size the popover */
      max-height: calc(var(--radix-popover-content-available-height) - ${HEADER_HEIGHT});
    }
  }
`;

export default function CountryPicker({ id, color, hasFlag }: Props) {
  const context = useContext(ServicesContext);
  const changeRegion = useChangeRegion();

  return (
    <Popover modal>
      <Popover.Trigger asChild>
        <Button variant='link' data-testid={id} color={color}>
          <FormattedMessage {...countryMessages[context.webStore.country.iso_name]} />
          {hasFlag && <Flag iso={context.webStore.country.iso} size='small' />}
        </Button>
      </Popover.Trigger>

      <StyledPopoverContent>
        <StyledNav>
          {shippingCountries.map(country => (
            <Popover.Item
              key={country.country.id}
              onClick={() => {
                changeRegion(country.country.iso);
              }}
              role='link'
              data-testid={`${id}.${country.country.iso}`}
            >
              <Flex gap='12px'>
                <Flag iso={country.country.iso} />
                <Typography color={brandColors.dark}>
                  <FormattedMessage {...countryMessages[country.name]} />
                </Typography>
              </Flex>
            </Popover.Item>
          ))}
        </StyledNav>
      </StyledPopoverContent>
    </Popover>
  );
}
