import React, { useContext } from 'react';
import { connectHits, connectStateResults } from 'react-instantsearch-dom';
import { FormattedMessage } from 'react-intl';

import VariantResult from '../VariantResult';
import ShowAll from '../ShowAll';

import { normalizeVariantHits } from '../../util';
import * as Types from '../../types';
import * as SearchStyles from '../../styles';
import * as Styles from './styles';
import messages from '../../messages';
import { ServicesContext } from 'services/context';

type VariantHitsProps = {
  allSearchResults: Types.AllSearchResultsProps;
  hasQuery: boolean;
  hits: Types.VariantHitProps[];
  searchState: {
    query: string;
  };
  closeHandler?: () => void;
};

const isProduction = process.env.NEXT_PUBLIC_IS_PRODUCTION;

const VariantHits = ({ allSearchResults, hits: rawHits, searchState, hasQuery, closeHandler }: VariantHitsProps) => {
  const {
    webStore: {
      country: { iso }
    }
  } = useContext(ServicesContext);
  const hits = normalizeVariantHits(rawHits, iso);

  if (hits.length === 0) return null;

  const variantsIndex =
    allSearchResults && (isProduction ? allSearchResults.variants_prod : allSearchResults.variants_show);
  const count = variantsIndex && variantsIndex.nbHits;

  return (
    <Styles.VariantsWrapper data-testid='searchSection-Variants'>
      <SearchStyles.ContentSectionTitle variant='h4'>
        <FormattedMessage {...messages.productResults} values={{ count }} />
      </SearchStyles.ContentSectionTitle>
      {hits.map(hit => (
        <VariantResult key={hit.objectID} hit={hit} hasQuery={hasQuery} closeHandler={closeHandler} />
      ))}
      <Styles.ShowAllContainer>
        <ShowAll variantsIndex={variantsIndex} query={searchState.query} closeHandler={closeHandler} />
      </Styles.ShowAllContainer>
    </Styles.VariantsWrapper>
  );
};

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export default connectHits(connectStateResults(VariantHits));
