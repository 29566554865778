import { grid, brandColors } from '@aceandtate/ds';
import styled from 'styled-components';

export const StyledTable = styled.div`
  font-size: 18px;
  height: 800px;
  width: 100%;
`;

export const StyledRow = styled.div<{ even: boolean }>`
  background: ${({ even }) => (even ? brandColors.light50 : 'transparent')};
  display: flex;
`;

export const StyledHeadingRow = styled.div`
  font-weight: 600;
  background: ${brandColors.dark10};
  display: flex;
  height: 35px;
  display: flex;
  align-items: center;
  white-space: nowrap;
`;

export const StyledColumn = styled.div`
  min-width: 240px;
  padding: 0px 20px;
  display: flex;
  align-items: center;
  white-space: nowrap;
`;

export const StyledInputContainer = styled.div`
  margin-top: ${grid[24]};
  margin-bottom: ${grid[24]};
`;

export const StyledLoaderIconContainer = styled.div`
  margin-top: ${grid[24]};
  display: flex;
  width: 100%;
  justify-content: center;
`;
