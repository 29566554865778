import { Cart } from 'types/solidus';
import { Currency } from 'types/currency';
import { paths } from 'paths';
import posthog from 'posthog-js';

function trackContentSquareEvent<T>(eventName, trackingData?: T) {
  if (typeof window === 'undefined') return trackingData;
  window._uxa = window._uxa || [];
  if (trackingData) {
    window._uxa.push([eventName, trackingData]);
  } else {
    window._uxa.push([eventName]);
  }
}

export function trackPageEvent(eventName: string) {
  trackContentSquareEvent('trackPageEvent', eventName);
}

export function trackCustomVariable(num: number, key: string, value: string) {
  if (typeof window === 'undefined') return;
  window._uxa = window._uxa || [];
  window._uxa.push(['setCustomVariable', num, key, value, 3]);
}

export function trackVariable<T>(key: string, value: T) {
  trackContentSquareEvent('trackDynamicVariable', { key, value });
}

const pageViewHistory = [];

export function trackPreviousArtificialPageView() {
  if (pageViewHistory[pageViewHistory.length - 2]) {
    trackContentSquareEvent('trackPageview', pageViewHistory[pageViewHistory.length - 2]);
    pageViewHistory.push(pageViewHistory[pageViewHistory.length - 2]);
  }
}

export function trackArtificialPageView(name: string, label = 'step') {
  const query = name ? `?${label}=${name}` : '';
  const pageview = window.location.pathname + window.location.hash.replace('#', '?__') + query;
  trackContentSquareEvent('trackPageview', pageview);
  pageViewHistory.push(pageview);
  if (pageViewHistory.length >= 10) {
    pageViewHistory.shift();
  }
}

export function trackTransaction(cart: Cart) {
  trackContentSquareEvent('ecommerce:addTransaction', {
    id: cart.id,
    revenue: convertCurrencyToEuro(cart.total, cart.currency),
    shipping: convertCurrencyToEuro(cart.ship_total, cart.currency),
    tax: convertCurrencyToEuro(cart.tax_total, cart.currency)
  });

  cart.regular_lines.forEach(line => {
    trackContentSquareEvent('ecommerce:addItem', {
      category: line.product_type,
      id: cart.id,
      name: line.variant.name,
      price: convertCurrencyToEuro(line.price, cart.currency),
      quantity: line.quantity,
      sku: line.variant.sku
    });
  });

  trackContentSquareEvent('ecommerce:send');

  posthog.capture('purchase', {
    revenue: convertCurrencyToEuro(cart.total, cart.currency),
    items: cart.regular_lines.map(line => ({
      category: line.product_type,
      id: cart.id,
      name: line.variant.name,
      price: convertCurrencyToEuro(line.price, cart.currency),
      quantity: line.quantity,
      sku: line.variant.sku,
      color: line.variant.color
    }))
  });
}

export function trackGaEvent<T>(trackingData: T) {
  if (typeof window === 'undefined') return trackingData;
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push(trackingData);

  return trackingData;
}

// last updated 30/08/2024
// conversion rates are purely used for tracking helpers
// no 100% accuracy required
const conversionRates = {
  CHF: 1.06,
  DKK: 0.13,
  EUR: 1,
  GBP: 1.19,
  SEK: 0.088,
  USD: 0.9
};

export function convertCurrencyToEuro(val: number, currency: Currency) {
  const rateToApply = conversionRates[currency];

  if (rateToApply) {
    return parseFloat((val * rateToApply).toFixed(2));
  }

  return val;
}

export function mapDynamicRoute(pathname: string) {
  const orderDetailRegex = /orders\/\d+$/;
  const matchOrderDetailRegex = orderDetailRegex.test(pathname.split('/').slice(-2).join('/'));

  if (matchOrderDetailRegex) {
    return paths.userOrderDetail;
  }

  const prescriptionEditRegex = /prescriptions\/\d+$/;
  const matchPrescriptionEditRegex = prescriptionEditRegex.test(pathname.split('/').slice(-2).join('/'));

  if (matchPrescriptionEditRegex) {
    return paths.userPrescriptionEdit;
  }
}
