import React from 'react';
import { Typography } from '@aceandtate/ds';
import * as Styles from '../styles';
import { PromotionRule } from 'types/solidus';
import messages from '../messages';
import ecommerceMessages from 'messages/ecommerce';
import prescriptionMessages from 'messages/prescription';
import { FormattedMessage } from 'react-intl';

type Props = {
  activePromotions: PromotionRule[];
};

export default function Promotion({ activePromotions }: Props) {
  return (
    <Styles.PromoContainer data-testid='promo-container'>
      <Styles.PromoCopy>
        <Typography variant='h4' gutterBottom='8'>
          <FormattedMessage {...messages.bundleDealTitle} />
        </Typography>
        <Typography variant='bodyM'>
          <FormattedMessage {...messages.bundleDealDescription} values={{ min: activePromotions[0].min_quantity }} />
        </Typography>
      </Styles.PromoCopy>
      <Styles.PromoList>
        {activePromotions.map(promo => (
          <Styles.PromoLabel key={promo.name}>
            <span data-testid={`qty-${promo.name}`}>
              {promo.min_quantity}
              {promo.max_quantity ? ` - ${promo.max_quantity}` : '+'}{' '}
              <FormattedMessage {...prescriptionMessages.contactPackLabel} values={{ count: 2 }} />
            </span>
            <span data-testid={`percentage-${promo.name}`}>
              {promo.discount_percentage}% <FormattedMessage {...ecommerceMessages.discount} />
            </span>
          </Styles.PromoLabel>
        ))}
      </Styles.PromoList>
    </Styles.PromoContainer>
  );
}
