import { defineMessages } from 'react-intl';

const productTypeMessages = defineMessages({
  accessory: {
    defaultMessage: 'Accessory',
    id: 'productType.accessory'
  },
  clipOn: {
    defaultMessage: 'Clip-on sunglasses',
    id: 'productType.clipOn'
  },
  contactLenses: {
    defaultMessage: 'Contacts',
    id: 'productType.contactLenses'
  },
  frameOnly: {
    defaultMessage: 'Frame only',
    id: 'productType.frameOnly'
  },
  giftcard: {
    defaultMessage: 'Giftcard',
    id: 'productType.giftcard'
  },
  glasses: {
    defaultMessage: 'Glasses',
    id: 'productType.glasses'
  },
  frame: {
    defaultMessage: 'Frame',
    id: 'productType.frame'
  },
  lensesOnly: {
    defaultMessage: 'Lenses only',
    id: 'productType.lensesOnly'
  },
  serviceItem: {
    defaultMessage: 'Service item',
    id: 'productType.serviceItem'
  },
  sunglasses: {
    defaultMessage: 'Sunglasses',
    id: 'productType.sunglasses'
  },
  sunny: {
    defaultMessage: 'Sunglasses',
    id: 'productType.sunny'
  }
});

export default productTypeMessages;
