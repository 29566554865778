import styled from 'styled-components';
import { breakpointRules, grid, InputControl } from '@aceandtate/ds';

export const FormGroup = styled.div`
  margin: ${grid[8]} 0 ${grid[12]} 0;

  @media ${breakpointRules.laptop} {
    margin: ${grid[12]} 0;
  }
`;

export const StyledInputControl = styled(InputControl)`
  margin-bottom: ${grid[8]};
`;

export const PrivacyPolicyContainer = styled.div`
  margin-bottom: ${grid[24]};
`;
