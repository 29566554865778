import styled, { css } from 'styled-components';
import { selectedStyle } from 'styles';
import { Typography, brandColors, uiColors, breakpointRules, grid } from '@aceandtate/ds';

type StyleProps = {
  disabled?: boolean;
  isMobile?: boolean;
  hasDescription?: boolean;
  selected?: boolean;
  withImage?: boolean;
};

export const Wrapper = styled.div<StyleProps>`
  align-items: flex-start;
  border: 1px solid ${brandColors.dark10};
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  max-width: 480px;
  overflow: hidden;
  position: relative;
  width: 100%;
  flex-flow: column;
  padding: ${props => (props.withImage ? 0 : grid[24])};
  ${props => props.selected && selectedStyle}

  @media ${breakpointRules.tablet} {
    flex-flow: ${props => (props.withImage ? 'row' : 'column')};
  }

  ${({ disabled }) =>
    disabled &&
    css`
      pointer-events: none;
      cursor: default;
    `}
`;

export const ImageWrapper = styled.div`
  position: relative;
  display: flex;
  flex-basis: 33.33%;
  width: 100%;
`;

export const Content = styled.div<StyleProps>`
  display: flex;
  flex-basis: 66.66%;
  flex-flow: column;
  margin-top: 0;
  padding: ${props => (props.withImage ? `0 ${grid[24]}` : 0)};
  width: 100%;
  @media ${breakpointRules.tablet} {
    margin-top: ${props => (props.withImage ? grid[24] : 0)};
    padding: ${props => (props.withImage ? `0 ${grid[40]}` : 0)};
  }
`;

export const HeadingRow = styled.div<StyleProps>`
  align-items: flex-start;
  align-self: ${props => (props.withImage ? 'center' : 'flex-start')};
  flex-flow: row;
  display: flex;
  justify-content: space-between;
  margin-bottom: ${props => (props.withImage ? 0 : grid[4])};
  margin-top: ${props => (props.withImage ? grid[24] : 0)};
  width: 100%;
`;

export const Text = styled(Typography)<StyleProps>`
  && {
    color: ${props => (props.disabled ? uiColors.disabled : 'inherit')};
  }
`;

export const Title = styled(Text)`
  width: 70%;
`;

export const InfoLabel = styled(Text)`
  && {
    white-space: nowrap;
  }
`;

type DescriptionWrapperProps = {
  hasSelector?: boolean;
};

export const DescriptionWrapper = styled.div<DescriptionWrapperProps>`
  display: flex;
  flex-flow: column;
  width: ${({ hasSelector }) => (hasSelector ? '85%' : '100%')};
`;

export const FocusContentContainer = styled.div`
  margin-top: ${grid[24]};
`;

type SelectorPositionProps = {
  position: 'absolute' | 'relative';
};

export const SelectorPosition = styled.div<SelectorPositionProps>`
  align-self: flex-end;
  bottom: ${props => (props.position === 'relative' ? 0 : grid[24])};
  display: flex;
  position: ${props => props.position};
  margin-right: ${props => (props.position === 'relative' ? grid[24] : 0)};
  margin-bottom: ${props => (props.position === 'relative' ? grid[24] : 0)};
  right: ${props => (props.position === 'relative' ? 0 : grid[24])};
  @media ${breakpointRules.tablet} {
    margin-right: ${props => (props.position === 'relative' ? grid[40] : 0)};
    right: ${props => (props.position === 'relative' ? 0 : grid[40])};
  }
`;
