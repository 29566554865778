import { Typography } from '@aceandtate/ds';
import styled from 'styled-components';
import { webColors, brandColors, grid } from 'styles';

type StyleProps = {
  selected?: boolean;
};

export const SelectorContainer = styled.div`
  align-items: center;
  border: 1px solid ${webColors.highlightPrimaryLight};
  border-radius: 100px;
  display: flex;
  flex-flow: row;
  padding: 2px;
`;

export const Add = styled.div<StyleProps>`
  align-items: center;
  background-color: ${props => (props.selected ? webColors.blueBright : brandColors.white)};
  border: 1px solid ${webColors.blueBright};
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  flex-flow: row;
  height: 32px;
  justify-content: center;
  width: 32px;

  &:after {
    color: ${props => (props.selected ? brandColors.white : webColors.dark)};
    content: '+';
    font-size: 32px;
    font-weight: lighter;
    line-height: normal;
  }
`;

export const Remove = styled.div<StyleProps>`
  align-items: center;
  background-color: ${props => (props.selected ? webColors.blueBright : brandColors.white)};
  border: 1px solid ${webColors.blueBright};
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  height: 32px;
  justify-content: center;
  width: 32px;

  &:after {
    color: ${props => (props.selected ? brandColors.white : webColors.dark)};
    content: '-';
    font-size: 32px;
    font-weight: lighter;
  }
`;

export const Counter = styled(Typography)`
  && {
    margin: 0 ${grid.xs};
  }
`;
