import React from 'react';
import { Typography } from '@aceandtate/ds';
import { footerEvents } from 'tracking';
import * as Styles from '../styles';
import * as Types from '../types';

export default function FooterEmail({ email }: Types.FooterEmailProps): JSX.Element {
  return (
    <Typography variant='bodyM' fontWeight='regular'>
      <a
        href={`mailto:${email}`}
        onClick={() => {
          footerEvents.getInTouch('Email');
        }}
      >
        <Styles.EmailIconStyled />
        {email}
      </a>
    </Typography>
  );
}
