import React, { useContext } from 'react';
import { Select } from '@aceandtate/ds';
import { useIntl } from 'react-intl';
import prescriptionMessages from 'messages/prescription';
import ecommerceMessages from 'messages/ecommerce';
import formLabelMessages from 'messages/formLabels';
import messages from '../messages';
import { ContactLensProduct } from 'types/torii';
import { LensConfiguration } from '..';
import * as Styles from '../styles';
import { ServicesContext } from 'services/context';
import type { LensTypeID } from '..';
import { SelectItem } from '@aceandtate/ds/dist/components/Select/Select';

// setting a hard limit for contact lens units
// this is mainly to improve UX by not making the select too long.
// there are no restrictions in solidus that would prevent a larger number (if needed)
const MAX_QUANT = 13;

type LensConfigurationKey =
  | 'prescriptionR'
  | 'prescriptionL'
  | 'baseCurveR'
  | 'baseCurveL'
  | 'quantityR'
  | 'quantityL'
  | 'axisR'
  | 'axisL'
  | 'cylinderR'
  | 'cylinderL';

type Props = {
  product: ContactLensProduct;
  lensConfiguration: LensConfiguration;
  minPromoAmount?: number;
  baseCurves: number[];
  lensType: LensTypeID;
  onChange: (newConfig: LensConfiguration) => void;
};

export default function Prescription(props: Props) {
  const { product, lensConfiguration, baseCurves, onChange, minPromoAmount, lensType } = props;
  const { isTrial } = product.properties;
  const canSelectQuantity = !isTrial;
  const canSelectBaseCurve = baseCurves.length > 1;
  const ctx = useContext(ServicesContext);

  const intl = useIntl();

  function handlePrescriptionChange(selectedItem: SelectItem<any>, key: LensConfigurationKey) {
    if (!selectedItem || selectedItem.value === null || selectedItem.value === undefined) return;

    const newConfig = { ...lensConfiguration };
    newConfig[key] = selectedItem.value;
    onChange(newConfig);
  }

  function handlePrescriptionSelect(prescription: SelectItem<any>, side: 'l' | 'r') {
    if (!prescription) return;

    if (side === 'l') {
      onChange({
        ...lensConfiguration,
        prescriptionL: prescription.value
      });
    } else {
      onChange({
        ...lensConfiguration,
        prescriptionR: prescription.value
      });
    }
  }

  const numberOptions = {
    currency: ctx.webStore.config.currency,
    maximumFractionDigits: 0,
    minimumFractionDigits: 0,
    style: 'currency'
  };

  const prescriptionItems = Array.from(new Set(product.variants.map(x => x.properties.power)))
    .filter(x => x !== 0)
    .sort((a, b) => (a + b > 0 ? a - b : b - a))
    .map(x => ({
      column: x > 0 ? 2 : 1,
      id: x,
      label: x > 0 ? `+${x.toFixed(2)}` : `${x.toFixed(2)}`,
      value: x
    }));

  const axisItems = Array.from(new Set(product.variants.map(x => x.properties.toricDetails?.axis)))
    .filter(Boolean)
    .map(x => ({
      id: x,
      label: `${x}°`,
      value: x
    }));

  const cylinderItems = Array.from(new Set(product.variants.map(x => x.properties.toricDetails?.cylinder)))
    .filter(Boolean)
    .map(x => ({
      id: x,
      label: `${x}`,
      value: x
    }));

  const baseCurveItems = baseCurves.map(x => ({
    id: x,
    label: `${x}`,
    value: x
  }));

  const diameterItems = [
    {
      id: product.properties.diameter,
      label: `${product.properties.diameter}`,
      value: product.properties.diameter
    }
  ];

  const quantityItems = [...Array(MAX_QUANT)].map((_, index) => ({
    id: index,
    label: `${index}`,
    value: index
  }));

  return (
    <>
      {isTrial && (
        <Styles.StepDescription>
          {intl.formatMessage(messages.trialLensesIntro, {
            voucherValue: intl.formatNumber(product.fromPrice.value * 2, numberOptions as any)
          })}
        </Styles.StepDescription>
      )}
      {!isTrial && (
        <Styles.StepDescription>
          {intl.formatMessage(messages.dailyLenses)},&nbsp;
          {intl.formatMessage(messages.pricePerBox, { price: product.fromPrice.display })}.
          {minPromoAmount && (
            <span>
              <br />
              {intl.formatMessage(messages.contactBundleIntro, { min: minPromoAmount })}
            </span>
          )}
        </Styles.StepDescription>
      )}
      <Styles.Content>
        <Styles.Column>
          <Styles.ColumnTitle>{intl.formatMessage(prescriptionMessages.rightEye)}</Styles.ColumnTitle>
          <Styles.Row>
            <Select
              placeholder={intl.formatMessage(formLabelMessages.select)}
              selectedItems={
                lensConfiguration.prescriptionR
                  ? {
                      id: lensConfiguration.prescriptionR,
                      label: `${lensConfiguration.prescriptionR}`,
                      value: lensConfiguration.prescriptionR
                    }
                  : undefined
              }
              fullWidth
              setSelectedItems={event => handlePrescriptionSelect(event as SelectItem<any>, 'r')}
              label={intl.formatMessage(prescriptionMessages.prescription)}
              tooltip={intl.formatMessage(messages.prescriptionTooltip)}
              id='prescription-picker-r'
              items={prescriptionItems}
            />
          </Styles.Row>
          {cylinderItems.length > 0 && (
            <Styles.Row>
              <Select
                placeholder={intl.formatMessage(formLabelMessages.select)}
                selectedItems={
                  lensConfiguration.cylinderR
                    ? {
                        id: lensConfiguration.cylinderR,
                        label: `${lensConfiguration.cylinderR}`,
                        value: lensConfiguration.cylinderR
                      }
                    : undefined
                }
                setSelectedItems={event => handlePrescriptionChange(event as SelectItem<any>, 'cylinderR')}
                fullWidth
                label={intl.formatMessage(prescriptionMessages.cylinder)}
                id='cylinder-picker-r'
                items={cylinderItems}
              />
            </Styles.Row>
          )}
          {axisItems.length > 0 && (
            <Styles.Row>
              <Select
                placeholder={intl.formatMessage(formLabelMessages.select)}
                selectedItems={
                  lensConfiguration.axisR
                    ? {
                        id: lensConfiguration.axisR,
                        label: `${lensConfiguration.axisR}`,
                        value: lensConfiguration.axisR
                      }
                    : undefined
                }
                setSelectedItems={event => handlePrescriptionChange(event as SelectItem<any>, 'axisR')}
                fullWidth
                label={intl.formatMessage(prescriptionMessages.axis)}
                id='axis-picker-r'
                items={axisItems}
              />
            </Styles.Row>
          )}
          <Styles.Row>
            <Select
              placeholder={intl.formatMessage(formLabelMessages.select)}
              selectedItems={{
                id: product.properties.diameter,
                label: `${product.properties.diameter}`,
                value: product.properties.diameter
              }}
              fullWidth
              label={intl.formatMessage(prescriptionMessages.diameter)}
              id='diameter-r'
              items={diameterItems}
              readonly
            />
          </Styles.Row>
          <Styles.Row>
            <Select
              placeholder={intl.formatMessage(formLabelMessages.select)}
              selectedItems={{
                id: lensConfiguration.baseCurveR,
                label: `${lensConfiguration.baseCurveR}`,
                value: lensConfiguration.baseCurveR
              }}
              fullWidth
              setSelectedItems={event => handlePrescriptionChange(event as SelectItem<any>, 'baseCurveR')}
              label={intl.formatMessage(prescriptionMessages.base_curve)}
              tooltip={lensType === 'regular' && intl.formatMessage(messages.basecurveTooltip)}
              id='basecurve-r'
              items={baseCurveItems}
              readonly={!canSelectBaseCurve}
            />
          </Styles.Row>
          {canSelectQuantity && (
            <Styles.Row>
              <Select
                placeholder={intl.formatMessage(formLabelMessages.select)}
                selectedItems={{
                  id: lensConfiguration.quantityR,
                  label: `${lensConfiguration.quantityR}`,
                  value: lensConfiguration.quantityR
                }}
                fullWidth
                setSelectedItems={event => handlePrescriptionChange(event as SelectItem<any>, 'quantityR')}
                label={intl.formatMessage(ecommerceMessages.quantity)}
                id='quantity-r'
                items={quantityItems}
              />
            </Styles.Row>
          )}
        </Styles.Column>
        <Styles.Column>
          <Styles.ColumnTitle>{intl.formatMessage(prescriptionMessages.leftEye)}</Styles.ColumnTitle>
          <Styles.Row>
            <Select
              placeholder={intl.formatMessage(formLabelMessages.select)}
              selectedItems={
                lensConfiguration.prescriptionL
                  ? {
                      id: lensConfiguration.prescriptionL,
                      label: `${lensConfiguration.prescriptionL}`,
                      value: lensConfiguration.prescriptionL
                    }
                  : undefined
              }
              fullWidth
              setSelectedItems={event => handlePrescriptionSelect(event as SelectItem<any>, 'l')}
              label={intl.formatMessage(prescriptionMessages.prescription)}
              tooltip={intl.formatMessage(messages.prescriptionTooltip)}
              id='prescription-picker-l'
              items={prescriptionItems}
            />
          </Styles.Row>
          {cylinderItems.length > 0 && (
            <Styles.Row>
              <Select
                placeholder={intl.formatMessage(formLabelMessages.select)}
                selectedItems={
                  lensConfiguration.cylinderL
                    ? {
                        id: lensConfiguration.cylinderL,
                        label: `${lensConfiguration.cylinderL}`,
                        value: lensConfiguration.cylinderL
                      }
                    : undefined
                }
                setSelectedItems={event => handlePrescriptionChange(event as SelectItem<any>, 'cylinderL')}
                fullWidth
                label={intl.formatMessage(prescriptionMessages.cylinder)}
                id='cylinder-picker-l'
                items={cylinderItems}
              />
            </Styles.Row>
          )}
          {axisItems.length > 0 && (
            <Styles.Row>
              <Select
                placeholder={intl.formatMessage(formLabelMessages.select)}
                selectedItems={
                  lensConfiguration.axisL
                    ? {
                        id: lensConfiguration.axisL,
                        label: `${lensConfiguration.axisL}`,
                        value: lensConfiguration.axisL
                      }
                    : undefined
                }
                setSelectedItems={event => handlePrescriptionChange(event as SelectItem<any>, 'axisL')}
                fullWidth
                label={intl.formatMessage(prescriptionMessages.axis)}
                id='axis-picker-l'
                items={axisItems}
              />
            </Styles.Row>
          )}
          <Styles.Row>
            <Select
              placeholder={intl.formatMessage(formLabelMessages.select)}
              selectedItems={{
                id: product.properties.diameter,
                label: `${product.properties.diameter}`,
                value: product.properties.diameter
              }}
              fullWidth
              label={intl.formatMessage(prescriptionMessages.diameter)}
              id='diameter-l'
              items={diameterItems}
              readonly
            />
          </Styles.Row>
          <Styles.Row>
            <Select
              placeholder={intl.formatMessage(formLabelMessages.select)}
              selectedItems={{
                id: lensConfiguration.baseCurveL,
                label: `${lensConfiguration.baseCurveL}`,
                value: lensConfiguration.baseCurveL
              }}
              fullWidth
              setSelectedItems={event => handlePrescriptionChange(event as SelectItem<any>, 'baseCurveL')}
              label={intl.formatMessage(prescriptionMessages.base_curve)}
              tooltip={lensType === 'regular' && intl.formatMessage(messages.basecurveTooltip)}
              id='basecurve-l'
              items={baseCurveItems}
              readonly={!canSelectBaseCurve}
            />
          </Styles.Row>
          {canSelectQuantity && (
            <Styles.Row>
              <Select
                placeholder={intl.formatMessage(formLabelMessages.select)}
                selectedItems={{
                  id: lensConfiguration.quantityL,
                  label: `${lensConfiguration.quantityL}`,
                  value: lensConfiguration.quantityL
                }}
                fullWidth
                setSelectedItems={event => handlePrescriptionChange(event as SelectItem<any>, 'quantityL')}
                label={intl.formatMessage(ecommerceMessages.quantity)}
                id='quantity-l'
                items={quantityItems}
              />
            </Styles.Row>
          )}
        </Styles.Column>
      </Styles.Content>
    </>
  );
}
