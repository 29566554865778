import React, { useMemo } from 'react';
import { TrackingPurpose, Service, AirgapMetadata } from 'types/airgap';
import useSWR from 'swr';
import * as Styles from './styles';
import { Typography } from '@aceandtate/ds';
import LoaderIcon from 'components/Loaders/LoaderIcon';
import { FormattedMessage } from 'react-intl';
import messages from './messages';

const CONSENT_MODE_FLOWS = ['Google Analytics', 'Google Ads'];

function filterMetadata(data: AirgapMetadata, trackingPurpose: TrackingPurpose) {
  if (!data?.services) return [];

  let services = data.services
    .map(service => {
      const filteredCookies = service.cookies.filter(cookie => cookie.trackingPurposes.includes(trackingPurpose));
      const filteredDataFlows = service.dataFlows.filter(
        dataFlow =>
          dataFlow.trackingPurposes.includes(trackingPurpose) && !dataFlow.value.match(/aceandtate.(com|show)/)
      );

      if (filteredCookies.length < 1 && filteredDataFlows.length < 1) {
        return null;
      }

      return {
        cookies: filteredCookies,
        dataFlows: filteredDataFlows,
        description: service.description,
        title: service.title
      };
    })
    .filter(Boolean);

  // Google Analytics and Google Ads are controlled directly in GTM with Google Consent Mode, they are manually being removed
  // and added back, because transcend sets them to essential so that GTM can manage consent.
  if (trackingPurpose === 'Analytics') {
    const analyticsServices = data.services.filter(x => x.title === 'Google Analytics');
    services = [...services.filter(x => !CONSENT_MODE_FLOWS.includes(x.title)), ...analyticsServices];
  } else if (trackingPurpose === 'Advertising') {
    services = services.filter(x => !CONSENT_MODE_FLOWS.includes(x.title));
    const adServices = data.services.filter(x => x.title === 'Google Ads');
    services = [...services.filter(x => !CONSENT_MODE_FLOWS.includes(x.title)), ...adServices];
  } else {
    services = services.filter(x => !CONSENT_MODE_FLOWS.includes(x.title));
  }

  return moveMiscToEnd(services);
}

function moveMiscToEnd(metadataResult: Service[]) {
  metadataResult?.push(
    metadataResult
      .splice(
        metadataResult.findIndex(service => service.title === 'Miscellaneous'),
        1
      )
      .pop()
  );
  return metadataResult;
}

type Props = {
  trackingPurpose?: TrackingPurpose;
};
export function GetCookieTableOfType({ trackingPurpose }: Props) {
  const metadataUrl = `https://cdn.transcend.io/cm/${process.env.NEXT_PUBLIC_TRANSCEND_BUNDLE_ID}/metadata.json`;
  const { data, error } = useSWR<AirgapMetadata>(
    metadataUrl,
    async () => await fetch(metadataUrl).then(res => res.json()),
    { revalidateOnFocus: false }
  );

  const filteredMetadata = useMemo(() => filterMetadata(data, trackingPurpose), [data, trackingPurpose]);

  if (error) {
    return <Styles.ErrorMessage>{error.message}</Styles.ErrorMessage>;
  }

  if (!filteredMetadata) {
    return (
      <Styles.LoaderWrapper>
        <LoaderIcon />
      </Styles.LoaderWrapper>
    );
  }

  return filteredMetadata.length > 0 ? (
    <Styles.TableScroll>
      <Styles.Table>
        <tr>
          <th>
            <Typography variant='h6'>
              <FormattedMessage {...messages.categoryTitle} />
            </Typography>
          </th>
          <th>
            <Typography variant='h6'>
              <FormattedMessage {...messages.dataFlowTitle} />
            </Typography>
          </th>
          <th>
            <Typography variant='h6'>
              <FormattedMessage {...messages.cookieTitle} />
            </Typography>
          </th>
        </tr>
        <tbody>
          {filteredMetadata.map(service => (
            <tr key={service.title.replace(' ', '-')}>
              <td>
                <Typography>{service.title}</Typography>
              </td>
              <td>
                {service.dataFlows.length > 0 && (
                  <ul>
                    {service.dataFlows.map(dataFlow => (
                      <Typography as='li' key={dataFlow.value}>
                        {dataFlow.value}
                      </Typography>
                    ))}
                  </ul>
                )}
              </td>
              <td>
                {service.cookies.length > 0 && (
                  <ul>
                    {service.cookies.map(cookie => (
                      <Typography as='li' key={cookie.name}>
                        {cookie.name}
                      </Typography>
                    ))}
                  </ul>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </Styles.Table>
    </Styles.TableScroll>
  ) : null;
}
