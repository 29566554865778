import { defineMessages } from 'react-intl';

export default defineMessages({
  disableDraftMode: {
    defaultMessage: 'Disable',
    id: 'draftmode.disable'
  },
  draftModeEnabled: {
    defaultMessage: 'Draft mode enabled',
    id: 'draftMode.notification'
  },
  draftModeProduct: {
    defaultMessage: 'draft product',
    id: 'draftMode.product'
  }
});
