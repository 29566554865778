// TODO: Find a better place for this file
const countryNames = {
  at: {
    localName: 'Österreich',
    name: 'Austria'
  },
  be: {
    localName: 'Belgium',
    name: 'Belgium'
  },
  ch: {
    localName: 'Switzerland',
    name: 'Switzerland'
  },
  cz: {
    localName: 'Czech Republic',
    name: 'Czech Republic'
  },
  de: {
    localName: 'Deutschland',
    name: 'Germany'
  },
  dk: {
    localName: 'Denmark',
    name: 'Denmark'
  },
  es: {
    localName: 'Spain',
    name: 'Spain'
  },
  fi: {
    localName: 'Finland',
    name: 'Finland'
  },
  fr: {
    localName: 'France',
    name: 'France'
  },
  gb: {
    localName: 'United Kingdom',
    name: 'United Kingdom'
  },
  gr: {
    localName: 'Greece',
    name: 'Greece'
  },
  ie: {
    localName: 'Ireland',
    name: 'Ireland'
  },
  it: {
    localName: 'Italy',
    name: 'Italy'
  },
  lu: {
    localName: 'Luxembourg',
    name: 'Luxembourg'
  },
  nl: {
    localName: 'Netherlands',
    name: 'Netherlands'
  },
  pl: {
    localName: 'Poland',
    name: 'Poland'
  },
  pt: {
    localName: 'Portugal',
    name: 'Portugal'
  },
  ro: {
    localName: 'Romania',
    name: 'Romania'
  },
  se: {
    localName: 'Sweden',
    name: 'Sweden'
  },
  us: {
    localName: 'United States',
    name: 'United States'
  }
};

module.exports = {
  at: {
    country: 'AT',
    language: 'de',
    ...countryNames.at
  },
  'at-en': {
    country: 'AT',
    language: 'en',
    ...countryNames.at
  },
  be: {
    country: 'BE',
    language: 'nl',
    ...countryNames.be
  },
  'be-en': {
    country: 'BE',
    language: 'en',
    ...countryNames.en
  },
  'be-fr': {
    country: 'BE',
    language: 'fr',
    ...countryNames.be
  },
  ch: {
    country: 'CH',
    language: 'de',
    ...countryNames.de
  },
  'ch-en': {
    country: 'CH',
    language: 'en',
    ...countryNames.de
  },
  'ch-fr': {
    country: 'CH',
    language: 'fr',
    ...countryNames.de
  },
  cz: {
    country: 'CZ',
    language: 'en',
    ...countryNames.cz
  },
  de: {
    country: 'DE',
    language: 'de',
    ...countryNames.de
  },
  'de-en': {
    country: 'DE',
    language: 'en',
    ...countryNames.de
  },
  dk: {
    country: 'DK',
    language: 'en',
    ...countryNames.dk
  },
  es: {
    country: 'ES',
    language: 'es',
    ...countryNames.es
  },
  'es-en': {
    country: 'ES',
    language: 'en',
    ...countryNames.es
  },
  fi: {
    country: 'FI',
    language: 'en',
    ...countryNames.fi
  },
  fr: {
    country: 'FR',
    language: 'fr',
    ...countryNames.fr
  },
  'fr-en': {
    country: 'FR',
    language: 'en',
    ...countryNames.fr
  },
  gb: {
    country: 'GB',
    language: 'en',
    ...countryNames.gb
  },
  ie: {
    country: 'IE',
    language: 'en',
    ...countryNames.ie
  },
  it: {
    country: 'IT',
    language: 'en',
    ...countryNames.it
  },
  lu: {
    country: 'LU',
    language: 'en',
    ...countryNames.lu
  },
  nl: {
    country: 'NL',
    language: 'nl',
    ...countryNames.nl
  },
  'nl-en': {
    country: 'NL',
    language: 'en',
    ...countryNames.nl
  },
  pl: {
    country: 'PL',
    language: 'en',
    ...countryNames.pl
  },
  pt: {
    country: 'PT',
    language: 'en',
    ...countryNames.pt
  },
  ro: {
    country: 'RO',
    language: 'en',
    ...countryNames.ro
  },
  se: {
    country: 'SE',
    language: 'en',
    ...countryNames.se
  },
  us: {
    country: 'US',
    language: 'en',
    ...countryNames.us
  }
};
