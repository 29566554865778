import { defineMessages } from 'react-intl';

const messages = defineMessages({
  Collection: {
    defaultMessage: 'Collections',
    id: 'search.content_type.collections'
  },
  ContentPage: {
    defaultMessage: 'Information',
    id: 'search.content_type.content'
  },
  FaqQuestion: {
    defaultMessage: 'FAQs',
    id: 'search.content_type.faqs'
  },
  giftcard148: {
    defaultMessage: '{price} - Pair of prescription sunglasses',
    id: 'giftcardSKU.giftcard148'
  },
  giftcardfull: {
    defaultMessage: '{price} - Pair of glasses',
    id: 'giftcardSKU."giftcardfull"'
  },
  giftcardhalf: {
    defaultMessage: '{price} - half a pair of glasses',
    id: 'giftcardSKU.giftcardhalf'
  },
  giftcardquarter: {
    defaultMessage: '{price} - a quarter pair of glasses',
    id: 'giftcardSKU.giftcardquarter'
  },
  journalPostOriginals: {
    defaultMessage: 'Journal',
    id: 'search.content_type.journal'
  },
  journalPostPortraits: {
    defaultMessage: 'Journal',
    id: 'search.content_type.journal'
  },
  journalPostSounds: {
    defaultMessage: 'Journal',
    id: 'search.content_type.journal'
  },
  journalPostThroughTheEyes: {
    defaultMessage: 'Journal',
    id: 'search.content_type.journal'
  },
  journalTitle: {
    defaultMessage: 'Journal',
    id: 'search.journalTitle'
  },
  placeholder: {
    defaultMessage: 'Pierce Large Metal Temples',
    id: 'search.inputPlaceholder'
  },
  productResults: {
    defaultMessage: 'Products ({count, number} {count, plural, one {result} other {results} })',
    id: 'search.productResults'
  },
  resetTitle: {
    defaultMessage: 'Reset',
    id: 'search.buttonReset'
  },
  results: {
    defaultMessage: '{count, number} {count, plural, one {result} other {results} }',
    id: 'search.results'
  },
  search: {
    defaultMessage: 'Search',
    id: 'search.search'
  },
  'search.noResultForQuery': {
    defaultMessage: 'No results have been found for {query}',
    id: 'search.noResultForQuery'
  },
  'search.showAllResults': {
    defaultMessage: 'Show all results',
    id: 'search.showAllResults'
  },
  searchSeeAllFaqs: {
    defaultMessage: 'See all FAQs',
    id: 'search.seeAllFaqs'
  },
  submitTitle: {
    defaultMessage: 'Search',
    id: 'search.buttonSubmit'
  }
});

export default messages;
