import React from 'react';
import { FormattedMessage } from 'react-intl';
import messages from './messages';
import { Typography, uiColors, grid } from '@aceandtate/ds';
import { Variant } from 'types/torii';
import NotifyMe from './partials/NotifyMe';
import * as Styles from './styles';
import stockMessages from 'messages/stock';

type Props = {
  currentVariant: Variant;
  type?: 'color' | 'product';
};

export default function OnlineProductStock(props: Props) {
  const { currentVariant } = props;

  const typeMessage =
    props.type === 'color' ? <FormattedMessage {...messages.color} /> : <FormattedMessage {...messages.product} />;

  if (!currentVariant.availability.isAvailableOnline && currentVariant.displayAttributes.isLimitedEdition) {
    return (
      <Typography variant='bodyS' color={uiColors.error}>
        <FormattedMessage values={{ type: typeMessage }} {...messages.soldout} />
      </Typography>
    );
  }
  if (!currentVariant.availability.isAvailableOnline) {
    return (
      <Styles.Row>
        <Typography variant='bodyS' color={uiColors.error} style={{ marginRight: grid[4] }}>
          <FormattedMessage values={{ type: typeMessage }} {...messages.notInStock} />
        </Typography>
        <NotifyMe currentVariant={currentVariant}>
          <FormattedMessage {...stockMessages.notifyMe} />
        </NotifyMe>
      </Styles.Row>
    );
  }
  if (currentVariant.availability.lowAvailabilityOnline) {
    return (
      <Typography variant='bodyS' color={uiColors.warning}>
        <FormattedMessage values={{ type: props.type || 'color' }} {...messages.lowStock} />
      </Typography>
    );
  }
  return null;
}
