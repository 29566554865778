import { defineMessages } from 'react-intl';

export default defineMessages({
  accessibility: {
    defaultMessage: 'accessibility',
    id: 'footer.link.accessibility'
  },
  cookiePolicy: {
    defaultMessage: 'cookies',
    id: 'footer.link.cookies'
  },
  'footer.copyright': {
    defaultMessage: '© {thisYear} Ace & Tate. All rights reserved',
    id: 'footer.copyright'
  },
  'footer.mail': {
    defaultMessage: 'email us on ',
    id: 'footer.mail'
  },
  'footer.phone': {
    defaultMessage: 'call us at ',
    id: 'footer.phone'
  },
  'footer.wereHereToHelp': {
    defaultMessage: "We're here to help",
    id: 'footer.wereHereToHelp'
  },
  'footer.whatsapp': {
    defaultMessage: 'whatsapp ',
    id: 'footer.whatsapp'
  },
  impressum: {
    defaultMessage: 'impressum',
    id: 'footer.link.impressum'
  },
  conformity: {
    defaultMessage: 'compliance',
    id: 'footer.link.compliance'
  },
  privacy: {
    defaultMessage: 'privacy',
    id: 'footer.link.privacy'
  },
  termsAndConditions: {
    defaultMessage: 'terms',
    id: 'footer.link.terms'
  },
  websiteTerms: {
    defaultMessage: 'website terms',
    id: 'footer.link.websiteTerms'
  },
  cxOpeningHours: {
    defaultMessage: 'Mon - Fri, 9:00 - 17:00',
    id: 'footer.openingHours.cxOpeningHours'
  }
});
