import React, { useState } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import * as Sentry from '@sentry/nextjs';
import * as Styles from './styles';

type PaymentIconProps = {
  name: string;
  hasBackgroundColor?: boolean;
};

export default function PaymentIcon({ name, hasBackgroundColor }: PaymentIconProps) {
  const [hiddenLogo, setHiddenLogo] = useState(false);
  const nameWithFallback = name === 'mc' ? 'mastercard' : name;
  return (
    <Styles.LabelImage
      data-testid={`paymentMethod.${name}`}
      hidden={hiddenLogo}
      hasBackgroundColor={hasBackgroundColor}
    >
      <LazyLoadImage
        src={`/static/payment/${nameWithFallback.toLowerCase()}.svg`}
        width={60}
        key={`${nameWithFallback}-flag`}
        onError={() => {
          setHiddenLogo(true);
          Sentry.captureMessage(`Payment icon ${name} could not be found`);
        }}
        style={{ borderRadius: 2 }}
      />
    </Styles.LabelImage>
  );
}
