import React, { Fragment, DetailedHTMLProps, AnchorHTMLAttributes } from 'react';
import { Button } from '@aceandtate/ds';
import qs from 'query-string';
import { usePath } from 'paths';
import Plugins from './Plugins';
import styled from 'styled-components';
import { TrackingPurpose } from 'types/airgap';

const StyledLink = styled.a<{ underlined?: boolean }>`
  cursor: pointer;
  text-decoration: ${({ underlined }) => (underlined ? 'underline' : 'none')};
`;

export function parsePluginOptions(href?: string) {
  const hash = href?.split('#').pop() || '';
  const [pluginName, paramsStr = ''] = hash.split('?');
  const props = paramsStr ? qs.parse(paramsStr) : {};
  const Plugin = href ? Plugins[pluginName as keyof typeof Plugins] : Fragment;
  const isPlugin = typeof Plugin === 'function';

  return { Plugin, isPlugin, props };
}

type LinkProps = DetailedHTMLProps<AnchorHTMLAttributes<HTMLAnchorElement>, HTMLAnchorElement>;

type Props = LinkProps & {
  trackingPurpose?: TrackingPurpose;
  children: React.ReactNode;
  buttonColor?: React.ComponentProps<typeof Button>['color'];
  buttonVariant?: React.ComponentProps<typeof Button>['variant'];
  'data-testid'?: string;
  ariaLabel?: string;
  to?: string;
  underlined?: boolean;
};

const Link = React.forwardRef<HTMLAnchorElement, Props>((props, ref) => {
  const pathTo = usePath();

  const { children, href = undefined, to = undefined, ...anchorProps } = props;

  const { Plugin, props: pluginProps } = parsePluginOptions(href);

  function scrollIntoView(e: React.MouseEvent) {
    const target = document.getElementById(href.substring(1));
    if (target && target.scrollIntoView) {
      e.preventDefault();
      target.scrollIntoView({ behavior: 'smooth' });
    }
  }

  if (typeof href === 'string' && typeof Plugin === 'function') {
    return (
      <Plugin {...props} {...pluginProps} {...anchorProps}>
        {children}
      </Plugin>
    );
  } else if (href && href.startsWith('#')) {
    return (
      <StyledLink
        ref={ref as any}
        href={href}
        {...anchorProps}
        onClick={scrollIntoView}
        data-testid={props['data-testid']}
      >
        {children}
      </StyledLink>
    );
  } else {
    return (
      <StyledLink ref={ref as any} href={href || pathTo(to)} {...anchorProps} data-testid={props['data-testid']}>
        {children}
      </StyledLink>
    );
  }
});

export default Link;
