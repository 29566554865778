import React from 'react';
import * as PopoverPrimitive from '@radix-ui/react-popover';
import { borderRadius, brandColors, zIndex, grid } from '@aceandtate/ds';
import styled from 'styled-components';

const StyledPopoverContent = styled(PopoverPrimitive.Content)`
  background-color: ${brandColors.light};
  border-radius: ${borderRadius.small};
  z-index: ${zIndex.selectMenu};
  overflow: hidden;
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14),
    0px 3px 14px 2px rgba(0, 0, 0, 0.12);
`;

const StyledListItem = styled.li`
  padding: ${grid[12]} ${grid[12]} !important;
  cursor: pointer;
  display: block;
  width: 100%;
  && {
    align-items: flex-start;
  }
  border-radius: ${borderRadius.small};

  :focus-visible {
    outline: none;
    background: ${brandColors.dark10};
  }

  :hover {
    background: ${brandColors.dark10};
  }
`;

type PopoverProps = {
  children: React.ReactNode;
  modal?: boolean;
};

const Popover = ({ children, modal = false }: PopoverProps) => {
  return <PopoverPrimitive.Root modal={modal}>{children}</PopoverPrimitive.Root>;
};

Popover.Trigger = styled(PopoverPrimitive.Trigger)``;
Popover.Item = StyledListItem;

Popover.Content = React.forwardRef<HTMLDivElement, PopoverPrimitive.PopoverContentProps>(
  ({ children, ...rest }, forwardedRef) => (
    <PopoverPrimitive.PopoverPortal>
      <StyledPopoverContent sideOffset={5} {...rest} ref={forwardedRef}>
        {children}
      </StyledPopoverContent>
    </PopoverPrimitive.PopoverPortal>
  )
);

export default Popover;
