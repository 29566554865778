import React from 'react';
import { Typography } from '@aceandtate/ds';
import { footerEvents } from 'tracking';
import { getCallablePhoneNumber } from 'utils/contact';
import * as Styles from '../styles';

interface Props {
  phone: string;
}

export default function FooterPhoneLinkInfo({ phone }: Props): JSX.Element {
  return (
    <Typography variant='bodyM' fontWeight='regular'>
      <a
        href={`tel:${getCallablePhoneNumber(phone)}`}
        onClick={() => {
          footerEvents.getInTouch('Phone');
        }}
      >
        <Styles.PhoneIconStyled />
        {phone}
      </a>
    </Typography>
  );
}
