import React, { useContext } from 'react';
import type { ProductVariant, Variant } from 'types/torii';
import { useMediaQuery, breakpointRules } from '@aceandtate/ds';
import ConfiguratorOption from 'components/Configurators/ConfiguratorOption';
import PriceLabel from 'components/DisplayLabels/PriceLabel';
import { ServicesContext } from 'services/context';
import { transform } from 'utils/images';
import { getImageTitleForProduct } from 'utils/product/productImages';
import { useIntl } from 'react-intl';

type Props = {
  upsellProducts: Variant[];
  selectedUpsellProducts: Variant[];
  toggleUpsellProduct: (val: Variant) => void;
};

export default function Upsell(props: Props) {
  const { upsellProducts, selectedUpsellProducts, toggleUpsellProduct } = props;

  const intl = useIntl();
  const ctx = useContext(ServicesContext);
  const { currency } = ctx.webStore.config;
  const isMobile = !useMediaQuery(breakpointRules.tablet);

  const recommendedProductImageConfig = (isMobile?: boolean) => ({
    height: isMobile ? 500 : 300,
    quality: 100,
    width: isMobile ? 500 : 300
  });

  return (
    <ul>
      {upsellProducts?.map((item: ProductVariant) => {
        const productIsSelected = selectedUpsellProducts?.some((product: Variant) => product.id === item.id);
        return (
          <li key={item.id}>
            <ConfiguratorOption
              title={item.name}
              selectorType='single'
              selected={productIsSelected}
              description={item.displayAttributes?.color}
              imageSrc={transform(item.images.front.url, recommendedProductImageConfig(isMobile))}
              key={item.id}
              data-testid={`${item.sku}-item`}
              onClick={() => toggleUpsellProduct(item)}
              infoLabel={<PriceLabel price={item.price.value} currency={currency} />}
              alt={getImageTitleForProduct(item, intl)}
            />
          </li>
        );
      })}
    </ul>
  );
}
