import { defineMessages } from 'react-intl';

export default defineMessages({
  abandonChangesButton: {
    defaultMessage: 'Abandon changes',
    id: 'giftcardConfigurator.abandonChangesButton'
  },
  addPersonalisationButton: {
    defaultMessage: 'personalise giftcard',
    id: 'giftcardConfigurator.addPersonalisationButton'
  },
  alreadyHaveGiftcard: {
    defaultMessage: 'Already got a Gift Card? {checkBalanceChunk} and start shopping.',
    id: 'giftcard.alreadyHaveGiftcard'
  },

  alreadyHaveGiftcardCheckBalanceChunk: {
    defaultMessage: 'Check your balance',
    id: 'giftcard.alreadyHaveGiftcardCheckBalanceChunk'
  },
  dateErrorMessage: {
    defaultMessage: 'Please select a date in the future',
    id: 'giftcardConfigurator.dateErrorMessage'
  },
  description: {
    defaultMessage: `In need of a great present? Get them something they'll actually use – get them an Ace & Tate Gift Card. Customise your card, add a personal message and have it sent straight to their inbox. Too easy. Prefer to hand it over in person? Simply enter your own email so you can print it out and present it yourself. Plus: our Gift Card can be used online and in-store.`,
    id: 'giftcard.description'
  },
  emailDoesNotExistPleaseCheck: {
    defaultMessage:
      'This email is not registered with an Ace & Tate account. The recipient may want to receive their gift card on another email.',
    id: 'giftcardConfigurator.emailDoesNotExistPleaseCheck'
  },
  giftcard148: {
    defaultMessage: '{price} - Pair of prescription sunglasses',
    id: 'giftcardSKU.giftcard148'
  },
  giftcardBalanceResultText: {
    defaultMessage: 'The balance on your gift card is',
    id: 'giftcardBalanceCheck.balanceResultText'
  },
  giftcardCallout: {
    defaultMessage: 'Keep it simple this year with our stress-free gift card.',
    id: 'giftcardConfigurator.callout'
  },
  giftcardCheckBalanceButton: {
    defaultMessage: 'Check your balance',
    id: 'giftcardBalanceCheck.checkBalanceButton'
  },
  giftcardChooseDesign: {
    defaultMessage: 'Choose a design',
    id: 'giftcardConfigurator.chooseDesign'
  },
  giftcardCloseModalDescription: {
    defaultMessage: 'You have unsaved changes. Do you want to save them before closing the window?',
    id: 'giftcardConfigurator.closeModalDescription'
  },
  giftcardCloseModalTitle: {
    defaultMessage: 'Do you wish to save changes?',
    id: 'giftcardConfigurator.closeModalTitle'
  },
  giftcardCodeLabel: {
    defaultMessage: 'Code',
    id: 'giftcardBalanceCheck.codeLabel'
  },
  giftcardCodeLabelExplanation: {
    defaultMessage:
      'For digital cards, enter the promotion code from the email. For physical cards, enter the code printed on the card.',
    id: 'giftcardBalanceCheck.codeLabelExplanation'
  },
  giftcardCreateYourPersonalisedGift: {
    defaultMessage: 'Create your personalised gift card',
    id: 'giftcardConfigurator.createYourPersonalisedGift'
  },
  giftcardDateLabel: {
    defaultMessage: 'Date',
    id: 'giftcardConfigurator.dateLabel'
  },
  giftcardDateTimeSendCaption: {
    defaultMessage: 'Your gift card will be sent between 9 and 10 AM CET',
    id: 'giftcardConfigurator.dateTimeSendCaption'
  },
  giftcardDuplicateOrder: {
    defaultMessage: 'duplicate order',
    id: 'giftcardConfigurator.duplicateOrder'
  },
  giftcardEditButton: {
    defaultMessage: 'edit',
    id: 'giftcardConfigurator.editButton'
  },
  giftcardEditPersonalisation: {
    defaultMessage: 'Adjust gift card  personalisation',
    id: 'giftcardConfigurator.editPersonalisation'
  },
  giftcardForWho: {
    defaultMessage: `Who is the gift card for?`,
    id: 'giftcardConfigurator.forWho'
  },
  giftcardFromWho: {
    defaultMessage: `Who shall we say it's from?`,
    id: 'giftcardConfigurator.fromWho'
  },
  giftcardNotFound: {
    defaultMessage: 'The giftcard code you entered was not found, or it does not belong to the entered email address.',
    id: 'giftcardBalanceCheck.notFound'
  },
  giftcardOwnerEmailLabel: {
    defaultMessage: 'email',
    id: 'giftcardBalanceCheck.ownerEmailLabel'
  },
  giftcardOwnerEmailLabelExplanation: {
    defaultMessage:
      'For digital gift cards, enter the email address it was sent to. For physical cards, enter the email address of the purchaser.',
    id: 'giftcardBalanceCheck.ownerEmailLabelExplanation'
  },
  giftcardPickDateLabel: {
    defaultMessage: 'Pick a date',
    id: 'giftcardConfigurator.pickDateLabel'
  },
  giftcardRecipientEmailLabel: {
    defaultMessage: `Recipient's email`,
    id: 'giftcardConfigurator.recipientEmailLabel'
  },
  giftcardRecipientNameCaption: {
    defaultMessage: 'The person receiving the Gift Card',
    id: 'giftcardConfiguartor.recipientNameCaption'
  },
  giftcardRecipientNameLabel: {
    defaultMessage: `Recipient's name`,
    id: 'giftcardConfigurator.recipientNameLabel'
  },
  giftcardSaveEditsButton: {
    defaultMessage: 'save edits',
    id: 'giftcardConfigurator.saveEditsButton'
  },
  giftcardSendNowLabel: {
    defaultMessage: 'Today',
    id: 'giftcardConfigurator.sendNowLabel'
  },
  giftcardSendWhen: {
    defaultMessage: `When shall we send the gift card?`,
    id: 'giftcardConfigurator.sendWhen'
  },
  giftcardSenderNameLabel: {
    defaultMessage: `Name`,
    id: 'giftcardConfigurator.senderNameLabel'
  },
  giftcardWriteSomethingNice: {
    defaultMessage: 'Write something nice here',
    id: 'giftcardConfigurator.writeSomethingNice'
  },
  giftcardfull: {
    defaultMessage: '{price} - Pair of glasses',
    id: 'giftcardSKU."giftcardfull"'
  },
  giftcardhalf: {
    defaultMessage: '{price} - half a pair of glasses',
    id: 'giftcardSKU.giftcardhalf'
  },
  giftcardquarter: {
    defaultMessage: '{price} - a quarter pair of glasses',
    id: 'giftcardSKU.giftcardquarter'
  },
  productTitle: {
    defaultMessage: 'Gift card',
    id: 'giftcard.productTitle'
  },
  saveAndCloseButton: {
    defaultMessage: 'Save and close',
    id: 'giftcardConfigurator.saveAndCloseButton'
  },
  viewPersonalisationButton: {
    defaultMessage: 'view personalisation',
    id: 'giftcardConfigurator.viewPersonalisationButton'
  }
});
