import { useContext } from 'react';
import { ServicesContext } from 'services/context';
import { Line } from 'types/solidus';
import { useFetch } from 'utils/helpers/customFetch';
import { PaymentMethods, AdyenApiResponse, AdyenGivingRequest } from './types/adyen';
import { captureException } from '@sentry/nextjs';
import AdyenCheckout from '@adyen/adyen-web';

import * as captcha from 'utils/captcha';
function useGetClientKey() {
  const fetch = useFetch();

  return () => {
    return fetch('/adyen/checkout/client_key');
  };
}

export function useMakeDonation() {
  const fetch = useFetch<AdyenApiResponse>();

  return async (adyenGivingRequest: AdyenGivingRequest) => {
    const postData = {
      // adyen requires a value in cents so 98.00 euros has to become 9800
      // Math round is used to avoid floating point issues

      ...adyenGivingRequest,
      amount: Math.round(adyenGivingRequest.amount * 100)
    };
    const res = await fetch('/adyen/checkout/donate', {
      body: postData,
      method: 'POST'
    });

    return res;
  };
}

export function useMakePayment() {
  const context = useContext(ServicesContext);
  const fetch = useFetch();

  return async ({ amount, returnUrl, paymentMethod, browserInfo }) => {
    const origin = `${window.location.protocol}//${window.location.host}`;

    let captcha_token: string;
    if (context.webStore.checkout_captcha_enabled) {
      await captcha.ready();
      try {
        captcha_token = await captcha.execute('submit');
      } catch (err) {
        captureException(`captcha validation failed ${err}`);
      }
    }

    const postData = {
      // adyen requires a value in cents so 98.00 euros has to become 9800
      // Math round is used to avoid floating point issues
      amount: Math.round(amount * 100),
      browserInfo,
      captcha_token,
      countryCode: context.webStore.country.iso,
      currency: context.webStore.config.currency,
      paymentMethod,
      returnUrl: `${origin}${returnUrl}`
    };

    const res = await fetch('/adyen/checkout/payments', {
      body: postData,
      method: 'POST'
    });

    return res;
  };
}

export function useGetPaymentDetails() {
  const fetch = useFetch();
  return body => {
    return fetch('/adyen/checkout/payment_details', {
      body,
      method: 'POST'
    });
  };
}

export function useGetPaymentMethods() {
  const context = useContext(ServicesContext);

  const fetch = useFetch<PaymentMethods>();
  const getCheckoutPaymentMethods = (amount: number) => {
    return fetch('/adyen/checkout/payment_methods', {
      body: {
        // adyen requires a value in cents so 98.00 euros has to become 9800
        // Math round is used to avoid floating point issues
        amount: Math.round(amount * 100),
        countryCode: context.webStore.country.iso,
        currency: context.webStore.config.currency
      },
      method: 'POST'
    });
  };

  const getCountryPaymentMethods = () => {
    return fetch('/adyen/checkout/payment_methods', {
      method: 'GET',
      params: {
        country_code: context.webStore.country.iso
      }
    });
  };

  return { getCheckoutPaymentMethods, getCountryPaymentMethods };
}

const adyenLocaleMap = {
  de: 'de-DE',
  es: 'es-ES',
  fr: 'fr-FR',
  nl: 'nl-NL'
};

export function customizePaymentMethods(paymentMethodsData: PaymentMethods, _cartLines: Line[]): PaymentMethods {
  if (!paymentMethodsData || !paymentMethodsData.paymentMethods) return paymentMethodsData;

  // *** In the future we will want to hide Klarna when the frameCount is bigger than 1 *** //
  // const frameCount = cartLines.reduce((acc, x) => (x.product_type === 'frame' ? acc + x.quantity : acc), 0);
  // const hideKlarna = frameCount > 1;
  // *** End *** //

  const hasKlarna = paymentMethodsData.paymentMethods.some(x => x.type === 'klarna');

  // if (hasKlarna && hideKlarna) {
  //   return {
  //     ...paymentMethodsData,
  //     paymentMethods: paymentMethodsData.paymentMethods.filter(x => x.type !== 'klarna')
  //   };
  // } else

  if (hasKlarna) {
    return {
      ...paymentMethodsData,
      paymentMethods: paymentMethodsData.paymentMethods.slice().sort((_, b) => (b.type === 'klarna' ? -1 : 1))
    };
  }

  return paymentMethodsData;
}

export function useInitializeAdyenPaymentWidget() {
  const { getCheckoutPaymentMethods } = useGetPaymentMethods();
  const getClientKey = useGetClientKey();
  const context = useContext(ServicesContext);

  return async (amount: number, cartLines: any[], configuration: any) => {
    const paymentMethodsResponse = await getCheckoutPaymentMethods(amount);
    const clientKeyRequest = await getClientKey();

    // hack to hide klarna as payment option in the UI if more than 1 product is in cart
    const updatedPaymentMethods = customizePaymentMethods(paymentMethodsResponse.data, cartLines);

    const locale = adyenLocaleMap[context.locale.lang] || 'en-US';

    return AdyenCheckout({
      clientKey: clientKeyRequest.data.client_key,
      environment: process.env.NEXT_PUBLIC_ADYEN_ENVIRONMENT,
      locale: locale,
      paymentMethodsResponse: updatedPaymentMethods,
      showPayButton: false,
      ...configuration
    });
  };
}
