import Cookie from 'js-cookie';
import { useContext } from 'react';
import { ServicesContext } from 'services/context';
import queryString from 'query-string';
import * as Sentry from '@sentry/nextjs';
import { Locale } from 'types/locale';

const SOLIDUS_ENDPOINT_BASE = process.env.NEXT_PUBLIC_SOLIDUS_ENDPOINT_BASE;
const TORII_ENDPOINT_BASE = process.env.NEXT_PUBLIC_TORII_ENDPOINT_BASE;

export type ApiResponse<T = unknown> = {
  error?: unknown;
  data?: T;
  success?: boolean;
  status?: number;
};

export function withTokens(headers = {}) {
  const guestToken = Cookie.get('guestToken');
  const accessToken = Cookie.get('accessToken');
  return {
    ...headers,
    ...(guestToken && { 'X-Spree-Guest-Token': guestToken }),
    ...(accessToken && { Authorization: `Bearer ${accessToken}` })
  };
}

interface CustomFetchOptions extends Omit<RequestInit, 'headers'> {
  params?: any;
  headers?: any;
}

export async function customFetch<T = unknown>(url: string, options: CustomFetchOptions = {}) {
  const query = queryString.stringify(options.params || {});

  try {
    const fetchOptions: RequestInit = {
      ...options,
      headers: {
        'content-type': 'application/json',
        ...options.headers
      }
    };

    if (options.headers && options.headers['content-type'] === false) {
      delete fetchOptions.headers['content-type'];
    }

    const res = await fetch(query ? `${url}?${query}` : `${url}`, fetchOptions);
    const text = await res.text();
    let data: T;
    try {
      data = JSON.parse(text);
    } catch (error) {
      data = text as unknown as T;
    }
    return {
      data,
      status: res.status,
      success: res.status >= 200 && res.status < 300
    };
  } catch (error) {
    Sentry.captureException(error);
    return {
      error
    };
  }
}

export function useFetch<T = any>() {
  const context = useContext(ServicesContext);
  return async (path: string, options: any = {}) => {
    const { locale, webStore } = context;
    let body;

    if (context) {
      if (options.form) {
        body = options.form;
      } else if (options.body) {
        body = JSON.stringify(options.body);
      }
      const apiResponse = await customFetch<T>(`${SOLIDUS_ENDPOINT_BASE}${path}`, {
        ...options,
        body,
        headers: withTokens(options.headers),
        params: {
          language: `${locale.lang}`,
          store_id: webStore.config.storeID,
          ...(options.params || {})
        }
      });
      return apiResponse;
    }
  };
}

export function useToriiFetch<T = any>() {
  const context = useContext(ServicesContext);

  return async function <K = T>(path: string, options: any = {}) {
    const { locale } = context;
    let body;

    if (options.form) {
      body = options.form;
    } else if (options.body) {
      body = JSON.stringify(options.body);
    }

    if (context) {
      const apiResponse = await customFetch<K>(`${TORII_ENDPOINT_BASE}${path}`, {
        ...options,
        body,
        params: {
          'country-code': locale.country ? locale.country.toLowerCase() : 'nl',
          'language-code': locale.lang ? locale.lang.toLowerCase() : 'en',
          ...options.params
        }
      });

      return apiResponse;
    }
  };
}

export async function toriiFetch<T>(path: string, options: any = {}, locale: Locale) {
  let body;

  if (options.form) {
    body = options.form;
  } else if (options.body) {
    body = JSON.stringify(options.body);
  }

  if (locale) {
    const apiResponse = await customFetch<T>(`${TORII_ENDPOINT_BASE}${path}`, {
      ...options,
      body,
      params: {
        'country-code': locale.country ? locale.country.toLowerCase() : 'nl',
        'language-code': locale.lang ? locale.lang.toLowerCase() : 'en',
        ...options.params
      }
    });

    return apiResponse;
  }
}
