import { useContext } from 'react';
import { useFetch } from 'utils/helpers/customFetch';
import { ServicesContext } from 'services/context';
import * as newsletterTracking from 'tracking/events/newsletter';
import { useNinetailed } from '@ninetailed/experience.js-next';

type SubscribeOptions = {
  email: string;
  formType?: string;
  reframe?: boolean;
  date_of_birth?: string;
  gender?: string;
};

type SubscribeResponse = {
  email: string;
  email_hash256: string;
};

export function useSubscribe() {
  const fetch = useFetch<SubscribeResponse>();
  const context = useContext(ServicesContext);
  const { track } = useNinetailed();

  if (context) {
    const { locale } = context;
    return async (options: SubscribeOptions) => {
      const country = `${locale.country.toUpperCase()}`;
      const lang = `${locale.lang}_${locale.country}`;
      const response = await fetch('/subscribe', {
        body: { ...options, country, lang },
        method: 'POST'
      });

      if (response.success) {
        track('newsletter_signup', { state: 'requested' });
        newsletterTracking.newsletterRequested({
          country,
          formType: options.formType,
          hashM: response.data.email_hash256,
          hashM256: response.data.email_hash256,
          localeLang: lang
        });
      }

      return response;
    };
  }
}

export function useUnsubscribe() {
  const fetch = useFetch<SubscribeResponse>();
  const context = useContext(ServicesContext);
  const { track } = useNinetailed();

  if (context) {
    return async (eid: string) => {
      const response = await fetch('/unsubscribe', {
        body: { eid },
        method: 'POST'
      });

      if (response.success) {
        track('newsletter_signup', { state: 'cancelled' });
      }

      return response;
    };
  }
}
