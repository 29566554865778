import React from 'react';
import * as Styles from './styles';

export default function UnidaysLandingPage() {
  return (
    <Styles.UnidaysIframeContainer>
      <script
        type='text/javascript'
        dangerouslySetInnerHTML={{
          __html: `(function(){try{var a=addEventListener,b=function(){};a&&a("touchstart",b);a&&a("touchmove",b); a&&a("touchend",b)}catch(e){}}())`
        }}
      />
      <Styles.UnidaysIframe src='https://www.myunidays.com/partners/aceandtate/embedded/online' />
    </Styles.UnidaysIframeContainer>
  );
}
