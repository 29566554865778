import { defineMessages } from 'react-intl';

export default defineMessages({
  DK: {
    defaultMessage: 'Excluding Faroe Islands, Greenland',
    id: 'shippingExclusions.DK'
  },
  ES: {
    defaultMessage: 'Excluding Canary Islands, Balearic Islands',
    id: 'shippingExclusions.ES'
  },
  GB: {
    defaultMessage: 'Excluding Channel Islands, Northern Isles',
    id: 'shippingExclusions.GB'
  },
  IT: {
    defaultMessage: 'Excluding San Marino, Vatican City',
    id: 'shippingExclusions.IT'
  },
  PT: {
    defaultMessage: 'Excluding Madeira, Azores',
    id: 'shippingExclusions.PT'
  },
  US: {
    defaultMessage: 'Excluding PO Boxes, Army Post Office Addresses',
    id: 'shippingExclusions.US'
  }
});
