import { paths } from 'paths';
import messages from '../messages';

export const links = [
  {
    href: paths.accessibility,
    message: messages.accessibility,
    noFollow: true
  },
  { href: paths.cookiePolicy, message: messages.cookiePolicy, noFollow: true },
  { href: paths.impressum, message: messages.impressum, noFollow: true },
  { href: paths.privacyPolicy, message: messages.privacy, noFollow: true },
  {
    href: paths.termsConditions,
    message: messages.termsAndConditions,
    noFollow: true
  },
  { href: paths.websiteTerms, message: messages.websiteTerms, noFollow: true },
  { href: paths.conformity, message: messages.conformity, noFollow: true }
];
