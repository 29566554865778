import React, { useContext } from 'react';
import type { ProductVariant } from 'types/torii';
import { FormattedMessage } from 'react-intl';
import { Typography, brandColors, fontSizes } from '@aceandtate/ds';
import Label from 'components/Label';
import ecommerceMessages from 'messages/ecommerce';
import { DEAL_LABEL_COUNTRIES } from 'globalConstants';
import { ServicesContext } from 'services/context';
import { calculateRoundedPercentageDiscount } from 'utils/helpers/cart';

export default function ({ variant }: { variant: ProductVariant }) {
  const { locale } = useContext(ServicesContext);
  const percentOff = calculateRoundedPercentageDiscount(variant.price.value, variant.salePrice.value) + '%';

  return (
    <Label backgroundColor={brandColors.sale}>
      <Typography
        variant='bodyM'
        fontWeight='bold'
        color={brandColors.light}
        style={{ lineHeight: `${fontSizes.sans.input.mobile.lineHeight}px` }}
      >
        {DEAL_LABEL_COUNTRIES.includes(locale.country) ? (
          <span>
            <FormattedMessage {...ecommerceMessages.deal} />
            &nbsp;
            {percentOff}
          </span>
        ) : (
          <span>
            <FormattedMessage {...ecommerceMessages.sale} />
            &nbsp;
            {percentOff}
          </span>
        )}
      </Typography>
    </Label>
  );
}
