import { brandColors } from '@aceandtate/ds';
import styled, { css, keyframes } from 'styled-components';

const shimmer = keyframes`
  100% {
    transform: translateX(100%);
  }
`;

/**
 * Skeleton Box placeholder to show while a component is loading.
 * @param width Optional. Desired width.
 * @param height Optional. Desired height.
 * @param ratio Optional. Desired ratio in width/height format. e.g: "16/9".
 * @returns Animated grey box with the desired size.
 */
export const SkeletonBox = styled.div<{
  width?: number | string;
  height?: number | string;
  ratio?: string; // "width/height"
}>`
  width: ${({ width = '100%' }) => width};
  height: ${({ height = '100%' }) => height};

  ${({ ratio = '1/1', width, height }) =>
    !width &&
    !height &&
    css`
      padding-top: calc(100% / (${ratio}));
    `}
  background-color: ${brandColors.dark10};
  position: relative;
  overflow: hidden;

  :after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transform: translateX(-100%);
    background-image: ${`linear-gradient(90deg, transparent 0, ${brandColors.light30} 20%, ${brandColors.light75} 60%, transparent)`};
    animation: ${shimmer} 2s infinite;
    content: '';
  }
`;
