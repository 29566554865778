export interface AirgapMetadata {
  services: Service[];
}

export interface Service {
  title: string;
  description: string;
  cookies: Cookie[];
  dataFlows: DataFlow[];
  logoSquare?: string;
}

export interface Cookie {
  name: string;
  trackingPurposes: TrackingPurpose[];
}

export interface DataFlow {
  value: string;
  type: Type;
  trackingPurposes: TrackingPurpose[];
}

export enum TrackingPurpose {
  Advertising = 'Advertising',
  Analytics = 'Analytics',
  Essential = 'Essential',
  Functional = 'Functional'
}

export enum Type {
  Host = 'HOST'
}
