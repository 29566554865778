import styled from 'styled-components';
import { brandColors, grid, Button } from '@aceandtate/ds';

export const FormGroup = styled.div`
  margin-bottom: ${grid[16]};
`;

export const SubmitButton = styled(Button)`
  background-color: transparent;
  color: ${brandColors.dark};
  padding: 0;
`;
