import styled, { css } from 'styled-components';
import { selectedStyle } from 'styles';
import { Typography, brandColors, uiColors, breakpointRules, grid } from '@aceandtate/ds';

type StyleProps = {
  disabled?: boolean;
  hasDescription?: boolean;
  selected?: boolean;
  withImage?: boolean;
  isExperiment?: boolean;
};

export const Wrapper = styled.div<StyleProps>`
  align-items: flex-start;
  border: 1px solid ${brandColors.dark10};
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  max-width: 480px;
  overflow: hidden;
  position: relative;
  width: 100%;
  flex-flow: column;
  padding: ${props => (props.withImage ? 0 : grid[24])};
  ${props => props.selected && selectedStyle}

  @media ${breakpointRules.tablet} {
    padding: ${props => (props.withImage ? '0' : grid[24])};
    flex-flow: ${props => (props.withImage ? 'row' : 'column')};
    max-width: inherit;
  }
  ${({ isExperiment }) =>
    isExperiment &&
    css`
      :hover {
        background-color: rgba(248, 246, 243, 0.4);
      }
    `}

  ${({ disabled }) =>
    disabled &&
    css`
      opacity: 0.5;
      pointer-events: none;
      cursor: default;
    `}
`;

export const CheckWrap = styled.div<{
  selected: boolean;
}>`
  color: ${({ selected }) => (selected ? brandColors.accent : uiColors.disabled)};
  display: flex;
`;

type ImageWrapperProps = {
  isMobile?: boolean;
};

export const ImageWrapper = styled.div<ImageWrapperProps>`
  position: relative;
  display: flex;
  flex-basis: 33.33%;
  width: 100%;
  ${CheckWrap} {
    position: absolute;
    top: ${({ isMobile }) => (isMobile ? grid[12] : grid[8])};
    right: ${({ isMobile }) => (isMobile ? grid[12] : grid[8])};
  }
`;

export const Content = styled.div<StyleProps>`
  display: flex;
  flex-basis: 66.66%;
  flex-flow: column;
  align-self: ${props => (props.withImage ? 'center' : 'flex-start')};
  padding: ${props => (props.withImage ? `${grid[24]} ${grid[24]}` : 0)};
  width: 100%;
`;

export const HeadingRow = styled.div<StyleProps>`
  align-items: flex-start;
  flex-flow: row;
  display: flex;
  justify-content: space-between;

  @media ${breakpointRules.tablet} {
    margin-top: 0;
  }
`;

export const ContentRow = styled.div<StyleProps>`
  flex-flow: row;
  display: flex;
  justify-content: space-between;
  gap: ${grid[8]};

  ${CheckWrap} {
    align-self: flex-end;
    justify-content: flex-end;
  }
`;

export const Text = styled(Typography)<StyleProps>`
  && {
    color: ${props => (props.disabled ? uiColors.disabled : 'inherit')};
  }
`;

export const Title = styled(Text)`
  width: 80%;
`;

export const InfoLabel = styled(Text)`
  && {
    white-space: nowrap;
  }
`;

export const FocusContentContainer = styled.div`
  margin-top: ${grid[24]};
`;

export const LearnMoreLink = styled.div`
  position: absolute;
  bottom: ${grid[24]};
`;
