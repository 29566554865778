import { grid, InputControl, TextInput, Typography } from '@aceandtate/ds';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import useValidationOptions from 'utils/hooks/useValidationOptions';
import { GiftcardBalanceResponse, useGiftcardService } from 'services/useGiftcardService';
import * as Styles from './styles';
import { FormattedMessage, useIntl } from 'react-intl';
import giftcardMessages from 'messages/giftcard';
import globalErrorMessages from 'messages/globalErrors';
import FormattedPrice from 'components/DisplayLabels/FormattedPrice';

export default function GiftcardBalanceCheck() {
  const { checkBalance } = useGiftcardService();
  const { handleSubmit, register, formState } = useForm<{ code: string; email: string }>({ mode: 'onBlur' });
  const { errors } = formState;
  const [balance, setBalance] = useState<GiftcardBalanceResponse>(null);
  const [isLoading, setIsLoading] = useState(false);
  const formValidation = useValidationOptions();
  const intl = useIntl();
  const [apiError, setApiError] = useState<{ message: string }>(null);

  async function onSubmit(data) {
    setApiError(null);
    setBalance(null);
    setIsLoading(true);
    const res = await checkBalance(data);

    if (!res.success) {
      switch (res.data.error) {
        case 'not_found':
          setApiError({ message: intl.formatMessage(giftcardMessages.giftcardNotFound) });
          break;
        default:
          setApiError({ message: intl.formatMessage(globalErrorMessages.apiRequestError) });
          break;
      }
    } else {
      setBalance(res.data);
    }

    setIsLoading(false);
  }

  function renderValidation(key: string) {
    return errors?.[key] && <InputControl.Validation status='error'>{errors[key].message}</InputControl.Validation>;
  }

  return (
    <Styles.FormContainer flexDirection='column' gap={grid[32]}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <InputControl id='code'>
          <InputControl.Label>
            <InputControl.Tooltip content={<FormattedMessage {...giftcardMessages.giftcardCodeLabelExplanation} />}>
              <FormattedMessage {...giftcardMessages.giftcardCodeLabel} />
            </InputControl.Tooltip>
          </InputControl.Label>
          <TextInput
            fullWidth
            autoFocus
            placeholder='1CBB3-EDCAF-1C2D8'
            {...register('code', { ...formValidation.requiredField })}
          />
          {renderValidation('code')}
        </InputControl>
        <InputControl id='email'>
          <InputControl.Label>
            <InputControl.Tooltip
              content={<FormattedMessage {...giftcardMessages.giftcardOwnerEmailLabelExplanation} />}
            >
              <FormattedMessage {...giftcardMessages.giftcardOwnerEmailLabel} />
            </InputControl.Tooltip>
          </InputControl.Label>
          <TextInput
            fullWidth
            type='email'
            placeholder='jon@doe.com'
            {...register('email', { ...formValidation.emailValidation })}
          />
          {renderValidation('email')}
        </InputControl>

        <Styles.SubmitButton color='accent' type='submit' loading={isLoading}>
          <FormattedMessage {...giftcardMessages.giftcardCheckBalanceButton} />
        </Styles.SubmitButton>
      </form>
      {apiError && <Styles.BalanceErrorBox>{apiError.message}</Styles.BalanceErrorBox>}
      {balance && (
        <Styles.BalanceDisplayBox justifyContent='space-between' alignItems='baseline'>
          <Typography as='span'>
            <FormattedMessage {...giftcardMessages.giftcardBalanceResultText} />
          </Typography>
          <Typography variant='h6' as='span'>
            {balance?.amount && balance?.currency && (
              <FormattedPrice currency={balance.currency} price={balance.amount} />
            )}
          </Typography>
        </Styles.BalanceDisplayBox>
      )}
    </Styles.FormContainer>
  );
}
