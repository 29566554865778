export const getWhatsAppLink = (whatsapp: string) => {
  const whatsAppLink = whatsapp
    .replace('(0)', '')
    .replace(/ /g, '')
    .replace(/-/g, '')
    .replace('+', '');
  return `https://wa.me/${whatsAppLink}`;
};

export const getCallablePhoneNumber = (phoneNumber: string) =>
  phoneNumber && phoneNumber.trim().replace(/[^0-9+]/g, '');
