import React from 'react';
import PupillaryDistance from './luna/pupillary-distance';
import ProductConfigurator from './product-configurator';
import Newsletter from './newsletter';
import ProductProperty from './product-property';
import BookEyeTest from './book-eye-test';
import UnidaysLandingPage from './unidays-landingpage';
import CookiePreferencesButton from './CookiePreferencesButton';
import { GetCookieTableOfType } from './transcend-cookie-categories';
import { TrackingPurpose } from 'types/airgap';
import GiftcardBalanceCheck from './GiftcardBalanceCheck';
import Icon from './icon';
import ConformityTable from './ConformityTable';

export default {
  /** @deprecated links to eye test booking page */
  'book-eye-test': BookEyeTest,
  'configure-product': ProductConfigurator,
  'cookie-policy': GetCookieTableOfType,
  'cookie-policy-advertising': () => <GetCookieTableOfType trackingPurpose={TrackingPurpose.Advertising} />,
  'cookie-policy-analytics': () => <GetCookieTableOfType trackingPurpose={TrackingPurpose.Analytics} />,
  'cookie-policy-essential': () => <GetCookieTableOfType trackingPurpose={TrackingPurpose.Essential} />,
  'cookie-policy-functional': () => <GetCookieTableOfType trackingPurpose={TrackingPurpose.Functional} />,
  'cookie-preferences': CookiePreferencesButton,
  'giftcard-balance-check': GiftcardBalanceCheck,
  icon: Icon,
  'landingpage-newsletter': Newsletter,
  product: ProductProperty,
  'pupillary-distance': PupillaryDistance,
  'reframe-newsletter': props => <Newsletter {...props} subscriptionType='reframe' />,
  'unidays-landingpage': UnidaysLandingPage,
  'conformity-table': ConformityTable
};
