import { defineMessages } from 'react-intl';

export const filterMessages = defineMessages({
  frame_width: {
    defaultMessage: 'Width',
    // eslint-disable-line
    id: 'filters.label.width'
  },
  material: {
    defaultMessage: 'Material',
    id: 'filters.label.material'
  },
  price_map: {
    defaultMessage: 'Price from',
    // eslint-disable-line
    id: 'filters.label.price'
  },
  shape: {
    defaultMessage: 'Shape',
    id: 'filters.label.shape'
  },
  simple_color: {
    defaultMessage: 'Color',
    // eslint-disable-line
    id: 'filters.label.color'
  }
});

export const materialMessages = defineMessages({
  acetate: {
    defaultMessage: 'acetate',
    id: 'filters.material.acetate'
  },
  bio_acetate: {
    defaultMessage: 'bio acetate',
    // eslint-disable-line
    id: 'filters.material.bio_acetate'
  },
  combi: {
    defaultMessage: 'combi',
    id: 'filters.material.combi'
  },
  materials: {
    defaultMessage: 'materials',
    id: 'filters.material.materials'
  },
  metal: {
    defaultMessage: 'metal',
    id: 'filters.material.metal'
  },
  recycled: {
    defaultMessage: 'recycled',
    id: 'filters.material.recycled'
  },
  renew_bio_acetate: {
    defaultMessage: 'renew bio acetate',
    id: 'filters.material.renew_bio_acetate'
  },
  titanium: {
    defaultMessage: 'titanium',
    id: 'filters.material.titanium'
  },
  unknownMaterial: {
    defaultMessage: 'unknown material',
    id: 'filters.material.unknownMaterial'
  }
});

export const shapeMessages = defineMessages({
  hexagonal: {
    defaultMessage: 'hexagonal',
    id: 'filters.shape.hexagonal'
  },
  oval: {
    defaultMessage: 'oval',
    id: 'filters.shape.oval'
  },
  rectangular: {
    defaultMessage: 'rectangle',
    id: 'filters.shape.rectanlge'
  },
  round: {
    defaultMessage: 'round',
    id: 'filters.shape.round'
  },
  square: {
    defaultMessage: 'square',
    id: 'filters.shape.square'
  }
});

export const widthMessages = defineMessages({
  medium: {
    defaultMessage: 'medium',
    id: 'filters.width.medium'
  },
  narrow: {
    defaultMessage: 'narrow',
    id: 'filters.width.narrow'
  },
  wide: {
    defaultMessage: 'wide',
    id: 'filters.width.wide'
  }
});

export const colorMessages = defineMessages({
  colored: {
    defaultMessage: 'colored',
    id: 'filters.color.colored'
  },
  dark: {
    defaultMessage: 'dark',
    id: 'filters.color.dark'
  },
  light: {
    defaultMessage: 'light',
    id: 'filters.color.light'
  },
  transparent: {
    defaultMessage: 'transparent',
    id: 'filters.color.transparent'
  }
});

export const priceMessages = defineMessages({
  base: {
    defaultMessage: '{price}',
    id: 'filters.price.base'
  },
  premium: {
    defaultMessage: '{price}',
    id: 'filters.price.premium'
  }
});

export const allMessages = {
  ...filterMessages,
  ...materialMessages,
  ...shapeMessages,
  ...widthMessages,
  ...colorMessages,
  ...priceMessages
};
