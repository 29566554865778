import React from 'react';
import { Typography } from '@aceandtate/ds';
import { FormattedMessage } from 'react-intl';
import messages from './messages';
import * as Styles from './styles';
import NewsletterForm from 'components/NewsletterForm';

export default function NewsletterFooter() {
  return (
    <Styles.Container data-testid='newsletterFooter.container'>
      <Styles.TextBlock>
        <Typography variant='h2' serif>
          <FormattedMessage {...messages.newsletterBlockCopy} />
        </Typography>
      </Styles.TextBlock>
      <Styles.EmailBlock>
        <NewsletterForm formType='footer' />
      </Styles.EmailBlock>
    </Styles.Container>
  );
}
