import React from 'react';
import { FormattedNumber } from 'react-intl';

type Props = {
  currency: string;
  price: number;
};

function FormattedPrice(props: Props) {
  const { currency, price } = props;
  return (
    <FormattedNumber
      value={price}
      currency={currency}
      style='currency'
      maximumFractionDigits={2}
      minimumFractionDigits={0}
    />
  );
}

export default FormattedPrice;
