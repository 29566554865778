import React, { useContext, useState, useEffect, useCallback } from 'react';
import Modal from 'components/Modal';
import CountrySelect from 'components/CountrySelect';
import ecommerceMessages from 'messages/ecommerce';
import { language as languageMessages } from 'messages/intl';
import messages from './messages';
import { FormattedMessage, useIntl } from 'react-intl';
import { ServicesContext } from 'services/context';
import * as Styles from './styles';
import { WebStore } from 'types/webStore';
import { useChangeRegion } from 'paths';
import { trackArtificialPageView } from 'tracking/helpers';
import { Button, InputControl, Select } from '@aceandtate/ds';
import { SelectItem } from '@aceandtate/ds/dist/components/Select/Select';

const { shippingCountries } = require('config/intl') as { shippingCountries: WebStore[] };

type Props = {
  onClose: () => void;
};

export default function CountryModal(props: Props) {
  const { onClose } = props;

  const intl = useIntl();

  const makeOption = useCallback(
    lang => ({
      id: lang,
      label: intl.formatMessage(languageMessages[lang]),
      value: lang
    }),
    [intl]
  );

  const [isLoading, setIsLoading] = useState(false);
  const { webStore, locale } = useContext(ServicesContext);
  const currentCountry = webStore.country.iso;
  const currentLang = locale.lang;

  const [selectedWebstore, setSelectedWebstore] = useState(findStoreByIso(currentCountry));
  const selectedWebstoreCountry = selectedWebstore.country.iso;

  const [selectedLang, setSelectedLang] = useState<string>(locale.lang);
  const changeRegion = useChangeRegion();

  useEffect(() => {
    trackArtificialPageView('country-select');
  }, []);

  function findStoreByIso(iso: string) {
    return shippingCountries.find(x => x.country.iso === iso);
  }

  function applyLocale() {
    if (currentCountry !== selectedWebstoreCountry || currentLang !== selectedLang) {
      setIsLoading(true);
      changeRegion(selectedWebstore.country.iso, selectedLang);
    } else {
      onClose();
    }
  }

  function updateSelectedLocale(iso: string) {
    const newStore = findStoreByIso(iso);
    if (newStore) {
      setSelectedWebstore(newStore);
      setSelectedLang(newStore.languages[0]);
    }
  }

  return (
    <Modal open onClose={onClose}>
      <Modal.Title>
        <FormattedMessage {...messages.title} />
      </Modal.Title>
      <Modal.Body>
        <Styles.CountryWrapper>
          <CountrySelect
            countryCode={selectedWebstore.country.iso}
            id='country-modal-select'
            stores={shippingCountries}
            label={<FormattedMessage {...messages.countryLabel} />}
            onChange={updateSelectedLocale}
          />
        </Styles.CountryWrapper>
        <InputControl id='country-modal'>
          <InputControl.Label>
            <FormattedMessage {...messages.languageLabel} />
          </InputControl.Label>
          <Select
            fullWidth
            data-testid='country-modal-lang'
            selectedItems={makeOption(selectedLang)}
            setSelectedItems={(item: SelectItem<any>) => setSelectedLang(item.value)}
            items={selectedWebstore.languages.map(lang => makeOption(lang))}
          />
        </InputControl>
      </Modal.Body>
      <Modal.Buttons>
        <Button loading={isLoading} data-testid='country-language-picker-modal-cta' onClick={applyLocale}>
          <FormattedMessage {...ecommerceMessages.continue} />
        </Button>
      </Modal.Buttons>
    </Modal>
  );
}
