import { trackGaEvent } from '../helpers';

export function searchError({ query }: { query: string }) {
  trackGaEvent({
    event: 'searchError',
    query
  });
}

type SiteSearchProps = {
  currentHitsCount: number;
  query: string;
}

export function siteSearch({ currentHitsCount, query }: SiteSearchProps) {
  trackGaEvent({
    event: 'siteSearch',
    query,
    resultNumber: currentHitsCount
  });
}

export function searchDefault(clickedItem: string) {
  trackGaEvent({
    clickedItem, // Pass the text of the searchDefault link
    event: 'searchDefault'
  });
}
