import styled from 'styled-components';
import { Typography, borderRadius, grid, uiColors, IconClose, breakpointRules } from '@aceandtate/ds';

export const CloseButton = styled(IconClose).attrs({ fontSize: 27 })`
  position: absolute;
  cursor: pointer;
  right: ${grid[12]};
  top: ${grid[12]};
`;

export const Content = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  padding: ${grid[24]};

  @media ${breakpointRules.tablet} {
    padding: ${grid[40]};
  }
`;

export const Body = styled.div`
  width: 100%;
  max-width: 600px;
`;

export const Title = styled(Typography).attrs({ variant: 'h4' })`
  margin-bottom: ${grid[24]};
`;

export const ErrorStyle = styled(Typography)`
  padding: ${grid[8]} ${grid[12]};
  background-color: ${uiColors.errorLight};
  color: ${uiColors.error};
  border: 1px solid ${uiColors.error};
  border-radius: ${borderRadius.medium};
  margin-top: ${grid[24]};
`;

export const Buttons = styled.div`
  display: flex;
  flex-flow: row-reverse;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  gap: ${grid[8]};
  width: 100%;
  margin-top: ${grid[40]};
`;
