import React, { useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import notificationMessages from 'messages/notifications';
import Emoji from 'components/Emoji';
import { ServicesContext } from 'services/context';
import Notification from '../Notification';

export default function CountryDisabled() {
  const { webStore } = useContext(ServicesContext);

  return (
    <Notification name='country-disabled-notification' sticky dismissType='permanent'>
      <FormattedMessage
        {...notificationMessages.countryDisabled}
        values={{
          br: <br />,
          email: <a href={`mailto:${webStore.contact.email}`}>{webStore.contact.email}</a>,
          sadFace: <Emoji symbol='😕' label='emoji-sad-face' />
        }}
      />
    </Notification>
  );
}
