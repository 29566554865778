import { createContext } from 'react';
import { WishlistContextProps } from './types';

export default createContext<WishlistContextProps>({
  async addToWishlist() {},
  count: 0,
  hasItem: () => false,
  async migrateWishlist() {},
  ready: false,
  async removeFromWishlist() {},
  wishlist: []
});
