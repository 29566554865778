import { borderRadius, brandColors, grid, IconCheck } from '@aceandtate/ds';
import styled from 'styled-components';

export const HiddenRadioInput = styled.input`
  cursor: inherit;
  height: 100%;
  left: 0px;
  margin: 0px;
  opacity: 0;
  padding: 0px;
  position: absolute;
  z-index: 1;
  top: 0px;
  width: 100%;
`;

export const RadioSelectedBorder = styled.div`
  display: none;
  top: 0;
  left: 0;
  position: absolute;
  height: 100%;
  width: 100%;
`;

export const ImageWrapper = styled.span`
  display: flex;
`;

export const CheckIcon = styled(IconCheck)`
  display: block;
`;

export const CheckWrapper = styled.span`
  display: none;
  position: absolute;
  right: ${grid[8]};
  top: ${grid[8]};
  padding: ${grid[4]};
  border-radius: ${borderRadius.full};
  background-color: ${brandColors.light};
`;

export const RadioWrapper = styled.div<{ isDisabled?: boolean }>`
  flex-basis: calc(50% - (${grid[12]} / 2));
  position: relative;
  isolation: isolate;
  border-radius: ${borderRadius.small};
  overflow: hidden;
  cursor: ${({ isDisabled }) => (isDisabled ? 'normal' : 'pointer')};

  input[type='radio']:checked ~ ${RadioSelectedBorder} {
    display: block;
    border-left: 6px solid ${brandColors.accent};
  }

  input[type='radio']:checked ~ ${CheckWrapper} {
    display: block;
  }

  input[type='radio']:disabled ~ ${ImageWrapper} {
    opacity: 0.8;
  }

  input[type='radio']:not(:checked):disabled ~ ${ImageWrapper} {
    opacity: 0.4;
  }
`;
