import React from 'react';

import Link from 'components/Link';
import { usePath } from 'paths';
import { searchEvents } from 'tracking';
import * as Types from '../../types';
import { Typography } from '@aceandtate/ds';

export default function ContentResult({ hit, hasQuery, closeHandler }: Types.ContentResultProps) {
  const pathTo = usePath();
  const handleClick = () => {
    !hasQuery && searchEvents.searchDefault(hit.path);
    closeHandler();
  };

  return (
    <Link href={pathTo(hit.path)} onClick={() => handleClick()}>
      <Typography data-testid='contentResultTitle' variant='bodyM' gutterBottom={8}>
        {hit.title}
      </Typography>
    </Link>
  );
}
