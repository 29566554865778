import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Button, Typography } from '@aceandtate/ds';

import Link from 'next/link';
import { paths, usePath } from 'paths';

import * as Types from '../../types';
import messages from '../../messages';

type Props = {
  query: string;
  variantsIndex: Types.IndexResultsProps;
  closeHandler?: () => void;
};

export default function ShowAll({ query, variantsIndex, closeHandler }: Props) {
  // Only use ShowAll for products
  const results = variantsIndex;
  const pathTo = usePath();

  if (!results) return null;

  if (results && results?.nbHits !== 0) {
    return (
      <Link href={pathTo(`${paths.search}?query=${results.query}`)} onClick={closeHandler}>
        <Button variant='link'>
          <FormattedMessage {...messages['search.showAllResults']} />
        </Button>
      </Link>
    );
  }

  return (
    <Typography fontWeight='regular'>
      <FormattedMessage {...messages['search.noResultForQuery']} values={{ query }} />
    </Typography>
  );
}
