import styled from 'styled-components';
import { mediaOnlyIe, breakpointRules } from 'styles';

export const PageWrapper = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;

  ${mediaOnlyIe(`
    display: block;
  `)};
`;

export const StyledWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;

  @media ${breakpointRules.smallMax} {
    overflow-x: hidden;
  }

  ${mediaOnlyIe(`
    display: block;
  `)};
`;
