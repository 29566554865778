import { defineMessages } from 'react-intl';

const messages = defineMessages({
  company: {
    defaultMessage: 'Company',
    id: 'addressForm.company'
  },
  postalCode: {
    defaultMessage: 'Postal code',
    id: 'addressForm.postalCode'
  },
  select: {
    defaultMessage: 'Select',
    id: 'formLabels.select'
  },
  yourEmail: {
    defaultMessage: 'Your Email',
    id: 'formLabels.yourEmail'
  },
  dateOfBirth: {
    defaultMessage: 'Date of birth',
    id: 'formLabels.dateOfBirth'
  },
  optional: {
    defaultMessage: 'optional',
    id: 'formLabels.optional'
  },
  shoppingPreference: {
    defaultMessage: 'Shopping preference',
    id: 'formLabels.shoppingPreference'
  },
  menPreference: {
    defaultMessage: "Men's",
    id: 'formLabels.menPreference'
  },
  womenPreference: {
    defaultMessage: "Women's",
    id: 'formLabels.womenPreference'
  },
  noPreference: {
    defaultMessage: 'No preference',
    id: 'formLabels.noPreference'
  },
  selectPreference: {
    defaultMessage: 'Select your preference',
    id: 'formLabels.selectPreference'
  }
});

export default messages;
