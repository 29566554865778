import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';

import LoaderIcon from 'components/Loaders/LoaderIcon';
import messages from 'messages/newsletterForm';
import { useSubscribe } from 'services/newsletterService';
import FormFields, { FormData } from './partials/FormFields';
import * as Styles from './styles';
import { brandColors, IconClose, Typography, uiColors } from '@aceandtate/ds';

export type ShopstoryAlign = {
  sm: AlignType;
  xs: AlignType;
  xl: AlignType;
  md: AlignType;
  lg: AlignType;
  '2xl': AlignType;
};

type AlignType = 'left' | 'center' | 'right';

type Props = {
  subscriptionType?: string;
  color?: string;
  align?: ShopstoryAlign;
};

type Status = 'loading' | 'error' | 'submitted';

export default function Newsletter(props: Props) {
  const { subscriptionType, color: colorProp, align } = props;
  const color = colorProp === 'light' ? colorProp : 'dark';

  const [status, setStatus] = useState<Status>(null);
  const subscribe = useSubscribe();

  const submitValues = async (data: FormData) => {
    setStatus('loading');
    const { email, dateOfBirth, gender } = data;

    if (!email || email.length <= 0) return;

    const { success } = await subscribe({
      email,
      formType: subscriptionType === 'reframe' ? 'reframe' : 'landing page',
      date_of_birth: dateOfBirth,
      gender: gender,
      reframe: subscriptionType === 'reframe'
    });

    if (success) {
      setStatus('submitted');
    } else {
      setStatus('error');
    }
  };

  if (status === 'loading') {
    return (
      <Typography textAlign='center'>
        <LoaderIcon color={brandColors[color]} />
      </Typography>
    );
  } else if (status === 'error') {
    return (
      <Styles.Container>
        <IconClose fontSize={20} color={uiColors.error} />
        <Typography color={uiColors.error}>
          <FormattedMessage {...messages.errorMessage} />
        </Typography>
      </Styles.Container>
    );
  } else if (status === 'submitted') {
    return (
      <Typography textAlign='center' color={brandColors[color]} fontWeight='bold'>
        <FormattedMessage {...messages.successMessage} />
      </Typography>
    );
  } else {
    return <FormFields color={color} onSubmit={data => submitValues(data)} align={align} />;
  }
}
