import React, { useContext } from 'react';
import * as Styles from './styles';
import NewsletterFooter from './NewsletterFooter';
import { ServicesContext } from 'services/context';
import Footer from 'blocks/Footer';
import MenuNavigation from 'blocks/MenuNavigation';

type FullTemplateProps = {
  children: React.ReactElement;
  menuHasBorder?: boolean;
  menuTextColor?: string;
};

export default function FullTemplate(props: FullTemplateProps) {
  const ctx = useContext(ServicesContext);
  const { children, menuHasBorder } = props;
  const { menuNavigation, webStore, footer } = ctx;
  const pageProps = props.children.props;

  return (
    <Styles.PageWrapper>
      {menuNavigation && (
        <MenuNavigation
          menu={menuNavigation}
          hasBorder={menuHasBorder}
          menuTextColor={pageProps?.homepage?.menuTextColor}
        />
      )}
      <Styles.StyledWrapper>{children}</Styles.StyledWrapper>
      <NewsletterFooter />
      {footer && <Footer footer={footer} webStore={webStore} />}
    </Styles.PageWrapper>
  );
}
