import { defineMessages } from 'react-intl';

export default defineMessages({
  acceptTerms: {
    defaultMessage: 'I agree to the {privacyPolicy}.*',
    id: 'authentication.checkbox.acceptTerms'
  },
  alreadyHaveAccount: {
    defaultMessage: 'Already have an account?',
    id: 'authentication.alreadyHaveAccount'
  },
  authenticationPageTitle: {
    defaultMessage: 'Login or Sign Up',
    id: 'authentication.pageTitle'
  },
  authenticationSuccessYouAreLoggedIn: {
    defaultMessage: 'You are logged in!',
    id: 'authentication.loginSuccessYouAreLoggedIn'
  },
  backToLogin: {
    defaultMessage: 'Back to login',
    id: 'authentication.backToLogin'
  },
  chooseNewPassword: {
    defaultMessage: 'Choose a new Ace & Tate account password',
    id: 'authentication.chooseNewPassword'
  },
  continue: {
    defaultMessage: 'Continue',
    id: 'authentication.continue'
  },
  continueAsNewCustomer: {
    defaultMessage: 'Continue as new customer',
    id: 'authentication.continueAsNewCustomer'
  },
  createAccount: {
    defaultMessage: 'Create Account',
    id: 'authentication.createAccount'
  },
  createAccountDescription: {
    defaultMessage: "Looks like you don't have an account with us yet. Want to create one?",
    id: 'authentication.createAccountDescription'
  },
  currentPassword: {
    defaultMessage: 'Current Password',
    id: 'labels.currentPassword'
  },
  dontHaveAnAccountYet: {
    defaultMessage: "Don't have an account? Create one",
    id: 'authentication.dontHaveAnAccountYet'
  },
  easyLoginDoneMessage: {
    defaultMessage: "We've sent a login link to {email}. Click on the link and we'll automatically log you in.",
    id: 'authDrawer.drawerEasyLoginDoneMessage'
  },
  easyLoginDoneTitle: {
    defaultMessage: 'Done! Check your mail.',
    id: 'authDrawer.drawerEasyLoginDoneTitle'
  },
  easyLoginError: {
    defaultMessage: 'There was an error requesting your login link. Do you already have an active login link?',
    id: 'authentication.easyLoginError'
  },
  easyLoginWrongEmail: {
    defaultMessage: 'Used the wrong email? {returnLink}.',
    id: 'authDrawer.drawerEasyLoginWrongEmail'
  },
  easyLoginWrongEmailReturnLink: {
    defaultMessage: 'Re-enter your email here',
    id: 'authDrawer.drawerEasyLoginWrongEmailReturnLink'
  },
  email: {
    defaultMessage: 'Email',
    id: 'labels.emailPlain'
  },
  emailLabel: {
    defaultMessage: 'Email Address',
    id: 'labels.email'
  },
  emailLoginLink: {
    defaultMessage: 'Email me a login link',
    id: 'authentication.emailLoginLink'
  },
  emailSent: {
    defaultMessage: 'Email sent !',
    id: 'labels.emailSent'
  },
  enterYourPasswordLabel: {
    defaultMessage: 'Enter your password',
    id: 'labels.loginPassword'
  },
  eyeTestCopy: {
    defaultMessage: 'Did you know we offer free eye tests in-store and online?',
    id: 'authentication.eyeTestCopy'
  },
  eyeTestCta: {
    defaultMessage: 'Book an eye test',
    id: 'authentication.eyeTestCta'
  },
  firstNameLabel: {
    defaultMessage: 'First Name',
    id: 'labels.firstName'
  },
  forgotPassword: {
    defaultMessage: 'Forgot your password?',
    id: 'authentication.forgotPassword'
  },
  forgotPasswordOrEasyLoginDescription: {
    defaultMessage:
      "Don't remember your password or is it too long to type? We'll email you a link so you can login, no password needed.",
    id: 'authDrawer.drawerForgotPasswordOrEasyLoginDescription'
  },
  lastNameLabel: {
    defaultMessage: 'Last Name',
    id: 'labels.lastName'
  },
  login: {
    defaultMessage: 'Login',
    id: 'authentication.login'
  },
  loginErrorMessage: {
    defaultMessage: "Sorry we don't recognize this combination of email and password. Try again or:",
    id: 'authentication.errorMessage'
  },
  loginLinkNoPasswordNeeded: {
    defaultMessage: 'no password needed',
    id: 'authentication.loginLinkNoPasswordNeeded'
  },
  loginOrRegister: {
    defaultMessage: 'Login or Register',
    id: 'authentication.loginOrRegister'
  },
  loginOrRegisterDescription: {
    defaultMessage: 'Want to log in or register? Type in your email to continue',
    id: 'authentication.loginOrSignupDescription'
  },
  loginOrSignup: {
    defaultMessage: 'Login or Sign Up',
    id: 'authentication.loginOrSignup'
  },
  loginSuccessGoToAccount: {
    defaultMessage: 'Go to my account',
    id: 'authentication.loginSuccessGoToAccount'
  },
  loginSuccessNotificationTitle: {
    defaultMessage: 'Welcome back!',
    id: 'authentication.loginSuccessNotificationTitle'
  },
  loginSuccessPersonalisedNotificationTitle: {
    defaultMessage: 'Welcome back {firstName}!',
    id: 'login.successPersonalisedNotificationTitle'
  },
  loginWithExistingAccount: {
    defaultMessage: 'Login with your existing account',
    id: 'authentication.loginWithExistingAccount'
  },
  logout: {
    defaultMessage: 'Logout',
    id: 'authentication.logout'
  },
  nameLabel: {
    defaultMessage: 'Name',
    id: 'labels.name'
  },
  newPasswordLabel: {
    defaultMessage: 'Create your password',
    id: 'labels.newPassword'
  },
  newPasswordRepeat: {
    defaultMessage: 'Confirm New Password',
    id: 'authentication.newPasswordRepeat'
  },
  newPasswordShort: {
    defaultMessage: 'New password',
    id: 'labels.newPasswordShort'
  },
  passwordRepeat: {
    defaultMessage: 'Confirm Password',
    id: 'authentication.passwordRepeat'
  },
  phoneLabel: {
    defaultMessage: 'Phone Number',
    id: 'labels.phone'
  },
  privacyPolicy: {
    defaultMessage: 'privacy policy',
    id: 'authentication.privacyPolicyLink'
  },
  privacyPolicyConsent: {
    defaultMessage: 'I hereby consent to the processing of my personal data and have read the {privacyPolicy} *.',
    id: 'authentication.requiredPrivacyPolicyConsent'
  },
  privacyPolicyStatement: {
    defaultMessage: 'Check our {privacyPolicy} for more info on how we collect data and process information.',
    id: 'authentication.privacyPolicyStatement'
  },
  recoverPassword: {
    defaultMessage: 'Recover your Ace & Tate account password',
    id: 'authentication.recoverPassword'
  },
  recoverPasswordSuccessMessage: {
    defaultMessage:
      'Email sent! If you have an account with us, you will have received an email to reset your password.',
    id: 'authentication.recoverPasswordSuccessMessage'
  },
  recoverTitle: {
    defaultMessage: 'Recover',
    id: 'authentication.recoverTitle'
  },
  registerNewAccount: {
    defaultMessage: 'Register with a new account',
    id: 'authentication.registerNewAccount'
  },
  registerSuccessNotificationWelcome: {
    defaultMessage: 'Welcome!',
    id: 'authentication.registerSuccessNotificationWelcome'
  },
  registerTitle: {
    defaultMessage: 'Register',
    id: 'authentication.registerTitle'
  },
  resetPassword: {
    defaultMessage: 'Reset password',
    id: 'authentication.resetPassword'
  },
  resetPasswordErrorMessage: {
    defaultMessage: "Sorry, we don't know anybody with that email address.",
    id: 'authentication.resetPasswordErrorMessage'
  },
  resetPasswordSuccessMessage: {
    defaultMessage: 'Password reset successfully',
    id: 'authentication.resetPasswordSuccessMessage'
  },
  resetYourPasswordTitle: {
    defaultMessage: 'Reset your password',
    id: 'authentication.resetPasswordTitle'
  },
  savePassword: {
    defaultMessage: 'Save password',
    id: 'authentication.savePassword'
  },
  sessionExpiredDescription: {
    defaultMessage:
      'Your session has expired. This can happen if you have been inactive for a while or recently changed the password to your account. Please log-in again to continue.',
    id: 'userProfile.profile.sessionExpiredDescription'
  },
  sessionExpiredTitle: {
    defaultMessage: 'Session Expired',
    id: 'userProfile.profile.sessionExpiredTitle'
  },
  signUp: {
    defaultMessage: 'Sign up',
    id: 'authentication.signUp'
  },
  tellUsAboutYourself: {
    defaultMessage: 'Tell Us About Yourself',
    id: 'authentication.tellUsAboutYourself'
  },
  termsAndConditions: {
    defaultMessage: 'terms & conditions',
    id: 'authentication.termsAndConditionsLabel'
  },
  useDifferentEmail: {
    defaultMessage: 'Use a different email address',
    id: 'authentication.useDifferentEmail'
  },
  welcomeBack: {
    defaultMessage: "Welcome Back! You're logged in with the following details:",
    id: 'authentication.welcomeBack'
  },
  welcomeBackNewUser: {
    defaultMessage: "You're creating a new account with the following details:",
    id: 'authentication.welcomeBackNewUser'
  },
  welcomeBackWithEasyLogin: {
    defaultMessage: 'Welcome back {email}! You can login with your password or we can send you a login link.',
    id: 'authentication.welcomBackWithEasyLogin'
  },
  welcomeYoureLogginIn: {
    defaultMessage: "Welcome! You're logged in with the following details:",
    id: 'authentication.welcomeYoureLogginIn'
  },
  whatIsYourEmail: {
    defaultMessage: 'What is your email address?',
    id: 'authentication.whatIsYourEmail'
  },
  yourNewPassword: {
    defaultMessage: 'Your new password',
    id: 'authentication.yourNewPassword'
  }
});
