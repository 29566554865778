import { Typography } from '@aceandtate/ds';
import { usePath } from 'paths';
import React from 'react';
import { footerEvents } from 'tracking';
import { SubLink } from '../../types';
import * as styles from './styles';
import Popover from 'components/Popover/Popover';

interface Props {
  id: string;
  text: string;
  href: string;
  subLinks?: SubLink[];
}

export default function FooterPopover({ text, subLinks }: Props) {
  const pathTo = usePath();

  return (
    <styles.FooterLinkWrapper>
      <Popover>
        <Popover.Trigger>
          <Typography variant='h4'>{text}</Typography>
        </Popover.Trigger>
        {subLinks && (
          <Popover.Content>
            <styles.FooterLinkPopover>
              {subLinks.map((subLink, index) => {
                const { href, text, urlRoute } = subLink;
                const linkHref = urlRoute?.path ? urlRoute.path : href;
                return (
                  <Popover.Item key={index}>
                    <a
                      href={pathTo(linkHref)}
                      aria-label={text}
                      onClick={() => {
                        footerEvents.footerInteraction(linkHref.replace(/^\/+/, ''));
                      }}
                    >
                      <Typography>{text}</Typography>
                    </a>
                  </Popover.Item>
                );
              })}
            </styles.FooterLinkPopover>
          </Popover.Content>
        )}
      </Popover>
    </styles.FooterLinkWrapper>
  );
}
