import React from 'react';
import * as Styles from './styles';

type Props = {
  counter: number;
  selected?: boolean;
  onClick?: () => void;
}

export default function QuantitySelector(props: Props) {
  const { selected, onClick, counter } = props;
  return (
    <Styles.SelectorContainer>
      <Styles.Remove
        selected={selected}
        onClick={onClick}
      />
      <Styles.Counter>{counter}</Styles.Counter>
      <Styles.Add
        selected={selected}
        onClick={onClick}
      />
    </Styles.SelectorContainer>
  );
}
