import styled from 'styled-components';
import Media from 'components/Media';
import { breakpointRules, grid, brandColors } from '@aceandtate/ds';

export const MediaCard = styled(Media)``;

export const TextContainer = styled.div`
  @media ${breakpointRules.laptop} {
    padding-top: ${grid[12]};
  }

  span {
    color: ${brandColors.dark50};
    margin-bottom: 0 !important;
    line-height: 14px !important;

    &:first-child {
      color: ${brandColors.dark};
    }
  }
`;
