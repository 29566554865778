const frameLimits = {
  'lemar-mochi': [-3, 3],
  'lemar-sunset': [-3, 3],
  'lemar-tigerwood': [-3, 3],
  'lemar-tigerwood-sun': [-3, 3],
  'celeste-jupiter': [-3, 3],
  'celeste-cherry': [-3, 3],
  'celeste-tigerwood': [-3, 3]
};

export default frameLimits;
