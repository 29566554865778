import styled, { keyframes } from 'styled-components';
import { brandColors, grid, breakpointRules } from '@aceandtate/ds';
import { navHeightDesktop } from 'styles';

type MobileLayoutProps = {
  isFirstMobileSubtitle?: boolean;
};

export const MobileLayout = styled.div<MobileLayoutProps>`
  display: flex;
  margin-top: ${({ isFirstMobileSubtitle }) => (isFirstMobileSubtitle ? 30 : 0)}px;

  @media ${breakpointRules.laptop} {
    display: none;
  }
`;

export const DesktopLayout = styled.div`
  display: none;

  @media ${breakpointRules.laptop} {
    display: flex;
  }
`;

const fadeIn = keyframes`
  0% {
    opacity: 0;
    transform: translateY(50px);
  }

  100% {
    opacity: 1;
    transform: translateY(0px);
  }
`;

type SubmenuContainerProps = {
  isMobileSubmenuOpen?: boolean;
  top?: number;
};

export const SubmenuContainer = styled.div<SubmenuContainerProps>`
  animation: ${fadeIn} ease-in-out 400ms 1;
  display: ${props => (props.isMobileSubmenuOpen ? 'flex' : 'none')};
  flex-flow: column;
  justify-content: space-evenly;

  @media ${breakpointRules.laptop} {
    border-top: 2px solid ${brandColors.dark10};
    animation: none;
    background-color: ${brandColors.light};
    bottom: auto;
    display: flex;
    flex-flow: row;
    justify-content: space-evenly;
    left: 0;
    margin-top: ${navHeightDesktop}px;
    max-height: 100vh;
    overflow-x: hidden;
    overflow-y: scroll;
    padding: 0 ${grid[60]};
    position: fixed;
    right: 0;
    top: ${props => (props.top ? `${props.top}px` : '0')};
  }
`;
