import { Typography } from '@aceandtate/ds';
import { usePath } from 'paths';
import React from 'react';
import { footerEvents } from 'tracking';
import * as styles from './styles';

interface Props {
  id: string;
  text: string;
  href: string;
}

export default function FooterLink({ text, href }: Props) {
  const pathTo = usePath();
  const displayLink = (): JSX.Element => {
    return (
      <>
        <a href={pathTo(href)} aria-label={text} color='inherit'>
          <Typography
            variant='h4'
            color='inherit'
            onClick={() => {
              footerEvents.footerInteraction(href.replace(/^\/+/, ''));
            }}
          >
            {text}
          </Typography>
        </a>
      </>
    );
  };

  return <styles.FooterLinkWrapper>{displayLink()}</styles.FooterLinkWrapper>;
}
