import React, { CSSProperties } from 'react';
import styled from 'styled-components';
import { grid, brandColors, borderRadius } from '@aceandtate/ds';

type StyleProps = Partial<{
  color: CSSProperties['color'];
  backgroundColor: CSSProperties['backgroundColor'];
  padding: CSSProperties['padding'];
}>;

const Label = styled.div<{ $styleProps: StyleProps }>`
  padding: ${({ $styleProps: { padding } }) => padding || `2px ${grid[8]}`};
  background-color: ${({ $styleProps: { backgroundColor } }) => backgroundColor || brandColors.light};
  color: ${({ $styleProps: { color } }) => (color ? brandColors.light : color || brandColors.dark)};
  border: 1px solid ${({ $styleProps: { backgroundColor } }) => (backgroundColor ? 'transparent' : brandColors.dark75)};
  border-radius: ${borderRadius.small};
  width: fit-content;
`;

type Props = {
  children: React.ReactNode;
} & Partial<StyleProps>;

export default function ({ children, color, backgroundColor }: Props) {
  return <Label $styleProps={{ backgroundColor, color }}>{children}</Label>;
}
