import React from 'react';
import { FormattedMessage } from 'react-intl';
import messages from './messages';
import {
  FlattenedSurcharges,
  FrameExtraConfiguration,
  FrameLensConfiguration,
  FramePrescriptionConfiguration
} from './types';
import { Surcharges, NestedSurcharge, WebStore } from 'types/webStore';
import { ProductVariant } from 'types/torii';
import { Flex, Typography, brandColors, fontSizes, grid } from '@aceandtate/ds';
import Label from 'components/Label';

export enum LENS_SURCHARGE_TO_CUSTOM_MAP {
  premium = 'premium_basic_lenses',
  sun_premium = 'sunny_pro_lenses',
  supreme = 'premium_plus_lenses'
}

export enum LENS_CUSTOM_TO_SURCHARGE_MAP {
  premium_basic_lenses = 'premium',
  sunny_pro_lenses = 'sun_premium',
  premium_plus_lenses = 'supreme'
}

const createBaseConfigurations = (isSunny: boolean, webStore: WebStore) => ({
  extras: [
    {
      id: 'no_lens_extras',
      surcharge: 0,
      title: <FormattedMessage {...messages.noLensExtras} />
    },
    {
      id: 'lens_color_blf',
      surcharge: 0,
      surchargeId: 'blue_light',
      description: <FormattedMessage {...messages.blueLightFilterDescription} />,
      title: <FormattedMessage {...messages.blueLightFilterTitle} />
    },
    {
      id: 'lens_color_photochromic',
      surcharge: 0,
      surchargeId: 'photochromic',
      description: <FormattedMessage {...messages.photochromicDescription} />,
      title: <FormattedMessage {...messages.photochromicTitle} />
    },
    {
      id: 'polarised_lenses',
      surcharge: 0,
      surchargeId: 'polarised',
      description: <FormattedMessage {...messages.polarisedLensesDescription} />,
      title: <FormattedMessage {...messages.polarisedLensesTitle} />
    }
  ],
  lenses: [
    {
      id: 'essential_lenses',
      surcharge: 0,
      description: <FormattedMessage {...messages.essentialLensesDescription} />,
      title: <FormattedMessage {...messages.essentialLensesTitle} />
    },
    {
      id: 'sunny_essential_lenses',
      surcharge: 0,
      description: <FormattedMessage {...messages.sunnyEssentialLensesDescription} />,
      title: <FormattedMessage {...messages.sunnyEssentialLensesTitle} />
    },
    {
      id: 'premium_lenses',
      surcharge: 0,
      surchargeId: 'premium_lenses',
      description: <FormattedMessage {...messages.premiumLensesDescription} />,
      title: <FormattedMessage {...messages.configuratorPremiumLensesTitle} />
    },
    {
      id: 'premium_basic_lenses',
      surcharge: 0,
      surchargeId: 'premium',
      description: <FormattedMessage {...messages.premiumBasicLensesDescription} />,
      title: <FormattedMessage {...messages.premiumBasicLensesTitle} />
    },
    {
      id: 'premium_plus_lenses',
      surcharge: 0,
      surchargeId: 'supreme',
      description: <FormattedMessage {...messages.premiumPlusLensesDescription} />,
      title: <FormattedMessage {...messages.premiumPlusLensesTitle} />
    },
    {
      id: 'sunny_pro_lenses',
      surcharge: 0,
      surchargeId: 'sun_premium',
      description: <FormattedMessage {...messages.sunnyProLensesDescription} />,
      title: <FormattedMessage {...messages.sunnyProLensesTitle} />
    }
  ],
  prescriptions: [
    {
      id: 'single_vision',
      surcharge: 0,
      excludeSunny: false,
      lensCategory: <FormattedMessage {...messages.withPrescription} />,
      description: <FormattedMessage {...messages.singleVisionDescription} />,
      title: isSunny ? (
        <FormattedMessage {...messages.singleVisionSunnyTitle} />
      ) : (
        <FormattedMessage {...messages.singleVisionTitle} />
      )
    },
    {
      id: 'relax_5_lenses',
      appendsToId: 'single_vision',
      surcharge: 0,
      excludeSunny: true,
      lensCategory: <FormattedMessage {...messages.withPrescription} />,
      description: <FormattedMessage {...messages.relaxLensesDescription} />,
      title: (
        <Flex gap={grid[12]} alignItems='center' style={{ flexWrap: 'wrap', rowGap: grid[4] }}>
          <FormattedMessage {...messages.relaxLensesTitle} />
          <Label backgroundColor={brandColors.accent10}>
            <Typography
              variant='bodyM'
              fontWeight='bold'
              style={{ lineHeight: `${fontSizes.sans.input.mobile.lineHeight}px` }}
            >
              <FormattedMessage {...messages.singleVisionLabel} />
            </Typography>
          </Label>
        </Flex>
      )
    },
    {
      id: 'multifocal',
      surcharge: 0,
      surchargeId: 'multifocal',
      excludeSunny: false,
      lensCategory: <FormattedMessage {...messages.withPrescription} />,
      description:
        webStore.country.iso === 'DE' ? (
          <>
            <Typography gutterBottom={12}>
              <FormattedMessage {...messages.multiFocalDescription} />
            </Typography>
            <Typography style={{ textDecoration: 'underline', cursor: 'pointer' }}>
              <a href='#multifocal-warning' onClick={e => e.stopPropagation()}>
                <FormattedMessage {...messages.multifocalWarningCta} />
              </a>
            </Typography>
          </>
        ) : (
          <FormattedMessage {...messages.multiFocalDescription} />
        ),
      title: isSunny ? (
        <FormattedMessage {...messages.multifocalSunnyTitle} />
      ) : (
        <FormattedMessage {...messages.multifocalTitle} />
      )
    },
    {
      id: 'bluelight-filter',
      appendsToId: 'plano',
      surcharge: 0,
      excludeSunny: true,
      lensCategory: <FormattedMessage {...messages.withoutPrescription} />,
      description: <FormattedMessage {...messages.screenGlassesDescription} />,
      title: <FormattedMessage {...messages.screenGlassesTitle} />
    },
    {
      id: 'plano',
      surcharge: 0,
      excludeSunny: false,
      lensCategory: <FormattedMessage {...messages.withoutPrescription} />,
      description: isSunny ? (
        <FormattedMessage {...messages.nonPrescriptionSunnyDescription} />
      ) : (
        <FormattedMessage {...messages.nonPrescriptionDescription} />
      ),
      title: isSunny ? (
        <FormattedMessage {...messages.nonPrescriptionSunnyTitle} />
      ) : (
        <FormattedMessage {...messages.nonPrescriptionTitle} />
      )
    }
  ]
});

const updateSurcharges = (surcharges: Record<string, number>, [key, value]: [string, number]) => {
  return {
    ...surcharges,
    [key]: value
  };
};

const flattenSurcharges = (surcharges: Surcharges) => {
  return Object.entries(surcharges).reduce((flatSurcharges, [key, value]) => {
    if (value instanceof Array) {
      return value.reduce((flatSurcharges, { key, surcharge }: NestedSurcharge<string>) => {
        return updateSurcharges(flatSurcharges, [key, surcharge]);
      }, flatSurcharges);
    } else {
      return updateSurcharges(flatSurcharges, [key, value]);
    }
  }, {}) as FlattenedSurcharges;
};

function applySurcharges(arr, surcharges: Surcharges) {
  const flatSurcharges = flattenSurcharges(surcharges);

  return arr.map(x => {
    return {
      ...x,
      surcharge: flatSurcharges[x.surchargeId] ? x.surcharge + flatSurcharges[x.surchargeId] : x.surcharge
    };
  });
}

// TODO: confirm that this is not longer needed, as those exceptions are handled in checkout and index_174 is deprecated
function applyDescriptions(lenseConfig, currentVariant) {
  return lenseConfig.map(config => {
    if (config.id === 'index_174') {
      const productAlwaysRequires174 = currentVariant.sku?.includes('sofia') || currentVariant.sku.includes('jimi');
      const isMetal = currentVariant.properties?.frameMaterials?.includes('metal');
      const description =
        isMetal && !productAlwaysRequires174 ? (
          <FormattedMessage {...messages.ultraThinLensesMetalFrame} />
        ) : (
          <FormattedMessage {...messages.ultraThinLensesAcetateFrame} />
        );

      return {
        ...config,
        description
      };
    }
    return config;
  });
}

export type FrameConfigurations = {
  extras: FrameExtraConfiguration[];
  lenses: FrameLensConfiguration[];
  prescriptions: FramePrescriptionConfiguration[];
};

export default function getFrameConfigurations(
  currentVariant: ProductVariant,
  webStore: WebStore
): FrameConfigurations {
  const { surcharges, config } = webStore;
  const { hasMultifocalDisabled } = config;

  if (!currentVariant) {
    return {
      extras: [],
      lenses: [],
      prescriptions: []
    };
  }
  const isOptical = currentVariant.filterOptions.types.includes('optical');
  const isSunny = currentVariant.filterOptions.types.includes('sunny');

  const configurations = createBaseConfigurations(isSunny, webStore);

  // removing invalid prescriptions for a variant (e.g. they are not supported by a specific frame)
  // allowing appendsTo field to work as substitute for custom UI only ids
  let prescriptions = configurations.prescriptions.filter(
    x =>
      currentVariant.filterOptions.prescriptionOptions &&
      (currentVariant.filterOptions.prescriptionOptions.includes(x.id) ||
        currentVariant.filterOptions.prescriptionOptions.includes(x.appendsToId))
  );

  // RELAX LENSES FEATURE FLAG
  prescriptions =
    process.env.NEXT_PUBLIC_ENABLE_RELAX_LENSES === 'true'
      ? prescriptions
      : prescriptions.filter(prescription => prescription.id !== 'relax_5_lenses');

  let { extras } = configurations;

  const { lenses } = configurations;

  // removing bluelight filter option for sunglasses
  // removing prescription types that do not allow sunglasses
  // adding surcharges for prescription sunnys
  if (isSunny) {
    prescriptions = prescriptions.filter(x => !x.excludeSunny).sort(a => (a.id === 'plano' ? -1 : 1));
    prescriptions = prescriptions.map(x => {
      if (x.id === 'single_vision' || x.id === 'multifocal') {
        return {
          ...x,
          surcharge: x.surcharge + surcharges.prescription_sunny
        };
      }
      return x;
    });

    extras = extras.filter(x => x.id !== 'UV420');
  }

  if (isOptical) {
    prescriptions = prescriptions.map(x => {
      if (x.id === 'relax_5_lenses') {
        return { ...x, surcharge: x.surcharge + surcharges.relax_5_lenses };
      }

      return x;
    });
  }

  if (hasMultifocalDisabled) {
    prescriptions = prescriptions.filter(x => x.id !== 'multifocal');
  }

  const lensesWithSurcharges = applySurcharges(lenses, surcharges);
  const lensesWithDescriptions = applyDescriptions(lensesWithSurcharges, currentVariant);

  return {
    extras: applySurcharges(extras, surcharges),
    lenses: lensesWithDescriptions,
    prescriptions: applySurcharges(prescriptions, surcharges)
  };
}
