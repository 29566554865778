import React, { ReactElement, useState } from 'react';
import { Button } from '@aceandtate/ds';
interface Props {
  children: React.ReactNode;
  buttonColor?: React.ComponentProps<typeof Button>['color'];
  buttonVariant?: React.ComponentProps<typeof Button>['variant'];
}

export default function CookiePreferencesButton({
  children,
  buttonColor,
  buttonVariant,
  ...props
}: Props): ReactElement {
  const [isLoading, setIsLoading] = useState(false);

  async function handleOpenConsentManager() {
    setIsLoading(true);
    location.hash = 'transcend-ui';
    setIsLoading(false);
  }

  return (
    <Button
      color={buttonColor}
      variant={buttonVariant}
      size='small'
      {...props}
      onClick={handleOpenConsentManager}
      loading={isLoading}
    >
      {children || 'Cookie Settings'}
    </Button>
  );
}
