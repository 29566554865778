import { defineMessages } from 'react-intl';

const messages = defineMessages({
  countryLabel: {
    defaultMessage: 'Country',
    id: 'intl.modal.labels.country'
  },
  languageLabel: {
    defaultMessage: 'Language',
    id: 'intl.modal.labels.language'
  },
  title: {
    defaultMessage: 'Please select your location and language',
    id: 'intl.modal.title'
  },
  titleOnlyCountry: {
    defaultMessage: 'Please select your location',
    id: 'intl.modal.title.onlyCountrySelect'
  }
});

export default messages;
