import { defineMessages } from 'react-intl';

const prescriptions = defineMessages({
  multiFocalDescription: {
    defaultMessage:
      "Near, intermediate and distance vision correction in one lens. So you don't have to switch between glasses.",
    id: 'configurator.multiFocalDescription'
  },
  multifocalSunnyTitle: {
    defaultMessage: 'Multifocal sunglasses',
    id: 'configurator.multifocalSunnyTitle'
  },
  multifocalTitle: {
    defaultMessage: 'Multifocal lenses',
    id: 'configurator.multifocalTitle'
  },
  multifocalWarningCta: {
    defaultMessage: 'Please read our notice regarding multifocal lenses',
    id: 'configurator.multifocalWarningCta'
  },
  nonPrescriptionDescription: {
    defaultMessage:
      'Glasses without prescription or Blue Light Filter. Style and 100% protection from UV-A and UV-B rays guaranteed.',
    id: 'configurator.nonPrescriptionDescription'
  },
  nonPrescriptionSunnyDescription: {
    defaultMessage:
      'Sunnies without prescription — for 100% protection from UV-A and UV-B rays. Just to make style a sure thing.',
    id: 'configurator.nonPrescriptionSunnyDescription'
  },
  nonPrescriptionSunnyTitle: {
    defaultMessage: 'Non-prescription sunglasses',
    id: 'configurator.nonPrescriptionSunnyTitle'
  },
  nonPrescriptionTitle: {
    defaultMessage: 'Non-prescription',
    id: 'configurator.nonPrescriptionTitle'
  },
  relaxLensesDescription: {
    defaultMessage:
      'Feature a small boost of power designed to relieve eye strain by helping the eyes transition from far to near. Improves visual stability and reduces fatigue symptoms caused by prolonged device use.',
    id: 'configurator.relaxLensesDescription'
  },
  relaxLensesTitle: {
    defaultMessage: 'Relax glasses',
    id: 'configurator.relaxLensesTitle'
  },
  screenGlassesDescription: {
    defaultMessage:
      'Glasses with our Blue Light Filter — which reduces eye strain by blocking high-energy blue light from digital screens.',
    id: 'configurator.screenGlassesDescription'
  },
  screenGlassesTitle: {
    defaultMessage: 'Screen lenses',
    id: 'configurator.screenGlassesTitle'
  },
  singleVisionDescription: {
    defaultMessage: 'Perfect if you need either near or distance vision correction.',
    id: 'configurator.singleVisionDescription'
  },
  singleVisionLabel: {
    defaultMessage: 'single vision',
    id: 'configurator.singleVisionLabel'
  },
  singleVisionSunnyTitle: {
    defaultMessage: 'Single vision sunglasses',
    id: 'configurator.singleVisionSunnyTitle'
  },
  singleVisionTitle: {
    defaultMessage: 'Single vision lenses',
    id: 'configurator.singleVisionTitle'
  },
  withPrescription: {
    defaultMessage: 'With prescription',
    id: 'configurator.withPrescription'
  },
  withoutPrescription: {
    defaultMessage: 'Without prescription',
    id: 'configurator.withoutPrescription'
  }
});

const lensesExtras = defineMessages({
  noLensExtras: {
    defaultMessage: 'No need for an upgrade, thanks!',
    id: 'configurator.noLensExtras'
  },
  noAccessories: {
    defaultMessage: 'No need for accessories, thanks!',
    id: 'configurator.noAccessories'
  },
  blueLightFilterDescription: {
    defaultMessage: 'Reduces eye strain by blocking high-energy blue light from digital screens.',
    id: 'configurator.blueLightFilterDescription'
  },
  blueLightFilterTitle: {
    defaultMessage: 'Blue light filter',
    id: 'configurator.blueLightTitle'
  },
  photochromicDescription: {
    defaultMessage:
      'Style + Adaptive Light Protection. Automatically adjust to varying light for your comfort transitioning to light colour grey',
    id: 'configurator.photochromicDescription'
  },
  photochromicTitle: {
    defaultMessage: 'Light Responsive Lenses',
    id: 'configurator.photochromicTitle'
  },
  polarisedLensesDescription: {
    defaultMessage:
      'Get rid of glare with our Polarised Lenses. They neutralise harsh sunlight — the kind that makes seeing clearly hard on a sunny day. That means reduced eye strain, more vibrant colours and a better view.',
    id: 'configurator.polarisedLensesDescription'
  },
  polarisedLensesTitle: {
    defaultMessage: 'Polarised lenses',
    id: 'configurator.polarisedLensesTitle'
  }
});

const lenses = defineMessages({
  /** @deprecated */
  configuratorPremiumLensesTitle: {
    defaultMessage: 'Premium Lenses',
    id: 'productConfigurator.premiumLensesTitle'
  },
  essentialLensesDescription: {
    defaultMessage:
      'Our standard high-quality lens with basic anti-scratch and anti-reflection coatings. Repels water and is impact resistant. Good for prescriptions up to +/- 4.00.',
    id: 'configurator.essentialLensesDescription'
  },
  essentialLensesTitle: {
    defaultMessage: 'Essential',
    id: 'configurator.essentialLensesTitle'
  },
  premiumBasicLensesDescription: {
    defaultMessage:
      'Offers improved durability and effortless care with upgraded coatings. Premium repels dust and dirt in addition to water and has a more refined look and finish. A great choice for all prescriptions.',
    id: 'configurator.premiumBasicLensesDescription'
  },
  premiumBasicLensesTitle: {
    defaultMessage: 'Premium',
    id: 'configurator.premiumBasicLensesTitle'
  },
  /** @deprecated */
  premiumLensesDescription: {
    defaultMessage:
      'Ultra-thin lenses with a superior durability, anti-dirt coating and defog layer. Great for all prescriptions, required above +/- 6.25 dioptres.',
    id: 'productConfigurator.premiumLensesDescription'
  },
  /** @deprecated */
  premiumLensesTitle: {
    defaultMessage: 'Premium Lenses',
    id: 'productConfigurator.premiumLensesTitle'
  },
  premiumPlusLensesDescription: {
    defaultMessage:
      "Our thinnest and most durable lens — 50% more scratch-resistant than Premium. Supreme offers an upgraded coating that's anti-static, which repels even the finest dust particles for an ultra-clean look. Required for prescriptions above +/- 6.00.",
    id: 'configurator.premiumPlusLensesDescription'
  },
  premiumPlusLensesTitle: {
    defaultMessage: 'Supreme',
    id: 'configurator.premiumPlusLensesTitle'
  },
  sunnyEssentialLensesDescription: {
    defaultMessage:
      'Our high-quality, standard lens offers 100% protection from UV-A and UV-B rays. It comes with basic anti-scratch, anti-reflection and water-repellent coatings.',
    id: 'configurator.sunnyEssentialLensesDescription'
  },
  sunnyEssentialLensesTitle: {
    defaultMessage: 'Sun Essential',
    id: 'configurator.sunnyEssentialLensesTitle'
  },
  sunnyProLensesDescription: {
    defaultMessage:
      'Sun Premium has upgraded front and back surface lens coatings, for ultimate durability and visual performance. Repels water, grease and dirt and gives a refined look with almost no reflection. Great for all prescriptions, required for prescriptions equal or above -/+ 6.00.',
    id: 'configurator.sunnyProLensesDescription'
  },
  sunnyProLensesTitle: {
    defaultMessage: 'Sun Premium',
    id: 'configurator.sunnyProLensesTitle'
  },
  ultraThinLensesAcetateFrame: {
    defaultMessage:
      'Our thinnest lens, recommended for prescriptions above +/- 5. For prescriptions above +/- 7.5 these lenses are required.',
    id: 'productConfigurator.ultraThinLensesRequiredAcetate'
  },
  ultraThinLensesMetalFrame: {
    defaultMessage:
      'Our thinnest lens, recommended for prescriptions above +/- 4. For prescriptions above +/- 6 these lenses are required.',
    id: 'productConfigurator.ultraThinLensesRequiredMetal'
  }
});

export default { ...prescriptions, ...lenses, ...lensesExtras };
