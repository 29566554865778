import React, { useState, useEffect, useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import { Index, Configure, connectStateResults } from 'react-instantsearch-dom';
import { Typography } from '@aceandtate/ds';

import { ServicesContext } from 'services/context';
import { trackArtificialPageView } from 'tracking/helpers';
import { searchEvents } from 'tracking';

import ContentfulHits from '../ContentfulHits';
import VariantHits from '../VariantHits';

import useDebounce from 'utils/hooks/useDebounce';
import * as Styles from './styles';
import messages from '../../messages';
import * as Types from '../../types';

type Props = {
  allSearchResults: Types.AllSearchResultsProps;
  asPage: boolean;
  searchState: {
    query: string;
  };
  closeHandler?: () => void;
};

const Results = (props: Props) => {
  const { asPage, searchState, allSearchResults, closeHandler } = props;

  const ctx = useContext(ServicesContext);
  const language = ctx.locale.lang;
  const genericHitsPerPage = asPage ? 40 : 15;

  const [currentHitsCount, setCurrentHitsCount] = useState(0);
  const debouncedSearchQuery = useDebounce(searchState.query, 1000);
  const noSearchResult = searchState.query && currentHitsCount === 0;

  useEffect(
    function onSearchChange() {
      if (!allSearchResults) return;

      let hitsCount: number;
      // Identify multi-index search: variants results & content results
      if (allSearchResults.production_frontend_search) {
        const indices = Object.values(allSearchResults);
        hitsCount = indices.map((index: Types.IndexResultsProps) => index.nbHits).reduce((a, b) => a + b, 0);

        setCurrentHitsCount(hitsCount);
      } else {
        hitsCount = allSearchResults.nbHits;
        setCurrentHitsCount(hitsCount);
      }
    },
    [searchState.query, allSearchResults?.production_frontend_search]
  );

  useEffect(
    function fireSearchEvents() {
      if (debouncedSearchQuery) {
        if (currentHitsCount === 0) {
          searchEvents.searchError({ query: debouncedSearchQuery });
        } else {
          searchEvents.siteSearch({ currentHitsCount, query: debouncedSearchQuery });
        }
        trackArtificialPageView(`search:${debouncedSearchQuery}`);
      }
    },
    [debouncedSearchQuery]
  );

  if (asPage) {
    // currently we don't have a search page in iris so we redirect the bananas one
  }

  return (
    <Styles.ResultsWrapper>
      <VariantHits
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        hasQuery={!!searchState.query}
        closeHandler={closeHandler}
      />
      <Index indexName='production_frontend_search'>
        <Configure hitsPerPage={genericHitsPerPage} facetFilters={[`language:${language}`]} />
        <ContentfulHits
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          asPage={asPage}
          hasQuery={!!searchState.query}
          closeHandler={closeHandler}
        />
      </Index>
      {noSearchResult && (
        <Typography fontWeight='regular'>
          <FormattedMessage {...messages['search.noResultForQuery']} values={{ query: searchState.query }} />
        </Typography>
      )}
    </Styles.ResultsWrapper>
  );
};

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export default connectStateResults(Results);
