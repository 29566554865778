import { createGlobalStyle } from 'styled-components';
import styledNormalize from 'styled-normalize';
import { gridBase } from 'styles';
import { fontSizes, zIndexMasterList } from './';
import { breakpointRules } from './sizes';
/*
 * Legal Disclaimer
 *
 * These Fonts are licensed only for use on these domains and their subdomains:
 * aceandtate.com
 * aceandtate.nl
 * aceandtate.de
 *
 * It is illegal to download or use them on other websites.
 *
 * While the @font-face statements below may be modified by the client, this
 * disclaimer may not be removed.
 *
 * Lineto.com, 2014
 */
const GlobalStyle = createGlobalStyle`
  :root {
    --grid-xxs: ${gridBase.desktop.xxs}px;
    --grid-xs: ${gridBase.desktop.xs}px;
    --grid-s: ${gridBase.desktop.s}px;
    --grid-m: ${gridBase.desktop.m}px;
    --grid-l: ${gridBase.desktop.l}px;
    --grid-xl: ${gridBase.desktop.xl}px;
    --grid-xxl: ${gridBase.desktop.xxl}px;
  }

   @media ${breakpointRules.smallMax} {                             
    :root {
      --grid-xl: ${gridBase.mobile.xl}px;
      --grid-xxl: ${gridBase.mobile.xxl}px;
    }
  }

  @media ${breakpointRules.tinyMax} {
    :root {
      --grid-xl: ${gridBase.tiny.xl}px;
      --grid-xxl: ${gridBase.tiny.xxl}px;
      --grid-l: ${gridBase.tiny.l}px;
      --grid-m: ${gridBase.tiny.m}px;
    }
  }


  ${styledNormalize}

  html {
    font-size: ${fontSizes.medium.fontSize}px;
    line-height: ${fontSizes.medium.lineHeight}px;
  }

  body {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    overflow-x: hidden;
  }

  body.ReactModal__Body--open,
  body.no-scroll  {
    overflow: hidden;
  }

  .ReactModal__Overlay {
    background-color: rgba(15,45,140,0.20);
  }

  .activeNav {
    opacity: .3;
  }

  .NewsletterModal {
    .ReactModal__Overlay {
      opacity: 0 !important;
      transition: all 1000ms ease;

      &--after-open {
          opacity: 1 !important;
      }

      &--before-close {
          opacity: 0 !important;
      }
    }

    .ReactModal__Content{
      display: block;
      transition: all 1000ms ease;

      &--after-open {
      }

      &--before-close {
      }
    }
  }

  .ReactModalFaq {
    .ReactModal__Overlay {
      opacity: 0 !important;
      transition: all 300ms ease;

      &--after-open {
          opacity: 1 !important;
      }

      &--before-close {
          opacity: 0 !important;
      }
    }

    .ReactModal__Content{
      top: 50% !important;
      transition: all 300ms ease;
      background: transparent !important;

      &--after-open {
        top: 10% !important;
      }

      &--before-close {
        top: 50% !important;
      }
    }
  }

  html {
    box-sizing: border-box;
  }

  *,
  *::before,
  *::after {
    box-sizing: inherit;
  }

  button, [role="button"] {
    cursor: pointer;
  }

  button {
    color: inherit;
    background-color: transparent;
    outline: none;
    border: none;
    :focus-visible{
      outline: -webkit-focus-ring-color auto 1px;
    } 
  }

  button,
  input,
  optgroup,
  select,
  textarea {
    font-family: 'AmperSans', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  }

  a {
    text-decoration: none;
    color: inherit;
    cursor: pointer;

    :hover {
      text-decoration: none;
    }
  }

  #kustomer-ui-sdk-iframe {
    z-index: ${zIndexMasterList.chatWidget} !important;
  }

  #_hj_feedback_container div[id$="feedback"][data-state=minimized] {
    z-index: ${zIndexMasterList.hotJarFeedback};
  }

  #_hj_feedback_container div[id$="feedback"][data-state=emotion] {
    z-index: ${zIndexMasterList.chatWidget + 1};
  }

  #optanon.classic {
    #optanon-popup-bg, #optanon-popup-wrapper {
      display: none;
    }
  }

  fieldset{
    padding: 0;
    border: 0;
  }

  @media print {
    .noPrint,
    .noPrint img {
      display: none !important;
    }
  }
`;

export default GlobalStyle;
