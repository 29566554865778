export const zIndexMasterList = {
  backDrop: 52,

  cartDropdown: 61,

  chatWidget: 52,

  cookieConsent: 19999999,
  draftMode: 999999999,

  // just above the chat widget,
  feedbackWidget: 0,

  filterNav: 50,

  // PCP: Filter must be below the navbar & modals (when its open on mobile)
  filtersMobile: 48,

  filtersMobileActive: 50,

  fixedTop: 51,

  hotJarFeedback: 47,

  modal: 60,

  navbar: 51,

  navbarBackDrop: 49,

  navbarSecondNav: 55,

  notificationBar: 50,

  pcpNavigation: 49,

  pdpConfiguratorSubmitBar: 48,
  pdpFullscreenImageCarousel: 99999999,

  tooltip: 99999,
  videoPlayerButton: 20
};
