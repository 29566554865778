import React from 'react';
import { FormattedMessage } from 'react-intl';
import Link from 'next/link';
import { usePath } from 'paths';
import { footerEvents } from 'tracking';
import { links } from './infoLinks';
import * as Styles from '../styles';
import * as Types from '../types';

export default function FooterInfoLinks(): JSX.Element {
  const pathTo = usePath();

  return (
    <Styles.FooterInfoLinksWrapper>
      {links.map((link: Types.FooterLinkProps) => (
        <Link
          rel={link.noFollow ? 'nofollow' : undefined}
          aria-label={link.message.defaultMessage}
          key={link.href}
          href={pathTo(link.href)}
          onClick={() => {
            footerEvents.footerInteraction(link.message.defaultMessage);
          }}
        >
          <FormattedMessage {...link.message} />
        </Link>
      ))}
    </Styles.FooterInfoLinksWrapper>
  );
}
