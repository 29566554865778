import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Typography, Button } from '@aceandtate/ds';
import messages from '../../messages';

type Props = {
  onClose: () => void;
};

export default function InvalidPrescriptionCombinationModal(props: Props) {
  const { onClose } = props;
  return (
    <>
      <Typography variant='h5' gutterBottom={24}>
        <FormattedMessage {...messages.selectGlasses} />
      </Typography>
      <Typography variant='bodyM' gutterBottom={24}>
        <FormattedMessage {...messages.invalidPrescriptionSelectedText} />
      </Typography>
      <Button data-testid='invalid-prescription-selected-cta' onClick={onClose} style={{ alignSelf: 'flex-end' }}>
        <FormattedMessage {...messages.invalidPrescriptionSelectedButton} />
      </Button>
    </>
  );
}
