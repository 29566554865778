import React, { useContext } from 'react';
import { useIntl } from 'react-intl';
import { Typography } from '@aceandtate/ds';
import Image from 'next/image';
import { ServicesContext } from 'services/context';
import { usePath } from 'paths';
import { transform } from 'utils/images';
import { getOptimizedZoomedInProductImageUrl } from 'utils/cloudinary';

import { searchEvents } from 'tracking';
import * as SearchStyles from '../../styles';
import messages from '../../messages';
import { NormalizedVariantHits } from 'components/Search/util';

type VariantResultProps = {
  hasQuery: boolean;
  hit: NormalizedVariantHits;
  closeHandler?: () => void;
};

const RenderDescription = (props: VariantResultProps) => {
  const intl = useIntl();
  const ctx = useContext(ServicesContext);
  const { currency } = ctx.webStore.config;
  const { hit } = props;

  if (hit.slug === 'giftcard') {
    const { hit } = props;

    const basePrice = hit.prices.find(price => price.currency === currency).price;

    const giftCardPrice = intl.formatNumber(basePrice, {
      currency,
      maximumFractionDigits: 0,
      minimumFractionDigits: 0,
      style: 'currency'
    });

    const messageId = hit.sku.replace(/-/g, '');

    return (
      <span>
        {messages[messageId] ? intl.formatMessage(messages[messageId], { price: giftCardPrice }) : giftCardPrice}
      </span>
    );
  }

  return hit.color;
};

export default function VariantResult(props: VariantResultProps) {
  const { hit, hasQuery, closeHandler } = props;
  const pathTo = usePath();

  if (!hit.sku) return null;

  const handleClick = () => {
    !hasQuery && searchEvents.searchDefault(hit.sku);
    closeHandler();
  };

  return (
    <SearchStyles.RowLink href={pathTo(`/${hit.sku}`)} onClick={() => handleClick()}>
      {hit.imageUrl && (
        <SearchStyles.ImageContainer>
          <Image
            src={getOptimizedZoomedInProductImageUrl({
              type: hit.frame_width.length > 0 || !!hit.color ? 'frame' : 'accessory',
              url: transform(hit.imageUrl, { width: 300 })
            })}
            height='110'
            width='110'
            alt={hit.sku}
            key={hit.imageUrl}
            title={hit.title}
            style={{
              maxWidth: '100%',
              height: 'auto'
            }}
          />
        </SearchStyles.ImageContainer>
      )}
      <SearchStyles.TextContainer>
        <Typography variant='h5' serif>
          {hit.name}
        </Typography>
        {hit.sku !== 'giftcard-variable-price' && <Typography variant='bodyM'>{RenderDescription(props)}</Typography>}
      </SearchStyles.TextContainer>
    </SearchStyles.RowLink>
  );
}
