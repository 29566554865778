import React, { ReactNode, useMemo } from 'react';
import { FormattedNumber } from 'react-intl';
import styled, { css } from 'styled-components';
import { brandColors } from '@aceandtate/ds';

type Props = {
  currency: string;
  'data-testid'?: string;
  maximumFractionDigits?: number;
  prefix?: string;
  /** remove string as soon as the order gives back numbers instead of strings. */
  price: number | string;
  whenZero?: string | ReactNode;
  /** Show styled as original price (red and crossed out) if a sale price exists */
  isBeforeSalePrice?: boolean;
  isSale?: boolean;
  isSpecialPrice?: boolean;
};

export default function DisplayPrice({
  isBeforeSalePrice = false,
  isSale = false,
  isSpecialPrice = false,
  price,
  currency = 'EUR',
  maximumFractionDigits = 2,
  whenZero = null,
  prefix = null,
  'data-testid': dataTestId = null
}: Props) {
  const hasFractionDigits = useMemo(() => {
    const [, decimals = []] = price.toString().split('.');
    return decimals.length > 0;
  }, [price]);

  if (price === 0 && whenZero) {
    return <span>{whenZero}</span>;
  }

  const priceInt = typeof price === 'string' ? parseFloat(price) : price;

  return (
    <StyledPriceLabel
      data-testid={dataTestId}
      isBeforeSale={isBeforeSalePrice}
      isSale={isSale}
      isSpecialPrice={isSpecialPrice}
    >
      {prefix && <>{prefix}&nbsp;</>}
      <span>
        <FormattedNumber
          value={priceInt}
          currency={currency}
          style='currency'
          maximumFractionDigits={maximumFractionDigits}
          minimumFractionDigits={hasFractionDigits ? 2 : 0}
        />
      </span>
    </StyledPriceLabel>
  );
}

const StyledPriceLabel = styled.span<{ isBeforeSale?: boolean; isSale?: boolean; isSpecialPrice: boolean }>`
  ${({ isBeforeSale }) =>
    isBeforeSale &&
    css`
      font-weight: 300;
      text-decoration: line-through;
    `}

  ${({ isSpecialPrice }) =>
    isSpecialPrice &&
    css`
      color: ${brandColors.accent};
    `}

  ${({ isSale }) =>
    isSale &&
    css`
      color: ${brandColors.sale};
    `}
`;
