import React from 'react';
import FooterLink from './FooterLink';
import * as styles from './styles';
import { Link } from '../../types';
import FooterPopover from './FooterPopover';

interface Props {
  links: Link[];
}

export default function FooterLinks({ links }: Props) {
  return (
    <styles.FooterLinksWrapper>
      {links.map((link, index) => {
        const { href, subLinksCollection, text, urlRoute } = link;
        const linkHref = urlRoute ? urlRoute.path : href;
        if (subLinksCollection.items?.length > 0) {
          return (
            <FooterPopover
              text={text}
              href={linkHref}
              subLinks={subLinksCollection.items?.length > 0 ? subLinksCollection.items : null}
              key={index}
              id={`footlink_${index}`}
            />
          );
        }
        return <FooterLink text={text} href={linkHref} key={index} id={`footlink_${index}`} />;
      })}
    </styles.FooterLinksWrapper>
  );
}
