import { defineMessages } from 'react-intl';

export default defineMessages({
  measurePdAgain: {
    defaultMessage: 'Measure your PD again',
    id: 'goLensesPlugin.measurePdAgain'
  },
  yourPdIs: {
    defaultMessage: 'Your pupillary distance is',
    id: 'goLensesPlugin.yourPdIs'
  }
});
