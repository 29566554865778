import React from 'react';
import { Typography, Button, InputControl, TextInput } from '@aceandtate/ds';
import authenticationMessages from 'messages/authentication';
import formValidation from 'messages/formValidation';
import { FormattedMessage, useIntl } from 'react-intl';
import useValidationOptions from 'utils/hooks/useValidationOptions';
import { SubmitHandler, useForm } from 'react-hook-form';
import * as Styles from '../styles';
import { useCheckIfEmailInUse, useCheckIfEmailIsValid } from 'services/userService';
import { ErrorMessage } from '@hookform/error-message';

type FormValues = {
  email: string;
};

type Props = {
  introCopy?: React.ReactNode;
  onSubmit: (state: 'emailExists' | 'emailDoesNotExist', email: string) => void;
};

const EmailForm = ({ introCopy, onSubmit }: Props) => {
  const {
    handleSubmit,
    setError,
    register,
    formState: { errors }
  } = useForm<FormValues>({ mode: 'onBlur' });
  const checkIfEmailInUse = useCheckIfEmailInUse();
  const checkIfEmailIsValid = useCheckIfEmailIsValid();
  const validationOptions = useValidationOptions();
  const intl = useIntl();

  const validateAndEvaluateEmail: SubmitHandler<FormValues> = async data => {
    if (!data.email) {
      return;
    }
    const emailIsValidCall = checkIfEmailIsValid(data.email);
    const emailExistsCall = checkIfEmailInUse(data.email);
    const [emailExists, emailIsValid] = await Promise.all([emailExistsCall, emailIsValidCall]);

    if (!emailIsValid.success) {
      setError('email', {
        message: intl.formatMessage(formValidation.invalidEmail),
        type: 'invalid'
      });
      return;
    }

    if (emailExists?.success && emailExists.data.emailExists) {
      onSubmit('emailExists', data.email);
    } else {
      onSubmit('emailDoesNotExist', data.email);
    }
  };

  const inlineValidateEmail = async email => {
    if (!email) {
      return;
    }
    const response = await checkIfEmailIsValid(email);
    if (!response.success) {
      return intl.formatMessage(formValidation.invalidEmail);
    }

    return true;
  };

  return (
    <form onSubmit={handleSubmit(validateAndEvaluateEmail)}>
      <Typography variant='h4' gutterBottom>
        <FormattedMessage {...authenticationMessages.loginOrRegister} />
      </Typography>
      <Typography gutterBottom>
        {introCopy || <FormattedMessage {...authenticationMessages.loginOrRegisterDescription} />}
      </Typography>
      <Styles.FormColumn>
        <Styles.FormRow>
          <InputControl id='email'>
            <InputControl.Label>
              <FormattedMessage {...authenticationMessages.emailLabel} />
            </InputControl.Label>
            <TextInput
              fullWidth
              {...register('email', {
                ...validationOptions.emailValidation,
                validate: { validateEmail: async email => await inlineValidateEmail(email) }
              })}
              type='email'
              data-testid='auth.email'
              autoComplete='email'
            />
            <ErrorMessage
              name='email'
              errors={errors}
              render={({ message }) => (
                <InputControl.Validation data-testid={`auth.email.error`} status='error'>
                  {message}
                </InputControl.Validation>
              )}
            />
          </InputControl>
        </Styles.FormRow>
        <Button data-cs-capture fullWidth data-testid='auth.continueButton'>
          <FormattedMessage {...authenticationMessages.continue} />
        </Button>
      </Styles.FormColumn>
    </form>
  );
};

export default EmailForm;
