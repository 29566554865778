import React from 'react';
import NotificationsContext, { NotificationProps, NotificationsConsumersProps } from './NotificationsContext';

export default class Notification extends React.PureComponent<NotificationProps> {
  displayName: 'notification';
  static contextType = NotificationsContext.Context;
  componentDidMount() {
    (this.context as NotificationsConsumersProps).addNotification(this.props);
  }

  render() {
    return null;
  }
}
