import styled from 'styled-components';
import { webColors, brandColors, grid } from 'styles';

type LabelImageProps = {
  hasBackgroundColor?: boolean;
};

export const LabelImage = styled.div<LabelImageProps>`
  ${props => props.hasBackgroundColor && `background-color: ${brandColors.white}`};
  border: 0.5px solid ${webColors.light};
  border-radius: 5px;
  margin-right: ${grid.xs};
  width: 40px;
  height: 25px;

  img {
    width: 100%;
  }
`;
