import React from 'react';
import CountryPicker from 'components/CountryPicker';
import LanguagePicker from './LanguagePicker';
import * as Styles from '../../styles';
import { IconLocation } from '@aceandtate/ds';

export default function FooterSwitcher() {
  return (
    <Styles.FooterSwitcherWrapper>
      <IconLocation fontSize={22} />
      <CountryPicker id='footer.country-picker' color='light' />
      <LanguagePicker id={'footer.language-picker'} />
    </Styles.FooterSwitcherWrapper>
  );
}
