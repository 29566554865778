import { User } from 'types/solidus';
import Cookie from 'js-cookie';
import * as Sentry from '@sentry/nextjs';
import jwtDecode from 'jwt-decode';

const cookieOptions = {
  expires: 30,
  path: '/'
};

export function setUserCookies(user: User, jwt?: string) {
  Cookie.set('accessToken', jwt, cookieOptions);
  Cookie.set('userId', user.id, cookieOptions);
  Cookie.set('emailHash', user.email_hash256, cookieOptions);
  Sentry.setExtra('userId', user.id);
  Sentry.configureScope(scope => {
    scope.setUser({ id: user.id.toString() });
  });
}

export function clearUserCookies() {
  Cookie.remove('accessToken');
  Cookie.remove('userId');
  Cookie.remove('emailHash');
}

export function accessTokenExpired(token: string) {
  try {
    const decoded = jwtDecode(token) as any;
    const currentTime = new Date().getTime() / 1000;
    if (currentTime > decoded.exp) {
      return true;
    }

    return false;
  } catch (err) {
    console.error(err); // eslint-disable-line
    return false;
  }
}

export function evaluateAccessToken() {
  const token = Cookie.get('accessToken');
  if (token && accessTokenExpired(token)) {
    Cookie.remove('accessToken');
    return undefined;
  }

  return token;
}
