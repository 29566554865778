import { useFetch } from 'utils/helpers/customFetch';
import { UserWishlist } from 'types/solidus';

// UserWishlistService

function useAddUserWishlistItems() {
  const fetch = useFetch<{ wishlist: UserWishlist }>();

  return async (skus: string[]) =>
    fetch('/wishlist/add_items', {
      body: { skus },
      method: 'POST'
    });
}

function useDeleteUserWishlistItems() {
  const fetch = useFetch();

  return async (skus: string[]) =>
    fetch('/wishlist/remove_items', {
      body: { skus },
      method: 'DELETE'
    });
}

export function useUserWishlist() {
  const addUserWishlistItems = useAddUserWishlistItems();
  const deleteUserWishlistItems = useDeleteUserWishlistItems();

  return {
    addUserWishlistItems,
    deleteUserWishlistItems
  };
}
