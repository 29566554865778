import styled, { css } from 'styled-components';
import { brandColors, grid } from '@aceandtate/ds';
import { shopstoryDevices } from 'shopstory/constants';
import { ShopstoryAlign } from '.';

export const Container = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  gap: ${grid[8]};
`;

const alignMap = {
  left: 'flex-start',
  center: 'center',
  right: 'flex-end'
};

function getAlignmentCSS(align: ShopstoryAlign) {
  let mediaQuery = '';
  for (const key in shopstoryDevices) {
    mediaQuery += ` @media (min-width: ${shopstoryDevices[key].startsFrom}px) {
      align-items: ${alignMap[align[key]]};
      text-align: ${align[key]};
    }`;
  }

  return mediaQuery;
}

type NewsletterInputProps = { color: 'light' | 'dark' };
type NewsletterFormProps = { align?: ShopstoryAlign };

export const PrivacyPolicyContainer = styled.div<NewsletterInputProps>`
  margin-top: ${grid[24]};
  color: ${({ color }) => brandColors[color]};
`;

export const StyledForm = styled.form<NewsletterFormProps>`
  display: flex;
  flex-flow: column;
  color: red;
  align-items: ${({ align }) =>
    align &&
    css`
      ${getAlignmentCSS(align)}
    `};
`;
