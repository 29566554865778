import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Typography, Button } from '@aceandtate/ds';
import ecommerceMessages from 'messages/ecommerce';
import messages from '../../messages';

type Props = {
  onClose: () => void;
};

export default function IncompatibleConfigurationModal(props: Props) {
  const { onClose } = props;
  return (
    <>
      <Typography variant='h5' gutterBottom={24}>
        <FormattedMessage {...messages.lensesAndCoatingsStepTitle} />
      </Typography>
      <Typography variant='bodyM' gutterBottom='24'>
        <FormattedMessage {...messages.configModalBody} />
      </Typography>
      <Button data-testid='incompatible-configuration-modal-cta' onClick={onClose} style={{ alignSelf: 'flex-end' }}>
        <FormattedMessage {...ecommerceMessages.ok} />
      </Button>
    </>
  );
}
