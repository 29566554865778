import { mapProductTorii } from 'tracking/utils';
import { ViewType } from 'features/Wishlist/types';
import { trackGaEvent, trackVariable } from '../helpers';
import { ProductType, Variant } from 'types/torii';

type WishlistEventOptions = {
  product: Variant;
  productType: ProductType;
  wishlistQuantity: number;
  from: ViewType;
};

export function addToWishlist(options: WishlistEventOptions) {
  const mappedProduct = mapProductTorii(options.product, options.productType);

  trackGaEvent({
    category: mappedProduct.category,
    event: 'addToWishlist',
    from: options.from,
    id: mappedProduct.id,
    name: mappedProduct.name,
    variant: mappedProduct.variant,
    wishlistQuantity: options.wishlistQuantity
  });

  trackVariable('addToWishlist', mappedProduct.variant);
}

export function removeFromWishlist(options: WishlistEventOptions) {
  const mappedProduct = mapProductTorii(options.product, options.productType);

  trackGaEvent({
    category: mappedProduct.category,
    event: 'removeFromWishlist',
    from: options.from,
    id: mappedProduct.id,
    name: mappedProduct.name,
    variant: mappedProduct.variant,
    wishlistQuantity: options.wishlistQuantity
  });

  trackVariable('removeFromWishlist', mappedProduct.variant);
}
