import { Button, Checkbox, InputControl, TextInput, Typography, Flex, grid } from '@aceandtate/ds';
import React, { useContext } from 'react';
import { ErrorMessage } from '@hookform/error-message';
import authenticationMessages from 'messages/authentication';
import formValidation from 'messages/formValidation';
import globalErrorMessages from 'messages/globalErrors';
import newsletterFormMessages from 'messages/newsletterForm';
import { FormattedMessage, useIntl } from 'react-intl';
import * as Styles from '../styles';
import useValidationOptions from 'utils/hooks/useValidationOptions';
import { Controller, useForm, FormProvider } from 'react-hook-form';
import { paths } from 'paths';
import { useCheckIfEmailInUse, useCheckIfEmailIsValid, useUserService } from 'services/userService';
import NotificationsContext from 'features/Notifications/NotificationsContext';
import { accountEvents } from 'tracking';
import { trackVariable } from 'tracking/helpers';
import * as Sentry from '@sentry/nextjs';
import CheckboxWithConditionalChildren from 'components/CheckboxWithConditionalChildren';
import { useSubscribe } from 'services/newsletterService';

type FormValues = {
  email: string;
  newPassword: string;
  confirmPassword: string;
  newsletter: boolean;
  dateOfBirth?: string;
  termsCheckbox: boolean;
  gender: string;
};

type RegisterFromProps = {
  onSuccess: () => void;
  email: string;
};

const RegisterForm = ({ onSuccess, email }: RegisterFromProps) => {
  const hookForm = useForm<FormValues>({ mode: 'onBlur', defaultValues: { email } });
  const {
    register,
    control,
    handleSubmit,
    formState: { errors, isSubmitting },
    setError,
    setValue
  } = hookForm;
  const { registerNewUser } = useUserService();
  const intl = useIntl();
  const validationOptions = useValidationOptions();
  const notifications = useContext(NotificationsContext.Context);
  const checkIfEmailInUse = useCheckIfEmailInUse();
  const checkIfEmailIsValid = useCheckIfEmailIsValid();
  const subscribeToNewsletter = useSubscribe();

  const handleUnexpectedError = () => {
    notifications.addNotification({
      children: <FormattedMessage {...globalErrorMessages.apiRequestError} />,
      name: 'error-notification',
      variant: 'error'
    });
  };

  const handleMismatchedPasswords = () => {
    setError('confirmPassword', {
      message: intl.formatMessage(formValidation.passwordsNoMatch),
      type: 'invalid'
    });
    setValue('newPassword', '');
    setValue('confirmPassword', '');
  };
  const attemptRegister = async (data: FormValues) => {
    if (data.newPassword !== data.confirmPassword) {
      handleMismatchedPasswords();
      return;
    }

    const newUserApiResponse = await registerNewUser({
      email: data.email,
      dateOfBirth: data.dateOfBirth,
      password: data.newPassword
    });

    if (data.newsletter) {
      subscribeToNewsletter({
        email: data.email,
        date_of_birth: data.dateOfBirth,
        formType: 'register',
        gender: data.gender
      });
    }

    if (!newUserApiResponse.success) {
      handleUnexpectedError();
      const err = new Error(
        `An unexpected error occured when creating a new user. ${JSON.stringify(newUserApiResponse)}`
      );
      Sentry.captureException(err);
      return newUserApiResponse;
    }

    accountEvents.registerSuccess(newUserApiResponse.data);
    trackVariable('userStatus', 'Register');
    onSuccess();
  };

  const validateEmail = async email => {
    if (!email) {
      return;
    }
    const callIsValid = checkIfEmailIsValid(email);
    const callIsInUse = checkIfEmailInUse(email);
    const [responseIsValid, responseIsInUse] = await Promise.all([callIsValid, callIsInUse]);
    if (!responseIsValid.success) {
      return intl.formatMessage(formValidation.invalidEmail);
    }

    if (responseIsInUse.success) {
      return intl.formatMessage(formValidation.alreadyInUseEmail);
    }
    return true;
  };

  return (
    <form onSubmit={handleSubmit(attemptRegister)}>
      <Typography gutterBottom data-cs-capture>
        <FormattedMessage {...authenticationMessages.createAccountDescription} />
      </Typography>
      <Typography variant='h4' gutterBottom data-cs-capture>
        <FormattedMessage {...authenticationMessages.createAccount} />
      </Typography>
      <Styles.FormColumn>
        <Styles.FormRow>
          <InputControl id='email'>
            <InputControl.Label>
              <FormattedMessage {...authenticationMessages.emailLabel} />
            </InputControl.Label>
            <TextInput
              fullWidth
              {...register('email', {
                ...validationOptions.emailValidation,
                validate: { validateEmail: async email => await validateEmail(email) }
              })}
              type='email'
              data-testid='auth.email'
              autoComplete='email'
            />
            <ErrorMessage
              name='email'
              errors={errors}
              render={({ message }) => (
                <InputControl.Validation data-testid={`auth.email.error`} status='error'>
                  {message}
                </InputControl.Validation>
              )}
            />
          </InputControl>
        </Styles.FormRow>
        <Styles.FormRow>
          <InputControl id='newPassword'>
            <InputControl.Label>
              <FormattedMessage {...authenticationMessages.enterYourPasswordLabel} />
            </InputControl.Label>
            <TextInput
              fullWidth
              {...register('newPassword', validationOptions.createPasswordValidation)}
              type='password'
              data-testid='auth.registerPassword'
              autoComplete='new-password'
            />
            <ErrorMessage
              name='newPassword'
              errors={errors}
              render={({ message }) => (
                <InputControl.Validation data-testid={`auth.newPassword.error`} status='error'>
                  {message}
                </InputControl.Validation>
              )}
            />
          </InputControl>
        </Styles.FormRow>
        <Styles.FormRow>
          <InputControl id='confirmPassword'>
            <InputControl.Label>
              <FormattedMessage {...authenticationMessages.newPasswordRepeat} />
            </InputControl.Label>
            <TextInput
              fullWidth
              {...register('confirmPassword', validationOptions.createPasswordValidation)}
              type='password'
              data-testid='auth.registerPasswordRepeat'
              autoComplete='new-password'
            />
            <ErrorMessage
              name='confirmPassword'
              errors={errors}
              render={({ message }) => (
                <InputControl.Validation data-testid={`auth.confirmPassword.error`} status='error'>
                  {message}
                </InputControl.Validation>
              )}
            />
          </InputControl>
        </Styles.FormRow>
        <Styles.Checkboxes data-cs-capture>
          <Controller
            control={control}
            name='newsletter'
            render={({ field: { onChange, value } }) => (
              <CheckboxWithConditionalChildren
                checked={value}
                onChange={onChange}
                id='newsletter'
                label={
                  <Typography variant='bodyS'>
                    {intl.formatMessage(newsletterFormMessages.subscribeToNewsletter)}
                  </Typography>
                }
              >
                <Flex flexDirection='row' gap={grid[12]}>
                  <FormProvider {...hookForm}>
                    <CheckboxWithConditionalChildren.Gender />
                  </FormProvider>
                  <CheckboxWithConditionalChildren.DateOfBirth {...register('dateOfBirth')} />
                </Flex>
              </CheckboxWithConditionalChildren>
            )}
          />
          <Controller
            control={control}
            name='termsCheckbox'
            rules={{ required: intl.formatMessage(formValidation.requiredField) }}
            render={({ field: { onChange, value, ...rest } }) => (
              <InputControl id='termsCheckbox'>
                <Checkbox
                  checked={value}
                  onCheckedChange={onChange}
                  data-testid='auth.termsCheckbox'
                  {...rest}
                  id='privacy-policy-consent'
                  label={
                    <Typography variant='bodyS'>
                      <FormattedMessage
                        {...authenticationMessages.privacyPolicyConsent}
                        values={{
                          privacyPolicy: (
                            <Styles.UnderlinedLink to={paths.privacyPolicy} rel='noopener noreferrer' target='_blank'>
                              <FormattedMessage {...authenticationMessages.privacyPolicy} />
                            </Styles.UnderlinedLink>
                          )
                        }}
                      />
                    </Typography>
                  }
                />
                <ErrorMessage
                  name='termsCheckbox'
                  errors={errors}
                  render={({ message }) => (
                    <InputControl.Validation status='error' data-testid='auth.termsCheckbox.error'>
                      {message}
                    </InputControl.Validation>
                  )}
                />
              </InputControl>
            )}
          />
        </Styles.Checkboxes>
        <Button data-cs-capture data-testid='auth.registerButton' type='submit' loading={isSubmitting} fullWidth>
          <FormattedMessage {...authenticationMessages.createAccount} />
        </Button>
      </Styles.FormColumn>
    </form>
  );
};

export default RegisterForm;
