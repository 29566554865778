import { trackGaEvent } from '../helpers';

// When the user submits the stock notification form successfully
// eslint-disable-next-line import/prefer-default-export
export function backInStockFormSubmitted({ sku }) {
  trackGaEvent({
    event: 'backInStockFormSubmitted',
    sku
  });
}
