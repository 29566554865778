import type { MouseEvent } from 'react';
import * as Sentry from '@sentry/nextjs';
import camelCase from 'lodash/camelCase';
import * as pageBannerEvents from './events/pageBanner';
import { paths, catalogPaths } from 'paths';
import { Cart, ContactLensLine, Line } from 'types/solidus';
import { convertCurrencyToEuro, mapDynamicRoute } from './helpers';
import { SolidusVariant } from 'types/products';
import { ProductType, ProductVariant, Variant } from 'types/torii';
import queryString from 'query-string';
import { getFromStorage, saveToStorage } from 'utils/helpers/storage';

function getClosestLink(element: EventTarget) {
  if (element instanceof HTMLAnchorElement) {
    return element as HTMLAnchorElement;
  }

  if (element instanceof Element) {
    return element.closest('a');
  }

  return null;
}

export function removeCountryLangPrefix(url: string) {
  if (typeof window === 'undefined') {
    throw new Error('removeCountryLangPrefix should only be called from the client side');
  }

  const anchor = document.createElement('a');
  anchor.setAttribute('href', url);
  // removing country info from url
  // this should work in 99% of cases but could potentially break when directly redirecting to
  // a two character domain without a country code e.g. aceandtate.com/hi
  // currently we are assuming that every two character string at the beginning would be a country code
  const pathMatch = /^(\/\w{2}(-\w{2})?(\/|$))/;
  return anchor.pathname.replace(pathMatch, '').replace(/^\/+/, '');
}

/**
 * Helper for creating event handlers to track analytics events
 */
export const onPageBannerClick = (bannerType: string) => (event: MouseEvent) => {
  const link = getClosestLink(event.target);
  if (link && link.href) {
    const clickedItem = removeCountryLangPrefix(link.href);
    pageBannerEvents.pageBannerClick({
      bannerType,
      clickedItem,
      clickedItemType: 'link'
    });
  }
};

// Try, TryBuy or Buy, coalesce to Buy
export function getCartType(cart) {
  if (!cart) {
    return '';
  }
  let type = '';
  const { hto_lines, regular_lines } = cart;
  if (hto_lines.length) {
    type += 'Try';
  }
  if (regular_lines.length) {
    type += 'Buy';
  }
  return type;
}

export function getSkuFromPathname(pathname) {
  // remove configSlug and all slashes to get the sku
  return pathname.replace('options', '').replace(/\//g, '');
}

export function isProduct(skus, pathname) {
  const sku = getSkuFromPathname(pathname);
  return sku && skus.includes(sku);
}

export const PageName = {
  About: 'about',
  Blog: 'blog',
  Cart: 'cart',
  Category: 'category',
  CheckoutDetails: 'checkoutDetails',
  Contact: 'contact',
  CreativeFund: 'creativeFund',
  CustomerCredit: 'customerCredit',
  CustomerLogin: 'customerLogin',
  DownloadInvoice: 'downloadInvoice',
  EyeTest: 'eyeTest',
  FAQ: 'FAQ',
  Guide: 'guide',
  Home: 'home',
  Locations: 'locations',
  Options: 'options',
  OrderHistory: 'orderHistory',
  PrescriptionHistory: 'prescriptionHistory',
  ProductDetail: 'productDetail',
  Profile: 'profile',
  SearchResult: 'searchResult',
  Shipping: 'shipping',
  Success: 'success'
};

export const PageType = {
  cart: 'cart',
  category: 'category',
  checkout: 'checkout',
  home: 'home',
  other: 'other',
  product: 'product',
  purchase: 'purchase',
  searchresult: 'searchresult'
};

function getPageNameFromPathname(pathname: string) {
  try {
    return camelCase(pathname);
  } catch {
    return '';
  }
}

export function getPageInfo(pathname: string, pagename?: string) {
  // First we check the pathname
  const mappedDynamicRoute = mapDynamicRoute(pathname);
  switch (mappedDynamicRoute || pathname) {
    case paths.home: {
      return {
        pageName: PageName.Home,
        pageType: PageType.home
      };
    }
    case paths.eyeTest: {
      return {
        pageName: PageName.EyeTest,
        pageType: PageType.other
      };
    }
    case paths.about: {
      return {
        pageName: PageName.About,
        pageType: PageType.other
      };
    }
    case paths.stores: {
      return {
        pageName: PageName.Locations,
        pageType: PageType.other
      };
    }
    case paths.contact: {
      return {
        pageName: PageName.Contact,
        pageType: PageType.other
      };
    }
    case paths.userProfile: {
      return {
        pageName: pagename,
        pageType: PageType.other
      };
    }
    case paths.userPrescriptions: {
      return {
        pageName: pagename,
        pageType: PageType.other
      };
    }
    case paths.userPrescriptionNew: {
      return {
        pageName: pagename,
        pageType: PageType.other
      };
    }
    case paths.userPrescriptionEdit: {
      return {
        pageName: pagename,
        pageType: PageType.other
      };
    }
    case paths.userOrders: {
      return {
        pageName: pagename,
        pageType: PageType.other
      };
    }
    case paths.userOrderDetail: {
      return {
        pageName: pagename,
        pageType: PageType.other
      };
    }
    case paths.userSubscriptions: {
      return {
        pageName: pagename,
        pageType: PageType.other
      };
    }
    case paths.search: {
      return {
        pageName: PageName.SearchResult,
        pageType: PageType.searchresult
      };
    }
    case paths.checkoutRegular:
    case paths.checkoutHTO: {
      return {
        pageName: PageName.CheckoutDetails,
        pageType: PageType.checkout
      };
    }
    case paths.login: {
      return {
        pageName: PageName.CustomerLogin,
        pageType: PageType.other
      };
    }
    default: {
      if (pathname.startsWith(paths.successHto) || pathname.startsWith(paths.successRegular)) {
        return {
          pageName: PageName.Success,
          pageType: PageType.purchase
        };
      }
      // When its a category
      if (catalogPaths.includes(pathname)) {
        return {
          pageName: getPageNameFromPathname(pathname),
          pageType: PageType.category
        };
      }

      if (pagename && pagename === 'collection') {
        return {
          pageName: getPageNameFromPathname(pathname),
          pageType: PageType.category
        };
      }
      if (pagename && pagename === 'product') {
        return {
          pageName: PageName.ProductDetail,
          pageType: PageType.product
        };
      }
    }
  }

  // Not yet defined page name or type is pathname camelcased and other
  return {
    pageName: getPageNameFromPathname(pathname),
    pageType: pagename || PageType.other
  };
}

export function getProductCategory(line: Line) {
  return line.product_type;
}

function getGender(sex) {
  switch (sex) {
    case 'female':
      return 'Woman';
    default:
      return 'Unisex';
  }
}

function getFilter(product) {
  if (product.blue_lens_filters_available) {
    return product.lens_color === 'UV420' ? 'Blue Light' : 'No Filter';
  }
  return undefined;
}

export function getLenseOption(prescriptionType) {
  switch (prescriptionType) {
    case 'plano': {
      return 'No prescription';
    }
    case 'single_vision': {
      return 'Single Focus';
    }
    case 'multifocal': {
      return 'Varifocal';
    }
    default: {
      return undefined;
    }
  }
}

function getSolidusProductCategory(variant: SolidusVariant, productType: ProductType) {
  if (variant.is_sunny && productType === 'frame') {
    return 'Sunglasses';
  } else if (productType === 'frame') {
    return 'Glasses';
  }

  return productType;
}

export function getToriiProductCategory(variant: Variant, productType: ProductType) {
  if (variant.filterOptions.types.includes('sunny') && productType === 'frame') {
    return 'sunglasses';
  } else if (productType === 'frame') {
    return 'glasses';
  }

  return productType;
}

export function mapProductSolidus(variant: SolidusVariant, productType: ProductType) {
  return {
    brand: 'Ace and Tate',
    category: getSolidusProductCategory(variant, productType),
    dimension10: variant.material,
    dimension5: variant.hto_available ? 'Yes' : 'No',
    dimension6: getGender(variant.sex),
    dimension7: variant.contains_colors,
    id: variant.sku,
    name: variant.name,
    price: variant.price,
    variant: variant.color
  };
}

export function mapProductTorii(variant: Variant, productType: ProductType) {
  return {
    brand: 'Ace and Tate',
    category: getToriiProductCategory(variant, productType),
    dimension10: productType !== 'contact_lenses' ? (variant as ProductVariant).properties?.frameMaterials : 'unknown',
    dimension8: variant.displayAttributes.frameWidth,
    dimension9: variant.filterOptions.shapes && variant.filterOptions.shapes[0],
    id: variant.sku,
    name: variant.name,
    price: variant.price,
    variant: variant.displayAttributes.color
  };
}

const isContactLens = (line: Line): line is ContactLensLine => line.product_type === 'contact_lenses';

function mapCartItem(line: Line, currency) {
  const {
    id,
    price,
    total,
    quantity,
    // eslint-disable-next-line camelcase
    prescription_type
  } = line;

  const { name, color, sku } = line.variant;

  return {
    category: getProductCategory(line),
    id,
    lenses: getLenseOption(prescription_type),
    name,
    // the numerical id of the product
    price: convertCurrencyToEuro(price, currency),
    // the color of the product in the basket
    quantity,
    // Value converted to euroes
    row_total: convertCurrencyToEuro(total, currency),
    sku,
    // Value converted to euroes
    variant: color,
    // optional isTrial attribute for contact lenses
    ...(isContactLens(line) ? { is_trial: line.product_properties.is_trial } : {})
  };
}

function getCartContents(cart: Cart) {
  const cartContents = [];
  cart.hto_lines.forEach(line => {
    cartContents.push({ ...mapCartItem(line, cart.currency), lineType: 'hto' });
  });
  cart.regular_lines.forEach(line => {
    cartContents.push({
      ...mapCartItem(line, cart.currency),
      lineType: 'regular'
    });
  });
  return cartContents;
}

export function mappedCartContent(cart: Cart) {
  const { currency, total } = cart;
  return {
    // it indicates the quantity of the products in the basket
    cartContents: getCartContents(cart),
    // Value converted to euroes
    cartQuantity: cart.item_count || 0,
    cartValue: convertCurrencyToEuro(total, currency),
    checkoutType: getCartType(cart)
  };
}

type CustomLine = Line & {
  is_hto?: boolean;
};

export function mapLine(line: CustomLine) {
  return {
    // lenses option chosen by the user. No prescription, Varifocal or Single Focus
    dimension3: getLenseOption(line.prescription_type),
    // HTO order statement. Please send 'Yes' if the order is for Home Try-On
    dimension54: getFilter(line),
    // if the product has available option for glass filter like Blue Light or No Filter (otherwise send type of undefined or exclude)
    dimension56: line.is_hto ? 'Yes' : 'No',
    dimension60:
      line.product_type === 'contact_lenses' && (line as ContactLensLine).product_properties.is_trial ? 'Yes' : 'No',
    quantity: line.quantity // HTO order statement. Please send 'Yes' if the order is for Home Try-On
  };
}

export function flattenQuery(query: string | string[]) {
  if (Array.isArray(query)) {
    return query[0];
  }

  return query;
}

export function parseAndStoreUTM() {
  try {
    const query = queryString.parse(window.location.search);

    if (query.utm_campaign) {
      saveToStorage(`utm_campaign`, flattenQuery(query.utm_campaign), 'session');
    }

    if (query.utm_source) {
      saveToStorage(`utm_source`, flattenQuery(query.utm_source), 'session');
    }

    if (query.utm_medium) {
      saveToStorage(`utm_medium`, flattenQuery(query.utm_medium), 'session');
    }

    if (query.gclid) {
      saveToStorage(`gclid`, flattenQuery(query.gclid), 'session');
    }
    if (query.ttclid) {
      saveToStorage(`ttclid`, flattenQuery(query.ttclid), 'session');
    }
    if (query.fbclid) {
      saveToStorage(`fbclid`, flattenQuery(query.fbclid), 'session');
    }
  } catch (err) {
    const errorMessage = 'unable to parse and store UTM query';
    console.error(errorMessage, err);
    Sentry.captureException(`${errorMessage} ${err}`);
  }
}

export function retrieveUTM() {
  try {
    const query = queryString.parse(window.location.search);

    return {
      utm_campaign: query.utm_campaign || getFromStorage('utm_campaign'),
      utm_source: query.utm_source || getFromStorage('utm_source'),
      utm_medium: query.utm_medium || getFromStorage('utm_medium'),
      gclid: query.gclid || getFromStorage('gclid'),
      ttclid: query.ttclid || getFromStorage('ttclid'),
      fbclid: query.fbclid || getFromStorage('fbclid')
    };
  } catch (err) {
    const errorMessage = 'unable to retrieve UTM query';
    console.error(errorMessage, err);
    Sentry.captureException(`${errorMessage} ${err}`);
    return {
      utm_campaign: null,
      utm_source: null,
      utm_medium: null,
      gclid: null,
      ttclid: null,
      fbclid: null
    };
  }
}
