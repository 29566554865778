import { defineMessages } from 'react-intl';

export const lensProductionTypes = defineMessages({
  computer_lenses: {
    defaultMessage: 'Computer lenses',
    id: 'lensesProductionType.computerLenses'
  },
  relax_5_lenses: {
    defaultMessage: 'Relax lenses (5)',
    id: 'lensesProductionType.relax_5_lenses'
  },
  relax_9_lenses: {
    defaultMessage: 'Relax lenses (9)',
    id: 'lensesProductionType.relax_9_lenses'
  },
  relax_lenses: {
    defaultMessage: 'Relax lenses',
    id: 'lensesProductionType.relax_lenses'
  },
  max2Files: {
    defaultMessage: 'Max 2 files',
    id: 'checkout.prescriptionRejectionReason.max2Files'
  }
});

export const prescriptionTypes = defineMessages({
  multifocal: {
    defaultMessage: 'Multifocal lenses',
    id: 'cart.productDetails.prescription.multifocal'
  },
  plano: {
    defaultMessage: 'No prescription lenses',
    id: 'cart.productDetails.prescription.plano'
  },
  single_vision: {
    defaultMessage: 'Single vision lenses',
    id: 'cart.productDetails.prescription.single_vision'
  }
});

export const generic = defineMessages({
  addPrescriptionSuccess: {
    defaultMessage: 'Your prescription was successfully added',
    id: 'prescriptionPage.addPrescriptionSuccess'
  },
  addedOn: {
    defaultMessage: 'Added on:',
    id: 'prescription.addedOn'
  },
  addition: {
    defaultMessage: 'Addition (ADD)',
    id: 'prescriptionTable.addition'
  },
  addition_short: {
    defaultMessage: 'ADD',
    id: 'prescriptionTable.additionShort'
  },
  axis: {
    defaultMessage: 'Axis (AXI)',
    id: 'prescriptionTable.axis'
  },
  axis_short: {
    defaultMessage: 'AXI',
    id: 'prescriptionTable.axisShort'
  },
  base_curve: {
    defaultMessage: 'Base Curve (BC)',
    id: 'prescription.baseCurve'
  },
  contactPackLabel: {
    defaultMessage: '{count, plural, one {pack} other {packs} }',
    id: 'prescription.label.contactPackLabel'
  },
  cylinder: {
    defaultMessage: 'Cylinder (CYL)',
    id: 'prescriptionTable.cylinder'
  },
  cylinder_short: {
    defaultMessage: 'CYL',
    id: 'prescriptionTable.cylinderShort'
  },
  diameter: {
    defaultMessage: 'Diameter',
    id: 'prescription.diameter'
  },
  expired: {
    defaultMessage: 'Expired',
    id: 'prescription.expired'
  },
  expiresAt: {
    defaultMessage: 'Expires at:',
    id: 'prescription.expiresAt'
  },
  iDontKnow: {
    defaultMessage: "I don't know",
    id: 'checkout.iDontKnow'
  },
  leftEye: {
    defaultMessage: 'Left Eye',
    id: 'prescription.leftEye'
  },
  leftEyeShort: {
    defaultMessage: 'Left',
    id: 'prescription.leftEyeShort'
  },
  measureMyPdOnline: {
    defaultMessage: 'Measure my pupilary distance (PD) now',
    id: 'prescriptionPage.measureMyPdOnline'
  },
  power: {
    defaultMessage: 'Power (PWR)',
    id: 'prescriptionTable.power'
  },
  power_short: {
    defaultMessage: 'PWR',
    id: 'prescriptionTable.powerShort'
  },
  prescription: {
    defaultMessage: 'Prescription',
    id: 'prescription.label'
  },
  prescriptionAddedOnDate: {
    defaultMessage: 'Added on: {date}',
    id: 'prescription.addedOnDate'
  },
  prescription_error_axis_missing_cylinder: {
    defaultMessage: 'You need to enter a cylinder if you have an axis',
    id: 'prescription.error.axisMissingCylinder'
  },
  prescription_error_cylinder_missing_axis: {
    defaultMessage: 'You need to an axis if you have a cylinder',
    id: 'prescription.error.cylinderMissingAxis'
  },
  prescription_subtype_distance: {
    defaultMessage: 'Distance',
    id: 'prescription.subtype.distance'
  },
  prescription_subtype_intermediate: {
    defaultMessage: 'Intermediate',
    id: 'prescription.subtype.intermediate'
  },
  prescription_subtype_near: {
    defaultMessage: 'Near',
    id: 'prescription.subtype.near'
  },
  prescription_type_multifocal: {
    defaultMessage: 'Multifocal',
    id: 'prescription.type.multifocal'
  },
  prescription_type_multifocal_infoText: {
    defaultMessage:
      'Varifocal lenses allow you to see clearly at every distance without you having to carry around and swap your frames for different uses.',
    id: 'prescription.type.multifocal.infoText'
  },
  prescription_type_multifocal_optionText: {
    defaultMessage: 'Perfect vision for near and distance in one lens. No more swapping glasses!',
    id: 'prescription.type.multifocal.optionText'
  },
  prescription_type_non_prescription: {
    defaultMessage: 'Non-prescription',
    id: 'prescription.type.nonPrescription'
  },
  prescription_type_not_available: {
    defaultMessage: 'No prescription available',
    id: 'prescription.type.prescriptionNotAvailable'
  },
  prescription_type_plano: {
    defaultMessage: 'Plano',
    id: 'prescription.type.plano'
  },
  prescription_type_plano_infoText: {
    defaultMessage:
      "If you don't really need to wear glasses for medical reasons but still want to rock a pair of Ace & Tate frames.",
    id: 'prescription.type.plano.infoText'
  },
  prescription_type_plano_optionText: {
    defaultMessage: 'No medical reasons, just a cool pair of glasses.',
    id: 'prescription.type.plano.optionText'
  },
  prescription_type_single_vision: {
    defaultMessage: 'Single vision',
    id: 'prescription.type.singleVision'
  },
  prescription_type_single_vision_infoText: {
    defaultMessage:
      'We make prescription lenses with a sphere (SPH) up to -10.00 or +10.00 and a cylinder up to -4.00 or +4.00.',
    id: 'prescription.type.singleVision.infoText'
  },
  prescription_type_single_vision_optionText: {
    defaultMessage: 'Only looking for glasses for either near or distance? This is you.',
    id: 'prescription.type.singleVision.optionText'
  },
  pupillary_distance: {
    defaultMessage: 'Pupillary distance (PD)',
    id: 'prescriptionTable.pupillaryDistance'
  },
  pupillary_distance_short: {
    defaultMessage: 'PD',
    id: 'prescriptionTable.pupillaryDistanceShort'
  },
  pupillary_height: {
    defaultMessage: 'Pupillary height (PH)',
    id: 'prescriptionTable.pupillaryHeight'
  },
  pupillary_height_short: {
    defaultMessage: 'PH',
    id: 'prescriptionTable.pupillaryHeightShort'
  },
  rightEye: {
    defaultMessage: 'Right Eye',
    id: 'prescription.rightEye'
  },
  rightEyeShort: {
    defaultMessage: 'Right',
    id: 'prescription.rightEyeShort'
  },
  saveChanges: {
    defaultMessage: 'Save changes',
    id: 'prescription.saveChanges'
  },
  sphere: {
    defaultMessage: 'Sphere (SPH)',
    id: 'prescriptionTable.sphere'
  },
  sphere_short: {
    defaultMessage: 'SPH',
    id: 'prescriptionTable.sphereShort'
  },
  unverified: {
    defaultMessage: 'Unverified',
    id: 'prescription.unverfied'
  },
  updatePrescriptionSuccess: {
    defaultMessage: 'Your prescription was successfully updated!',
    id: 'prescriptionPage.updatePrescriptionSuccess'
  },
  verified: {
    defaultMessage: 'Verified',
    id: 'prescription.verified'
  },
  prescriptionFileIsMandatory: {
    defaultMessage: 'The prescription file is required to proceed',
    id: 'prescription.fileIsMandatory'
  },
  invalidFileType: {
    defaultMessage: 'The type of the file must be HEIC, JPG, PNG or PDF',
    id: 'prescription.invalidFileType'
  }
});

export default { ...prescriptionTypes, ...lensProductionTypes, ...generic };
