import React from 'react';
import styled from 'styled-components';

type IconWrapProps = {
  color?: string;
  size?: number;
};

const IconWrap = styled.span<IconWrapProps>`
  display: inline-block;
  color: ${props => props.color};
  width: ${props => props.size}px;
  height: ${props => props.size}px;

  svg {
    width: ${props => props.size}px;
    height: ${props => props.size}px;

    g,
    path,
    circle {
      fill: currentColor;
    }
  }
`;

type Props = {
  className?: string;
  color?: string;
  size?: number;
  style?: React.CSSProperties;
};

const LoaderIcon = ({ color, size, className, style }: Props) => (
  <IconWrap
    size={size || 50}
    color={color || '#000'}
    className={className ? `loaderIcon ${className}` : 'fullscreen'}
    style={style}
  >
    <svg
      version='1.1'
      id='L4'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      x='0px'
      y='0px'
      viewBox='0 0 100 100'
      enableBackground='new 0 0 0 0'
      xmlSpace='preserve'
    >
      <circle stroke='none' cx='24' cy='50' r='8'>
        <animate attributeName='opacity' dur='1s' values='0;1;0' repeatCount='indefinite' begin='0.1' />
      </circle>
      <circle stroke='none' cx='48' cy='50' r='8'>
        <animate attributeName='opacity' dur='1s' values='0;1;0' repeatCount='indefinite' begin='0.2' />
      </circle>
      <circle stroke='none' cx='72' cy='50' r='8'>
        <animate attributeName='opacity' dur='1s' values='0;1;0' repeatCount='indefinite' begin='0.3' />
      </circle>
    </svg>
  </IconWrap>
);

export default LoaderIcon;
