import { defineMessages } from 'react-intl';

const generic = defineMessages({
  askForPrescriptionLater: {
    defaultMessage:
      "We'll ask for your prescription during checkout. And don't worry if you don't have it yet, you can also send it after your purchase.",
    id: 'configurator.prescription.notice.askForPrescriptionLater'
  },
  configModalBody: {
    defaultMessage:
      "We're really sorry, but it is not possible to order Ultra thin lenses with Blue light filter. Please continue with only one of these options.",
    id: 'productConfigurator.configModal.body'
  },
  free: {
    defaultMessage: 'free',
    id: 'configurator.tag.free'
  },
  invalidPrescriptionSelectedButton: {
    defaultMessage: 'continue shopping',
    id: 'productConfigurator.modal.invalidPrescriptionSelected.button'
  },
  invalidPrescriptionSelectedText: {
    defaultMessage:
      'We’re really sorry. You cannot checkout with single and multifocal lenses in the same order. Please place 2 seperate orders. We’re working on solving this limitation.',
    id: 'productConfigurator.modal.invalidPrescriptionSelected.text'
  },
  learnMoreLink: {
    defaultMessage: 'Learn more',
    id: 'experimentalConfigurator.learnMoreLink'
  },
  lensesAndCoatingsStepTitle: {
    defaultMessage: 'Lenses & coatings',
    id: 'productConfigurator.lensesAndCoatingsTitle'
  },
  multifocalNotice: {
    defaultMessage:
      "Looking for multifocal (sun)glasses? These glasses require extra measurements that are best taken in-person. We'll happily help you in one of <link>our stores</link>.",
    id: 'configurator.multifocal.notice.disabled'
  },
  multifocalPrescriptionWarning: {
    defaultMessage: 'Please read our notice regarding multifocal lenses',
    id: 'configurator.multifocal.multifocalPrescriptionWarning'
  },
  multifocalPrescriptionWarningModalCaption: {
    defaultMessage:
      "For the best results with multifocal lenses, consider dropping by <link>our stores</link>. It's crucial to get those personal details like pupillary distance, corneal vertex distance, frame tilt, and grinding height just right. Skipping this step might lead to some road safety concerns, especially when ordering multifocal lenses online without a visit to an optician in our stores. Just a friendly heads-up to take care in such situations!",
    id: 'configurator.multifocal.multifocalPrescriptionWarningModalCaption'
  },
  premiumModalCaption: {
    defaultMessage:
      'Premium Lenses are available for all prescriptions but are required for prescriptions of +/- 6.25 dioptres or higher — so we can provide you with the best-looking lenses possible.',
    id: 'experimentalConfigurator.premiumModalCaption'
  },
  premiumModalTitle: {
    defaultMessage: 'Compare lenses',
    id: 'experimentalConfigurator.premiumModalTitle'
  },
  selectGlasses: {
    defaultMessage: 'Select your glasses',
    id: 'productConfigurator.selectGlasses'
  }
});

export const features = defineMessages({
  blfDescription: {
    defaultMessage:
      'Our Blue Light Filter can create a little more comfort for your eyes — for long office days, gaming sessions, or quick emails before bed.',
    id: 'configurator.feature.blf.description'
  },
  blfSubtitle: {
    defaultMessage: 'Recommended for prescriptions up to -/+ 6.00.',
    id: 'configurator.feature.blf.subtitle'
  },
  blfTitle: {
    defaultMessage: 'Looking at screens a lot?',
    id: 'configurator.feature.blf.title'
  },
  polarisedDescription: {
    defaultMessage:
      "Polarised lenses are great for when you're outdoors a lot or when driving in wet or sunny conditions.",
    id: 'configurator.feature.polarised.description'
  },
  polarisedTitle: {
    defaultMessage: 'Are you the outdoorsy type?',
    id: 'configurator.feature.polarised.title'
  }
});

export const configuratorSteps = defineMessages({
  accessoriesStep: {
    defaultMessage: 'Accessories',
    id: 'configurator.step.accessories.title'
  },
  cartStep: {
    defaultMessage: 'My Cart',
    id: 'configurator.step.cart.title'
  },
  lensesExtrasStep: {
    defaultMessage: 'Upgrade your lenses',
    id: 'configurator.step.lensesExtras.title'
  },
  lensesStep: {
    defaultMessage: 'Select lens type',
    id: 'configurator.step.lenses.title'
  },
  prescriptionStep: {
    defaultMessage: 'Select glasses type',
    id: 'configurator.step.prescription.title'
  },
  sunPrescriptionStep: {
    defaultMessage: 'Select sunglasses type',
    id: 'configurator.step.prescription.sunglasses.title'
  }
});

export default { ...configuratorSteps, ...generic, ...features };
