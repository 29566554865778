import { defineMessages } from 'react-intl';

const messages = defineMessages({
  apiRequestError: {
    defaultMessage:
      'Whoops, looks like something went wrong. If this error persists, please reach out to our support team.',
    id: 'global.api.error'
  },
  fileUploadFailed: {
    defaultMessage:
      'Something went wrong when uploading your file. If this error persists, please reach out to our support team.',
    id: 'global.api.fileUploadFailed'
  },
  invalidLogin: {
    defaultMessage: 'Invalid login',
    id: 'global.error.invalidLogin'
  },
  oops: {
    defaultMessage: 'Oops!',
    id: 'global.error.oops'
  },
  somethingWentWrong: {
    defaultMessage: 'Oops, Something went wrong.',
    id: 'global.errors.somethingWentWrong'
  },
  looksLikeSomethingWentWrong: {
    defaultMessage: 'Oops, looks like something went wrong.',
    id: 'global.errors.looksLikeSomethingWentWrong'
  },
  somethingWentWrongPleaseTryAgain: {
    defaultMessage: 'Oops! Something went wrong. Please try again.',
    id: 'global.errors.somethingWentWrongPleaseTryAgain'
  },
  refreshPage: {
    defaultMessage: 'Please, refresh the page',
    id: 'global.errors.actions.refreshPage'
  },
  contactSupport: {
    defaultMessage: 'If this error persists, please reach out to our support team.',
    id: 'global.errors.actions.contactSupport'
  },
  globalTestKey: {
    defaultMessage: 'This is a new global test message',
    id: 'global.test.aTestKey'
  }
});

export default messages;
