import React, { useEffect, useState } from 'react';
import { WishlistItem } from './types';
import WishlistContext from './WishlistContext';
import { wishListEvents } from 'tracking';
import { useUserState } from 'services/userService';
import { useUserWishlist } from 'services/wishlistService';
import { removeFromStorage } from 'utils/helpers/storage';

const KEY = 'sku';
const STORE_KEY = 'wishlist-v1';

export default function WishlistProvider(props) {
  const { isUserLoggedIn, isValidating, userProfile } = useUserState();
  const [wishlist, setWishlist] = useState<WishlistItem[]>([]);
  const { addUserWishlistItems, deleteUserWishlistItems } = useUserWishlist();
  const [ready, setReady] = useState(false);

  useEffect(() => {
    if (!isUserLoggedIn && !isValidating) {
      setWishlist(getFromLocalStorage());
      window.addEventListener('storage', handleLocalStorageUpdate);
      setReady(true);
    }

    return () => {
      window.removeEventListener('storage', handleLocalStorageUpdate);
    };
  }, [isUserLoggedIn, isValidating]);

  function initUserWishlist() {
    setWishlist(userProfile.wishlist || []);
    setReady(true);
  }

  useEffect(() => {
    userProfile && initUserWishlist();
  }, [userProfile?.wishlist]);

  function handleLocalStorageUpdate(e) {
    if (e.key === STORE_KEY) {
      setWishlist(getFromLocalStorage());
    }
  }

  function getFromLocalStorage() {
    try {
      const rawJson = window.localStorage.getItem(STORE_KEY);
      return rawJson ? JSON.parse(rawJson) : [];
    } catch (err) {
      return [];
    }
  }

  function updateLocalStorage(wishlist: WishlistItem[]) {
    const prunedWishlist = wishlist.map(item => ({ [KEY]: item[KEY] }));
    const data = JSON.stringify(prunedWishlist);
    try {
      window.localStorage.setItem(STORE_KEY, data);
    } catch (err) {
      // We cannot recover from this, but the data will
      // still be available in memory.
    }
  }

  async function addToWishlist(options) {
    const { from, item: itemToAdd, productType } = options;

    if (itemToAdd instanceof Array) {
      // this is the case when we add a few frames (migrate localStorage to userProfile)
      const newItemsToAdd = itemToAdd.map(i => ({
        displayName: `${i.name} ${i.displayAttributes.color}`.trim(),
        sku: i.sku
      }));

      const updatedWishlist = wishlist.concat(newItemsToAdd);
      setWishlist(updatedWishlist);

      itemToAdd.forEach(item => {
        wishListEvents.addToWishlist({
          from,
          product: item,
          productType,
          wishlistQuantity: updatedWishlist.length
        });
      });

      if (isUserLoggedIn) {
        const wishlistResult = await addUserWishlistItems(itemToAdd.map(item => item.sku));
        if (wishlistResult.success) {
          setWishlist(wishlistResult.data.wishlist);
        }
      } else {
        updateLocalStorage(updatedWishlist);
      }
    } else {
      if (hasItem(itemToAdd)) return;
      const updatedWishlist = wishlist.concat({
        displayName: `${itemToAdd.name} ${itemToAdd.displayAttributes.color}`.trim(),
        sku: itemToAdd.sku
      });
      setWishlist(updatedWishlist);

      wishListEvents.addToWishlist({
        from,
        product: itemToAdd,
        productType,
        wishlistQuantity: updatedWishlist.length
      });

      if (isUserLoggedIn) {
        const wishlistResult = await addUserWishlistItems([itemToAdd.sku]);
        if (wishlistResult.success) {
          setWishlist(wishlistResult.data.wishlist);
        }
      } else {
        updateLocalStorage(updatedWishlist);
      }
    }
  }

  function hasItem(itemToFind) {
    return wishlist.some(item => item[KEY] === itemToFind[KEY]);
  }

  async function removeFromWishlist(options) {
    const { from, item: itemToRemove, productType } = options;
    const updatedWishlist = wishlist.filter(item => item[KEY] !== itemToRemove[KEY]);
    setWishlist(updatedWishlist);

    if (isUserLoggedIn) {
      deleteUserWishlistItems([itemToRemove.sku]);
    } else {
      updateLocalStorage(updatedWishlist);
    }

    wishListEvents.removeFromWishlist({
      from,
      product: itemToRemove,
      productType,
      wishlistQuantity: updatedWishlist.length
    });
  }

  async function migrateWishlist() {
    const localWishlist = getFromLocalStorage();
    addUserWishlistItems(localWishlist.map(item => item.sku));
    removeFromStorage(STORE_KEY);
  }

  const contextValue = {
    addToWishlist,
    count: wishlist.length,
    hasItem,
    migrateWishlist,
    ready,
    removeFromWishlist,
    wishlist
  };

  return <WishlistContext.Provider value={contextValue}>{props.children}</WishlistContext.Provider>;
}
