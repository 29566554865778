import styled from 'styled-components';

export const UnidaysIframeContainer = styled.div`
  width: 100vw;
`;

export const UnidaysIframe = styled.iframe`
  height: 705px;
  width: 100%;
  max-width: 1440px;
  border: 0;
`;
