import { grid, uiColors, borderRadius } from '@aceandtate/ds';
import styled, { css } from 'styled-components';

export const LoginDrawerContent = styled.div`
  padding: ${grid[24]};
`;

export const FormColumn = styled.div`
  display: flex;
  flex-flow: column;
  width: 100%;
`;

export const InlineLinkButton = styled.button`
  background: none;
  border: none;
  padding: 0;
  text-decoration: underline;
  font-weight: inherit;
  cursor: pointer;

  &:hover {
    text-decoration: none;
  }
`;

export const FormRow = styled.div<{
  marginTop?: string;
}>`
  margin-bottom: ${grid[24]};
  ${({ marginTop }) => css`
    margin-top: ${marginTop};
  `}

  a {
    text-decoration: underline;
  }
`;

export const LoginErrorBox = styled.div`
  color: ${uiColors.error};
  background-color: ${uiColors.errorLight};
  border-radius: ${borderRadius.small};
  padding: ${grid[12]};
`;

export const OrSpacer = styled.div`
  padding: ${grid[12]};
  align-items: center;
  display: flex;

  :before {
    content: '';
    width: 100px;
    flex-grow: 1;
    margin-right: ${grid[24]};
    border-bottom: 1px solid ${uiColors.dark};
  }

  :after {
    content: '';
    width: 100px;
    flex-grow: 1;
    margin-left: ${grid[24]};
    border-bottom: 1px solid ${uiColors.dark};
  }
`;
