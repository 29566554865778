import { createContext } from 'react';
import type { AsyncReturnType } from 'type-fest';
import type {
  getPageLayoutData,
  getHomepageBlocks,
  UrlRouteMeta,
  getNinetailedExperiences,
  getNinetailedExperiments,
  retrieveCollectionPageData,
  retrieveLandingPageData
} from '../contentful';
import type { getLocale, getMessages } from '../locale';
import type { fetchProduct } from '../productsService';
import type { getWebStore } from '../store';

export type BaseServices = {
  pageName: string;
  locale: ReturnType<typeof getLocale>;
  params?: {
    locale: string;
  };
  webStore: AsyncReturnType<typeof getWebStore>;
  messages: AsyncReturnType<typeof getMessages>;
};

export type Services = BaseServices & {
  collection?: AsyncReturnType<typeof retrieveCollectionPageData>;
  homepage?: AsyncReturnType<typeof getHomepageBlocks>;
  footer?: AsyncReturnType<typeof getPageLayoutData>['menu'];
  landingPage?: AsyncReturnType<typeof retrieveLandingPageData>;
  menuNavigation?: AsyncReturnType<typeof getPageLayoutData>['menu'];
  product?: AsyncReturnType<typeof fetchProduct>;
  urlRoute?: UrlRouteMeta;
  key?: string;
  draftMode?: boolean;
  ninetailed?: {
    userExperiencesMap?: Record<string, number> | Record<string, never>; // possible by esr in cloudflare worker
    experiences?: AsyncReturnType<typeof getNinetailedExperiences>;
    experiments?: AsyncReturnType<typeof getNinetailedExperiments>;
  };
};

export const ServicesContext = createContext<Services>(undefined);

type Redirect = {
  destination: string;
  permanent: boolean;
};

export type StaticProps<T> = Promise<{
  redirect?: Redirect;
  notFound?: boolean;
  props?: T;
  revalidate?: number;
}>;
