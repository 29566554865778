import React from 'react';
import { Modal } from '@aceandtate/ds';
import IncompatibleConfigurationModal from './IncompatibleConfigurationModal';
import InvalidPrescriptionCombinationModal from './InvalidPrescriptionCombinationModal';
import { useConfiguratorStore } from '../../configuratorStore';
import { trackPreviousArtificialPageView } from 'tracking/helpers';
import { useRouter } from 'next/router';
import MultifocalPrescriptionWarningModal from './MultifocalPrescriptionWarningModal';

export default function ConfiguratorModal() {
  const { data, methods } = useConfiguratorStore();
  const router = useRouter();
  function handleClose() {
    methods.setWarningModal(null);
    // remove all active hashes, in case the router was opened from a link with hash (e.g. multifocal warning)
    router.replace({ hash: undefined, query: router.query }, undefined, { scroll: false, shallow: true });
    trackPreviousArtificialPageView();
  }

  return (
    <Modal
      open={!!data.warningModal}
      onClose={handleClose}
      data-testid={`product-configurator-modal-${data.warningModal}`}
      content={
        <>
          {data.warningModal === 'invalidPrescriptionCombination' && (
            <InvalidPrescriptionCombinationModal onClose={handleClose} />
          )}
          {data.warningModal === 'incompatibleConfiguration' && (
            <IncompatibleConfigurationModal onClose={handleClose} />
          )}
          {data.warningModal === 'multifocalPrescriptionWarning' && (
            <MultifocalPrescriptionWarningModal onClose={handleClose} />
          )}
        </>
      }
    />
  );
}
