import { ImageLoaderProps } from 'next/image';

const DEBUG = false;
export const BASE_URL = '//images.aceandtate.com/image/upload/';

const uploadString = 'upload/';

const scaleAccessoryDown = 'c_lfill,h_1455,w_1200/';
const scaleDown = 'c_lfill,h_1855,w_2000/';
export const scaleAndZoom = `${scaleDown}c_crop,h_1200,w_960,x_520,y_480/`;

export const accessoryScaleAndZoom = `${scaleAccessoryDown}c_crop,h_1200,w_960,x_100,y_80/`;

function isUrl(src) {
  return typeof src === 'string' && src.indexOf(uploadString) > -1;
}

export function scaleFromUrl(url, scaleFactor) {
  if (!url || typeof url !== 'string') return null;
  const uploadPoint = url.indexOf(uploadString) + uploadString.length;
  return [url.slice(0, uploadPoint), scaleFactor, url.slice(uploadPoint)].join('');
}

type GetOptimizedProductUrlOptions = {
  width?: number;
  height?: number;
  quality: number;
  type: string;
  isFrame: boolean;
};

export function getOptimizedProductUrl(
  src: string,
  { width, type, isFrame, quality = 85 }: GetOptimizedProductUrlOptions
) {
  const isProductShot = ['front', 'profile', 'side'].includes(type);
  const format =
    isFrame && isProductShot
      ? `c_scale,w_3000/c_fill,h_${width},w_${width},q_${quality}/`
      : `c_fill,h_${width},w_${width},g_face,q_${quality}/`;

  return src.replace('/upload/', `/upload/${format}`);
}

export function getOptimizedZoomedInProductImageUrl({ url, type }): string {
  const format = type === 'accessory' ? 'c_fill,h_400,w_400,g_face/' : 'c_fill,w_2000,h_2000/';
  return url?.replace('/upload/', `/upload/${format}`);
}

export const getImgSourceSet = (url: string) => {
  const sizes = [200, 400, 800, 1600, 2400];
  let aSrcSet = [];
  aSrcSet = sizes.map(size => {
    const strSize = `w_${size},c_scale/`;
    const strDebug = DEBUG ? `l_text:Arial_80:${size}/` : '';
    const strQuality = 'q_auto:best/';
    const urlWithSize = url.replace('/upload/', `/upload/${strSize}${strDebug}${strQuality}`);
    return `${urlWithSize} ${size}w`;
  });
  return aSrcSet.join(', ');
};

export function getScaledSrc(src: string, { width, quality = 85 }: { width: number; quality: number }) {
  if (!src || src.length === 0) {
    return '';
  }

  const scaleAndZoom = `c_fill,h_${width},w_${width},q_${quality}/`;

  if (isUrl(src)) {
    return scaleFromUrl(src, scaleAndZoom);
  }
  return `${BASE_URL}${scaleAndZoom}${src}`;
}

export function getScaledAccessorySrc(src: string, { width, quality }: { width: number; quality: number }) {
  if (!src || src.length === 0) {
    return '';
  }

  const scaleAccessoryDown = `c_lfill,h_${width},w_${width}/`;
  const accessoryScaleAndZoom = `${scaleAccessoryDown}c_fill,h_1200,w_1200,x_100,y_80,q_${quality}/`;

  if (isUrl(src)) {
    return scaleFromUrl(src, accessoryScaleAndZoom);
  }

  return `${BASE_URL}${accessoryScaleAndZoom}${src}`;
}

export function getSkuHoverSrc(url, { width, quality = 85 }: { width: number; quality: number }) {
  return scaleFromUrl(url, `ar_0.8,g_face,c_fill,h_${width},w_${width}/q_${quality}/`);
}

const baseUrl = '//images.aceandtate.com/image/upload/';
const qualitySetting = 'q_auto:best/';

export function getWidthMaxed(publicId: string, width: number) {
  return `${baseUrl}c_scale,w_${width}/${qualitySetting}${publicId}`;
}

export function getCroppedMetaImage(publicId: string) {
  return `${baseUrl}c_crop,w_1200,h_630/${qualitySetting}${publicId}`;
}

const allowedHosts = ['images.aceandtate.com', 'images.aceandtate.show', 'ace-tate-res.cloudinary.com'];
export function cloudinaryLoader({ src, width, quality }: ImageLoaderProps): string {
  if (!allowedHosts.some(host => src.includes(host))) {
    throw Error(`Cloudinary loader only works with images from ${allowedHosts.join(' or ')}, got ${src} instead`);
  }

  const params = ['f_auto', 'c_limit', 'w_' + width, 'q_' + (quality || 'auto')];
  const paramsString = params.join(',') + '/';
  return `${src.replace(uploadString, uploadString + paramsString)}`;
}
