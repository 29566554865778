import React from 'react';
import { FormattedNumber } from 'react-intl';
import * as Sentry from '@sentry/nextjs';

import { isBold } from './utils';
import { useProduct } from 'services/productsService';
import { Product } from 'types/torii';

type Props = {
  children: React.ReactElement;
  property: keyof typeof productPropertyMapper;
  sku: string;
  quantity?: string;
};

const productPropertyMapper = {
  price(product: Product, props: Props) {
    const { quantity } = props;
    const { value, currency } = product.currentVariant.price;
    const total = quantity ? value * parseInt(quantity) : value;

    return <FormattedNumber currency={currency} minimumFractionDigits={0} style='currency' value={total} />;
  }
};

export default function ProductProperty(props: Props) {
  const { children, property, sku } = props;
  const productQuery = useProduct(sku);
  const product = productQuery.data;

  const Wrapper = isBold(children) ? 'strong' : React.Fragment;

  if (!product) {
    return null;
  }

  if (typeof productPropertyMapper[property] !== 'function') {
    Sentry.captureMessage(`No property mapper exists for ${property}`);
    return null;
  }

  try {
    const value = productPropertyMapper[property](product, props);
    return <Wrapper>{value}</Wrapper>;
  } catch (err) {
    Sentry.captureMessage(`Attempt to read property ${property} from ${sku} failed`);
    return null;
  }
}
