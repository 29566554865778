import React, { useContext } from 'react';
import useSWR, { useSWRConfig } from 'swr';
import * as Sentry from '@sentry/nextjs';
import Cookie from 'js-cookie';
import { ServicesContext } from 'services/context';
import { cartEvents } from 'tracking';
import type {
  Cart,
  Line,
  OrderData,
  OrderResponse,
  RemovedLine,
  ShippingAddress,
  Prescription,
  GiftcardLine
} from 'types/solidus';
import { customFetch, withTokens } from 'utils/helpers/customFetch';
import cartCount from 'utils/helpers/cartCount';
import NotificationsContext from 'features/Notifications/NotificationsContext';
import { FormattedMessage } from 'react-intl';
import ecommerceMessages from 'messages/ecommerce';
import stockMessages from 'messages/stock';
import { Typography, Button } from '@aceandtate/ds';

function useFetch<T = any>() {
  const context = useContext(ServicesContext);
  return async function fetch<IT = T>(path: string, options: any = {}) {
    const { locale, webStore } = context;

    const apiResponse = await customFetch<IT>(`${process.env.NEXT_PUBLIC_SOLIDUS_ENDPOINT_BASE}${path}`, {
      ...options,
      headers: withTokens(options.headers),
      params: {
        ...options.params,
        language: `${locale.lang}_${locale.country}`,
        store_id: webStore.config.storeID
      },
      body: options.form ? options.form : options.body ? JSON.stringify(options.body) : undefined // eslint-disable-line
    });

    if (apiResponse.success && (apiResponse.data as any).cart?.guest_token) {
      Cookie.set('guestToken', (apiResponse.data as any).cart?.guest_token);
      Sentry.setExtra('guestToken', (apiResponse.data as any).cart?.guest_token);
    }

    return apiResponse;
  };
}

function getLensDefault() {
  return {
    is_hto: false,
    lens_color: null,
    lens_upgrade_type: null,
    // Available types: 'multifocal', 'single_vision', 'plano' (AKA no prescription), 'no_lenses' (ordering just the frame).
    prescription_type: null,
    // UV420 means bluelight-filter. All other string values for lens_color will mean sunny. Leave empty for regular optical glasses.
    quantity: 1,
    variant_id: null
  };
}

export function convertLegacyLenses(line: Line): string | null {
  return line.premium_lenses || line.lens_index === 'index_174' ? 'premium' : null;
}

type UpdateCartLines = {
  original: Line;
  new: Line;
};

const getUpdatableProps = (line: Line, isHto: boolean) => ({
  gift_card_personalisation: (line as GiftcardLine).gift_card_personalisation || null,
  id: line.id,
  is_hto: isHto,
  lens_color: line.lens_color,
  /** @deprecated */
  lens_index: line.lens_index,
  lens_upgrade_type: line.lens_upgrade_type,
  lenses_production_type: line.lenses_production_type,
  polarised: line.polarised,
  /** @deprecated */
  premium_lenses: line.premium_lenses,
  prescription_type: line.prescription_type,
  quantity: line.quantity,
  user_entered_price: (line as GiftcardLine).user_entered_price || null,
  variant_id: line.variant_id
});

export function useFetchCart() {
  const { mutate } = useSWRConfig();

  const fetch = useFetch<{ cart: Cart }>();
  return async () => {
    const response = await fetch(cartPath);
    if (response.success) {
      mutate(cartPath, response.data);
    }
    return response;
  };
}

function getDefaultCart(): Cart {
  return {
    additional_tax_total: 0,
    adjustment_total: 0,
    bill_address: null,
    completed_at: null,
    currency: 'EUR',
    delivery_range: {
      max: null,
      min: null
    },
    delivery_window: null,
    direct_sale: false,
    email: null,
    email_hash256: null,
    frontend_status: null,
    guest_token: '',
    hto_lines: [],
    id: 0,
    included_tax_total: 0,
    insurance_adjustments: [],
    item_count: 0,
    item_total: 0,
    line_items_promotions: [],
    number: null,
    payment_method: null,
    promotion_adjustments: [],
    regular_lines: [],
    removed_lines: [],
    removed_lines_reason: null,
    return_label_available: null,
    ship_address: null,
    ship_total: 0,
    status: '',
    store_credit: 0,
    store_id: 0,
    sub_status: null,
    tax_total: 0,
    total: 0,
    track_and_trace_url: null,
    user_id: null,
    warranty_started_at: null
  };
}

export const cartPath = '/cart';

function generateCartChangeMessage(reason: string, lines: RemovedLine[]) {
  const products = lines.map(x => `${x.name}${x.color ? ' ' + x.color : ''}`).join(', ');
  const values = { products: <strong>{products}</strong> };

  if (reason === 'not_available_in_store') {
    return <FormattedMessage {...stockMessages.notAvailableInYourCountry} values={values} />;
  } else if (reason === 'out_of_stock') {
    return <FormattedMessage {...stockMessages.notAvailableNoStock} values={values} />;
  }

  return <FormattedMessage {...stockMessages.notAvailableNoReason} values={values} />;
}

export function useCartState() {
  const { cache } = useSWRConfig();
  const fallbackData = { cart: getDefaultCart() };
  const fetch = useFetch<{ cart: Cart }>();
  const notifications = useContext(NotificationsContext.Context);

  function onSuccess(res) {
    if (res.cart && res.cart.removed_lines?.length > 0) {
      notifications.addNotification({
        action: closeFn => (
          <Button onClick={closeFn}>
            <FormattedMessage {...ecommerceMessages.ok} />
          </Button>
        ),
        children: (
          <Typography>{generateCartChangeMessage(res.cart.removed_lines_reason, res.cart.removed_lines)}</Typography>
        ),
        isModal: true,
        name: 'error-notification',
        sticky: true,
        title: <FormattedMessage {...stockMessages.stockChangeTitle} />
      });
    }
  }

  return useSWR(
    cartPath,
    async () => {
      const response = await fetch(cartPath);
      if (response.success) {
        return response.data;
      } else {
        return fallbackData;
      }
    },
    { fallbackData, onSuccess, revalidateOnMount: !cache.get(cartPath) }
  );
}

export function useCartCount() {
  const cartState = useCartState();
  const { hasHTO } = useContext(ServicesContext).webStore.config;

  if (cartState.error) {
    return {
      ...cartState,
      data: 0
    };
  }

  const { cart } = cartState.data;
  const count = cartCount(hasHTO ? cart : { regular_lines: cart.regular_lines });
  return {
    ...cartState,
    data: count
  };
}

export function useCartService() {
  const { mutate } = useSWRConfig();
  const fetch = useFetch();

  return {
    async addPrescription(prescription: Prescription | { prescription_type: 'will_send_later' }) {
      return fetch(`/cart/prescriptions`, {
        body: { prescription },
        method: 'POST'
      });
    },

    async addToCart(newLines) {
      const lines = [].concat(newLines).map(line => ({
        ...getLensDefault(),
        ...line
      }));

      let response: Awaited<ReturnType<typeof fetch<{ cart: Cart }>>>;

      for (const line of lines) {
        response = await fetch(cartPath, {
          body: { lines: [line] },
          method: 'POST'
        });
      }

      if (response.success) {
        await mutate(cartPath, response.data, false);

        // map variant ids to their original quantity
        const reducer = (acc: Record<number, any>, val: Partial<Line>) => {
          acc[val.variant_id] = val.quantity;
          return acc;
        };

        const regularLineSkus = lines.filter(x => !x.is_hto).reduce(reducer, {});
        const htoLineSkus = lines.filter(x => x.is_hto).reduce(reducer, {});

        const addedRegularCartLines = response.data.cart.regular_lines
          .filter(line => regularLineSkus[line.variant.id])
          .map(line => ({ ...line, is_hto: false, quantity: regularLineSkus[line.variant.id] }));

        const addedHtoCartLines = response.data.cart.hto_lines
          .filter(line => htoLineSkus[line.variant.id])
          .map(line => ({ ...line, is_hto: true, quantity: htoLineSkus[line.variant.id] }));

        cartEvents.addToCart([...addedRegularCartLines, ...addedHtoCartLines], response.data.cart);
      }
      return response;
    },

    async applyCoupon(coupon_code: string) {
      const response = await fetch(`/cart/apply_coupon_code`, {
        body: { coupon_code },
        method: 'PUT'
      });

      if (response.success) {
        mutate(cartPath, response.data, false);
      }

      return response;
    },

    async clearHtoCart() {
      const response = fetch(`/cart/clear_hto`, {
        method: 'DELETE'
      });

      return response;
    },

    async clearRegularCart() {
      const response = fetch(`/cart/clear_regular`, {
        method: 'DELETE'
      });

      return response;
    },

    async completeOrder(data: OrderData) {
      return fetch<OrderResponse>(`/cart/complete`, {
        body: { data },
        method: 'PUT'
      });
    },

    async fetchOrder(orderNumber: string) {
      return fetch(`/cart/reference/${orderNumber}`);
    },

    async getCartReference(orderNumber: string) {
      return fetch(`/cart/reference/${orderNumber}`);
    },

    async orderIsValid(orderNumber: string) {
      return fetch(`/orders/${orderNumber}/completed`);
    },

    async removeCoupon(coupon_code: string) {
      const response = await fetch(`/cart/unapply_coupon_code`, {
        body: { coupon_code },
        method: 'PUT'
      });

      if (response.success) {
        mutate(cartPath, response.data, false);
      }

      return response;
    },

    async removeFromCart(line: Line, { isHto } = { isHto: false }) {
      const params = {
        line_item_id: line.id
      };

      const response = await fetch(`${cartPath}/remove_line_item`, {
        method: 'DELETE',
        params
      });
      if (response.success) {
        cartEvents.removeFromCart([line], response.data.cart, isHto);
        mutate(cartPath, response.data, false);
      }
      return response;
    },

    async restoreCart(orderNumber: string) {
      return fetch(`/cart/restore/${orderNumber}`);
    },

    async restoreCartFromGuestToken() {
      return fetch<{ cart: Cart }>(`/cart/restore_from_guest_token`);
    },

    async sendOrderConfirmation(orderNumber: string) {
      return fetch(`/cart/send_order_confirmation/${orderNumber}`, {
        method: 'POST'
      });
    },

    async updateCartBillingAddress(address: Partial<ShippingAddress>) {
      return fetch(`/cart/address`, {
        body: { order: { bill_address: address } },
        method: 'PUT'
      });
    },

    async updateCartCombinedAddress(
      shippingAddress: Partial<ShippingAddress>,
      billingAddress?: Partial<ShippingAddress>
    ) {
      return fetch(`/cart/address`, {
        body: { order: { bill_address: billingAddress || shippingAddress, ship_address: shippingAddress } },
        method: 'PUT'
      });
    },

    async updateCartEmail(email: string) {
      return fetch(`/cart/email`, {
        body: { order: { email } },
        method: 'PUT'
      });
    },

    async updateCartLine(props: UpdateCartLines, { isHto } = { isHto: false }) {
      const simpleProps = {
        line_item_id: props.original.id,
        new: getUpdatableProps(props.new, isHto)
      };

      const response = await fetch<{ cart: Cart }>(`${cartPath}/update_line_item`, {
        body: simpleProps,
        method: 'PUT'
      });
      if (response.success) {
        mutate(cartPath, response.data, false);
      }
      return response;
    },

    async updateCartShippingAddress(address: Partial<ShippingAddress>) {
      return fetch(`/cart/address`, {
        body: { order: { ship_address: address } },
        method: 'PUT'
      });
    },

    async updateDateOfBirth(dateOfBirth: string) {
      return fetch(`/cart/date_of_birth`, {
        body: {
          order: {
            date_of_birth: dateOfBirth
          }
        },
        method: 'PUT'
      });
    },

    async uploadPrescription(files: Array<File>) {
      const form = new FormData();
      form.append('prescription[prescription_type]', 'upload');

      files.forEach(file => {
        form.append('file[]', file);
      });

      return fetch(`/cart/prescriptions`, {
        form,
        headers: {
          'content-type': false
        },
        method: 'POST'
      });
    }
  };
}
