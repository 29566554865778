import React, { useState } from 'react';
import ConfiguratorOption from 'components/Configurators/ConfiguratorOption';
import { useIntl } from 'react-intl';
import type { LensTypeID } from '..';
import messages from '../messages';
import { ContactLensProduct } from 'types/torii';

type Lens = {
  id: LensTypeID;
  description: React.ReactNode;
  title: React.ReactNode;
  price: string;
};

type Props = {
  onChange: (type: LensTypeID) => void;
  regularProduct: ContactLensProduct;
  toricProduct?: ContactLensProduct;
};

export default function LensType(props: Props) {
  const { onChange, regularProduct, toricProduct } = props;
  const intl = useIntl();

  const lensTypes: Lens[] = [
    {
      description: intl.formatMessage(messages.regularLensDescription),
      id: 'regular',
      price: regularProduct.fromPrice.display,
      title: 'Daily Contacts'
    },
    {
      description: intl.formatMessage(messages.toricLensDescription),
      id: 'toric',
      price: toricProduct.fromPrice.display,
      title: 'Daily Toric Contacts'
    }
  ];

  const [selectedId, setSelectedId] = useState<LensTypeID | null>(null);

  function toggleElement(item: Lens) {
    setSelectedId(item.id);
    onChange(item.id);
  }

  return (
    <ul>
      {lensTypes.map(item => (
        <li key={item.id}>
          <ConfiguratorOption
            title={item.title}
            selected={selectedId === item.id}
            description={item.description}
            infoLabel={intl.formatMessage(messages.pricePerBox, { price: item.price })}
            key={item.id}
            data-testid={`${item.id}-item`}
            onClick={() => toggleElement(item)}
          />
        </li>
      ))}
    </ul>
  );
}
