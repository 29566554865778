import { CSSProperties } from 'react';
import { Typography, breakpointRules, grid, brandColors, borderRadius } from '@aceandtate/ds';
import styled from 'styled-components';
import Link from '../Link';

export const FormContainer = styled.div`
  width: 100%;
`;

export const FormRow = styled.div`
  display: flex;
  flex-flow: column;
  margin-bottom: ${grid[24]};

  > * {
    margin-bottom: ${grid[24]};
  }

  > *:last-child {
    margin-bottom: 0;
  }

  @media ${breakpointRules.tablet} {
    flex-flow: row;

    > * {
      margin-bottom: 0;
      margin-right: ${grid[40]};
      max-width: calc(50% - ${grid[24]});
      width: 100%;
    }

    > *:last-child {
      margin-right: 0;
      max-width: 100%;
    }
  }
`;

export const Visibility = styled.div<{ visible: boolean }>`
  display: ${({ visible }) => (visible ? 'block' : 'none')};
`;

export const LoginLinkBox = styled.div`
  padding: ${grid[24]};
  background-color: ${brandColors.sandLight};
  border-radius: ${borderRadius.small};
`;

export const FormColumn = styled.div`
  display: flex;
  flex-flow: column;
  width: 100%;
  margin-top: ${grid[12]};

  @media ${breakpointRules.tablet} {
    margin-top: ${grid[24]};
  }
`;

export const ButtonRow = styled.div<Pick<CSSProperties, 'alignItems' | 'gap' | 'flexGrow'>>`
  display: flex;
  align-items: ${({ alignItems }) => alignItems || 'stretch'};
  flex-flow: column;
  gap: ${({ gap }) => gap || grid[24]};

  @media ${breakpointRules.tablet} {
    flex-flow: row wrap;
    align-items: ${({ alignItems }) => alignItems || 'center'};
    justify-content: space-between;

    & > * {
      flex-grow: ${({ flexGrow }) => flexGrow || 1};
    }
  }

  @media ${breakpointRules.laptop} {
    flex-flow: column;
  }
`;

export const NotificationButtonRow = styled(ButtonRow)`
  @media ${breakpointRules.laptop} {
    flex-flow: row;
  }
`;

export const Checkboxes = styled.div`
  display: flex;
  flex-flow: column;
  gap: ${grid[16]};
  margin-bottom: ${grid[24]};
  text-align: left;
`;

export const InlineLinkButton = styled.button`
  background: none;
  border: none;
  padding: 0;
  text-decoration: underline;
  font-weight: inherit;
  cursor: pointer;

  &:hover {
    text-decoration: none;
  }
`;

export const DescriptionWrapper = styled.div`
  display: none;
  max-width: 390px;

  @media ${breakpointRules.tablet} {
    display: block;
    max-width: 766px;
  }
`;

export const LinkContainer = styled.div`
  align-items: center;
  display: flex;
  flex-flow: column;
  margin-right: auto;
  margin-top: ${grid[24]};
  text-align: left;

  span {
    cursor: pointer;
    text-decoration: underline;

    :hover {
      opacity: 0.75;
    }
  }

  @media ${breakpointRules.tablet} {
    align-items: flex-start;
  }
`;

export const ProfileContent = styled.div`
  @media ${breakpointRules.tablet} {
    margin-top: ${grid[24]};
  }
`;

export const ProfileItem = styled.div`
  margin-bottom: ${grid[24]};
`;

export const MobileTitle = styled(Typography).attrs({ variant: 'bodyM' })`
  margin-bottom: ${grid[40]};
  text-align: center;

  @media ${breakpointRules.tablet} {
    display: none;
  }
`;

export const UnderlinedLink = styled(Link)`
  cursor: pointer;
  text-decoration: underline;
`;
