import React, { useState, ReactElement } from 'react';
import { FormattedMessage } from 'react-intl';
import { Button, IconBack } from '@aceandtate/ds';
import ecommerceMessages from 'messages/ecommerce';
import { useUserState } from 'services/userService';
import * as Styles from './styles';
import EmailForm from './components/EmailForm';
import LoginForm from './components/LoginForm';
import RegisterForm from './components/RegisterForm';
import EasyLoginSent from './components/EasyLoginSent';

export type AuthenticationFormV2State = 'register' | 'login' | 'email' | 'easy_login_sent' | 'success';

export type Props = {
  authenticationType: AuthenticationFormV2State;
  disableEasyLogin?: boolean;
  disableNotificationSuccess?: boolean;
  introCopy?: JSX.Element;
  onFormChange?: React.Dispatch<React.SetStateAction<AuthenticationFormV2State>>;
  requireTos?: boolean;
  redirectUrl?: string;
};

type FormData = {
  email?: string;
  loginPassword?: string;
  newPassword?: string;
  confirmPassword?: string;
  termsCheckbox?: boolean;
  newPhone?: {
    countryCode: string;
    number: string;
  };
};

export default function AuthenticationFormV2({
  authenticationType,
  onFormChange,
  introCopy = null,
  disableEasyLogin
}: Props): ReactElement {
  const { isUserLoggedIn } = useUserState({
    revalidateOnFocus: false
  });
  const [formData, setFormData] = useState<FormData>(null);
  const [formState, setFormState] = useState(authenticationType);

  return (
    <Styles.FormContainer>
      <Styles.FormColumn>
        {formState === 'easy_login_sent' && <EasyLoginSent email={formData.email} changeFormState={setFormState} />}
        {formState === 'email' && (
          <EmailForm
            introCopy={introCopy}
            onSubmit={(result, email) => {
              setFormData({ email });
              if (result === 'emailExists') {
                setFormState('login');
                onFormChange('login');
              } else {
                setFormState('register');
                onFormChange('register');
              }
            }}
          />
        )}

        {(formState === 'login' || formState === 'register') && (
          <div data-cs-capture style={{ marginBottom: '12px' }} className='back-button-auth-form'>
            <Button variant='link' type='button' onClick={() => setFormState('email')}>
              <IconBack fontSize={20} /> <FormattedMessage {...ecommerceMessages.back} />
            </Button>
          </div>
        )}

        {formState === 'login' && !isUserLoggedIn && (
          <LoginForm
            email={formData.email}
            disableEasyLogin={disableEasyLogin}
            onSuccess={status => {
              if (status === 'easy_login_sent') {
                setFormState('easy_login_sent');
              } else {
                setFormState('success');
                onFormChange('success');
              }
            }}
          />
        )}
        {formState === 'register' && (
          <RegisterForm
            onSuccess={() => {
              setFormState('success');
              onFormChange('success');
            }}
            email={formData.email}
          />
        )}
      </Styles.FormColumn>
    </Styles.FormContainer>
  );
}
