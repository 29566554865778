import { defineMessages } from 'react-intl';

export default defineMessages({
  color: {
    defaultMessage: 'color',
    id: 'productStock.color'
  },
  comingSoon: {
    defaultMessage: 'Coming soon!',
    id: 'productStock.comingSoon'
  },
  lowStock: {
    defaultMessage: 'this {type} is low on stock',
    id: 'productStock.lowStock'
  },
  notInStock: {
    defaultMessage: 'this {type} is not in stock',
    id: 'productStock.notInStock'
  },
  product: {
    defaultMessage: 'product',
    id: 'productStock.product'
  },
  soldout: {
    defaultMessage: 'this {type} is sold out',
    id: 'productStock.soldout'
  }
});
