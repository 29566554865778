import * as Sentry from '@sentry/nextjs';
import React from 'react';
import styled from 'styled-components';

const ErrorBox = styled.div`
  max-width: 680px;
  padding: 1.2rem;
  display: flex;
  flex-flow: column;
  align-items: center;
  a {
    text-decoration: underline;
  }
`;

const ErrorContainer = styled.div`
  z-index: 10000;
  display: grid;
  height: 100vh;
  left: 0;
  place-items: center;
  position: absolute;
  top: 0;
  width: 100vw;
  background: #eee;

  h2 {
    font-weight: 500;
  }
`;

class ErrorBoundary extends React.Component<any, any> {
  constructor(props) {
    super(props);
    this.state = { error: null, hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { error, hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    if (process.env.NODE_ENV !== 'development') {
      Sentry.withScope(scope => {
        if (errorInfo) {
          Object.keys(errorInfo).forEach(key => {
            scope.setExtra(key, errorInfo[key]);
          });
        }
        Sentry.captureException(`Error caught by ErrorBoundary: ${error?.toString()}`);
      });
    } else {
      console.error(error); // eslint-disable-line
    }
  }

  render() {
    if (this.state.hasError) {
      if (process.env.NODE_ENV === 'development') {
        return null;
      }

      const mailtoLink = `mailto:hallo@aceandtate.nl?subject=Client-side%20exception&body=Hello%20Ace%20%26%20Tate%2C%0D%0A%0D%0AI%20did%20this%3A%20...%0D%0A%0D%0AOn%20this%20page%3A%20${
        window.location.href
      }%0D%0AAnd%20saw%20an%20error%20page%20with%20last%20event%20ID%3A%20${this.state.error?.toString()}`;
      return (
        <ErrorContainer>
          <ErrorBox>
            <img src='/static/generic/crying_eye.svg' />
            <h2>Looks like we broke something...</h2>
            <p>
              Application error: a client-side exception has occurred. Please try again later. Send a screenshot of this
              page to{' '}
              <a href={mailtoLink} rel='noopener noreferrer'>
                customer support
              </a>{' '}
              if this keeps happening.
            </p>
            <p>Error: {this.state.error?.toString()}</p>
            <p>URL: {window.location.href}</p>
          </ErrorBox>
        </ErrorContainer>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
