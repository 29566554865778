import { ProductType, Variant } from 'types/torii';
import { isProductVariant } from 'utils/types';
import getToriiProductType from 'utils/helpers/getToriiProductType';
import { defineMessages, IntlShape } from 'react-intl';
import { materialMessages, shapeMessages } from 'messages/pcpFilters';
import frameFilterMessages from 'messages/filters';

const messages = defineMessages({
  frameImageAltMessage: {
    defaultMessage: 'Ace & Tate {productCategory} | {shape} {material} in {color}',
    id: 'ProductImages.frameImageAltMessage'
  },
  imageTitleMessage: {
    defaultMessage: 'Ace & Tate {name} in {color} ',
    id: 'ProductImages.imageTitleMessage'
  }
});

const getImageAltForCliponProduct = ({
  variant,
  productType,
  intl
}: {
  variant: Variant;
  productType: ProductType;
  intl: IntlShape;
}) => {
  return `Ace & Tate ${getToriiProductType({ intl, productType, variant })}`;
};

export const getImageAltForProduct = ({
  variant,
  intl,
  productType
}: {
  variant: Variant;
  intl: IntlShape;
  productType: ProductType;
}) => {
  if (!variant) return 'no product';

  if (productType === 'clip_on') {
    return getImageAltForCliponProduct({ intl, productType, variant });
  }

  if (!isProductVariant(variant)) {
    return (variant.name && `Ace & Tate ${getToriiProductType({ intl, productType, variant })} ${variant.name}`) || '';
  }

  const material = materialMessages[variant.properties.frameMaterials[0]]
    ? intl.formatMessage(materialMessages[variant.properties.frameMaterials[0]])
    : '';

  const shape = shapeMessages[variant.filterOptions.shapes[0]]
    ? intl.formatMessage(shapeMessages[variant.filterOptions.shapes[0]])
    : '';

  const { colors } = variant.filterOptions;
  const translatedColors = colors.map(productColor =>
    frameFilterMessages[productColor] ? intl.formatMessage(frameFilterMessages[productColor]) : productColor
  );

  const productCategory = getToriiProductType({ intl, productType, variant });

  return intl.formatMessage(messages.frameImageAltMessage, {
    color: translatedColors.join(', '),
    material,
    productCategory,
    shape
  });
};

export const getImageTitleForProduct = (product: Variant, intl: IntlShape) => {
  if (!product) return 'no product';
  if (!isProductVariant(product)) return (product.name && `Ace & Tate ${product.name}`) || '';

  const { color } = product.displayAttributes;

  return intl.formatMessage(messages.imageTitleMessage, {
    color,
    name: product.name
  });
};
