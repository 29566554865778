import styled from 'styled-components';
import { grid } from '@aceandtate/ds';

export const MainContentWrapper = styled.div`
  display: flex;
  flex: 2;
  flex-direction: column;
`;

export const ContentSectionWrapper = styled.div`
  margin-bottom: ${grid['16']};
`;
