import { uiColors, grid as dsGrid, borderRadius, breakpointRules } from '@aceandtate/ds';
import styled from 'styled-components';
import { grid } from 'styles';

type InputWrapperProps = {
  maxWidth: number;
};

export const InputWrapper = styled.div`
  flex: 1;
  max-width: ${(props: InputWrapperProps) => (props.maxWidth ? `${props.maxWidth}px` : 'unset')};

  .ais-SearchBox {
    font-size: 32px;
    font-weight: 400;
    flex: 1;
    position: relative;

    & > form {
      display: flex;
      flex-flow: row-reverse;
      position: relative;
      align-items: center;
    }

    .ais-SearchBox-input {
      border: none;
      margin-bottom: ${grid.s};
      outline: none;
      width: 100%;

      ::placeholder {
        color: ${uiColors.light};
      }

      &::-webkit-search-cancel-button {
        appearance: none;
      }

      @media ${breakpointRules.laptop} {
        height: calc(${grid.xxl} / 2);
      }
    }

    .ais-SearchBox-submit {
      background: none;
      border: none;
      bottom: 0;
      cursor: pointer;
      padding: 0;
      margin-right: var(--grid-xxs);
      color: ${uiColors.dark};

      @media ${breakpointRules.laptop} {
        margin-right: var(--grid-s);
        bottom: unset;
        top: ${grid.s};
      }

      svg {
        height: 2rem;
        width: 2rem;
      }
    }

    .ais-SearchBox-reset {
      right: 0;
      position: absolute;
      cursor: pointer;
      height: ${dsGrid[24]};
      width: ${dsGrid[24]};
      justify-content: center;
      align-items: center;
      padding: 0;
      border-radius: ${borderRadius.full};
      border: none;
      background-color: transparent;

      :not([hidden]) {
        display: flex;
      }

      svg {
        width: ${dsGrid[12]};
      }

      @media (pointer: coarse) {
        height: ${dsGrid[40]};
        width: ${dsGrid[40]};
      }
      @media ${breakpointRules.laptop} {
        background-color: ${uiColors.light};
      }
    }
  }
`;

export const StyledSearchBox = styled.div`
  display: flex;
  align-items: center;
  flex-flow: row;
  gap: ${grid.s};
  border-bottom: solid 2px #000000;
`;
