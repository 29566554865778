import { borderRadius, brandColors, Button, Flex, grid, Typography, uiColors } from '@aceandtate/ds';
import styled from 'styled-components';

export const SubmitButton = styled(Button)`
  margin-top: ${grid[24]};
`;

export const BalanceErrorBox = styled(Typography)`
  background-color: ${uiColors.errorLight};
  border-radius: ${borderRadius.small};
  color: ${uiColors.error};
  padding: ${grid[8]} ${grid[12]};
`;

export const BalanceDisplayBox = styled(Flex)`
  background-color: ${brandColors.light};
  border: 1px solid ${brandColors.dark30};
  border-radius: ${borderRadius.small};
  padding: ${grid[16]} ${grid[32]};
`;

export const FormContainer = styled(Flex)`
  max-width: 572px;
  width: 100%;
`;
