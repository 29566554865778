import React, { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { trackArtificialPageView, trackPreviousArtificialPageView } from 'tracking/helpers';
import { configuratorError } from 'tracking/events/contactLenses';
import { Modal, Typography, Button } from '@aceandtate/ds';
import ecommerceMessages from 'messages/ecommerce';
import globalErrorMessages from 'messages/globalErrors';
import messages from '../messages';
import { ErrorState, LensConfiguration } from '..';

type Props = {
  onClose: () => void;
  error: ErrorState;
  lensConfiguration: LensConfiguration;
  open: boolean;
};

export default function IncompatibleConfigModal(props: Props) {
  const { onClose, error, open, lensConfiguration } = props;

  useEffect(() => {
    if (open) {
      trackArtificialPageView(`contactLensConfigurator-error-${error}`);
      configuratorError(`contactLensConfigurator-error-${error}`, lensConfiguration);
    } else {
      trackPreviousArtificialPageView();
    }
  }, [open]);

  return (
    <Modal
      open={open}
      onClose={onClose}
      content={
        <>
          <Typography variant='h5' gutterBottom='12'>
            {error === 'OOS' && <FormattedMessage {...messages.oosTitle} />}
            {error === 'productNotFound' && <FormattedMessage {...messages.productNotFoundTitle} />}
            {(error === 'quantityRequired' || error === 'invalidQuantity') && (
              <FormattedMessage {...globalErrorMessages.oops} />
            )}
          </Typography>
          <Typography variant='bodyM' gutterBottom='24'>
            {error === 'quantityRequired' && <FormattedMessage {...messages.missingInputError} />}
            {error === 'invalidQuantity' && <FormattedMessage {...messages.missingInputErrorNoQuantity} />}
            {error === 'OOS' && <FormattedMessage {...messages.oos} />}
            {error === 'productNotFound' && <FormattedMessage {...messages.productNotFound} />}
          </Typography>
          <Button data-testid='incompatible-configuration-modal-cta' onClick={onClose}>
            <FormattedMessage {...ecommerceMessages.ok} />
          </Button>
        </>
      }
    />
  );
}
