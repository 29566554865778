export const SWRCacheKeys = {
  eyeTestBooking: {
    availableDays: 'eye-test-available-days',
    forms: 'eye-test-forms'
  },
  recommendedClipons: 'recommended-clipons',
  recommendedProducts: 'recommended-products',
  user: '/me',
  userAddress: '/me/address',
  wishlistProducts: 'wishlist-products'
};
