import messages from './messages';
import { materialMessages, shapeMessages, colorMessages } from './partials/PCPFilters/messages';
import * as Types from './types';

export const getThumbSrc = id => `//images.aceandtate.com/image/upload/ar_0.8,c_crop/c_scale,w_100/${id}`;

export const getImageSrc = id => `//images.aceandtate.com/image/upload/c_scale,w_1200/${id}`;

export const getImageAltForProduct = (
  intl,
  { productName, productShape, productMaterial, productColor }: Types.GetImageAltProps
) => {
  if (!productShape) return 'no product';

  const shape = shapeMessages[productShape] ? intl.formatMessage(shapeMessages[productShape]) : productShape;
  const material = materialMessages[productMaterial]
    ? intl.formatMessage(materialMessages[productMaterial])
    : productMaterial;
  const color = colorMessages[productColor] ? intl.formatMessage(colorMessages[productColor]) : productColor;

  return intl.formatMessage(messages.imageAltMessage, {
    color,
    material,
    name: productName,
    shape
  });
};

export const getImageTitleForProduct = (intl, productColor: string, productName: string) => {
  if (!productColor) return 'no product';

  const color = colorMessages[productColor] ? intl.formatMessage(colorMessages[productColor]) : productColor;

  return intl.formatMessage(messages.imageTitleMessage, {
    color,
    name: productName
  });
};
