import React from 'react';
import * as Styles from './styles';

type Props = {
  limit: number;
  count: number;
};

export default function CharacterCounter({ count, limit }: Props) {
  const currentCount = count;

  function getCounterState() {
    const fraction = currentCount / limit;
    if (fraction > 1) {
      return 'error';
    } else if (fraction > 0.7) {
      return 'warn';
    } else {
      return 'valid';
    }
  }
  const state = getCounterState();

  return (
    <Styles.CharacterCountWrapper forwardedAs='span' variant='bodyS' state={state}>
      {currentCount}/{limit}
    </Styles.CharacterCountWrapper>
  );
}
