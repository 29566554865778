let globalKey = '6LckN3kkAAAAAA-0CwZZxrvdE5wwXzthUO6EuQVy';

const setKey = (key: string): void => {
  globalKey = key;
};

const ready = (): Promise<void> =>
  new Promise((resolve, reject) => {
    if (!('grecaptcha' in window)) {
      reject(new Error('ReCaptcha V3 is not loaded on the webpage'));
      return;
    }

    window.grecaptcha.ready(() => {
      resolve();
    });
  });

const execute = (action: string): Promise<string> =>
  new Promise((resolve, reject) => {
    if (globalKey === null || globalKey === undefined) {
      reject(new Error('Global Key for RecaptchaV3 must be set by calling setKey function'));
      return;
    }

    window.grecaptcha
      .execute(globalKey, { action })
      .then((token: string) => {
        try {
          resolve(token);
        } catch (err) {
          reject(err);
        }
      })
      .catch(err => reject(err));
  });

const load = () =>
  new Promise(function (resolve, reject) {
    const s = document.createElement('script') as any;
    let r = false;
    s.type = 'text/javascript';
    s.src = `https://www.google.com/recaptcha/api.js?render=${globalKey}`;
    s.async = true;
    s.onerror = function (err) {
      reject(err);
    };
    s.onload = s.onreadystatechange = function () {
      if (!r && (!this.readyState || this.readyState === 'complete')) {
        r = true;
        resolve(s);
      }
    };
    const t = document.getElementsByTagName('script')[0];
    t.parentElement.insertBefore(s, t);
  });

export { ready, execute, setKey, load };
