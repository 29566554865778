import React from 'react';
import { Typography } from '@aceandtate/ds';
import { getWhatsAppLink } from 'utils/contact';
import { footerEvents } from 'tracking';
import * as Styles from '../styles';

type Props = {
  whatsapp: string;
};

export default function FooterWhatsappInfo(props: Props) {
  const { whatsapp } = props;

  return (
    <Typography variant='bodyM' fontWeight='regular'>
      <a
        href={getWhatsAppLink(whatsapp)}
        target='blank'
        rel='noopener noreferrer'
        onClick={() => {
          footerEvents.getInTouch('Whatsapp');
        }}
      >
        <Styles.WhatsappIconStyled />
        {whatsapp}
      </a>
    </Typography>
  );
}
