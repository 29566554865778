import React from 'react';
import * as Styles from '../styles';

export type ProgressIndicatorProps = {
  stepIndex: number;
  totalSteps: number;
  height?: '5px' | '8px';
};

export default function ProgressIndicator({ stepIndex, totalSteps, height = '5px' }: ProgressIndicatorProps) {
  return (
    <Styles.ProgressIndicatorShell height={height}>
      <Styles.ProgressIndicatorBar width={`${((stepIndex / (totalSteps - 1)) * 100) / 100}`} />
    </Styles.ProgressIndicatorShell>
  );
}
