import React, { useState } from 'react';
import PdPageContext from './PdToolContext';

const parsePd = (pd: number): { left: string; right: string } => {
  if (pd % 2 === 0) {
    return {
      left: `${Math.floor(pd / 2)}`,
      right: `${Math.floor(pd / 2)}`
    };
  } else if (pd % 2 === 1) {
    return {
      left: `${parseFloat((pd / 2).toFixed(1))}`,
      right: `${parseFloat((pd / 2).toFixed(1))}`
    };
  }
  return {
    left: `${Math.ceil(pd / 2)}`,
    right: `${Math.floor(pd / 2) + 0.5}`
  };
};

const PdPageProvider = (props: { children: React.ReactNode }) => {
  const [orderNumber, setOrderNumber] = useState('');
  const [pd, setPd] = useState({
    left: null,
    right: null
  });

  function updatePd(pd: string) {
    const parsedPd = parseFloat(pd) % 2 === 1.5 ? parseFloat(pd) : parseInt(pd, 10);
    const newPd = parsePd(parsedPd);
    setPd(newPd);
  }

  return <PdPageContext.Provider
    value={{
      orderNumber,
      pd,
      setOrderNumber,
      updatePd
    }}>
    {props.children}
  </PdPageContext.Provider>;
};

export default PdPageProvider;
