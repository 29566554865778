import React, { ReactElement, useEffect, useState } from 'react';
import { Experience } from '@ninetailed/experience.js-next';
import Manager from './Manager';
import { useExperienceConfiguration } from './useExperienceConfiguration';
import { SupportedComponentProps } from 'types/ninetailed';
import { useRouter } from 'next/router';

type Props = {
  id: string;
  name: string;
  variants: ReactElement[];
  passthroughProps?: Partial<SupportedComponentProps>;
  /** for internal reference only. useful to keep track, when multiple instances of `NinetailedExperience` are part of the same experience */
  instance?: number | string;
  // loadingComponent?:  TODO support loadingComponent
};

const NinetailedExperience = ({ name, id, variants, passthroughProps }: Props) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_control, ...variantsList] = variants;
  const baseline = {
    id: `${name}-control`,
    variantType: '0'
  };

  // support turning off ninetailed for playwright tests
  const router = useRouter();
  const { ninetailed } = router.query;
  const [turnExperiencesOff, setTurnExperiencesOff] = useState(false);

  useEffect(() => {
    setTurnExperiencesOff(ninetailed === '0');
  }, [ninetailed]);

  const config = useExperienceConfiguration({
    baseline,
    experiencesVariants:
      !turnExperiencesOff && variantsList
        ? [
            {
              experienceId: id, // matches contentful experience entry id
              variants: variantsList.map((_v, index) => {
                return {
                  hidden: false,
                  id: `${name}-variant-${index + 1}`,
                  variantType: `${index + 1}`
                };
              })
            }
          ]
        : []
  });

  return (
    <Experience
      id={baseline.id}
      experiences={config.experiences}
      component={Manager}
      variantType={baseline.variantType}
      passthroughProps={{ ...passthroughProps, variants }}
    />
  );
};

export default NinetailedExperience;
