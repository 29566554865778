import * as Sentry from '@sentry/nextjs';

type StorageType = 'local' | 'session';

export function saveToStorage(key: string, val: string, type: StorageType = 'local'): void {
  if (typeof window === 'undefined') return;

  try {
    const storage = type === 'local' ? window.localStorage : window.sessionStorage;
    if (storage && val) {
      storage.setItem(key, JSON.stringify(val));
    }
  } catch (err) {
    Sentry.captureException(err, {
      extra: { args: { key, type }, message: `Error while trying to save to storage` }
    });
    console.log(err); // eslint-disable-line
  }
}

export function getFromStorage<ReturnType = null>(key: string): ReturnType | null {
  if (typeof window === 'undefined') return null;
  let result = null;

  try {
    result = window.sessionStorage.getItem(key);
    if (!result) {
      result = window.localStorage.getItem(key);
    }
    return result && JSON.parse(result);
  } catch (err) {
    Sentry.captureException(err, {
      extra: { args: { key }, message: `Error while trying to read from storage` }
    });
    console.log(err); // eslint-disable-line
    return null;
  }
}

export function removeFromStorage(key: string): void {
  if (typeof window === 'undefined') return null;

  try {
    window.sessionStorage.removeItem(key);
    window.localStorage.removeItem(key);
  } catch (err) {
    Sentry.captureException(err, {
      extra: { args: { key }, message: `Error while trying to remove from storage` }
    });
    console.log(err); // eslint-disable-line
  }
}
