import React, { useEffect, useState, useContext } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { filterMessages } from 'messages/pcpFilters';
import productTypeMessages from 'messages/productType';
import { ServicesContext } from 'services/context';
import { getAllFramesExploded } from 'services/productsService';
import {
  StyledTable,
  StyledRow,
  StyledHeadingRow,
  StyledColumn,
  StyledInputContainer,
  StyledLoaderIconContainer
} from './styles';
import { FixedSizeList as List } from 'react-window';
import AutoSizer from 'react-virtualized-auto-sizer';
import { TextInput } from '@aceandtate/ds';
import messages from './messages';
import LoaderIcon from 'components/Loaders/LoaderIcon';

export default function ConformityTable() {
  const ctx = useContext(ServicesContext);
  const intl = useIntl();
  const [isLoading, setIsLoading] = useState(true);
  const [frames, setFrames] = useState([]);
  const [visibleFrames, setVisibleFrames] = useState([]);

  async function loadFrames() {
    const res = await getAllFramesExploded(ctx.locale);
    res.sort((a, b) => a.currentVariant.name.localeCompare(b.currentVariant.name));
    setFrames(res);
    setVisibleFrames(res);
    setIsLoading(false);
  }

  useEffect(() => {
    if (!ctx?.locale) return;

    loadFrames();
  }, [ctx?.locale]);

  function filterFrames(e: React.FormEvent<HTMLInputElement>) {
    const search = e.currentTarget.value.toLowerCase();
    setVisibleFrames(
      frames.filter(
        x =>
          x.currentVariant.name.toLowerCase().includes(search) ||
          x.currentVariant.displayAttributes.color.toLowerCase().includes(search)
      )
    );
  }

  const Row = ({ index, style }) => {
    if (!visibleFrames[index]) return null;

    return (
      <StyledRow style={style} even={index % 2 === 0}>
        <StyledColumn> {visibleFrames[index].currentVariant.name}</StyledColumn>
        <StyledColumn> {visibleFrames[index].currentVariant.displayAttributes.color}</StyledColumn>
      </StyledRow>
    );
  };

  return (
    <div>
      <StyledInputContainer>
        <TextInput placeholder={intl.formatMessage(messages.searchPlaceholder)} onChange={filterFrames} fullWidth />
      </StyledInputContainer>
      <StyledHeadingRow>
        <StyledColumn>
          <FormattedMessage {...productTypeMessages.frame} />
        </StyledColumn>
        <StyledColumn>
          <FormattedMessage {...filterMessages.simple_color} />
        </StyledColumn>
      </StyledHeadingRow>
      <StyledTable>
        {isLoading && (
          <StyledLoaderIconContainer>
            <LoaderIcon />
          </StyledLoaderIconContainer>
        )}
        {!isLoading && (
          <AutoSizer>
            {({ height, width }) => (
              <List height={height} itemCount={visibleFrames.length - 1} itemSize={45} width={width}>
                {Row}
              </List>
            )}
          </AutoSizer>
        )}
      </StyledTable>
    </div>
  );
}
