import React from 'react';
import algoliasearch from 'algoliasearch/lite';
import { InstantSearch, Configure } from 'react-instantsearch-dom';

import * as Styles from './styles';
import { SearchInput, Results } from './partials';

const searchClient = algoliasearch(
  process.env.NEXT_PUBLIC_ALGOLIA_APP_ID,
  process.env.NEXT_PUBLIC_ALGOLIA_FRONTEND_API_KEY
);

const isProduction = process.env.NEXT_PUBLIC_IS_PRODUCTION;
const indexName = isProduction ? 'variants_prod' : 'variants_show';

type Props = {
  asPage?: boolean;
  closeHandler?: () => void;
};

export default function Search(props: Props) {
  const { asPage = false, closeHandler = () => null } = props;

  const variantHitsPerPage = asPage ? 20 : 4;

  return (
    <Styles.GlobalSearchWrapper data-testid='search.globalWrapper'>
      <InstantSearch searchClient={searchClient} indexName={indexName}>
        <SearchInput maxWidth={asPage ? 1085 : null} stats={asPage} closeHandler={closeHandler} />
        {/* __source is needed here to not break search, as a debugging plugging
        of nextjs is including this props on every component it mounts */}
        <Configure hitsPerPage={variantHitsPerPage} />
        {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
        {/* @ts-ignore */}
        <Results asPage={asPage} closeHandler={closeHandler} />
      </InstantSearch>
    </Styles.GlobalSearchWrapper>
  );
}
