import React from 'react';
import Link from 'next/link';
import * as Styles from './styles';

type Props = {
  icon: React.ReactNode;
  label: string;
  active?: boolean;
  count?: number;
  href?: string;
  mobile?: boolean;
  isOpen?: boolean;
  isHidden?: boolean;
  onClick?: () => void;
};

export default function MenuNavIcon(props: Props) {
  const { active, count, href, icon, isOpen, label, mobile, isHidden, onClick } = props;
  const hasItemsInBasket = typeof count === 'number' && count !== 0;
  const mobileAttributeValue = mobile ? 'true' : 'false';

  const PlainIcon = () => (
    <Styles.NavigationIcon
      name={label}
      active={active ? active.toString() : undefined}
      $isOpen={isOpen}
      hidden={isHidden}
      onClick={onClick}
      data-testid={`menuNav.icon.${label}`}
    >
      {icon}
    </Styles.NavigationIcon>
  );

  const NavigationIcon = () => {
    if (hasItemsInBasket) {
      return (
        <Styles.NumberContainer hidden={isHidden} active={active ? active.toString() : undefined} $isOpen={isOpen}>
          <Styles.Number count={count} variant='bodyXXS' data-testid='menuNav.cartCount'>
            {count}
          </Styles.Number>
          <PlainIcon />
        </Styles.NumberContainer>
      );
    } else {
      return <PlainIcon />;
    }
  };

  return (
    <Styles.MenuNavIconWrapper mobile={mobileAttributeValue}>
      {href ? (
        <Link
          href={href}
          prefetch={false}
          role='button'
          tabIndex={0}
          aria-label={label}
          data-testid={`menuNav.linkIcon.${label}`}
        >
          <NavigationIcon />
        </Link>
      ) : (
        <NavigationIcon />
      )}
    </Styles.MenuNavIconWrapper>
  );
}
