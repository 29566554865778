export type TransformOptions = {
  /** Affects interpretation of width and height. All resizing modes preserve aspect ratio. */
  fit?: 'scale-down' | 'contain' | 'cover' | 'crop';

  /** Allows serving of the WebP format to browsers that support it. If this option is not
   * specified, a standard format like JPEG or PNG will be used. */
  format?: 'auto';

  /** See https://developers.cloudflare.com/images/about/ for details */
  gravity?: string;

  /** Specifies maximum height of the image in pixels. Exact behavior depends on the fit mode. */
  height?: number;

  /** See https://developers.cloudflare.com/images/about/ for details */
  onerror?: 'redirect';

  /** Specifies quality for images in JPEG and WebP format. The quality is in 1-100 scale,
   * but useful values are between 50 (low quality, small file size) and 90 (high quality,
   * large file size). 85 is the default.  */
  quality?: number;

  /** Specifies maximum width of the image in pixels. Exact behavior depends on the fit mode. */
  width?: number;
};

// Note that slashes should be added to the end of the hosts
const hosts = Object.entries({
  '//ace-tate-res.cloudinary.com/': '//images.aceandtate.com/',
  '//images.aceandtate.com/': '//images.aceandtate.com/',
  '//images.aceandtate.rocks/': '//images.aceandtate.rocks/',
  '//images.aceandtate.show/': '//images.aceandtate.show/',
  '//images.ctfassets.net/': '//ctfassets.aceandtate.com/',
  '//res.cloudinary.com/ace-tate/': '//images.aceandtate.com/',
  '//videos.ctfassets.net/': '//videos.aceandtate.com/'
});

function flattenOptions(options: TransformOptions) {
  return Object.entries(options)
    .map(keyVal => keyVal.join('='))
    .join(',');
}

export function transformHostOnly(url: string) {
  for (let i = 0; i < hosts.length; i++) {
    const [host, target] = hosts[i];
    if (url.includes(host)) {
      return url.replace(host, target);
    }
  }

  return url;
}

export function transform<T>(url: T, options: TransformOptions = {}): T | string {
  if (typeof url === 'string') {
    const index = hosts.findIndex(host => url.includes(host[0]));

    if (index >= 0) {
      const [host, target] = hosts[index];

      return url.replace(host, `${target}cdn-cgi/image/${flattenOptions(options)}/`);
    }
  }
  // If none matched, let's return the original URL
  return url;
}

export function transformCfAsset(url: string, options?: TransformOptions) {
  return transform(url, options);
}

export function transformCfVideo(url: string) {
  if (typeof url === 'string') {
    const index = hosts.findIndex(host => url.includes(host[0]));

    if (index >= 0) {
      const [host, target] = hosts[index];

      return url.replace(host, target);
    }
  }

  return url;
}

export function compressCloudinaryVideo(url: string, codec: string) {
  if (!url || typeof url !== 'string') return null;

  return url.replace('f_auto/q_auto', '').replace('/upload/', `/upload/${codec}/q_auto:good,c_limit`) + '#t=0.5';
}
