import React, { useContext, useState, useEffect, useMemo } from 'react';
import { ContactLensProduct, Variant } from 'types/torii';
import { WebStore } from 'types/webStore';
import { Summary, BoxSize, Prescription, ErrorModal, LensType, Promotion } from './partials';

import Configurator from 'components/Configurators/Configurator';
import LoaderIcon from 'components/Loaders/LoaderIcon';
import { trackArtificialPageView } from 'tracking/helpers';
import Upsell from './partials/Upsell';
import messages from './messages';
import { FormattedMessage } from 'react-intl';
import { useCartService } from 'services/cartService';
import { ServicesContext } from 'services/context';
import { useGetUpsellProducts } from 'services/productsService';
import { paths } from 'paths';
import NotificationsContext from 'features/Notifications/NotificationsContext';
import globalErrorMessages from 'messages/globalErrors';
import * as Sentry from '@sentry/nextjs';
import { PromotionRule } from 'types/solidus';
import * as Styles from './styles';
import { useRouter } from 'next/router';

export type LensTypeID = 'regular' | 'toric';

type Props = {
  lensType?: LensTypeID;
  isDrawerOpen: boolean;
  regularProduct: ContactLensProduct;
  toricProduct?: ContactLensProduct;
  toggleDrawer: React.Dispatch<React.SetStateAction<boolean>>;
  webStore?: WebStore;
  loading?: boolean;
  promotions?: PromotionRule[];
};

export type ErrorState = 'invalidQuantity' | 'quantityRequired' | 'OOS' | 'productNotFound';

// Object keys are required to match all step ids that are defined as const below
const pageViewLabels = {
  regular: {
    BOX_SIZE: 'contactLensBoxSize',
    LENS_TYPE: 'contactLensType',
    PRESCRIPTION: 'contactLensPrescription',
    UPSELL: 'contactLensUpsell'
  },
  trial: {
    BOX_SIZE: 'trialContactLensBoxSize',
    LENS_TYPE: 'trialContactLensType',
    PRESCRIPTION: 'trialContactLensPrescription',
    UPSELL: 'trialContactLensUpsell'
  }
};

// not all steps are in use yet, this config is mainly to future
// proof the configurator to also support box size select etc.
const selectBoxSize = {
  id: 'BOX_SIZE',
  title: <FormattedMessage {...messages.selectBoxSize} />
} as const;

const selectLensType = {
  id: 'LENS_TYPE',
  title: <FormattedMessage {...messages.selectLensType} />
} as const;

const configurePrescription = {
  id: 'PRESCRIPTION',
  title: <FormattedMessage {...messages.selectPrescription} />
} as const;

const upsell = {
  id: 'UPSELL',
  title: <FormattedMessage {...messages.upsell} />
} as const;

type Config = {
  boxSizesCount: number;
  allowLensSelect: boolean;
  hasUpsellProducts: boolean;
};

export type LensConfiguration = {
  baseCurveL: number;
  baseCurveR: number;
  boxSize: number;
  prescriptionL: number | null;
  prescriptionR: number | null;
  cylinderL: number | null;
  cylinderR: number | null;
  axisL: number | null;
  axisR: number | null;
  quantityL: number;
  quantityR: number;
};

function findFlow(config: Config) {
  const flow = [];

  if (config.boxSizesCount > 1) {
    flow.push(selectBoxSize);
  }

  if (config.allowLensSelect) {
    flow.push(selectLensType);
  }

  flow.push(configurePrescription);

  if (config.hasUpsellProducts) {
    flow.push(upsell);
  }

  return flow;
}

export default function ContactLensConfigurator(props: Props) {
  const { isDrawerOpen, toggleDrawer, regularProduct, toricProduct, loading, promotions = [], lensType } = props;

  const [showLoadingButton, setShowLoadingButton] = useState(false);
  const [selectedLensType, setSelectedLensType] = useState(lensType || null);
  const [stepIndex, setStepIndex] = useState(0);
  const [error, setError] = useState<ErrorState>(null);
  const [upsellProducts, setUpsellProducts] = useState(null);
  const [selectedUpsellProducts, setSelectedUpsellProducts] = useState([]);

  const cartService = useCartService();
  const router = useRouter();
  const { webStore } = useContext(ServicesContext);
  const notifications = useContext(NotificationsContext.Context);
  const getUpsellProducts = useGetUpsellProducts();
  const product = selectedLensType === 'regular' ? regularProduct : toricProduct;

  const variantsByBoxSize = useMemo(
    () =>
      product.variants.reduce((acc, variant) => {
        const { boxSize } = variant.properties;
        if (!acc[boxSize]) {
          acc[boxSize] = [variant];
        } else {
          acc[boxSize].push(variant);
        }
        return acc;
      }, {}),
    [product]
  );

  const uniqueBaseCurves = Array.from(new Set(product.variants.map(x => x.properties.baseCurve)));
  const uniqueBoxSizes = Object.keys(variantsByBoxSize);
  const hasUpsellProducts = upsellProducts?.length > 0;

  const CONFIGURATOR_FLOW = findFlow({
    allowLensSelect: !props.lensType && !!toricProduct && !!regularProduct,
    boxSizesCount: uniqueBoxSizes.length,
    hasUpsellProducts
  });

  const configuratorFlowIds = CONFIGURATOR_FLOW.map(x => x.id);
  const currentConfigurationStep = CONFIGURATOR_FLOW[stepIndex];
  const [lensConfiguration, setLensConfiguration] = useState<LensConfiguration>({
    axisL: null,
    axisR: null,
    baseCurveL: uniqueBaseCurves[0],
    baseCurveR: uniqueBaseCurves[0],
    boxSize: parseInt(uniqueBoxSizes[0]),
    cylinderL: null,
    cylinderR: null,
    prescriptionL: null,
    prescriptionR: null,
    quantityL: 1,
    quantityR: 1
  });

  function trackCurrentStep() {
    if (product.properties.isTrial) {
      trackArtificialPageView(pageViewLabels.trial[currentConfigurationStep.id]);
    } else {
      trackArtificialPageView(pageViewLabels.regular[currentConfigurationStep.id]);
    }
  }

  useEffect(() => {
    if (isDrawerOpen) {
      trackCurrentStep();
    }
  }, [isDrawerOpen, currentConfigurationStep]);

  useEffect(() => {
    setLensConfiguration({
      axisL: null,
      axisR: null,
      baseCurveL: uniqueBaseCurves[0],
      baseCurveR: uniqueBaseCurves[0],
      boxSize: parseInt(uniqueBoxSizes[0]),
      cylinderL: null,
      cylinderR: null,
      prescriptionL: null,
      prescriptionR: null,
      quantityL: 1,
      quantityR: 1
    });
  }, [product]);

  useEffect(() => {
    async function initData() {
      const products = await getUpsellProducts().then(res => {
        return res.filter(x => x.sku.includes('eye-drops'));
      });

      setUpsellProducts(products);
    }

    initData();
  }, []);

  function findSku(side: 'l' | 'r') {
    let curve = 'baseCurveL';
    let power = 'prescriptionL';
    let axis = 'axisL';
    let cylinder = 'cylinderL';

    if (side === 'r') {
      curve = 'baseCurveR';
      power = 'prescriptionR';
      axis = 'axisR';
      cylinder = 'cylinderR';
    }

    if (product.variants[0].properties.toricDetails) {
      return product.variants.find(
        x =>
          x.properties.baseCurve === lensConfiguration[curve] &&
          x.properties.power === lensConfiguration[power] &&
          x.properties.toricDetails.axis === lensConfiguration[axis] &&
          x.properties.toricDetails.cylinder === lensConfiguration[cylinder]
      );
    }

    return product.variants.find(
      x => x.properties.baseCurve === lensConfiguration[curve] && x.properties.power === lensConfiguration[power]
    );
  }

  const matchedSkuL = useMemo(() => findSku('l'), [product, lensConfiguration]);
  const matchedSkuR = useMemo(() => findSku('r'), [product, lensConfiguration]);

  const matchedPromotions = promotions
    .filter(promotion => promotion.products.includes(product.url))
    .sort((a, b) => a.min_quantity - b.min_quantity);

  const totalBoxCount = lensConfiguration.quantityL + lensConfiguration.quantityR;
  const activePromotion = matchedPromotions.find(
    x => x.min_quantity <= totalBoxCount && (!x.max_quantity || x.max_quantity >= totalBoxCount)
  );

  function handleClose() {
    trackArtificialPageView('');
    toggleDrawer(false);
    setTimeout(() => {
      setStepIndex(0);
    }, 300);
  }

  async function handleNextStep() {
    if (CONFIGURATOR_FLOW[stepIndex].id === configurePrescription.id) {
      setShowLoadingButton(true);
      const variantsToAdd = await computeVariantsToAdd();
      setShowLoadingButton(false);
      if (!variantsToAdd) return;
    }

    if (CONFIGURATOR_FLOW.length > stepIndex + 1) {
      setStepIndex(stepIndex + 1);
    } else {
      attemptAddToCart();
    }
  }

  function handleBack() {
    setStepIndex(stepIndex - 1);
  }

  async function attemptAddToCart() {
    setShowLoadingButton(true);
    const variantsToAdd = await computeVariantsToAdd();

    if (!variantsToAdd) {
      setShowLoadingButton(false);
      return;
    }

    try {
      const req = await cartService.addToCart(variantsToAdd);
      if (req.success) {
        router.push(paths.cart);
      } else {
        setShowLoadingButton(false);
        throw new Error(JSON.stringify(req));
      }
    } catch (err) {
      // failed to fetch
      setShowLoadingButton(false);
      notifications.addNotification({
        children: <FormattedMessage {...globalErrorMessages.apiRequestError} />,
        name: 'error-notification',
        sticky: true,
        variant: 'error'
      });
      Sentry.captureException(err, { extra: { message: 'Something went wrong when adding contact lenses to cart' } });
    }
  }

  async function computeVariantsToAdd() {
    const variantsToAdd = [];
    if (matchedSkuL && lensConfiguration.quantityL > 0) {
      variantsToAdd.push({
        quantity: lensConfiguration.quantityL,
        variant_id: matchedSkuL.id
      });
    }

    if (matchedSkuR && lensConfiguration.quantityR > 0) {
      variantsToAdd.push({
        quantity: lensConfiguration.quantityR,
        variant_id: matchedSkuR.id
      });
    }

    if (selectedUpsellProducts && selectedUpsellProducts.length > 0) {
      const upsellItem = selectedUpsellProducts.find(x => x.id);
      variantsToAdd.push({
        quantity: selectedUpsellProducts.length,
        variant_id: upsellItem.id
      });
    }

    // user has defined a quantity but not selected a prescription
    const hasInvalidQuantity =
      (lensConfiguration.quantityL > 0 && !lensConfiguration.prescriptionL) ||
      (lensConfiguration.quantityR > 0 && !lensConfiguration.prescriptionR);

    if (hasInvalidQuantity) {
      setError(product.properties.isTrial ? 'invalidQuantity' : 'quantityRequired');
      return null;
    }

    // user has defined a quantity, selected a prescription but no sku was matched
    const noProductFoundL = !matchedSkuL && lensConfiguration.quantityL > 0 && lensConfiguration.prescriptionL;
    const noProductFoundR = !matchedSkuR && lensConfiguration.quantityR > 0 && lensConfiguration.prescriptionR;

    if (noProductFoundL || noProductFoundR) {
      setError('productNotFound');
      return null;
    }

    // check availability
    const availabilityRequests = [];

    if (matchedSkuL) {
      availabilityRequests.push(
        fetch(matchedSkuL.availability.liveEndpoint)
          .then(res => res.json())
          .then(res => res.data[0]?.isAvailableOnline)
      );
    }

    if (matchedSkuR) {
      availabilityRequests.push(
        fetch(matchedSkuR.availability.liveEndpoint)
          .then(res => res.json())
          .then(res => res.data[0]?.isAvailableOnline)
      );
    }

    const availabilityStatus = await Promise.all(availabilityRequests);
    const hasInvalidVariants = availabilityStatus.filter(Boolean).length < availabilityStatus.length;

    if (hasInvalidVariants) {
      setError('OOS');
      return null;
    }

    return variantsToAdd;
  }

  function calculateTotal() {
    const baseVariantL = matchedSkuL || product.variants[0];
    const baseVariantR = matchedSkuR || product.variants[0];

    return (
      baseVariantL.price.value * lensConfiguration.quantityL + baseVariantR.price.value * lensConfiguration.quantityR
    );
  }

  function updateLensConfiguration(newConfig: LensConfiguration) {
    setLensConfiguration(newConfig);
  }

  const total = calculateTotal();
  const upsellTotal = calculateUpsellProductsCost();

  function toggleUpsellProduct(upsellProduct: Variant) {
    const additionalProducts = [...selectedUpsellProducts];
    const productIndex = selectedUpsellProducts.findIndex(x => x.id === upsellProduct.id);
    if (productIndex < 0) {
      additionalProducts.push(upsellProduct);
    } else {
      additionalProducts.splice(productIndex, 1);
    }
    setSelectedUpsellProducts(additionalProducts);
  }

  function calculateUpsellProductsCost() {
    return selectedUpsellProducts?.reduce((cost: number, product: Variant) => {
      if (product) {
        return cost + product.price.value;
      } else {
        return cost;
      }
    }, 0);
  }

  function hasValidLens() {
    const { prescriptionL, prescriptionR, axisL, axisR, cylinderL, cylinderR } = lensConfiguration;

    if (selectedLensType === 'regular') {
      return prescriptionL !== null || prescriptionR !== null;
    } else if (selectedLensType === 'toric') {
      return (
        (prescriptionL !== null && axisL !== null && cylinderL !== null) ||
        (prescriptionR !== null && axisR !== null && cylinderR !== null)
      );
    }
  }

  function canContinue() {
    if (loading) return false;

    if (currentConfigurationStep.id === 'LENS_TYPE' && selectedLensType !== null) {
      return true;
    } else if (currentConfigurationStep.id === 'PRESCRIPTION' && hasValidLens()) {
      return true;
    } else if (currentConfigurationStep.id === 'UPSELL') {
      return true;
    }

    return false;
  }

  return (
    <>
      <ErrorModal onClose={() => setError(null)} error={error} open={!!error} lensConfiguration={lensConfiguration} />
      <Configurator
        stepIndex={stepIndex}
        titles={currentConfigurationStep.title}
        open={isDrawerOpen}
        showSummary={!!selectedLensType}
        onBack={handleBack}
        onClose={handleClose}
        additionalContent={
          promotions &&
          promotions.length > 0 && [
            {
              component: <Promotion activePromotions={promotions} />,
              pageIndex: configuratorFlowIds.indexOf(configurePrescription.id)
            }
          ]
        }
        summary={
          <Summary
            loading={showLoadingButton}
            lensConfiguration={lensConfiguration}
            product={product}
            total={total}
            hideContent={currentConfigurationStep.id === selectLensType.id}
            discountPercentage={activePromotion && activePromotion.discount_percentage}
            canEmitOrder={CONFIGURATOR_FLOW.length <= stepIndex + 1}
            canContinue={canContinue()}
            onNextStepClick={handleNextStep}
            onEmitOrder={attemptAddToCart}
            currency={webStore.config.currency}
            upsellTotal={upsellTotal}
          />
        }
      >
        {loading && (
          <Styles.LoadingOverlay>
            <LoaderIcon />
          </Styles.LoadingOverlay>
        )}
        {CONFIGURATOR_FLOW.map(step => {
          if (step.id === 'BOX_SIZE') {
            return <BoxSize key={step.id} />;
          } else if (step.id === 'LENS_TYPE') {
            return (
              <LensType
                key={step.id}
                onChange={setSelectedLensType}
                regularProduct={regularProduct}
                toricProduct={toricProduct}
              />
            );
          } else if (step.id === 'PRESCRIPTION') {
            return (
              <Prescription
                key={step.id}
                lensType={selectedLensType}
                product={product}
                minPromoAmount={matchedPromotions.length > 0 && matchedPromotions[0].min_quantity}
                baseCurves={uniqueBaseCurves}
                lensConfiguration={lensConfiguration}
                onChange={updateLensConfiguration}
              />
            );
          } else if (step.id === 'UPSELL') {
            return (
              <Upsell
                key={step.id}
                upsellProducts={upsellProducts}
                selectedUpsellProducts={selectedUpsellProducts}
                toggleUpsellProduct={toggleUpsellProduct}
              />
            );
          } else return null;
        })}
      </Configurator>
    </>
  );
}
