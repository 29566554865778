import { useEffect, useState } from 'react';

export async function loadScript(src: string) {
  return new Promise(resolve => {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.async = true;
    script.onload = resolve;
    script.src = src;
    document.getElementsByTagName('head')[0].appendChild(script);
  });
}

export function once<T extends (...args: any[]) => any>(fn: T) {
  let result: ReturnType<T>;
  let ran = false;
  return (...args: Parameters<T>) => {
    if (!ran) {
      ran = true;
      result = fn(...args);
    }
    return result;
  };
}

export function useLoadScript(src: string) {
  const [isLoaded, setIsLoaded] = useState(false);
  useEffect(() => {
    async function run() {
      setIsLoaded(false);
      await loadScript(src);
      setIsLoaded(true);
    }
    run();
  }, [src]);
  return isLoaded;
}
