import { Typography, Button } from '@aceandtate/ds';
import * as Styles from '../styles';
import PriceLabel from 'components/DisplayLabels/PriceLabel';
import React from 'react';
import ecommerceMessages from 'messages/ecommerce';
import { FormattedMessage, FormattedNumber, useIntl } from 'react-intl';
import { Currency } from 'types/currency';
import { LensConfiguration } from '..';
import { ContactLensProduct } from 'types/torii';
import messages from '../messages';

type Props = {
  total: number;
  product: ContactLensProduct;
  canEmitOrder: boolean;
  canContinue?: boolean;
  lensConfiguration: LensConfiguration;
  discountPercentage?: number;
  onEmitOrder: () => void;
  onNextStepClick: () => void;
  currency: Currency;
  loading?: boolean;
  upsellTotal?: number;
  hideContent?: boolean;
};

export default function Summary(props: Props) {
  const {
    total,
    loading,
    onEmitOrder,
    canContinue = true,
    canEmitOrder,
    onNextStepClick,
    currency,
    lensConfiguration,
    discountPercentage,
    product,
    upsellTotal,
    hideContent
  } = props;

  const totalBoxCount = lensConfiguration.quantityL + lensConfiguration.quantityR;
  const totalLenses = totalBoxCount * lensConfiguration.boxSize;
  const intl = useIntl();

  return (
    <Styles.ConfiguratorSummaryContainer>
      {/* div spacer for justify when no content exists */}
      {hideContent && <div />}
      {!hideContent && (
        <>
          <Styles.SummaryDescription>
            <Typography variant='bodyM'>{product.name}</Typography>
            <Typography variant='bodyM'>
              {totalBoxCount} x {intl.formatMessage(messages.boxSizeLabel, { amount: lensConfiguration.boxSize })}
              &nbsp;(
              {intl.formatMessage(messages.lensCount, { amount: totalLenses })})
            </Typography>
            {discountPercentage && (
              <Typography variant='bodyM'>
                <Styles.TextHighlight>
                  <FormattedNumber value={discountPercentage} maximumFractionDigits={0} />
                  %&nbsp;
                  <FormattedMessage {...ecommerceMessages.discount} />
                </Styles.TextHighlight>
              </Typography>
            )}
          </Styles.SummaryDescription>
          <Styles.MobileSummary>
            <Typography variant='bodyM'>
              <span>
                {totalBoxCount} x {intl.formatMessage(messages.boxSizeLabel, { amount: lensConfiguration.boxSize })}
                &nbsp;(
                {intl.formatMessage(messages.lensCount, { amount: totalLenses })})
              </span>
              <Styles.Price lineThrough={!!discountPercentage}>
                <b>
                  <PriceLabel data-testid='frame-info-total-amount' price={total + upsellTotal} currency={currency} />
                </b>
              </Styles.Price>
            </Typography>
            {discountPercentage && (
              <Typography variant='bodyM' data-testid='discounted-price-mobile'>
                <Styles.TextHighlight>
                  <FormattedNumber value={discountPercentage} maximumFractionDigits={0} />
                  %&nbsp;
                  <FormattedMessage {...ecommerceMessages.discount} />
                </Styles.TextHighlight>
                <Styles.TextHighlight>
                  <Styles.Price>
                    <b>
                      <PriceLabel
                        data-testid='frame-info-total-amount'
                        price={total - (total * discountPercentage) / 100 + upsellTotal}
                        currency={currency}
                      />
                    </b>
                  </Styles.Price>
                </Styles.TextHighlight>
              </Typography>
            )}
          </Styles.MobileSummary>
        </>
      )}

      <Styles.PriceContainer>
        {!hideContent && (
          <Styles.PriceLabel>
            <Typography variant='h3'>
              <Styles.Price lineThrough={!!discountPercentage}>
                <PriceLabel data-testid='frame-info-total-amount' price={total + upsellTotal} currency={currency} />{' '}
              </Styles.Price>
              {discountPercentage && (
                <Styles.TextHighlight data-testid='discounted-price'>
                  &nbsp;
                  <PriceLabel
                    data-testid='frame-info-total-amount'
                    price={total - (total * discountPercentage) / 100 + upsellTotal}
                    currency={currency}
                  />
                </Styles.TextHighlight>
              )}
            </Typography>
          </Styles.PriceLabel>
        )}

        {!canEmitOrder && (
          <Styles.ATCButton>
            <Button
              loading={loading}
              disabled={!canContinue}
              color='accent'
              onClick={onNextStepClick}
              data-testid='configurator-next'
            >
              <FormattedMessage {...ecommerceMessages.next} />
            </Button>
          </Styles.ATCButton>
        )}
        {canEmitOrder && (
          <Styles.ATCButton>
            <Button
              loading={loading}
              disabled={!canContinue}
              color='accent'
              data-testid='configurator-add-to-cart'
              onClick={onEmitOrder}
            >
              <FormattedMessage {...ecommerceMessages.addToCart} />
            </Button>
          </Styles.ATCButton>
        )}
      </Styles.PriceContainer>
    </Styles.ConfiguratorSummaryContainer>
  );
}
