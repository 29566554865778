import { defineMessages } from 'react-intl';

const messages = defineMessages({
  callout: {
    defaultMessage: 'Save {percentage} on any extra pair of frames.',
    id: 'bundleDeal.callout'
  },
  calloutBlockBody: {
    defaultMessage:
      'Switch up your style. Always have a backup. Take {percentage} off when you buy two or more frames. <sunniesLink>Shop Sunnies</sunniesLink>  <glassesLink>Shop Glasses</glassesLink>',
    id: 'bundleDeal.callout.block.body'
  },
  calloutBlockTitle: {
    defaultMessage: 'Make it two.',
    id: 'bundleDeal.callout.block.title'
  }
});

export default messages;
