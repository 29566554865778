import React from 'react';
import { Button } from '@aceandtate/ds';
import { paths, usePath } from 'paths';

type Props = {
  children: React.ReactNode;
};

export default function BookEyeTest({ children, ...props }: Props) {
  const pathTo = usePath();

  return (
    <Button color='secondary' variant='default' {...props} role='link' href={pathTo(paths.bookAppointment)}>
      {children}
    </Button>
  );
}
