import { Typography } from '@aceandtate/ds';
import authenticationMessages from 'messages/authentication';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import * as Styles from '../styles';

const EasyLoginSent = ({ email, changeFormState }) => {
  return (
    <Styles.FormColumn style={{ textAlign: 'center' }}>
      <Typography variant='h4' gutterBottom data-cs-capture>
        <FormattedMessage {...authenticationMessages.easyLoginDoneTitle} />
      </Typography>
      <Typography variant='bodyM' gutterBottom>
        <FormattedMessage {...authenticationMessages.easyLoginDoneMessage} values={{ email: <b>{email}</b> }} />
      </Typography>
      <Typography variant='bodyM' data-cs-capture>
        <FormattedMessage
          {...authenticationMessages.easyLoginWrongEmail}
          values={{
            returnLink: (
              <Styles.InlineLinkButton aria-label='Return to login form' onClick={() => changeFormState('email')}>
                <FormattedMessage {...authenticationMessages.easyLoginWrongEmailReturnLink} />
              </Styles.InlineLinkButton>
            )
          }}
        />
      </Typography>
    </Styles.FormColumn>
  );
};

export default EasyLoginSent;
