import { defineMessages } from 'react-intl';

const messages = defineMessages({
  searchPlaceholder: {
    defaultMessage: 'Search by style or color',
    id: 'conformityTable.searchPlaceholder'
  }
});

export default messages;
