import Flag from 'components/Flag';
import { country as countryMessages } from 'messages/intl';
import shippingExclusionMessages from 'messages/shippingExclusion';
import React, { useCallback, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { WebStore } from 'types/webStore';
import * as Styles from './styles';
import { InputControl, Select } from '@aceandtate/ds';
import { SelectItem } from '@aceandtate/ds/dist/components/Select/Select';

type Props = {
  label?: React.ReactNode;
  countryCode?: string;
  stores: WebStore[];
  showShippingExclusions?: boolean;
  dataTestid?: string;
  onChange: (val) => void;
  id: string;
};

export default function CountrySelector(props: Props) {
  const { countryCode, stores, onChange, label, showShippingExclusions = false, id, dataTestid = '' } = props;

  const makeOption = useCallback(
    (store: WebStore) => ({
      id: store.country.iso,
      label: store.name,
      value: store.country.iso
    }),
    []
  );
  const storeOptions = useMemo(() => stores.map(store => makeOption(store)), [stores, makeOption]);
  const storeValue = useMemo(() => {
    if (countryCode) {
      return storeOptions.find(store => store.value === countryCode.toUpperCase());
    }

    return undefined;
  }, [storeOptions, countryCode]);

  const renderValue = option => {
    const { value, label } = option;

    return (
      <div style={{ alignItems: 'center', display: 'flex' }}>
        <Styles.FlagWrapper>
          <Flag iso={value} />
        </Styles.FlagWrapper>
        &nbsp;
        <Styles.MessageWrapper>
          <FormattedMessage {...countryMessages[label]} />

          {showShippingExclusions && shippingExclusionMessages[value] && (
            <Styles.ShippingExclusion>
              (<FormattedMessage {...shippingExclusionMessages[value]} />)
            </Styles.ShippingExclusion>
          )}
        </Styles.MessageWrapper>
      </div>
    );
  };

  return (
    <InputControl id={id}>
      <InputControl.Label>{label}</InputControl.Label>
      <Select
        fullWidth
        items={storeOptions}
        selectedItems={storeValue}
        setSelectedItems={(item: SelectItem<any>) => onChange(item.value)}
        renderValue={renderValue}
        renderItem={renderValue}
        data-testid={dataTestid}
      />
    </InputControl>
  );
}
