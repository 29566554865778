import React from 'react';
import ScrollLock from 'components/ScrollLock';

import * as Styles from './styles';

type Props = {
  open: boolean;
  top?: number;
  children: React.ReactNode;
  name: string;
  withScrollLock?: boolean;
};

export default function MenuPane(props: Props) {
  const { open, top, children, name, withScrollLock } = props;
  return (
    <>
      <Styles.MenuPane name={name} hidden={!open} top={top}>
        {children}
      </Styles.MenuPane>
      {open && withScrollLock && <ScrollLock />}
    </>
  );
}
