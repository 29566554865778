import { ProductType, Variant } from 'types/torii';
import { IntlShape } from 'react-intl';
import productTypeMessages from 'messages/productType';

export default function getToriiProductType({
  variant,
  productType,
  intl
}: {
  variant: Variant;
  productType: ProductType;
  intl: IntlShape;
}) {
  const camelProductType = productType.replace(/([-_][a-z])/g, g => g[1].toUpperCase());

  if (variant.filterOptions.types.includes('sunny') && productType === 'frame') {
    return intl.formatMessage(productTypeMessages.sunny);
  } else if (productType === 'frame') {
    return intl.formatMessage(productTypeMessages.glasses);
  } else if (productTypeMessages[camelProductType]) {
    return intl.formatMessage(productTypeMessages[camelProductType]);
  }

  return productType;
}
