import styled, { css } from 'styled-components';
import { grid, brandColors, Typography } from '@aceandtate/ds';
import { rotateAnimation, blinker } from 'styles';

const animationDuration = 15;
const animationDelay = animationDuration;
const animationTimingFuction = 'ease';

type StyleProps = {
  fullscreen?: boolean;
  messagesLength?: number;
};

export const Wrapper = styled.div<StyleProps>`
  align-items: ${props => (props.fullscreen ? 'center' : 'flex-start')};
  background: ${brandColors.light};
  display: flex;
  flex-flow: column;
  height: ${props => (props.fullscreen ? '100vh' : '100%')};
  justify-content: ${props => (props.fullscreen ? 'center' : 'flex-start')};
  overflow: hidden;
  position: relative;
  text-align: ${props => (props.fullscreen ? 'center' : 'left')};
  width: 100%;
`;

export const MessagesWrapper = styled.div<StyleProps>`
  align-items: ${props => (props.fullscreen ? 'center' : 'flex-start')};
  display: flex;
  justify-content: ${props => (props.fullscreen ? 'center' : 'flex-start')};
  padding-bottom: ${props => (props.messagesLength === 1 ? grid[24] : grid[40])};
  width: 100%;
`;

type MessageProps = {
  index: number;
  messagesLength: number;
};

export const Message = styled(Typography)<MessageProps>`
  opacity: 0;
  position: ${({ messagesLength }) => (messagesLength === 1 ? 'relative' : 'absolute')};

  ${({ index, messagesLength }) =>
    css`
      &:nth-of-type(${index + 1}) {
        animation: ${rotateAnimation} ${animationDuration}s ${animationTimingFuction} ${animationDelay * index}s;
      }

      &:nth-last-child(1) {
        animation: ${blinker} 3s ease-out ${animationDuration * (messagesLength - 1)}s;
        animation-fill-mode: forwards;
      }
    `}
`;
