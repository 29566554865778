import { defineMessages } from 'react-intl';

export default defineMessages({
  freeShippingAndFreeReturnsToLink: {
    defaultMessage: 'Free shipping and returns to {link}.',
    id: 'countrySwitcher.freeShippingAndFreeReturnsToLink'
  },
  freeShippingMinimumLink: {
    defaultMessage: 'Free shipping to {link} from {shippingMinimum}.',
    id: 'countrySwitcher.freeShippingMinimumLink'
  },
  freeShippingToLink: {
    defaultMessage: 'Free shipping to {link}',
    id: 'countrySwitcher.freeShippingToLink'
  },
  pleaseSelectACountry: {
    defaultMessage: 'Please select a country {link}.',
    id: 'countrySwitcher.pleaseSelectACountry'
  },
  returnPolicy: {
    defaultMessage: '30 days return policy.',
    id: 'countrySwitcher.returnPolicy'
  },
  returnPolicyFreeReturns: {
    defaultMessage: '30 days return policy & free returns.',
    id: 'countrySwitcher.returnPolicyFreeReturns'
  }
});
