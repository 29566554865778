import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Typography, Button } from '@aceandtate/ds';
import ecommerceMessages from 'messages/ecommerce';
import * as Styles from '../../styles';
import { paths } from 'paths';
import messages from '../../messages';

type Props = {
  onClose: () => void;
};

export default function MultifocalPrescriptionWarningModal(props: Props) {
  const { onClose } = props;
  return (
    <>
      <Typography variant='h5' gutterBottom={24}>
        <FormattedMessage {...messages.multifocalPrescriptionWarning} />
      </Typography>
      <Typography gutterBottom={24}>
        <FormattedMessage
          {...messages.multifocalPrescriptionWarningModalCaption}
          values={{
            link: chunks => <Styles.UnderlineNextLink href={paths.stores}>{chunks}</Styles.UnderlineNextLink>
          }}
        />
      </Typography>
      <Button onClick={onClose} style={{ alignSelf: 'flex-end' }}>
        <FormattedMessage {...ecommerceMessages.ok} />
      </Button>
    </>
  );
}
