import { trackGaEvent } from 'tracking/helpers';

const BUNDLE_ID = process.env.NEXT_PUBLIC_TRANSCEND_BUNDLE_ID;
const isProduction = process.env.NEXT_PUBLIC_IS_PRODUCTION === 'true';

let airgapAPI;
export function getAirgap() {
  return (airgapAPI ??= new Promise((resolve, reject) => {
    // Stub airgap.js ready queue
    if (!self?.airgap?.ready) {
      self.airgap = {
        ready(callback) {
          this.readyQueue.push(callback);
        },
        readyQueue: [],
        suppressTelemetry: {
          cookies: [/_gd.*/, /amp_cookie_test.*/], // match any cookie with _gd followed by any number, match any amplify cookie
          requests: [/w(s)?s(\d{1}|\d{2}).hotjar.com/] // match all hotjar websockets, ex: ws9.hotjar.com, ws10.hotjar.com & wss10.hotjar.com
        },
        ui: isProduction ? 'https://consent.aceandtate.com' : 'https://consent.aceandtate.show',
        ...self?.airgap
      };
    }

    // Wait for airgap.js to be ready
    self?.airgap.ready(airgap => {
      airgap.addEventListener('consent-resolution', () => {
        trackGaEvent({
          event: 'ConsentResolution'
        });
        resolve(airgap);
      });

      airgap.addEventListener('consent-change', () => {
        // The setTimeout is a workaround to ensure 'ConsentUpdate' event is triggered only after gtm consent event update
        setTimeout(() => {
          trackGaEvent({
            event: 'ConsentUpdate'
          });
        });
      });

      // Set AirgapReady event as the first item in the array
      // That makes it the first event to fire and sets up all main tracking scripts before page events fire
      self.dataLayer = [
        {
          event: 'AirgapReady'
        },
        ...self.dataLayer.filter(x => !Array.isArray(x)) // filter out invalid data that airgap is pushing in array shapes into the datalayer
      ];

      // This loads GTM script
      try {
        const gtm = document.createElement('script');
        gtm.innerHTML = `try {
         (function(w,d,s,l,i){w[l] = w[l] || [];w[l].push({'gtm.start':
        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
      })(window,document,'script','dataLayer','${process.env.NEXT_PUBLIC_GTM_ID}');
        } catch (err) {
          console.error('Failure in GTM script tag.', err);
        }`;

        document.documentElement.appendChild(gtm);
      } catch (err) {
        console.error('Failed to attach GTM to dom.', err);
      }
    });

    const script = document.createElement('script');
    // Reject promise if airgap.js fails to load
    script.addEventListener('error', evt => {
      console.error('Error within airgap.js script', evt);
      reject(evt);
    });

    script.async = script.defer = true; // Specify load options (auto-prompt on second and later pageviews)
    script.dataset.prompt = '2';
    script.dataset.unknownRequestPolicy = 'allow';
    script.dataset.sync = 'off'; // disables XDI for cross domain tracking
    script.src = `https://transcend-cdn.com/cm/${BUNDLE_ID}/airgap.js`;
    document.documentElement.appendChild(script);
  }).catch(e => {
    console.log(e); // eslint-disable-line
  }));
}
