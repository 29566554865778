import React, { ReactElement } from 'react';
import Image, { ImageProps } from 'next/image';
import { cloudinaryLoader } from 'utils/cloudinary';

export default function OptimizedCloudinaryImage({ src, quality = 75, ...props }: ImageProps): ReactElement {
  return (
    <Image
      src={src}
      quality={quality}
      loader={cloudinaryLoader}
      {...props}
      style={{
        maxWidth: '100%',
        ...(props.style ? props.style : {})
      }}
    />
  );
}
