import React from 'react';
import queryString from 'query-string';
import { LazyLoadComponent, LazyLoadImage } from 'react-lazy-load-image-component';
import Image from 'next/image';
import { transformCfAsset } from 'utils/images';
import { MediaProps } from './types';
import * as styles from './styles';

type ContentfulAssetConfig = {
  q?: number;
  w?: number;
  fm?: string;
};

const img = (src: string, config: ContentfulAssetConfig) => `https:${src}?${queryString.stringify(config)}`;

type State = {
  isVideoPlaying: boolean;
  userCanStartVideo: boolean;
};

class Media extends React.PureComponent<MediaProps, State> {
  videoRef?: HTMLVideoElement;
  state: State = {
    isVideoPlaying: !!this.props.autoPlay || !!this.props.autoPlayWithButton,
    userCanStartVideo: !!this.props.autoPlayWithButton
  };

  // TODO should muted / autoplay be Booleans or Strings?
  onVideoRef = (videoEl: HTMLVideoElement) => {
    this.videoRef = videoEl;
    // In order to have autoPlay work on mobile, we also need to set the muted prop.
    // We need to do it manually via refs since there's a long running
    // bug in React that prevents the muted property from being set
    // in the video element. You can read more about it in the following links:
    // https://github.com/facebook/react/issues/10389
    // https://github.com/facebook/react/issues/6544
    if (videoEl && this.props.autoPlay) {
      videoEl.setAttribute('muted', 'true');
      videoEl.setAttribute('autoplay', 'true');
      videoEl.play();
    }
  };

  toggleVideoPlay = () => {
    if (this.props.autoPlay) {
      // Make it a no-op if autoPlay is set
      return;
    }

    if (this.state.userCanStartVideo) {
      this.playVideoFromStart();
      this.setState({ userCanStartVideo: false });
      return;
    }
    this.state.isVideoPlaying ? this.pauseVideo() : this.playVideo();
  };

  playVideoFromStart() {
    const node = this.videoRef;

    if (node) {
      this.setState({ isVideoPlaying: true });
      node.currentTime = 0;
      node.play();
    }
  }

  playVideo() {
    const node = this.videoRef;
    if (node) {
      this.setState({ isVideoPlaying: true });
      node.play();
    }
  }

  pauseVideo() {
    const node = this.videoRef;
    if (node) {
      this.setState({ isVideoPlaying: false });
      node.pause();
    }
  }

  onVideoEnded = (event: React.SyntheticEvent<HTMLVideoElement, Event>) => {
    // Seek back to the start of video
    this.setState({ isVideoPlaying: false });
    (event.target as HTMLVideoElement).load();
  };

  render() {
    const {
      autoPlay,
      autoPlayWithButton,
      className,
      fitContainer,
      landscapeConfig,
      landscapePoster,
      onClick,
      portraitConfig,
      portraitPoster,
      title,
      animate = false
    } = this.props;

    const imageLandscapeConfig = {
      q: 80,
      w: 1200,
      ...portraitConfig,
      ...landscapeConfig
    };

    const imagePortraitConfig = {
      q: 80,
      w: 800,
      ...landscapeConfig,
      ...portraitConfig
    };

    // default Model of portrait / landscape types in case none are defined
    const defaultModel = {
      file: {
        contentType: '',
        details: {
          image: {
            height: null,
            width: null
          },
          size: null
        },
        fileName: '',
        url: ''
      },
      title: '',
      description: ''
    };

    const { isVideoPlaying, userCanStartVideo } = this.state;

    // We need to define a fallback in case only one of them is set
    const landscape = this.props.landscape || this.props.portrait || defaultModel;
    const portrait = this.props.portrait || this.props.landscape || defaultModel;

    const isLandscapeVideo = landscape.file.contentType.split('/')[0] === 'video';
    const isPortraitVideo = portrait.file.contentType.split('/')[0] === 'video';
    const isVideoMedia = isLandscapeVideo || isPortraitVideo;
    const displayPlayButton =
      isVideoMedia &&
      ((!autoPlay && !autoPlayWithButton) || (isVideoMedia && userCanStartVideo) || (!isVideoPlaying && !autoPlay));
    const isLandscapeImage = landscape.file.contentType.split('/')[0] === 'image';
    const isPortraitImage = portrait.file.contentType.split('/')[0] === 'image';

    const portraitPosterUrl = portraitPoster && img(portraitPoster.file.url, { fm: 'jpg', q: 80, w: 1200 });
    const landscapePosterUrl = landscapePoster && img(landscapePoster.file.url, { fm: 'jpg', q: 80, w: 800 });

    const hasAutoplay = autoPlay || (autoPlayWithButton && userCanStartVideo);

    return (
      <styles.MediaWrapper
        className={className}
        onClick={onClick}
        isManualPlay={!autoPlay && isVideoMedia}
        fitContainer={fitContainer}
        animate={animate}
        {...this.props}
      >
        <styles.Container>
          {title && (
            <styles.Text variant='h2' hidden={isVideoPlaying} data-media={isVideoMedia ? 'video' : 'image'}>
              {title}
            </styles.Text>
          )}
          {displayPlayButton && (
            <styles.PlayIcon
              id='playIconSvg'
              hidden={isVideoPlaying && !userCanStartVideo}
              onClick={this.toggleVideoPlay}
            />
          )}
        </styles.Container>
        <styles.MediaQuery media='(orientation: landscape)'>
          {landscape && isLandscapeVideo && (
            <LazyLoadComponent threshold={600}>
              <video
                autoPlay={hasAutoplay}
                loop={hasAutoplay}
                muted={hasAutoplay}
                onClick={this.toggleVideoPlay}
                onEnded={this.onVideoEnded}
                playsInline
                poster={landscapePosterUrl}
                ref={this.onVideoRef}
              >
                <source src={landscape.file.url} type={landscape.file.contentType} />
              </video>
            </LazyLoadComponent>
          )}
          {landscape &&
            isLandscapeImage &&
            (landscape.file.details?.image ? (
              <Image
                src={transformCfAsset(img(landscape.file.url, imageLandscapeConfig))}
                height={landscape.file.details.image.height}
                width={landscape.file.details.image.width}
                unoptimized
                style={{
                  maxWidth: '100%',
                  height: 'auto'
                }}
                alt={landscape.description}
              />
            ) : (
              <LazyLoadImage src={img(landscape.file.url, imageLandscapeConfig)} effect='opacity' />
            ))}
        </styles.MediaQuery>
        <styles.MediaQuery media='(orientation: portrait)'>
          {portrait && isPortraitVideo && (
            <LazyLoadComponent threshold={300}>
              <video
                autoPlay={hasAutoplay}
                loop={hasAutoplay}
                muted={hasAutoplay}
                onClick={this.toggleVideoPlay}
                onEnded={this.onVideoEnded}
                playsInline
                poster={portraitPosterUrl}
                ref={this.onVideoRef}
              >
                <source src={portrait.file.url} type={portrait.file.contentType} />
              </video>
            </LazyLoadComponent>
          )}
          {portrait &&
            isPortraitImage &&
            (portrait.file.details?.image ? (
              <Image
                src={transformCfAsset(img(portrait.file.url, imagePortraitConfig))}
                height={portrait.file.details.image.height}
                width={portrait.file.details.image.width}
                unoptimized
                style={{
                  maxWidth: '100%',
                  height: 'auto'
                }}
                alt={portrait.description}
              />
            ) : (
              <LazyLoadImage src={img(portrait.file.url, imagePortraitConfig)} effect='opacity' />
            ))}
        </styles.MediaQuery>
      </styles.MediaWrapper>
    );
  }
}

export default Media;
