export type FormPrescriptionType = keyof typeof prescriptionTypes;

export const prescriptionTypes = {
  multifocal: {
    fields: [
      { id: 'sphere', type: 'required', values: [-8, 8, 0.25] },
      { id: 'cylinder', type: 'conditionallyRequired', values: [-4, 4, 0.25] },
      {
        id: 'axis',
        suffix: '°',
        type: 'conditionallyRequired',
        values: [0, 180, 1]
      },
      { id: 'pupillary_distance', showDontKnow: true, type: 'optional', values: [24, 40, 0.5] },
      { id: 'addition', type: 'required', values: [0.75, 3.5, 0.25] }
    ]
  },
  single_vision: {
    fields: [
      { id: 'sphere', type: 'required', values: [-10, 10, 0.25] },
      { id: 'cylinder', type: 'conditionallyRequired', values: [-4, 4, 0.25] },
      {
        id: 'axis',
        suffix: '°',
        type: 'conditionallyRequired',
        values: [0, 180, 1]
      },
      { id: 'pupillary_distance', showDontKnow: true, type: 'optional', values: [24, 40, 0.5] }
    ]
  },
  relax_5_lenses: {
    fields: [
      { id: 'sphere', type: 'required', values: [-9, 9, 0.25] },
      { id: 'cylinder', type: 'conditionallyRequired', values: [-4, 4, 0.25] },
      {
        id: 'axis',
        suffix: '°',
        type: 'conditionallyRequired',
        values: [0, 180, 1]
      },
      { id: 'pupillary_distance', showDontKnow: true, type: 'optional', values: [24, 40, 0.5] }
    ]
  }
};

export const NON_PLANO_PRESCRIPTIONS = ['single_vision', 'multifocal'];

// sphere always required
// cylinder is not required but if it is entered, axis is required too
// PD is never required
