import styled from 'styled-components';
import { brandColors, Typography, borderRadius, breakpointRules, grid } from '@aceandtate/ds';

type MenuNavIconWrapper = {
  mobile: string;
};

type NavigationIconProps = {
  active?: string;
  count?: number;
  hidden?: boolean;
  $isOpen?: boolean;
  name?: string;
};

export const MenuNavIconWrapper = styled.span<MenuNavIconWrapper>`
  @media ${breakpointRules.laptop} {
    display: ${props => (props.mobile === 'true' ? 'none' : 'block')};
  }
`;

export const NavigationIcon = styled.span<NavigationIconProps>`
  width: 1em;
  height: 1em;
  overflow: hidden;
  font-size: 1.5rem;
  flex-shrink: 0;
  user-select: none;

  color: ${props => (!props.active && props.$isOpen ? brandColors.dark50 : 'inherit')};
  cursor: pointer;
  display: ${props => (props.hidden ? 'none' : 'flex')};
  flex-flow: column;
  margin: ${props => (props.name === 'Cart' ? 0 : `0 ${grid[4]} 0 0`)};
  position: relative;

  svg {
    height: 24px;
    width: 24px;
  }

  @media (max-width: 330px) {
    svg {
      height: 20px;
      width: 20px;
    }
  }

  @media ${breakpointRules.laptop} {
    display: ${props => (props.name === 'Open menu' ? 'none' : 'flex')};
    margin: ${props => (props.name === 'Cart' ? 0 : `0 ${grid[8]} 0 0`)};
  }
`;

export const NumberContainer = styled.span<NavigationIconProps>`
  position: relative;
  display: ${props => (props.hidden ? 'none' : 'flex')};
`;

export const Number = styled(Typography)<NavigationIconProps>`
  background-color: ${brandColors.accent};
  border-radius: ${borderRadius.full};

  text-align: center;
  right: 8px;
  min-width: 12px;
  height: 12px;
  transform: translateX(50%);
  top: 4px;
  line-height: ${grid[12]};
  padding: 0 2px;
  position: absolute;
  z-index: 1;
  display: grid;
  place-items: center;
  font-weight: 500;

  && {
    color: ${brandColors.light};
  }
`;
