import React from 'react';
import { Experience } from '@ninetailed/experience.js-next';
import { ExperienceMapper } from '@ninetailed/experience.js-utils';
import { Typography } from '@aceandtate/ds';
import Link from 'next/link';

import * as Styles from './styles';
import type { Menu } from 'blocks/MenuNavigation/types';
import { usePath } from 'paths';

interface Props {
  menuBlocks: Menu[];
}

export default function SecondaryMobileMenu({ menuBlocks }: Props) {
  const pathTo = usePath();

  return (
    <Styles.SecondaryMobileMenuContainer>
      {menuBlocks.map(menu => {
        if (menu.ntExperiencesCollection.items.length > 0) {
          return (
            <Experience
              passthroughProps={{
                children: Typography({ children: menu.text, variant: 'h5' }),
                href: menu.urlRoute.path
              }} // component props
              experiences={menu.ntExperiencesCollection.items.map(item => {
                return ExperienceMapper.mapExperience({
                  audience: item.nt_audience && { id: item.nt_audience.nt_audience_id },
                  config: item.nt_config,
                  id: item.sys.id,
                  name: item.nt_name,
                  type: item.nt_type,
                  variants: item.ntVariantsCollection.items.map(item => ({
                    /* to ensure the component displays the correct data and behaviour, any variant specific data/fn should be added here) */
                    children: Typography({ children: item.text, variant: 'h5' }),
                    href: item.urlRoute?.path || pathTo(item.path),
                    prefetch: false,
                    id: item.sys.id
                  }))
                });
              })}
              id={menu.sys.id}
              key={menu.sys.id}
              component={Link}
              // loadingComponent={ESRLoadingComponent}
            />
          );
        }
        return (
          <Link key={menu.sys.id} href={menu.urlRoute?.path || pathTo(menu.path)} prefetch={false}>
            <Typography variant='h5' color={menu.color}>
              {menu.text}
            </Typography>
          </Link>
        );
      })}
    </Styles.SecondaryMobileMenuContainer>
  );
}
