import { css } from 'styled-components';
import { brandColors } from '@aceandtate/ds';

export const selectedStyle = css`
  :before {
    content: '';
    height: 100%;
    position: absolute;
    width: 4px;
    background: ${brandColors.accent};
    left: 0;
    top: 0;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    z-index: 1;
  }
`;

export const mediaOnlyIe = (styles: string) => `
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    ${styles};
  }
`;

export function listUnstyled() {
  return `
    margin: 0;
    padding-left: 0;
    list-style: none;
  `;
}

export function listInline() {
  return `
    ${listUnstyled()};
    li {
        display:inline-block;
    }
`;
}

// See bootstrap: https://getbootstrap.com/docs/4.0/utilities/screenreaders/

// Only display content to screen readers
//
// See: http://a11yproject.com/posts/how-to-hide-content
export function srOnly() {
  return `
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0,0,0,0);
    border: 0;
`;
}

// Use in conjunction with .sr-only to only display content when it's focused.
//
// Useful for "Skip to main content" links; see https://www.w3.org/TR/2013/NOTE-WCAG20-TECHS-20130905/G1
//
// Credit: HTML5 Boilerplate
export function srOnlyFocusable() {
  return `
  &:active,
  &:focus {
    position: static;
    width: auto;
    height: auto;
    margin: 0;
    overflow: visible;
    clip: auto;
  }
`;
}
