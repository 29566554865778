import { trackGaEvent } from '../helpers';

type QuickActionsProps = {
  clickedItem: string;
  elementPosition: string;
}

type PageBannerClickOptions = {
  bannerType: string;
  clickedItemType: string;
  clickedItem: string;
}

type BannerClickProps = {
  bannerType: string;
  headerBannerType: string;
  clickedItemType: string;
  clickedItem: string;
}

export function bannerClick({ bannerType, headerBannerType, clickedItemType, clickedItem }: BannerClickProps) {
  trackGaEvent({
    bannerType,
    // pass type of the element clicked on the banner, e.g. button, image, link, title
    clickedItem: clickedItem.replace(/^\/+/g, ''),
    // pass the page from where the event has happened, e.g homepage, landingpage
    clickedItemType,
    event: 'bannerClicks',
    // headerBanner, banner (applies for other banners as well)
    headerBannerType // remove leading slash to get the destination URL without country/language extensions, e.g. https://www.aceandtate.com/gb/en/lenny-satin-gold-sun --> lenny-satin-gold-sun
  });
}

export function pageBannerClick({ bannerType, clickedItemType, clickedItem }: PageBannerClickOptions) {
  trackGaEvent({
    bannerType,
    clickedItem,
    clickedItemType,
    event: 'bannerClicks'
  });
}

export function quickActions({ clickedItem, elementPosition }: QuickActionsProps) {
  trackGaEvent({
    clickedItem,
    elementPosition,
    event: 'quickActions'
  });
}
