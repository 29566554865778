import React from 'react';
import { Typography } from '@aceandtate/ds';
import Link from 'next/link';
import { headerInteraction } from 'tracking';

import * as Styles from './styles';
import * as MenuStyles from '../../styles';
import * as Types from '../../types';

type Props = {
  datalayerL1?: string;
  isMobile?: boolean;
  list?: Types.Menu[];
  title?: string;
};

export default function TextColumn({ title, list, datalayerL1, isMobile }: Props) {
  return (
    <MenuStyles.ColumnContainer>
      {list && list.length > 0 && (
        <Typography variant='bodyM' gutterBottom={12}>
          {title}
        </Typography>
      )}
      <Styles.LinksList>
        {list &&
          list.map((li, index) => (
            <Link href={li.urlRoute?.path || li.path} key={index}>
              <MenuStyles.TextLink
                variant={isMobile ? 'h6' : 'bodyS'}
                onClick={() =>
                  headerInteraction.level2({
                    clickedItemType: 'link',
                    navigationL1: `${datalayerL1}`,
                    navigationL2: `${li.datalayerId}`
                  })
                }
                data-testid={`menuNav.textColumn.${li.datalayerId}`}
              >
                {li.text}
              </MenuStyles.TextLink>
            </Link>
          ))}
      </Styles.LinksList>
    </MenuStyles.ColumnContainer>
  );
}
