/* eslint-disable */
import { defineMessages } from 'react-intl';

export const filterMessages = defineMessages({
  material: {
    id: 'filters.label.material',
    defaultMessage: 'Material'
  },
  shape: {
    id: 'filters.label.shape',
    defaultMessage: 'Shape'
  },
  frame_width: {
    id: 'filters.label.width',
    defaultMessage: 'Width'
  },
  simple_color: {
    id: 'filters.label.color',
    defaultMessage: 'Color'
  },
  price_map: {
    id: 'filters.label.price',
    defaultMessage: 'Price from'
  }
});

export const materialMessages = defineMessages({
  acetate: {
    id: 'filters.material.acetate',
    defaultMessage: 'acetate'
  },
  bio_acetate: {
    id: 'filters.material.bio_acetate',
    defaultMessage: 'bio acetate'
  },
  renew_bio_acetate: {
    id: 'filters.material.renew_bio_acetate',
    defaultMessage: 'renew bio acetate'
  },
  metal: {
    id: 'filters.material.metal',
    defaultMessage: 'metal'
  },
  combi: {
    id: 'filters.material.combi',
    defaultMessage: 'combi'
  },
  titanium: {
    id: 'filters.material.titanium',
    defaultMessage: 'titanium'
  }
});

export const shapeMessages = defineMessages({
  round: {
    id: 'filters.shape.round',
    defaultMessage: 'round'
  },
  square: {
    id: 'filters.shape.square',
    defaultMessage: 'square'
  },
  rectangular: {
    id: 'filters.shape.rectanlge',
    defaultMessage: 'rectangle'
  }
});

export const widthMessages = defineMessages({
  narrow: {
    id: 'filters.width.narrow',
    defaultMessage: 'narrow'
  },
  medium: {
    id: 'filters.width.medium',
    defaultMessage: 'medium'
  },
  wide: {
    id: 'filters.width.wide',
    defaultMessage: 'wide'
  }
});

export const colorMessages = defineMessages({
  colored: {
    id: 'filters.color.colored',
    defaultMessage: 'colored'
  },
  light: {
    id: 'filters.color.light',
    defaultMessage: 'light'
  },
  dark: {
    id: 'filters.color.dark',
    defaultMessage: 'dark'
  },
  transparent: {
    id: 'filters.color.transparent',
    defaultMessage: 'transparent'
  }
});

export const priceMessages = defineMessages({
  base: {
    id: 'filters.price.base',
    defaultMessage: '{price}'
  },
  premium: {
    id: 'filters.price.premium',
    defaultMessage: '{price}'
  },
  limitedEdition: {
    id: 'filters.price.limitedEdition',
    defaultMessage: '{price}'
  }
});

export const allMessages = {
  ...filterMessages,
  ...materialMessages,
  ...shapeMessages,
  ...widthMessages,
  ...colorMessages,
  ...priceMessages
};
