import React from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { Button, Typography, grid, uiColors } from '@aceandtate/ds';
import { BUNDLE_DEAL_PERCENTAGE } from 'globalConstants';
import bundleDealMessages from 'messages/bundleDeal';
import { paths, usePath } from 'paths';

export const BundleDealBlock = styled.div`
  display: flex;
  flex-direction: column;

  gap: ${grid[8]};
  padding: ${grid[12]} ${grid[24]};

  background: ${uiColors.infoLight};

  *,
  *:hover {
    color: ${uiColors.info};
  }

  p {
    * {
      font-size: inherit;
      line-height: inherit;
    }
  }
`;

export default function BundleDealCalloutBlock() {
  const pathTo = usePath();

  return (
    <BundleDealBlock>
      <Typography variant='h4' as='p'>
        <FormattedMessage {...bundleDealMessages.calloutBlockTitle} />
      </Typography>
      <Typography variant='bodyM'>
        <FormattedMessage
          {...bundleDealMessages.calloutBlockBody}
          values={{
            glassesLink: chunks => (
              <Button variant='link' href={pathTo(paths.glasses)}>
                {chunks}
              </Button>
            ),
            percentage: `${BUNDLE_DEAL_PERCENTAGE}%`,
            sunniesLink: chunks => (
              <Button variant='link' href={pathTo(paths.sunglasses)}>
                {chunks}
              </Button>
            )
          }}
        />
      </Typography>
    </BundleDealBlock>
  );
}
