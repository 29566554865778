import { trackGaEvent } from '../helpers';

export function flowEnd() {
  trackGaEvent({
    event: 'pupillaryDistanceToolInteraction',
    type: 'flow end'
  });
}

export function flowStart() {
  trackGaEvent({
    event: 'pupillaryDistanceToolInteraction',
    type: 'flow start'
  });
}

export function flowStateUpdate(type: string) {
  trackGaEvent({
    event: 'pupillaryDistanceToolStateUpdate',
    type
  });
}
