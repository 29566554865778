import React, { useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import messages from '../../messages';
import { ServicesContext } from 'services/context';

export default function OpeningHours() {
  const { locale } = useContext(ServicesContext);

  return (
    <>
      <FormattedMessage {...messages.cxOpeningHours} /> {locale.country === 'US' && '(CET)'}
    </>
  );
}
