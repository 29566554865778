import React, { ComponentProps } from 'react';
import { Modal as PhotonModal } from '@aceandtate/ds';

import * as Styles from './styles';

export type ModalProps = Omit<ComponentProps<typeof PhotonModal>, 'content' | 'image'> & {
  children: React.ReactNode;
};

const Modal = (props: ModalProps) => {
  const { children, ...modalProps } = props;
  return (
    <PhotonModal {...modalProps}>
      <Styles.Content>{children}</Styles.Content>
    </PhotonModal>
  );
};

Modal.Buttons = Styles.Buttons;
Modal.Title = Styles.Title;
Modal.Body = Styles.Body;
Modal.Error = Styles.ErrorStyle;

export default Modal;
