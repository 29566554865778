import React, { ComponentProps, forwardRef, HTMLAttributes, ReactNode } from 'react';
import * as Styles from './styles';

type Props = {
  children: ReactNode;
  withCheck?: boolean;
  parentProps?: HTMLAttributes<HTMLDivElement>;
} & ComponentProps<typeof Styles.HiddenRadioInput>;

const ImageRadioButton = forwardRef<HTMLInputElement, Props>(
  ({ children, parentProps, style, className, withCheck = false, ...inputProps }: Props, ref) => {
    return (
      <Styles.RadioWrapper isDisabled={inputProps.disabled} style={style} className={className} {...parentProps}>
        <Styles.HiddenRadioInput {...inputProps} ref={ref} type='radio' />
        <Styles.ImageWrapper>{children}</Styles.ImageWrapper>
        {withCheck && (
          <Styles.CheckWrapper>
            <Styles.CheckIcon color='accent' />
          </Styles.CheckWrapper>
        )}
        <Styles.RadioSelectedBorder />
      </Styles.RadioWrapper>
    );
  }
);

export default ImageRadioButton;
