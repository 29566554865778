import { Cart, Line, LineItemPromotion } from 'types/solidus';
import { WebStore } from 'types/webStore';
import { cloneDeep } from 'lodash';

export const TABS = {
  HTO: 1,
  REGULAR: 0
};

export const NON_PLANO_PRESCRIPTIONS = ['single_vision', 'multifocal'];

export function getActiveTab(cart: Cart) {
  const { hto_lines, regular_lines } = cart;

  return hto_lines.length > regular_lines.length ? TABS.HTO : TABS.REGULAR;
}

export function getCheckoutTotal(lines: Line[]) {
  return lines.map(l => l.price * l.quantity).reduce((a, b) => a + b, 0);
}

export function canAddCleaningKitToCart(regularLines: Line[]) {
  return (
    regularLines.length > 0 &&
    regularLines.some(product => product.product_type !== 'giftcard' && !product.variant.sku.includes('cleaning-kit'))
  );
}

export function canAddTheBoxToCart(regularLines: Line[]) {
  return regularLines.length > 0 && regularLines.every(product => product.variant.sku !== 'box');
}

export function hasOnlyContactLenses(lines: Line[]) {
  return lines.every(line => line.product_type === 'contact_lenses');
}

export function showUpsellProducts(regularLines: Line[]) {
  return canAddCleaningKitToCart(regularLines) || canAddTheBoxToCart(regularLines);
}

export const isPrescriptionType = (prescriptionType: string) =>
  ['single_vision', 'multifocal'].includes(prescriptionType);

export function getCartProductsPrescription(cart: Cart) {
  // Returns either null, 'single_vision' or 'multifocal', based on cart contents
  if (!cart || !cart.regular_lines || cart.regular_lines.length < 1) return null;
  const prescriptionProduct = cart.regular_lines.find(line =>
    ['single_vision', 'multifocal'].includes(line.prescription_type)
  );
  return prescriptionProduct ? prescriptionProduct.prescription_type : null;
}

export function getOrderPrescription(cart: Cart) {
  let orderPrescription = null;

  cart.regular_lines?.map((orderLine: Line) => {
    if (orderLine.line_item_prescription) {
      orderPrescription = orderLine.line_item_prescription;
    }
    return orderPrescription;
  });
  return orderPrescription;
}

export const AGGREGATED_SALE_PROMOTION_NAME = 'sale';
export const AGGREGATED_SALE_PROMOTION_LABEL = 'Sale';

export function isSalePromotionItem(lineItemPromotion: LineItemPromotion, webStore: WebStore) {
  return webStore.salePromotions.some(x => x.promotion_name === lineItemPromotion.promotion_name);
}

export function isSale(cart: Cart, webStore: WebStore) {
  const { salePromotions = [] } = webStore;

  const promotionNames = salePromotions.map(promotion => promotion.promotion_name);

  return (
    cart.promotion_adjustments.some(adjustment => promotionNames.includes(adjustment?.code)) ||
    cart.line_items_promotions.some(promotion => promotionNames.includes(promotion?.promotion_name))
  );
}

export function calculateRoundedPercentageDiscount(originalPrice: number, discountedPrice: number): number {
  const discount = ((originalPrice - discountedPrice) / originalPrice) * 100;

  return Math.floor(discount);
}

export function duplicateOrderLine(line: Line): Line {
  const clone = cloneDeep(line);

  delete clone.id;
  clone.quantity = 1;

  return clone;
}
