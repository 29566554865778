import styled from 'styled-components';
import { breakpointRules, grid } from 'styles';

export const VariantsWrapper = styled.div`
  flex: 2;

  > *:first-child {
    margin-bottom: ${grid.s};
  }

  > * {
    margin-bottom: ${grid.m};
  }
`;

export const ShowAllContainer = styled.div`
  margin-bottom: calc(${grid.xs} * 7);

  @media ${breakpointRules.largeMin} {
    margin-bottom: 0;
  }
`;
