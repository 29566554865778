import React from 'react';
import * as Styles from './styles';

export type ProgressBarProps = {
  duration?: string;
  width?: string;
};

export default function ProgressBar(props: ProgressBarProps) {
  const { duration, width } = props;

  return (
    <Styles.OuterBar width={width}>
      <Styles.InnerBar duration={duration} />
    </Styles.OuterBar>
  );
}
