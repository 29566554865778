import React, { useContext } from 'react';
import Modal from 'components/Modal';
import NotificationsContext from './NotificationsContext';
import { breakpointRules, Toast, useMediaQuery } from '@aceandtate/ds';

const DEFAULT_AUTO_HIDE_DURATION = 3500;

const defaultAction = () => <Toast.Close />;

export default function Notifications() {
  const { notification, onNotificationClosed, open } = useContext(NotificationsContext.Context);
  const isTablet = useMediaQuery(breakpointRules.tablet);
  if (!notification) {
    return null;
  }
  const notificationAction = notification.action || defaultAction;
  const autoHideDuration = notification.sticky ? undefined : notification.duration || DEFAULT_AUTO_HIDE_DURATION;
  const onClose = autoHideDuration ? notification.onClose || onNotificationClosed : undefined;
  return notification.isModal ? (
    <Modal open={open} onClose={onClose}>
      {notification.title && <Modal.Title>{notification.title}</Modal.Title>}
      <Modal.Body>{notification.children}</Modal.Body>
      {notification.action && <Modal.Buttons>{notification.action(onNotificationClosed)}</Modal.Buttons>}
    </Modal>
  ) : (
    <>
      <Toast.Viewport position={isTablet ? 'top-right' : 'bottom-right'} />
      <Toast.Root
        open={open}
        onOpenChange={isOpen => {
          !isOpen && onNotificationClosed();
        }}
        variant={notification.variant}
        duration={autoHideDuration}
      >
        <Toast.Title>{notification.title}</Toast.Title>
        <Toast.Description>{notification.children}</Toast.Description>
        {notificationAction(onNotificationClosed)}
      </Toast.Root>
    </>
  );
}
