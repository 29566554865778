import { defineMessages } from 'react-intl';

export default defineMessages({
  actionOnAddedTo: {
    defaultMessage: 'View',
    id: 'wishlist.actionOnAddedTo'
  },
  actionOnRemovedFrom: {
    defaultMessage: 'Undo',
    id: 'wishlist.actionOnRemovedFrom'
  },
  addToWishlist: {
    defaultMessage: 'Add to wishlist',
    id: 'wishlist.addToWishlist'
  },
  removeFromWishlist: {
    defaultMessage: 'Remove from wishlist',
    id: 'wishlist.removeFromWishlist'
  },
  wishlistAddedTo: {
    defaultMessage: '{displayName} added to your wishlist',
    id: 'wishlist.wishlistAddedTo'
  },
  wishlistAllAdded: {
    defaultMessage: 'all the items were added to your wishlist',
    id: 'wishlist.allAdded'
  },
  wishlistRemovedFrom: {
    defaultMessage: '{displayName} removed from your wishlist',
    id: 'wishlist.wishlistRemovedFrom'
  }
});
