import { defineMessages } from 'react-intl';

const messages = defineMessages({
  categoryTitle: {
    defaultMessage: 'Category',
    id: 'transcend.categoryTitle'
  },
  cookieTitle: {
    defaultMessage: 'Cookies',
    id: 'transcend.cookieTitle'
  },
  dataFlowTitle: {
    defaultMessage: 'Data flows',
    id: 'transcend.dataFlowTitle'
  }
});

export default messages;
