import { defineMessages } from 'react-intl';

export default defineMessages({
  description: {
    defaultMessage:
      "New Summer '23 arrivals, exciting collabs and surprising drops. Sign up to our newsletter to be the first to know about all that newness coming your way.",
    id: 'newsletter.description'
  },
  emailAddress: {
    defaultMessage: 'Email',
    id: 'form.emailAddress'
  },
  emailError: {
    defaultMessage: 'Please fill in a valid email address',
    id: 'newsletter.form.invalidEmail'
  },
  emailPlaceholder: {
    defaultMessage: 'Enter your email address',
    id: 'newsletter.form.emailPlaceholder'
  },
  errorMessage: {
    defaultMessage: 'An unknown error occurred please try again later',
    id: 'newsletter.errorMessage'
  },
  female: {
    defaultMessage: 'female',
    id: 'form.gender.female'
  },
  joinTheListForUpdates: {
    defaultMessage: 'Join the list for Ace & Tate updates',
    id: 'productStock.oosModalCheckboxLabel'
  },
  male: {
    defaultMessage: 'male',
    id: 'form.gender.male'
  },
  men: {
    defaultMessage: 'men',
    id: 'newsletter.action.men'
  },
  neutral: {
    defaultMessage: 'neutral',
    id: 'form.gender.neutral'
  },
  signMeUpForNewsletter: {
    defaultMessage: 'Sign me up for Ace & Tate updates.',
    id: 'newsletter.signMeUpForNewsletter'
  },
  signUp: {
    defaultMessage: 'sign up',
    id: 'newsletterModal.subscribe'
  },
  submit: {
    defaultMessage: 'submit',
    id: 'action.submit'
  },
  subscribe: {
    defaultMessage: 'Subscribe',
    id: 'action.subscribe'
  },
  subscribeSuccessAccountSection: {
    defaultMessage: 'You have successfully subscribed to our list. Welcome to the party!',
    id: 'newsletter.profile.subscribeSuccessAccountSection'
  },
  subscribeToNewsletter: {
    defaultMessage: 'Subscribe to our newsletter and be the first to know about all things Ace & Tate',
    id: 'newsletter.subscribeToNewsletter'
  },
  successMessage: {
    defaultMessage: 'Welcome to the party!',
    id: 'newsletter.succesMessage'
  },
  title: {
    defaultMessage: 'Good stuff coming your way!',
    id: 'newsletter.title'
  },
  unsubscribe: {
    defaultMessage: 'Unsubscribe',
    id: 'newsletter.profile.unsubscribe'
  },
  unsubscribeSuccessAccountSection: {
    defaultMessage:
      'You have successfully unsubscribed from our list. If you get FOMO and want to come back to the party, we’re always here for a second chance.',
    id: 'newsletter.profile.unsubscribeSuccessAccountSection'
  },
  women: {
    defaultMessage: 'women',
    id: 'newsletter.action.women'
  },
  yourEmail: {
    defaultMessage: 'your@email.com',
    id: 'form.yourEmail'
  },
  yourOnTheList: {
    defaultMessage: "You're on the list!",
    id: 'form.yourOnTheList'
  }
});
