import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Typography } from '@aceandtate/ds';
import FooterInfoLinks from './partials/FooterInfoLinks';
import FooterPhoneLinkInfo from './partials/FooterPhoneLinkInfo';
import FooterOpeningHours from './partials/FooterOpeningHours/index';
import FooterEmail from './partials/FooterEmail';
import FooterLinks from './partials/FooterLinks';
import FooterSwitcher from './partials/FooterSwitcher';
import messages from './messages';
import { FooterData } from './types';
import bcorp from './image/bcorp.svg';
import brandLogo from './image/brand-logo.svg';
import TrustpilotWidget from './partials/TrustpilotWidget';
import FooterWhatsappInfo from './partials/FooterWhatsappInfo';
import Image from 'next/image';
import { WebStore } from 'types/webStore';
import irisConfig from 'iris.config';
import Visibility from 'components/Visibility';
import * as Styles from './styles';

interface FooterProps {
  footer: FooterData;
  webStore: WebStore;
}

export default function Footer(props: FooterProps): JSX.Element {
  const {
    footer,
    webStore: {
      contact: { phone, email, whatsapp }
    }
  } = props;

  return (
    <>
      {irisConfig.trustpilotEnabled && <TrustpilotWidget />}
      <Styles.FooterWrapper data-cs-capture>
        <Styles.FooterContainer>
          <Styles.InfoColumn>
            <Typography variant='h3' gutterBottom={12}>
              <FormattedMessage {...messages['footer.wereHereToHelp']} />
            </Typography>
            <Styles.ContactDetails>
              <FooterOpeningHours />
              <FooterPhoneLinkInfo phone={phone} />
              <FooterWhatsappInfo whatsapp={whatsapp} />
              <FooterEmail email={email} />
            </Styles.ContactDetails>
          </Styles.InfoColumn>
          <FooterLinks links={footer.linksCollection.items} />
        </Styles.FooterContainer>

        <Visibility visibleOn={['laptop', 'desktop']}>
          <Styles.ContactContainer>
            <Styles.InfoGroup>
              <Styles.ContactWrapper>
                <Styles.BCorpImage>
                  <Image
                    loading='lazy'
                    width={90}
                    height={150}
                    src={bcorp}
                    alt='bcorp logo'
                    sizes='100vw'
                    style={{
                      width: '100%',
                      height: 'auto'
                    }}
                  />
                </Styles.BCorpImage>
              </Styles.ContactWrapper>
              <FooterInfoLinks />
              <FooterSwitcher />
            </Styles.InfoGroup>
          </Styles.ContactContainer>
        </Visibility>
        <Visibility visibleOn={['tablet', 'mobile']}>
          <Styles.ContactWrapper>
            <FooterSwitcher />
            <Styles.BCorpImage>
              <Image
                loading='lazy'
                width={90}
                height={150}
                src={bcorp}
                alt='bcorp logo'
                sizes='100vw'
                style={{
                  width: '100%',
                  height: 'auto'
                }}
              />
            </Styles.BCorpImage>
          </Styles.ContactWrapper>
          <FooterInfoLinks />
        </Visibility>

        <Styles.Hr />
        <Image
          loading='lazy'
          width={1392}
          height={224}
          src={brandLogo}
          alt='brand logo'
          sizes='100vw'
          style={{
            width: '100%',
            height: 'auto',
            objectFit: 'cover'
          }}
        />
      </Styles.FooterWrapper>
    </>
  );
}
