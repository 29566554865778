import { trackGaEvent } from '../helpers';
import { User } from 'types/solidus';

export function forgotPassword() {
  trackGaEvent({
    event: 'forgotPassword'
  });
}

export function loginSuccess(user: User) {
  const emarsysId = user && user.customer && user.customer.newsletter ? user.email_hash256 : undefined;

  trackGaEvent({
    emarsysId,

    event: 'loginSuccess',
    // send ID as provided by the backend
    hashM: user.email_hash256,
    // sha1 hash of the logged in user's email
    hashM256: user.email_hash256,

    loginCount: user.login_count,
    userId: user.id // Login counter. After the successful registration of new user, set this to 1. Later on increment by 1 on every successfull login
  });
}

export function registerSuccess(user: User) {
  const { id, email_hash, email_hash256, newsletterSubscribe } = user;

  trackGaEvent({
    emarsysId: newsletterSubscribe ? email_hash : undefined,

    event: 'registerSuccess',
    hashM: email_hash256,
    hashM256: email_hash256,

    userId: id,
    withNewsletter: newsletterSubscribe ? 'Yes' : 'No' // 'Yes'or send 'No'
  });
}
