import styled from 'styled-components';
import { brandColors, grid, breakpointRules } from '@aceandtate/ds';

export const EmailBlock = styled.div`
  align-self: flex-start;
  margin: 0 ${grid[24]} ${grid[40]} ${grid[24]};
`;

export const TextBlock = styled.div`
  @media ${breakpointRules.laptop} {
    max-width: 990px;
  }
`;

export const Container = styled.div`
  border-top: 1px solid ${brandColors.dark};
  background-color: ${brandColors.sandDark};
  color: ${brandColors.dark};
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  ${TextBlock} {
    margin: ${grid[40]} ${grid[24]} ${grid[24]} ${grid[24]};

    @media ${breakpointRules.laptop} {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
`;
