import styled from 'styled-components';
import { Typography } from '@aceandtate/ds';
import { breakpointRules, grid } from 'styles';
import Link from 'components/Link';

export const GlobalSearchWrapper = styled.div`
  padding: calc(${grid.xs} * 2) 0;

  @media ${breakpointRules.xlargeMin} {
    padding: calc(${grid.m} * 2) calc(${grid.xxl} + ${grid.xs} * 2);
  }
`;

export const ContentSectionTitle = styled(Typography)``;

export const RowLink = styled(Link)`
  display: flex;
`;

export const ImageContainer = styled.div`
  max-width: 110px;
  min-width: 110px;

  img {
    width: 100%;
  }
`;

export const TextContainer = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: ${grid.s};
`;
