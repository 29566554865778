import { uiColors, Button } from '@aceandtate/ds';
import messages from './messages';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import * as Styles from './styles';
import { useRouter } from 'next/router';

export default function DraftModeNotice() {
  const router = useRouter();

  const disableDraftModeLink = `/api/iris/draft-mode?disable=true&slug=${encodeURIComponent(router.asPath)}`;

  return (
    <Styles.DraftModeToast>
      <Styles.DraftModeToastContent fontWeight='regular' forwardedAs='span'>
        <FormattedMessage {...messages.draftModeEnabled} />

        <svg height='1em' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg'>
          <circle cx='12' cy='12' r='12' fill={uiColors.warning} />
        </svg>
      </Styles.DraftModeToastContent>
      <Styles.ButtonWrap>
        <Button href={disableDraftModeLink} variant='link'>
          <FormattedMessage {...messages.disableDraftMode} />
        </Button>
      </Styles.ButtonWrap>
    </Styles.DraftModeToast>
  );
}
