import styled, { css } from 'styled-components';
import { grid, brandColors, breakpointRules } from '@aceandtate/ds';

type SlideAnimationInnerScrollProps = {
  stepIndex: number;
};

export const TopIconsContainer = styled.div<SlideAnimationInnerScrollProps>`
  align-items: center;
  background-color: none;
  display: flex;
  justify-content: ${props => (props.stepIndex > 0 ? 'space-between' : 'flex-end')};
  position: relative;
  height: 0;
  top: ${grid[24]};
  width: 100%;
  z-index: 1;
  @media ${breakpointRules.desktop} {
    top: ${grid[40]};
  }
`;

type SliderProps = {
  active?: boolean;
  fullScreen?: boolean;
};

export const InnerModalContainer = styled.div<SliderProps>`
  height: 100%;
  ${props =>
    props.fullScreen &&
    css`
      height: 100vh;
      margin: auto;
      overflow: scroll;
      width: 768px;
      ::-webkit-scrollbar {
        display: none;
      }
    `}
`;

export const SliderContainer = styled.aside<SliderProps>`
  box-sizing: border-box;
  display: flex;
  flex-flow: column;
  height: calc(100vh - calc(100vh - 100%));
  max-width: 100vw;
  width: ${props => (props.fullScreen ? '100%' : '768px')};
`;

export const SlideHeader = styled.div`
  padding-bottom: ${grid[24]};
  padding-top: ${grid[40]};
  position: relative;
  text-align: center;
`;

export const Slide = styled.div`
  width: 100%;
  min-width: 100%;
  display: flex;
  flex-flow: column;
  overflow: auto;
  position: relative;
`;

export const SlideContent = styled.div<SliderProps>`
  box-sizing: border-box;
  margin-left: auto;
  margin-right: auto;
  opacity: ${props => (props.active ? 1 : 0)};
  padding: 0 ${grid[24]};
  width: calc(480px + ${grid[24]} * 2);
  max-width: 100%;
  transition: opacity 300ms ease-out;
  padding-bottom: ${grid[40]};
  :not(:last-child) {
    margin-bottom: ${grid[24]};
  }
  > ul {
    list-style: none;
    margin: 0;
    padding: 0;
    li {
      margin-bottom: ${grid[24]};
    }
  }
`;

export const BottomDrawer = styled.div`
  background: ${brandColors.light};
  border-top: 1px solid ${brandColors.dark10};
  box-sizing: border-box;
  margin-top: auto;
  padding: ${grid[24]};
  width: 100%;
`;

export const SlideAnimationWrapper = styled.div`
  height: 100%;
  overflow: hidden;
  width: 100%;
`;

export const SlideAnimationInnerScroll = styled.div<SlideAnimationInnerScrollProps>`
  display: flex;
  height: 100%;
  transition: transform 500ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  transform: ${props => `translateX(-${props.stepIndex * 100}%)`};
`;
