export const brandColors = {
  black: '#000',
  blue: '#2F26DD',
  gold: '#C68C2E',
  mint: '#ECF1E4',
  responsibility: '#58B388',
  soft: '#F1B194',
  white: '#FFF'
};

export const webColors = {
  alabaster: '#F8F6F3',
  blue: '#2F26DD',
  blueBright: '#2F26DD',
  cement: '#F3F3F4',
  dark: '#111111',
  disabled: '#929292',
  error: '#CC3333',
  highlightPrimaryDark: '#99ADD6',
  highlightPrimaryLight: '#E6E8F2',
  light: '#E8E8E8',
  medium: '#333333',
  sale: '#EC5D2A',
  sand: '#D7CDC3',
  softBlue: '#F2F5FA',
  success: '#58B388',
  veryLight: '#F8F8F8'
};

export const typographyColors = {
  disabled: '#B2B2B2',
  inverted: brandColors.white,
  primary: webColors.dark,
  secondary: webColors.medium
};

export const paletteColors = {
  disabled: webColors.disabled,
  hoverOutlinedPrimary: webColors.highlightPrimaryLight,
  hoverOutlinedSecondary: webColors.light,
  hoverPrimary: '#332F69',
  hoverSecondary: '#333333',
  primary: webColors.blue,
  secondary: webColors.dark
};
