/** as these are not mandatory fields in solidus admin, check it's use before relying on it */
export const PRODUCT_TAXONS = {
  FRAMES: 'category/frames',
  REGULAR: 'category/frames/regular',
  KIDS: 'category/frames/kids-glasses',
  SKI_GOGGLES: 'category/frames/ski-goggles',
  SPORT_GLASSES: 'category/frames/sport-glasses',
  NOT_RETURNABLE: 'feature/not-returnable',
  ARCHIVE_SALE: 'sale/archive-sale'
} as const;
