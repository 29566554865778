import React, { useContext, useState } from 'react';
import { Modal, Typography, grid, Button, breakpointRules } from '@aceandtate/ds';
import Link from 'next/link';
import { paths } from 'paths';
import { FormattedMessage } from 'react-intl';
import messages from './messages';
import styled from 'styled-components';
import { ServicesContext } from 'services/context';
import { Cart, Line } from 'types/solidus';
import Image from 'next/image';
import { usePathname } from 'next/navigation';
import { isArchiveSaleLine } from 'utils/cartLine';
import { Product } from 'types/torii';
import { RequireExactlyOne } from 'type-fest';
import { isArchiveSale } from 'utils/product/productTaxonomies';

type CheckFrameLimitOptions = {
  line: Line;
  product: Product;
};

type FrameLimitModalType = 'limit_reached' | 'archive_limit_reached' | null;

type CheckFrameLimit = (cart: Cart, options: RequireExactlyOne<CheckFrameLimitOptions, 'line' | 'product'>) => boolean;

type UseFrameLimitModalReturn = {
  FrameLimitModal: React.FC;
  checkFrameLimit: CheckFrameLimit;
  checkFrameLimitWithOrder: (cart: Cart, order: Cart) => boolean;
};

const contentMap = {
  limit_reached: {
    title: <FormattedMessage {...messages.frameLimitModalTitle} />,
    body: <FormattedMessage {...messages.frameLimitModalBody} />,
    image: (
      <Image
        src='/static/images/generic-glasses/multiple-glasses.jpg'
        sizes={`${breakpointRules.laptop} 50vw, 100vw`}
        alt='Multiple glasses on a table'
        fill
        objectFit='cover'
      />
    )
  },
  archive_limit_reached: {
    title: <FormattedMessage {...messages.frameLimitModalTitle} />,
    body: <FormattedMessage {...messages.archiveFrameLimitModalBody} />,
    image: (
      <Image
        src='/static/images/generic-glasses/multiple-glasses.jpg'
        sizes={`${breakpointRules.laptop} 50vw, 100vw`}
        alt='Multiple glasses on a table'
        fill
        objectFit='cover'
      />
    )
  }
} as const;

const StyledButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${grid[12]};
  @media ${breakpointRules.tablet} {
    flex-direction: row;
  }
`;

/**
 * Custom React hook to show the frame limit modal. You must call the checkFrameLimit before adding a frame to the cart,
 * if the limit and conditions are reached the modal will be shown. Note: This does not stop the add frame process, you should handle that based on the return value.
 *
 * @returns {UseFrameLimitModalReturn} An object containing the FrameLimitModal component and the checkFrameLimit function.
 *
 * @example
 * const { FrameLimitModal, checkFrameLimit } = useFrameLimitModal();
 */
const useFrameLimitModal = (): UseFrameLimitModalReturn => {
  const [frameLimitModalType, setFrameLimitModalType] = useState<FrameLimitModalType>(null);
  const pathname = usePathname();
  const { webStore } = useContext(ServicesContext);

  const checkFrameLimit: CheckFrameLimit = (cart, { line, product }) => {
    const productType = line ? line.product_type : product.productType;
    const isArchiveFrame = line ? isArchiveSaleLine(line) : isArchiveSale(product.currentVariant);

    const frameCount = cart?.regular_lines?.filter(line => line.product_type === 'frame').length || 0;
    const archiveFrameCount = cart?.regular_lines?.filter(line => isArchiveSaleLine(line)).length || 0;

    if (frameCount >= 4 && webStore.country.iso === 'DE' && productType === 'frame') {
      setFrameLimitModalType('limit_reached');
      return true;
    }

    if (archiveFrameCount >= 1 && isArchiveFrame) {
      setFrameLimitModalType('archive_limit_reached');
      return true;
    }

    return false;
  };

  const checkFrameLimitWithOrder = (cart: Cart, order: Cart) => {
    const frameCount = cart?.regular_lines?.filter(line => line.product_type === 'frame').length || 0;
    const orderframeCount = order?.regular_lines?.filter(line => line.product_type === 'frame').length || 0;
    if (frameCount + orderframeCount >= 4 && webStore.country.iso === 'DE') {
      setFrameLimitModalType('limit_reached');
      return true;
    }
    return false;
  };

  const onClose = () => {
    setFrameLimitModalType(null);
  };

  const FrameLimitModal = () =>
    !!frameLimitModalType && (
      <Modal
        image={contentMap[frameLimitModalType].image}
        open={!!frameLimitModalType}
        width='750px'
        onClose={onClose}
        content={
          <>
            <Typography variant='h3' as='div' gutterBottom>
              {contentMap[frameLimitModalType].title}
            </Typography>
            <Typography gutterBottom={24}>{contentMap[frameLimitModalType].body}</Typography>
            <StyledButtonContainer>
              {pathname === paths.cart ? (
                <Button as='span' onClick={onClose}>
                  <FormattedMessage {...messages.frameLimitModalPrimaryButton} />
                </Button>
              ) : (
                <Link href={paths.cart}>
                  <Button as='span' fullWidth>
                    <FormattedMessage {...messages.frameLimitModalPrimaryButton} />
                  </Button>
                </Link>
              )}
            </StyledButtonContainer>
          </>
        }
      />
    );

  return { FrameLimitModal, checkFrameLimit, checkFrameLimitWithOrder };
};

export default useFrameLimitModal;
