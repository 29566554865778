import { defineMessages } from 'react-intl';

export default defineMessages({
  colorNotInStockButton: {
    defaultMessage: 'Colour is not in stock - Notify me',
    id: 'productStock.color.notifyMe'
  },
  notAvailableInYourCountry: {
    defaultMessage:
      'Unfortunately some products in your cart are not available in your location. Therefore, we have removed the {products} from your cart.',
    id: 'cart.modal.itemsNotAvailableInCountry'
  },
  notAvailableNoReason: {
    defaultMessage:
      'Unfortunately some products in your cart are currently out of stock. Therefore, we have removed the {products} from your cart.',
    id: 'cart.modal.itemsNotAvailableNoReason'
  },
  notAvailableNoStock: {
    defaultMessage:
      'Unfortunately, some products have become unavailable. Therefore, we have removed the {products} from your cart.',
    id: 'cart.modal.itemsNotAvailableNoStock'
  },
  notifyMe: {
    defaultMessage: 'Notify me when its back online',
    id: 'productStock.notifyMe'
  },
  outOfStock: {
    defaultMessage: 'this product is currently out of stock.',
    id: 'cart.item.outOfStock'
  },
  removeItem: {
    defaultMessage: 'Remove',
    id: 'cart.removeItem'
  },
  stockChangeTitle: {
    defaultMessage: 'Your cart has changed',
    id: 'cart.modal.stockChangeTitle'
  }
});
