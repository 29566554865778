import { brandColors, Typography, uiColors } from '@aceandtate/ds';
import styled, { css } from 'styled-components';

export const CharacterCountWrapper = styled(Typography)<{ state: 'warn' | 'error' | 'valid' }>`
  ${({ state }) => {
    switch (state) {
      case 'warn':
        return css`
          color: ${uiColors.warning};
        `;
      case 'error':
        return css`
          color: ${uiColors.error};
        `;
      default:
        return css`
          color: ${brandColors.dark50};
        `;
    }
  }}
`;
