import { GENDER } from 'globalConstants';
import {
  CliponProduct,
  FamilyVariant,
  FrameProduct,
  GenericProduct,
  Product,
  ProductType,
  ProductVariant,
  Variant
} from 'types/torii';
import { IntlShape } from 'react-intl';
import { getImageAltForProduct, getImageTitleForProduct } from './productImages';
import { ProductImage } from 'types/images';
import { ColorProps } from 'types/colorSwatches';

export function isSkuSunny(sku: string) {
  return sku.endsWith('-sun') || sku.endsWith('-polarised');
}

export function isSunny(variant: Variant) {
  return variant?.filterOptions.types.includes('sunny');
}

export const variantAllowsPrescription = (variant: Variant) => {
  return (
    variant?.filterOptions.prescriptionOptions.includes('multifocal') ||
    variant?.filterOptions.prescriptionOptions.includes('single_vision')
  );
};

export function getSunnyOrOpticalVariants(product: Product) {
  return product.variants.filter(
    variant =>
      (isSunny(product.currentVariant) ? isSunny(variant) : !isSunny(variant)) && variant.visibility.includes('pdp')
  );
}

export function getSunnyOrOpticalVariantSkus(product: FrameProduct) {
  return product.variantSkus.filter(variantSku => {
    return isSunny(product.currentVariant)
      ? variantSku.endsWith('-sun') || variantSku.endsWith('-polarised')
      : !variantSku.endsWith('-sun') && !variantSku.endsWith('-polarised');
  });
}

// List of how the head images are prioritised
export const productImageMap = [
  {
    column: 0,
    gender: GENDER.MEN,
    id: 'model2',
    type: 'male'
  },
  {
    column: 1,
    gender: GENDER.WOMEN,
    id: 'model2',
    type: 'female'
  },
  {
    column: 0,
    gender: GENDER.UNISEX,
    id: 'unisex',
    type: 'unisex'
  },
  { column: 0, id: 'front', type: 'front' },
  { column: 1, id: 'flat', type: 'flat' },
  { column: 0, id: 'side', type: 'side' },
  { column: 1, id: 'dynamic', type: 'dynamic' },
  { column: 0, id: 'special', type: 'special' }
];

// returns true if image is just the product
export const isProductImage = image => ['front', 'flat', 'side'].includes(image.id);

const sortByGender = gender => (acc, next) => gender && next.gender === gender ? [next].concat(acc) : acc.concat(next);

/**
 * get src of each image, give it a correct column (if there are no head-shots, send front-image to first column)
 * @param  {Object} product
 * @return {Array<ProductImage>} images
 */
export const getProductImages = ({
  variant,
  gender,
  productType,
  intl
}: {
  intl: IntlShape;
  variant: Variant;
  gender;
  productType: ProductType;
}): Array<ProductImage> => {
  // Filter the image map to only include the config for images that
  // the product does include
  const imageMap = productImageMap.filter(image => variant.images[image.type]?.url);

  // Find out if we have any column that would be empty otherwise
  const allColumnsIncludeImages =
    imageMap.every(image => image.column === 0) && imageMap.every(image => image.column === 1);

  return imageMap
    .map((image, index) => {
      const srcId = variant.images[image.type];
      // we will alternate image display between the first column
      // and the second one when otherwise any of the columns would be empty
      return {
        ...image,
        alt: getImageAltForProduct({ intl, productType, variant }),
        column: allColumnsIncludeImages ? image.column : index % 2,
        isFrame: productType === 'frame' || productType === 'clip_on',
        src: srcId.url,
        title: getImageTitleForProduct(variant, intl),
        type: image.type
      };
    })
    .reduce(sortByGender(gender), []);
};

export function getProductColors(product: Product) {
  if (product.productType === 'contact_lenses') return [];

  return product.variants
    .filter(
      variant =>
        (isSunny(product.currentVariant) ? isSunny(variant) : !isSunny(variant)) && variant.visibility.includes('pdp')
    )
    .reduce((acc, variant) => {
      if (variant.displayAttributes.color) {
        acc.push({
          href: `/${variant.sku}`,
          id: variant.id,
          image: variant.images.colorSwatch.url.split('upload').join('upload/c_crop,h_20,w_20'),
          isAvailableOnline: variant.availability.isAvailableOnline,
          name: variant.displayAttributes.color,
          sku: variant.sku,
          visibility: variant.visibility
        });
      }
      return acc;
    }, [] as Array<ColorProps>);
}

const sizeWeight = {
  'Extra Large': 4,
  'Extra Small': 0,
  Large: 3,
  Medium: 2,
  Small: 1
};

export function getAvailableSizes(
  product: FrameProduct | GenericProduct | CliponProduct,
  currentVariant: ProductVariant
) {
  const currentSize =
    product.familyVariants.find((sizeMap: FamilyVariant) => sizeMap.sku === currentVariant.sku) ||
    ({} as FamilyVariant);

  function sortSizes(product: FrameProduct | GenericProduct | CliponProduct, currentSize: FamilyVariant) {
    return product.familyVariants
      .filter(sizeMap => (currentSize.isSunny ? sizeMap.isSunny : !sizeMap.isSunny))
      .sort((a, b) => (a.size === b.size ? -1 : 1))
      .sort(a => (a.color === currentSize.color ? -1 : 1));
  }

  const availableSizes = (sortedSizes: ReturnType<typeof sortSizes>, currentSize: FamilyVariant) =>
    sortedSizes
      .filter((sizeMap, index) => {
        return sortedSizes.map(sizeMap => sizeMap.size).indexOf(sizeMap.size) === index;
      })
      .filter((sizeMap: FamilyVariant) => sizeMap.size !== currentSize.size)
      .map((sizeMap: FamilyVariant) => {
        if (sizeWeight[sizeMap.size]) {
          sizeMap.weight = sizeWeight[sizeMap.size];
        }
        return sizeMap;
      })
      .sort((a: FamilyVariant, b: FamilyVariant) => a.weight - b.weight);

  return availableSizes(sortSizes(product, currentSize), currentSize);
}
