import { Toast } from '@aceandtate/ds';
import React, { createContext, useState, useRef, ReactNode } from 'react';

export type OnNotificationClosedCallback = (e?: unknown, reason?: string) => void;

export type NotificationsConsumersProps = {
  onNotificationClosed: OnNotificationClosedCallback;
  open: boolean;
  notification?: NotificationProps;
  addNotification: (notification: NotificationProps) => void;
};

const NotificationsContext = createContext<NotificationsConsumersProps>({
  addNotification: () => null,
  notification: undefined,
  onNotificationClosed: () => {
    return undefined;
  },
  open: false
});

export type NotificationProps = {
  children: React.ReactNode;
  name: string;
  duration?: number;
  action?: (callback: OnNotificationClosedCallback) => React.ReactNode;
  'data-testid'?: string;
  dismissType?: 'permanent' | 'temporary';
  onClose?: () => void;
  onExited?: () => void;
  sticky?: boolean;
  isModal?: boolean;
  title?: React.ReactNode;
  variant?: React.ComponentProps<typeof Toast.Root>['variant'];
};

const pickStorage = (notification: NotificationProps) => {
  if (!notification.dismissType) {
    return { get: () => {}, set: () => {} };
  }
  const storageName = notification.dismissType === 'permanent' ? 'localStorage' : 'sessionStorage';
  const storage = window[storageName];
  const key = `dismissed ${notification.name} notification`;
  return {
    get: () => {
      try {
        return storage.getItem(key);
      } catch (err) {
        return undefined;
      }
    },
    set: (value: string) => {
      try {
        return storage.setItem(key, value);
      } catch (err) {
        return undefined;
      }
    }
  };
};

function Provider({ children }: { children: ReactNode }) {
  const [currentNotification, setCurrentNotification] = useState(undefined);
  const [isOpen, setIsOpen] = useState(false);

  const notifications = useRef<NotificationProps[]>([]);

  function processQueue() {
    if (notifications.current.length > 0) {
      setCurrentNotification(notifications.current.shift());
      setIsOpen(true);
    }
  }

  function addNotification(notification: NotificationProps) {
    const storage = pickStorage(notification);

    if (storage.get() !== '1') {
      notifications.current.push(notification);
      if (isOpen) {
        setIsOpen(false);
      }
      processQueue();
    }
  }

  function onNotificationClosed(event: unknown, reason?: 'timeout' | 'clickaway') {
    if (reason === 'clickaway') {
      return;
    }

    if (currentNotification) {
      const storage = pickStorage(currentNotification);
      storage.set('1');
    }

    setIsOpen(false);
  }

  const value = {
    addNotification: addNotification,
    notification: currentNotification,
    notifications: notifications,
    onNotificationClosed: onNotificationClosed,
    open: isOpen
  };

  return <NotificationsContext.Provider value={value}>{children}</NotificationsContext.Provider>;
}

export default {
  Consumer: NotificationsContext.Consumer,
  Context: NotificationsContext,
  Provider: Provider
};
