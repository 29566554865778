import React from 'react';
import Image from 'next/image';
import { useMediaQuery, breakpointRules } from '@aceandtate/ds';

// import { breakpointRules } from 'styles';
import AddSelector from 'components/Configurators/ConfiguratorOption/AddSelector';
import QuantitySelector from 'components/QuantitySelector';
import * as Styles from './styles';

type ConfiguratorOptionProps = {
  description?: React.ReactNode;
  title: React.ReactNode;
  counter?: number;
  'data-testid'?: string;
  disabled?: boolean;
  focusContent?: React.ReactNode;
  imageCrop?: string;
  imageSrc?: string;
  infoLabel?: React.ReactNode;
  onClick?: () => void;
  selectorType?: 'single' | 'double';
  selected?: boolean;
  className?: string;
  alt?: string;
};

export default function ConfiguratorOption(props: ConfiguratorOptionProps) {
  const {
    title,
    description,
    infoLabel,
    selected,
    disabled,
    onClick,
    selectorType,
    counter,
    focusContent,
    imageSrc,
    imageCrop,
    alt = 'Ace & Tate | Product image',
    'data-testid': dataTestId = null,
    className
  } = props;

  const isMobile = !useMediaQuery(breakpointRules.tablet);

  const objectPositionMobile = imageCrop || '0 50%';
  const objectPositionDesktop = imageCrop ? 'bottom' : 'inherit';

  return (
    <Styles.Wrapper
      withImage={!!imageSrc}
      selected={selected}
      onClick={onClick}
      data-testid={dataTestId}
      className={className}
      disabled={disabled}
    >
      {imageSrc && (
        <Styles.ImageWrapper>
          <div
            style={{
              position: 'relative',
              width: '100%',
              paddingBottom: isMobile ? '60%' : '100%'
            }}
          >
            <Image
              src={imageSrc}
              unoptimized
              objectFit={isMobile && imageCrop ? 'none' : 'cover'}
              objectPosition={isMobile ? objectPositionMobile : objectPositionDesktop}
              fill
              alt={alt}
            />
          </div>
        </Styles.ImageWrapper>
      )}
      <Styles.Content hasDescription={!!description} withImage={!!imageSrc}>
        <Styles.HeadingRow hasDescription={!!description} withImage={!!imageSrc}>
          <Styles.Title variant='h5' disabled={disabled}>
            {title}
          </Styles.Title>
          {infoLabel && (
            <Styles.InfoLabel variant='h5' disabled={disabled} withImage={!!imageSrc}>
              {infoLabel}
            </Styles.InfoLabel>
          )}
        </Styles.HeadingRow>
        {description && (
          <Styles.DescriptionWrapper hasSelector={!!selectorType}>
            <Styles.Text variant='bodyM' disabled={disabled}>
              {description}
            </Styles.Text>
          </Styles.DescriptionWrapper>
        )}
      </Styles.Content>
      {selected && focusContent && <Styles.FocusContentContainer>{focusContent}</Styles.FocusContentContainer>}
      {selectorType === 'single' && (
        <Styles.SelectorPosition position={imageSrc && isMobile ? 'relative' : 'absolute'}>
          <AddSelector
            preventDefaultClick // we already call onClick in the wrapper
            selected={selected}
            onClick={onClick}
          />
        </Styles.SelectorPosition>
      )}
      {selectorType === 'double' && (
        <Styles.SelectorPosition position={imageSrc && isMobile ? 'relative' : 'absolute'}>
          <QuantitySelector selected={selected} onClick={onClick} counter={counter} />
        </Styles.SelectorPosition>
      )}
    </Styles.Wrapper>
  );
}
