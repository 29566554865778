import React from 'react';
import Image from 'next/image';
import * as Styles from '../styles';
import { Typography } from '@aceandtate/ds';
import { FormattedMessage } from 'react-intl';
import messages from '../messages';

export default function PolarisedHint() {
  return (
    <Styles.FeatureContainer>
      <Typography variant='h5' gutterBottom={20}>
        <FormattedMessage {...messages.polarisedTitle} />
      </Typography>
      <Styles.FeatureImage>
        <Image
          src='/static/images/configurator/polarised.svg'
          fill
          sizes='100vw'
          style={{
            objectPosition: 'center'
          }}
          alt='Ace & Tate | Illustration of Polarised Coating Add-On'
        />
      </Styles.FeatureImage>
      <Typography gutterBottom={8}>
        <FormattedMessage {...messages.polarisedDescription} />
      </Typography>
    </Styles.FeatureContainer>
  );
}
