import React from 'react';
import { Typography } from '@aceandtate/ds';

// TODO box sizes are currently not used
export default function BoxSize() {
  return (
    <>
      <Typography variant='h6'>Placeholder</Typography>
    </>
  );
}
