import { Menu } from '../types';

export default function getMenuBlocksForPath(menuBlocks: Menu[], currentPath: string) {
  return menuBlocks.filter(({ displayOnPaths }) => {
    if (!displayOnPaths) return true;
    const inclusionPaths = displayOnPaths.filter(path => path.startsWith('/'));
    const exclusionPaths = displayOnPaths.filter(path => path.startsWith('!')).map(path => path.slice(1));

    const allowedOnPath = !inclusionPaths.length || (inclusionPaths as string[]).includes(currentPath);
    const disallowedOnPath = (exclusionPaths as string[]).includes(currentPath);

    return allowedOnPath && !disallowedOnPath;
  });
}
