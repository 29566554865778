import React, { useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import FormattedPrice from 'components/DisplayLabels/FormattedPrice';
import { ServicesContext } from 'services/context';
import messages from './messages';
import { Typography } from '@aceandtate/ds';

type Props = {
  link: React.ReactNode | string;
  hasFreeReturns: boolean;
  hasFreeShipping: boolean;
  isoCode?: string | null;
  showReturnPolicy?: boolean;
};

export default function ShippingInfo(props: Props) {
  const { link, showReturnPolicy, isoCode, hasFreeReturns, hasFreeShipping } = props;

  const ctx = useContext(ServicesContext);
  const { webStore } = ctx;
  const shippingMinimum = (
    <FormattedPrice currency={webStore.config.currency} price={webStore.shipping.free_shipping_threshold} />
  );

  if (!isoCode) {
    return (
      <Typography variant='bodyM'>
        <FormattedMessage
          {...messages.pleaseSelectACountry}
          values={{
            link: <>{link}</>
          }}
        />
      </Typography>
    );
  }

  return (
    <Typography variant='bodyM'>
      {hasFreeShipping ? (
        <FormattedMessage {...messages.freeShippingToLink} values={{ link }} />
      ) : (
        <FormattedMessage
          {...messages.freeShippingMinimumLink}
          values={{
            link,
            shippingMinimum
          }}
        />
      )}
      <br />
      {showReturnPolicy && hasFreeReturns && <FormattedMessage {...messages.returnPolicyFreeReturns} />}
      {showReturnPolicy && !hasFreeReturns && <FormattedMessage {...messages.returnPolicy} />}
    </Typography>
  );
}
