import React from 'react';
import styled from 'styled-components';

import { zIndexMasterList, breakpointRules } from 'styles';
import { brandColors } from '@aceandtate/ds';

type DropProps = {
  mobileOnly?: boolean;
  zIndex?: number;
  top: number;
};

const Drop = styled.div<DropProps>`
  background: ${brandColors.dark};
  bottom: 0;
  left: 0;
  opacity: 0.4;
  position: fixed;
  right: 0;
  top: ${props => props.top}px;
  transition: background 0.25s ease-in-out;
  z-index: ${props => (props.zIndex ? props.zIndex : zIndexMasterList.backDrop)};

  @media ${breakpointRules.largeMin} {
    display: ${props => (props.mobileOnly ? 'none' : 'block')};
  }
`;

type Props = {
  isOpen: boolean;
  onClick: () => void;
  className?: string;
  mobileOnly?: boolean;
  zIndex?: number;
  top: number;
};

const Backdrop = (props: Props) => {
  const { isOpen, className, onClick, mobileOnly, zIndex, top } = props;

  return (
    <>
      {!!isOpen && (
        <Drop top={top} zIndex={zIndex} className={className} mobileOnly={mobileOnly} onClick={() => onClick()} />
      )}
    </>
  );
};

export default Backdrop;
