import React from 'react';
import {
  IconAccount,
  IconCalendar,
  IconChevronRight,
  IconEmail,
  IconEye,
  IconLogout,
  IconShoppingBag,
  Typography,
  grid
} from '@aceandtate/ds';
import Link from 'next/link';
import styled from 'styled-components';
import { paths } from 'paths';
import ProtectedRoute from 'blocks/ProtectedRoute';
import { FormattedMessage } from 'react-intl';
import messages from 'views/ProfileView/messages';
import authenticationMessages from 'messages/authentication';
import { AuthenticationFormV2State } from 'components/AuthenticationFormV2';

const StyledNav = styled.nav`
  ul {
    list-style: none;
    padding: 0;
  }
  li {
    margin-bottom: ${grid[32]};
    :last-child {
      margin-top: 64px;
    }
    a,
    button {
      padding: 0;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    span {
      display: flex;
      align-items: center;
      gap: 8px;
    }
  }
`;

type ProfileMenuProps = {
  onClickLink?: () => void;
  onFormChange?: React.Dispatch<React.SetStateAction<AuthenticationFormV2State>>;
};

const ProfileMenu = ({ onClickLink, onFormChange }: ProfileMenuProps) => {
  return (
    <ProtectedRoute isDrawer={true}>
      {({ logout, userProfile }) => (
        <>
          <Typography variant='h3' data-cs-mask>
            <FormattedMessage {...messages.menuTitle} values={{ name: userProfile?.customer?.firstname }} />
          </Typography>
          <Typography>
            <FormattedMessage {...messages.menuSubtitle} />
          </Typography>
          <StyledNav>
            <ul>
              <li>
                <Link href={paths.userOrders} shallow onClick={onClickLink}>
                  <span>
                    <IconAccount /> <FormattedMessage {...messages.menuOrders} />
                  </span>
                  <IconChevronRight />
                </Link>
              </li>

              <li>
                <Link href={paths.userAccountInformation} shallow onClick={onClickLink}>
                  <span>
                    <IconShoppingBag /> <FormattedMessage {...messages.menuAccountInfo} />
                  </span>
                  <IconChevronRight />
                </Link>
              </li>
              <li>
                <Link href={paths.userPrescriptions} shallow onClick={onClickLink}>
                  <span>
                    <IconEye /> <FormattedMessage {...messages.menuPrescriptions} />
                  </span>
                  <IconChevronRight />
                </Link>
              </li>
              <li>
                <Link href={paths.userAppointments} shallow onClick={onClickLink}>
                  <span>
                    <IconCalendar /> <FormattedMessage {...messages.menuAppointments} />
                  </span>
                  <IconChevronRight />
                </Link>
              </li>

              <li>
                <Link href={paths.userSubscriptions} shallow onClick={onClickLink}>
                  <span>
                    <IconEmail /> <FormattedMessage {...messages.menuSubscriptions} />
                  </span>
                  <IconChevronRight />
                </Link>
              </li>
              <li>
                <button
                  onClick={() => {
                    onClickLink();
                    window.setTimeout(() => {
                      onFormChange('email');
                      logout();
                    }, 250);
                  }}
                >
                  <span>
                    <IconLogout /> <FormattedMessage {...authenticationMessages.logout} />
                  </span>
                  <IconChevronRight />
                </button>
              </li>
            </ul>
          </StyledNav>
        </>
      )}
    </ProtectedRoute>
  );
};

export default ProfileMenu;
