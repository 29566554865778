import React, { useState, useEffect } from 'react';
import Link from 'next/link';
import { headerInteraction } from 'tracking';
import { usePath } from 'paths';
import TextColumn from '../TextColumn';
import MediaColumn from '../MediaColumn';
import { useRouter } from 'next/router';
import * as Styles from './styles';
import * as MenuStyles from '../../styles';
import * as Types from '../../types';

type Props = {
  menu: Types.Menu;
  firstMobileSubtitle?: Types.Menu;
  isInactive?: boolean;
  isMobile?: boolean;
  isMobileSubmenuOpen?: boolean;
  toggleMobileMenuCallback?: () => void;
  toggleDesktopMenuCallback?: (openState: boolean) => void;
  top?: number;
};

export default function Submenu(props: Props) {
  const [isSubmenuOpen, setIsSubmenuOpen] = useState(false);
  const {
    menu,
    isInactive,
    top,
    isMobile,
    isMobileSubmenuOpen,
    firstMobileSubtitle,
    toggleMobileMenuCallback,
    toggleDesktopMenuCallback
  } = props;

  const router = useRouter();
  const pathTo = usePath();

  useEffect(() => {
    if (!props.isMobile) {
      window.addEventListener('scroll', closeSubmenu, {
        passive: true
      });
    }

    router.events.on('routeChangeComplete', closeSubmenu);

    return () => {
      window.removeEventListener('scroll', closeSubmenu);
      router.events.off('routeChangeComplete', closeSubmenu);
    };
  }, []);

  useEffect(() => {
    if (isMobile && !isMobileSubmenuOpen) {
      setIsSubmenuOpen(false);
    }
  }, [isMobileSubmenuOpen]);

  const openSubmenu = () => {
    setIsSubmenuOpen(true);
    if (toggleMobileMenuCallback) {
      toggleMobileMenuCallback();
    } else if (toggleDesktopMenuCallback) {
      toggleDesktopMenuCallback(true);
    }
  };

  const closeSubmenu = () => {
    setIsSubmenuOpen(false);
    if (toggleDesktopMenuCallback) {
      toggleDesktopMenuCallback(false);
    }
  };

  const submenuColumns = menu.menuCollection.items;
  const simpleLink = !menu.menuCollection.items || menu.menuCollection.items.length === 0;
  const menuPath = menu.urlRoute?.path || menu.path;
  const clickable = simpleLink || !!menuPath;

  // external urls rendered on menu require a full page reload
  // stores are currently hosted on yext
  const isExternal = menuPath.includes('/stores');

  const MobileLinkComponent = simpleLink && !isExternal ? Link : 'a';

  return (
    <>
      {isMobile ? (
        <Styles.MobileLayout isFirstMobileSubtitle={menu === firstMobileSubtitle}>
          <MenuStyles.TopMenuContainer
            $styleProps={{
              color: menu.color
            }}
            isActive={isSubmenuOpen}
            displayOn={menu.displayOn}
            isMobile
          >
            <MobileLinkComponent
              href={simpleLink ? menuPath : null}
              onClick={
                simpleLink
                  ? () =>
                      headerInteraction.level1({
                        navigationL1: `${menu.datalayerId}`
                      })
                  : openSubmenu
              }
              {...(isExternal ? { href: pathTo(menuPath) } : {})}
              data-testid={`menuNav.topMenuItem.mobile.${menu.text}`}
            >
              <MenuStyles.TopMenuItem
                variant={!(menu.displayOn === 'mobile') ? 'h4' : 'bodyL'}
                $styleProps={{
                  displayOn: menu.displayOn,
                  isFirstMobileSubtitle: menu === firstMobileSubtitle,
                  isInactive,
                  isMobileSubmenuOpen,
                  isSubmenuOpen
                }}
              >
                {menu.text}
              </MenuStyles.TopMenuItem>
            </MobileLinkComponent>
            {isSubmenuOpen && (
              <Styles.SubmenuContainer top={top} isMobileSubmenuOpen={isMobileSubmenuOpen}>
                {submenuColumns &&
                  submenuColumns.map((column, index) =>
                    column.media ? null : (
                      <TextColumn
                        key={index}
                        isMobile={isMobile}
                        title={column.text}
                        list={column.menuCollection.items}
                        datalayerL1={menu.datalayerId}
                      />
                    )
                  )}
              </Styles.SubmenuContainer>
            )}
          </MenuStyles.TopMenuContainer>
        </Styles.MobileLayout>
      ) : (
        <Styles.DesktopLayout>
          <MenuStyles.TopMenuContainer
            $styleProps={{
              color: menu.color
            }}
            onMouseEnter={openSubmenu}
            onMouseLeave={closeSubmenu}
            isActive={isSubmenuOpen}
            displayOn={menu.displayOn}
          >
            <Link
              href={menuPath}
              prefetch={false}
              data-testid={`menuNav.topMenuItem.desktop.${menu.text}`}
              onClick={() =>
                headerInteraction.level1({
                  navigationL1: `${menu.datalayerId}`
                })
              }
            >
              <MenuStyles.TopMenuItem
                variant='h6'
                $styleProps={{
                  displayOn: menu.displayOn,
                  isInactive,
                  isSubmenuOpen
                }}
                $isClickable={clickable}
              >
                {menu.text}
              </MenuStyles.TopMenuItem>
            </Link>
            {isSubmenuOpen && (
              <Styles.SubmenuContainer top={top}>
                {submenuColumns &&
                  submenuColumns.map((column, index) =>
                    column.media ? (
                      <MediaColumn key={index} menu={column} datalayerL1={menu.datalayerId} />
                    ) : (
                      <TextColumn
                        key={index}
                        isMobile={isMobile}
                        title={column.text}
                        list={column.menuCollection.items}
                        datalayerL1={menu.datalayerId}
                      />
                    )
                  )}
              </Styles.SubmenuContainer>
            )}
          </MenuStyles.TopMenuContainer>
        </Styles.DesktopLayout>
      )}
    </>
  );
}
