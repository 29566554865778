import styled from 'styled-components';
import { grid, Typography, brandColors, borderRadius, uiColors, InputControl, breakpointRules } from '@aceandtate/ds';

export const ProductHighlightBlock = styled.div`
  text-align: center;
  padding: ${grid[8]} ${grid[12]};
  background-color: ${uiColors.warningLight};
  border-radius: ${borderRadius.medium};
`;

export const GiftcardSection = styled.div`
  margin-top: ${grid[24]};
`;

export const GiftcardDrawerInset = styled.div`
  margin: ${grid[40]} ${grid[24]};

  @media ${breakpointRules.tablet} {
    margin: ${grid[40]} ${grid[60]};
  }
`;

export const InputControlDense = styled(InputControl)`
  margin-bottom: ${grid[8]};
`;

export const InputControlCaption = styled(Typography).attrs({ gutterBottom: true, variant: 'bodyXS' })`
  color: ${brandColors.dark50};
`;
